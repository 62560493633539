import React, { useState } from "react";

import { EditorState } from "draft-js";
import { findTasksFromBucket } from "utils/findItem";

import SingleTaskComponent from "./SingleTaskComponent";

const CustomTasksContainerComponent = (props) => {
  const [isEmpty, setIsEmpty] = useState(true);

  const entityKey = props.block.getEntityAt(0);
  const entityInstance = props.contentState.getEntity(entityKey);
  const { bucketBlockId } = entityInstance.getData();
  const { tasks } = findTasksFromBucket(
    bucketBlockId,
    props.blockProps.projectItems
  );

  const featureItemId = props.blockProps.projectItems.find(
    (item) => item.blockId === bucketBlockId
  )?.id;

  const projectId = props.activeProjectId;

  const bucketLevel = props.blockProps.hierarchyState[bucketBlockId]?.level;
  const bucketLevelWords =
    props.blockProps.hierarchyState[bucketBlockId]?.type.split("-")[1];

  const emptyTasks = [0];

  const editorStateForceSelection = () => {
    const editorState = props.blockProps.editorState;
    setTimeout(() => {
      props.blockProps.setEditorState(
        EditorState.forceSelection(editorState, editorState.getSelection())
      );
    }, 100);
  };

  return (
    <>
      <figure>
        <p className="text-charcoalBlack font-bold mb-2">Task List</p>
        <div className="flex flex-col items-center gap-2 ">
          {tasks &&
            tasks.map((task) => (
              <SingleTaskComponent
                {...task}
                dispatch={props.blockProps.dispatch}
                bucketBlockId={bucketBlockId}
                onFocus={() => props.blockProps.onFocus()}
                onBlur={() => props.blockProps.onBlur()}
                editorStateForceSelection={editorStateForceSelection}
              />
            ))}
          {emptyTasks.map((task) => (
            <SingleTaskComponent
              sandboxId="sandboxTask"
              dispatch={props.blockProps.dispatch}
              {...props}
              bucketBlockId={bucketBlockId}
              onFocus={() => props.blockProps.onFocus()}
              onBlur={() => props.blockProps.onBlur()}
              setIsEmpty={() => setIsEmpty(false)}
              isEmpty
              emptyTasks={emptyTasks}
              featureItemId={featureItemId}
              projectId={projectId}
              editorStateForceSelection={editorStateForceSelection}
            />
          ))}
        </div>
      </figure>
    </>
  );
};

export default CustomTasksContainerComponent;
