import React, { useEffect } from "react";

import { useAbility } from "@casl/react";
import amplitude from "amplitude-js";
import { ReactComponent as EmptyFileIcon } from "assets/empty_file.svg";
import { ReactComponent as CreateIcon } from "assets/plus-icon.svg";
import { ReactComponent as ProjectIcon } from "assets/project-icon.svg";
import { MODAL_NAMES } from "components/Modal/Modal";
import { AbilityContext, Can } from "components/RoleManagement/Can";
import { useTrackedState, useDispatch, ACTIONS } from "context";
import { format } from "date-fns";
import get from "lodash.get";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { parseDate } from "utils/date";
import { getStatusId } from "utils/status";
import Loader from "components/Loader";
import { getUserData, getProjectData, getBasicProjectsData } from "../../api";
import { STATUSES_SLUGS } from "../../constants";
import { auth } from "../../firebaseSetup";
import EmptyDashboard from "./EmptyDashboard";
import PriorityIndicator from "./PriorityIndicator";
import SprintBadge from "./SprintBadge";
import StatusIndicator from "./StatusIndicator";

const DashboardRestyle = () => {
  const [user, initialising, error] = useAuthState(auth);
  const [userProjects, setUserProjects] = React.useState([]);
  const [userItems, setUserItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [taskId, setTaskId] = React.useState(null);
  const ability = useAbility(AbilityContext);

  const dispatch = useDispatch();
  const state = useTrackedState();
  const currentUserId = get(auth, "currentUser.uid", false);

  function setUserData({ userProjectsData, userItemsData }) {
    setUserProjects(userProjectsData);
    setUserItems(userItemsData);
  }

  useEffect(() => {
    amplitude.getInstance().logEvent("dashboard view");
  }, []);

  useEffect(() => {
    // User not set while firestore library is initialising
    if (!user) return;
    getUserData(user.uid, setUserData, setLoading);
  }, [user]);

  useEffect(() => {
    const newUserItems = [
      ...state.projectItems.filter(
        (pItem) =>
          userProjects.map((uProj) => uProj.id).includes(pItem.projectId) &&
          pItem.assignee?.id === user.uid
      ),
      ...userItems,
    ];

    const itemsWithoutDuplicates = newUserItems.filter(
      (item, index, arr) => index === arr.findIndex((t) => t.id === item.id)
    );

    setUserItems(itemsWithoutDuplicates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.projectItems, userProjects]);

  useEffect(() => {
    // With [ ...new Set(array) ] we remove duplicate items in array
    const projectIds = [...new Set(userProjects.map((proj) => proj.id))];
    getBasicProjectsData(projectIds, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userProjects]);

  useEffect(() => {
    if (!taskId) return;
    const projectId = userItems.find((ui) => ui.id === taskId).projectId;
    const isProjectActive = state.projectItems.find(
      (pi) => pi.projectId === projectId
    );
    const getDataCb = () => {
      dispatch({
        type: ACTIONS.SHOW_ITEM_DETAILS,
        payload: {
          selectedItemId: taskId,
          sidebarVisible: true,
        },
      });
    };

    // Everything under this line in this useEffect should not be modified until we start refactoring
    if (!isProjectActive) {
      getProjectData({
        projectId,
        dispatch,
        onAccessDenied: false,
        getDataCb,
      });
    }

    if (state.loadedProjects.hasOwnProperty(projectId)) {
      dispatch({
        type: ACTIONS.SET_SELECTED_ITEM_PROJECT_ID,
        payload: {
          selectedItemProjectId: projectId,
        },
      });
    }
    if (isProjectActive) {
      getDataCb();
    }
  }, [taskId, dispatch, state.loadedProjects, state.projectItems, userItems]);

  useEffect(() => {
    if (!taskId || !state.selectedItemProjectId) return;
    setTaskId(null);
  }, [taskId, state.selectedItemProjectId, dispatch]);

  // Disable tour on this version for now

  // React.useEffect(() => {
  //   if (localStorage.getItem("returningUser")) {
  //     return;
  //   }
  //   dispatch({
  //     type: ACTIONS.OPEN_MODAL,
  //     payload: {
  //       name: MODAL_NAMES.WELCOME_MODAL,
  //     },
  //   });
  // }, [dispatch]);

  React.useEffect(() => {
    if (
      !ability ||
      !state.selectedItemProjectId ||
      !currentUserId ||
      !state.loadedProjects
    ) {
      return;
    }
    // We update abilities here since the projectlayout component only wraps those views which are bound to a certain project
    function updateAbility(ability, userId) {
      const projMember =
        state.loadedProjects[state.selectedItemProjectId]?.members[userId];
      if (projMember) {
        ability.update(projMember.permissions);
      }
    }
    updateAbility(ability, currentUserId);
  }, [
    ability,
    currentUserId,
    state.selectedItemProjectId,
    state.loadedProjects,
  ]);

  //Data for react-table
  const data = React.useMemo(
    () =>
      userItems
        .filter((data) =>
          userProjects.find(
            (project) =>
              project.id === data.projectId &&
              data.status !==
                getStatusId(project.statuses, STATUSES_SLUGS.CLOSED) &&
              data.status !== getStatusId(project.statuses, STATUSES_SLUGS.DONE)
          )
        )
        .map((item) => {
          const project = userProjects.find((up) => up.id === item.projectId);
          const projectName = project ? project.name : "Unlinked";
          const projectStatuses = project ? project.statuses : null;

          return {
            project: projectName,
            priority: item.priority,
            title: item.title,
            status: item.status,
            dueDate:
              item.dueDate && format(parseDate(item.dueDate), "yyyy-MMM-dd"),
            sprint: item.sprint,
            projectId: item.projectId,
            projectStatuses: projectStatuses,
          };
        }),
    [userItems, userProjects]
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project", // accessor is the "key" in the data
      },
      {
        Header: "Priority",
        accessor: "priority.label",
        Cell: (cell) => (
          <PriorityIndicator
            label={cell.row.original?.priority?.label}
            number={cell.row.original?.priority?.number}
          />
        ),
        sortType: (a, b) => {
          if (a.original?.priority?.number === b.original?.priority?.number) {
            return 0;
          }
          // nulls sort after anything else
          else if (!a.original?.priority?.number) {
            return 1;
          } else if (!b.original?.priority?.number) {
            return -1;
          }
          // if descending, highest sorts first
          else {
            return a.original.priority.number > b.original.priority.number
              ? 1
              : -1;
          }
        },
      },
      {
        Header: "Task",
        accessor: "title",
      },
      {
        Header: "Status",
        Cell: (cell) => (
          <StatusIndicator
            status={cell.row.original.status}
            projectStatuses={cell.row.original.projectStatuses}
          />
        ),
        accessor: "status",
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        // Filter: DateColumnFilter,
      },
      {
        Header: "Sprint",
        Cell: (cell) => <SprintBadge sprint={cell.row.original.sprint} />,
        accessor: "sprint",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data },

      useSortBy
    );

  if (initialising) {
    return (
      <section className="w-full mt-40 column-center">
        <h1 className="w-1/2 text-center"> Preparing your experience </h1>
      </section>
    );
  }

  if (!loading && !userProjects.length) {
    return <EmptyDashboard />;
  }

  return !initialising && !loading ? (
    <div className="flex bg-gray1">
      <div className="w-1/4 max-w-xs ">
        <div className="sticky mt-4 ml-10 top-14">
          <div
            className={`flex ${
              userProjects.length >= 10 && "flex-col"
            } items-center justify-between`}
          >
            <div
              className={`${userProjects.length >= 10 ? "w-full" : "w-10/12"}`}
            >
              <span className="block text-lg">Projects</span>
              <span className="block text-sm text-grayBlue">
                All your projects in one place
              </span>
            </div>

            <button
              className="p-2 bg-white border rounded-lg shadow-md border-gray3"
              onClick={() => {
                dispatch({
                  type: ACTIONS.OPEN_MODAL,
                  payload: {
                    name: MODAL_NAMES.PROJECT_TEMPLATE_MODAL,
                  },
                });
              }}
            >
              <CreateIcon className="fill-current text-chillBlue" />
            </button>
          </div>
          <div className="mt-8">
            {userProjects.map((project) => {
              return (
                <Link to={`/project/${project.id}/editor`}>
                  <div
                    className="flex items-center p-2 rounded-md cursor-pointer hover:bg-white hover:shadow-md"
                    key={project.id}
                  >
                    <ProjectIcon className="mr-2" />
                    {project.name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {userItems.length > 0 ? (
        <div className="w-full pl-10 pt-4">
          <h1 className="text-4xl text-nearBlack font-bold">My Tasks</h1>
          <p className="text-sm text-grayBlue">All your tasks in one place</p>
          <table
            className="w-9/12 border-separate border-spacing-4"
            {...getTableProps()}
          >
            <thead className="text-left">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="sticky z-10 p-4 pb-2 text-sm font-medium border-b-2 top-14 bg-gray1 border-gray1"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>

                      {/* <div className="text-black">
                      {column.canFilter ? column.render("Filter") : null}
                    </div> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => {
                      setTaskId(
                        userItems.find(
                          (ui) =>
                            ui.title === row.original.title &&
                            ui.projectId === row.original.projectId
                        ).id
                      );
                    }}
                    className="h-20 text-sm duration-300 ease-in transform bg-white shadow-sm cursor-pointer hover:scale-102 hover:shadow-xl"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="px-4 py-6 border border-l-0 border-r-0 first:border-l last:border-r first:rounded-l-lg last:rounded-r-lg border-grayBorder"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="w-1/2 flex flex-col column-center pt-24 ml-16">
          <div>
            <EmptyFileIcon />
          </div>
          <p className="text-2xl font-bold text-primaryText text-center mt-6">
            There are currently no tasks
            <br /> assigned to you
          </p>
        </div>
      )}
    </div>
  ) : (
    <div className="w-full min-h-screen pt-16 ml-2">
      <Loader size="md" className="mx-auto" />
    </div>
  );
};

export default DashboardRestyle;
