import { Map } from "immutable";
import immutableDiff from "immutablediff";
import { getDiffChangeMeta, addEntitiesDiff } from "utils/editor";
import { WebrtcProvider } from "y-webrtc";
import * as Y from "yjs";

import { ACTIONS } from "../../context";

const EVENT_UPDATE = "update";
const LOG_HOOK_SYNC = "SYNC";

let lastSyncAppliedTS = null;

function removeAppliedDiffsBasedOnTimestamp(diffsArray, lastAppliedTS) {
  let splitIndex = 0;
  let _diffsArray = diffsArray;

  if (lastSyncAppliedTS) {
    for (let i = _diffsArray.length - 1; i >= 0; i--) {
      const _diff = _diffsArray[i];

      if (lastSyncAppliedTS < _diff.meta.ts) {
        splitIndex = i;
        break;
      }
    }
  }

  const result = _diffsArray.splice(splitIndex);

  if (result.length) {
    setLastAppliedTS(result[result.length - 1].meta.ts);
  }

  return result;
}

/**
 * On initialize we save the reference to the context dispatch
 */
let _dispatch = null;

/**
 * Connection type that is used to communicate between clients
 */
let _provider = null;

/**
 * Array of all diffs that are done by all clients.
 * Any change in editor content by any client is recorded here
 * sequentially.
 */
let _yEditorDiffs = null;

/**
 * Track all instances of corruption in applying the editor diff patch
 */
let _yEditorCorruptStates = null;

/**
 * Shared array of all Master fallbacks.
 * Master will set it's full editor state and all clients need to update from it!
 */
let _yEditorMasterRestore = null;

/**
 * The shared document instance.
 */
let _yDoc = null;

/**
 * A reference to the entityMap of the current editorState.
 * Needed to check if there is a difference in entities and broadcast
 * that change along with the diffs.
 */
let _lastSavedEntitiesMap = null;

let _awarenessConnectionComplete = false;
// TODO - initialized timestamp

function setInitialAwarenessInfo(awareness, userId) {
  awareness.setLocalState({
    userId,
    ts: Date.now(),
  });
}

function checkAndSetMaster(awareness) {
  let masterExists = false;

  const awarenessStates = awareness.getStates();

  for (let [_, value] of awarenessStates) {
    const master = value?.master;
    if (master) {
      masterExists = true;
    }
  }

  if (!masterExists) {
    // TODO - FIXME!
    // console.log("awareness", "Master does not exist, setting new master!");

    // Am I the oldest, next in line!
    const myClientID = awareness.doc.clientID;

    let oldestId = null;
    let oldestTs = null;

    for (let [id, value] of awarenessStates) {
      if (!oldestTs) {
        oldestTs = value.ts;
        oldestId = id;
      } else if (oldestTs > value.ts) {
        oldestTs = value.ts;
        oldestId = id;
      }
    }

    const isMaster = myClientID === oldestId;

    awareness.setLocalStateField("master", isMaster);

    _dispatch({
      type: ACTIONS.SET_EDITOR_MASTER,
      payload: {
        isMaster,
      },
    });
  }
}

function removeUserInfoFromAwareness(awareness) {
  awareness.setLocalState(null);
}

export function broadcastFallbackFromMaster(
  masterRawContentState = null,
  corruptDiff = null
) {
  // Broadcast to all clients to set the new content state from master!
  _yEditorMasterRestore.push([
    {
      masterRawContentState, // rawContentState
      corruptDiff,
    },
  ]);

  // CLEAR ALL PENDING DIFFS, CLEAN SLATE?
  _yEditorDiffs.delete(0, _yEditorDiffs.length);

  setLastAppliedTS(Date.now());
}

export function broadcastDiff({ newEditorState, localEditorState }) {
  if (!_yEditorDiffs) return;

  console.time("SYNC TIME - prepare diff patch");

  const localContentState = localEditorState.getCurrentContent();
  const newContentState = newEditorState.getCurrentContent();
  const newEntitiesMap = newContentState.getAllEntities();

  let entitiesDiff = null;

  const blockDiff = immutableDiff(
    localContentState.blockMap,
    newContentState.blockMap
  );

  const jsonDiff = blockDiff.toJSON();

  let entitiesDiffList = addEntitiesDiff(
    immutableDiff(_lastSavedEntitiesMap, newEntitiesMap),
    jsonDiff,
    newEditorState
  );

  if (entitiesDiffList.size > 0) {
    entitiesDiff = entitiesDiffList;
    _lastSavedEntitiesMap = Map(newEntitiesMap);
  }

  // console.log(LOG_HOOK_SYNC, "Trigger Sync", {
  //   diff: blockDiff.toArray(),
  //   meta: getDiffChangeMeta(newEditorState, localEditorState, jsonDiff),
  // });

  console.timeEnd("SYNC TIME - prepare diff patch");

  _yDoc.transact(() => {
    _yEditorDiffs.push([
      {
        clientID: _yDoc.clientID,
        diff: jsonDiff,
        entitiesDiff: entitiesDiff ? entitiesDiff.toJSON() : null,
        meta: getDiffChangeMeta(newEditorState, localEditorState, jsonDiff),
      },
    ]);
  }, _yDoc.clientID);
}

function documentUpdated(update) {
  // console.log(LOG_HOOK_SYNC, "Document updated", { update });
}

function registerDocumentListeners(yDoc) {
  /**
   * Every listener that is registered must be destroyed!
   */
  yDoc.on(EVENT_UPDATE, documentUpdated);
}

function destroyDocumentListeners(yDoc) {
  /**
   * Make sure that all registered listeners are destroyed
   */
  yDoc.off(EVENT_UPDATE, documentUpdated);
}

export function initialize(projectId, dispatch, userId) {
  // console.log(LOG_HOOK_SYNC, "Initializing peer connection");

  // If a provider already exist stop
  if (_provider) {
    throw new Error(
      "Provider is alreay registered. You need to destroy the previous provider"
    );
  }

  if (!projectId) {
    throw new Error("projectId is required to initialize the communication");
  }

  _dispatch = dispatch;
  _yDoc = new Y.Doc();

  registerDocumentListeners(_yDoc);

  // clients connected to the same room-name share document updates
  _provider = new WebrtcProvider(projectId, _yDoc, {
    password: "lfgkjadklalfsadlkfjasldkfjalksdfaslkdfj",
  });

  // console.log("SYNC connected", _provider, _provider.connected);

  _provider.awareness.on("change", (changeObject, origin) => {
    /**
     * First onChange is triggered while other awareness connections and document
     * sync is still not complete. Skip the first onChange since we need fully
     * available data!
     */
    if (!_awarenessConnectionComplete) {
      _awarenessConnectionComplete = true;
      return;
    }
    checkAndSetMaster(_provider.awareness);
    const awareness = _provider.awareness;
    const awarenessStates = awareness.getStates();

    // console.log("awareness", _provider, { changeObject, origin });
    // console.log("awareness", { awarenessStates });

    // Use Set cause we don't want duplicates
    let memberIds = new Set();

    for (let [_, value] of awarenessStates) {
      const id = value?.userId;
      if (id) {
        memberIds = memberIds.add(id);
      }
    }

    _dispatch({
      type: ACTIONS.EDITOR_SYNC_COLLABORATION_META,
      payload: {
        editorMemberIds: [...memberIds],
        editorUsersCount: awarenessStates.size,
      },
    });
  });

  // Called when new clients are added or removed to the Room
  _provider.on("peers", (data) => {
    // console.log(LOG_HOOK_SYNC, "Peers changed", data);
  });

  _yEditorMasterRestore = _yDoc.getArray("masterRestores");

  _yEditorMasterRestore.observe((event, transaction) => {
    // Master already has the 'correct' state
    if (amIMaster()) return;

    if (!_yEditorMasterRestore.length) return;

    const lastMasterRestore = _yEditorMasterRestore.get(
      _yEditorMasterRestore.length - 1
    );

    // Set the last applied diff timestamp so we can start accepting new changes
    // from other clients!
    setLastAppliedTS(lastMasterRestore.corruptDiff.ts);

    // Dispatch to the Editor to use the master's contentState and apply it!
    _dispatch({
      type: ACTIONS.EDITOR_CONTENT_MASTER_RESTORE,
      payload: {
        lastMasterRestore,
      },
    });
  });

  _yEditorCorruptStates = _yDoc.getArray("corruptStates");

  /**
   * 
      {
        diff,
        affectedClientID: _yDoc.clientID,
        corruptOriginID: diff.clientID,
        ts: diff.meta.ts,
      },
      
   */
  _yEditorCorruptStates.observe((event, transaction) => {
    // No corrupts
    if (!_yEditorCorruptStates.length) return;

    // Something bad happened in one of the clients!
    // Trigger content update with correctly set timestamp
    const lastCorruptState = _yEditorCorruptStates.get(
      _yEditorCorruptStates.length - 1
    );

    // Am I the master
    // I handle the corrupt state!
    if (amIMaster()) {
      const myClientID = _yDoc.clientID;

      // Am I the origin of the corrupt diff!
      if (myClientID === lastCorruptState.corruptOriginId) {
        // Revert my content state to before I intruduced the diff!
        // HOW !?!?
        // Keep my last 10 states!?
        _dispatch({
          type: ACTIONS.EDITOR_CONTENT_IS_MASTER_CORRUPT_FALLBACK,
          payload: {
            lastCorruptState,
          },
        });
      }

      // Am I the affected client of the corrupt diff!
      if (myClientID === lastCorruptState.affectedClientID) {
        // I already have the last working content state!
        // Let everyone know to update to MY last content state!
        // I don't have to update my editorState
        _dispatch({
          type: ACTIONS.EDITOR_CONTENT_IS_MASTER_FALLBACK,
          payload: {
            lastCorruptState,
          },
        });
      }
    }
  });

  _yEditorDiffs = window.__yEditorDiffs = _yDoc.getArray("diffs");

  _yEditorDiffs.observe((event, transaction) => {
    // console.log(
    //   LOG_HOOK_SYNC,
    //   { event, transaction, _yEditorDiffs },
    //   _yEditorDiffs.toArray()
    // );

    // TODO - use something else to detect where the change originated from
    if (transaction.origin === _yDoc.clientID) {
      // console.log(LOG_HOOK_SYNC, "This client is the origin, skipping update");
      return;
    }

    const diffsArray = _yEditorDiffs.toArray();

    _dispatch({
      type: ACTIONS.EDITOR_CONTENT_APPLY_PATCH,
      payload: {
        diffs: removeAppliedDiffsBasedOnTimestamp(diffsArray),
        lastDiff: diffsArray.length ? diffsArray[diffsArray.length - 1] : null,
        diffSize: diffsArray.length,
      },
    });
  });

  /**
   * This will immmediately trigger awareness change since we're setting the local
   * awareness state.
   *
   * Other clients info, awareness and shared DOC will not be available in this
   * change.
   */
  setInitialAwarenessInfo(_provider.awareness, userId);
}

export function destroy() {
  // console.log(LOG_HOOK_SYNC, "Destroying active peer connection!");

  if (_provider) {
    _provider.disconnect();
    _provider.destroy();
    // console.log(LOG_HOOK_SYNC, "Provider disconnected and destroyed");
  }

  if (_yEditorDiffs) {
    _yEditorDiffs.unobserve();
    // console.log(LOG_HOOK_SYNC, "Project diff array unobserved!");
  }

  if (_yDoc) {
    destroyDocumentListeners(_yDoc);
  }

  removeUserInfoFromAwareness(_provider.awareness);

  _provider = null;
  _yEditorDiffs = null;
  _yDoc = null;
  _lastSavedEntitiesMap = Map();
  _awarenessConnectionComplete = false;

  // Reset context values
  _dispatch({
    type: ACTIONS.EDITOR_SYNC_COLLABORATION_META,
    payload: {
      editorMemberIds: [],
      editorUsersCount: 1,
    },
  });
}

export function loadInitialEntities(editorState) {
  _lastSavedEntitiesMap = editorState.getCurrentContent().getAllEntities();
  // TODO - set initial timestamp
}

export function fallbackOnAppyPatchException({ lastDiff: diff }) {
  _yDoc.transact(() => {
    if (_yEditorDiffs.length && diff) {
      // Notify all clients that one client is corrupted and that
      // one change was removed to they update as well
      _yEditorCorruptStates.push([
        {
          diff,
          affectedClientID: _yDoc.clientID,
          corruptOriginID: diff.clientID,
          ts: diff.meta.ts,
        },
      ]);
    }
  });
}

export function amIMaster() {
  return _provider.awareness.getLocalState().master;
}

export function setLastAppliedTS(ts) {
  lastSyncAppliedTS = ts - 5000;
}
