import React from "react";

import { useTrackedState, useDispatch } from "context";

import { listenToProjectItems } from "../api";

// Hook
export default function useSnapshotListener() {
  const dispatch = useDispatch();
  const state = useTrackedState();

  const { activeProjectId } = state;

  React.useEffect(() => {
    if (!activeProjectId) return;
    let unsubscribe = listenToProjectItems(dispatch, activeProjectId);
    return () => {
      unsubscribe();
    };
  }, [activeProjectId, dispatch]);
}
