/**
 * Common helpers to work with core Draft.js API
 */

import { Modifier, SelectionState } from "draft-js";
/**
 * Returns an array of all `ContentBlock` instances within two block keys
 *
 * @param  {object} contentState A draft.js `ContentState` instance
 * @param  {string} anchorKey    The block key to start searching from
 * @param  {string} focusKey     The block key until which to search
 *
 * @return {array} An array containing the found content blocks
 */
export function getSelectedBlocks(contentState, anchorKey, focusKey) {
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = anchorKey;

    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }

  return selectedBlocks;
}

/**
 * For the given EditorState return the EditorBlock of the current SelectionState.
 * Most often this is the block that has the carret on it.
 *
 * @param {*} editorState DraftJS EditorState object.
 */
export function getActiveBlock(editorState) {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const startKey = currentSelection.getStartKey();
  const endKey = currentSelection.getEndKey();

  const selectedBlocks = getSelectedBlocks(currentContent, startKey, endKey);

  return selectedBlocks[0];
}

export function isHeaderBlock(block) {
  return block.getType().indexOf("header") === 0;
}

/**
 * Given a block key merge the block data.
 *
 * @param {*} blockKey
 * @param {*} contentState
 * @param {*} selectionState
 * @param {*} data
 */
export function mergeBlockDataByKey(blockKey, contentState, blockData = {}) {
  //  Create selection state
  const selectionState = SelectionState.createEmpty(blockKey);

  return Modifier.mergeBlockData(contentState, selectionState, blockData);
}
