import React from "react";

import { useTrackedState } from "context";
import { NavLink } from "react-router-dom";
import { isSandbox } from "utils/isSandboxEnv";

const NavItem = ({ color, icon, itemName, routeName, id }) => {
  const state = useTrackedState();

  return (
    <li className="m-2 " id={id}>
      <NavLink
        activeClassName="border-gray1 shadow-md"
        exact
        to={`/${isSandbox() ? "sandbox" : "project"}/${
          state.activeProjectId
        }/${routeName}`}
        style={{ borderRadius: 16 }}
        className="flex flex-col items-center p-4 border border-transparent hover:shadow-md hover:border-gray1"
      >
        <div
          className={`flex items-center justify-center w-8 h-8 text-white rounded-md bg-${color}`}
        >
          <img src={icon} alt={itemName} />
        </div>
        <span className="mt-1 text-xs font-bold text-center text-grayBlue">
          {itemName}
        </span>
      </NavLink>
    </li>
  );
};

export default NavItem;
