import React from "react";

import { ReactComponent as CrossIcon } from "assets/cross_icon.svg";
import { ReactComponent as EmptyDashboardIcon } from "assets/empty-dashboard.svg";
import { ReactComponent as LogoIcon } from "assets/logo_no_text.svg";
import { MODAL_NAMES } from "components/Modal/Modal";
import { useDispatch, ACTIONS } from "context";

const EmptyDashboard = () => {
  const dispatch = useDispatch();
  return (
    <section className="w-full mt-10 flex flex-col justify-start items-center text-primaryText text-center">
      <div>
        <EmptyDashboardIcon />
      </div>
      <h3 className="font-bold text-2xl text-primaryText mt-6">
        Hi, welcome to JadeALM!
      </h3>
      <p className="text-lg mt-6">
        You don't have any projects, but don't worry, <br /> we'll set you up in
        no time and show you around
      </p>
      <div className="flex justify-center items-baseline gap-4 mt-10">
        <div className="column-center w-1/2">
          <div
            onClick={() => {
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.CREATE_PROJECT_MODAL,
                  activeModalData: { blankProject: true },
                },
              });
            }}
            className="w-32 h-32 flex justify-center items-center shadow-sm rounded-lg border border-grayBorder hover:border-linkBlue cursor-pointer"
          >
            <CrossIcon />
          </div>
          <h3 className="font-bold text-2xl text-primaryText mt-4">
            Blank project
          </h3>
          <p className="text-sm mt-2">Start from scratch</p>
        </div>
        <div className="column-center w-1/2">
          <div
            onClick={() => {
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.CREATE_PROJECT_MODAL,
                  activeModalData: { blankProject: false },
                },
              });
            }}
            className="w-32 h-32 flex justify-center items-center bg-white shadow-sm rounded-lg border border-grayBorder hover:border-linkBlue cursor-pointer"
          >
            <LogoIcon className="w-full" />
          </div>
          <h3 className="font-bold text-2xl text-primaryText mt-4">
            Example project
          </h3>
          <p className="text-sm mt-2">
            Edit and organize existing tasks to your liking
          </p>
        </div>
      </div>
    </section>
  );
};

export default EmptyDashboard;
