import ability from "components/RoleManagement/defineAbility";

import { PERMISSIONS_ACTIONS, PERMISSIONS_SUBJECTS } from "../constants";

export function canUserEditMeta() {
  return ability.can(PERMISSIONS_ACTIONS.MANAGE, PERMISSIONS_SUBJECTS.METADATA);
}

export function canUserEditDocument() {
  return ability.can(PERMISSIONS_ACTIONS.MANAGE, PERMISSIONS_SUBJECTS.EDITOR);
}

export function canUserEditPermissions() {
  return ability.can(
    PERMISSIONS_ACTIONS.MANAGE,
    PERMISSIONS_SUBJECTS.USER_PERMISSIONS
  );
}
