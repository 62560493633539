import React, { useState, useEffect } from "react";

import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { ReactComponent as ViewBoard } from "assets/view_board.svg";
import { ReactComponent as ViewGantt } from "assets/view_gantt.svg";
import { ReactComponent as ViewWbs } from "assets/view_wbs.svg";
import { ReactComponent as InfoIcon } from "assets/info_icon.svg";
import { ITEM_TYPES } from "../../utils/metadataModel";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import { Parser } from "html-to-react";
import Highlighter from "react-highlight-words";
import TaskTitleSearch from "./TaskTitleSearch";
import GlobalSearchTask from "./GlobalSearchTask";
import EmptyTaskSearchModal from "./EmptyTaskSearchModal";
import { useHistory } from "react-router-dom";
import { isSandbox } from "utils/isSandboxEnv";

const htmlToReactParser = new Parser();

const GlobalSearchBucket = ({ ...props }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { hierarchyState, projectItems, activeProjectId, globalSearchFilter } =
    state;
  const [metadata, setMetadata] = useState(null);
  const title = metadata && metadata.title ? metadata.title : "";
  const [finalArray, setFinalArray] = useState([]);
  const [activeHeader, setActiveHeader] = useState(null);
  const [clickedBucketKey, setClickedBucketKey] = useState(null);
  const [clickedBucketId, setClickedBucketId] = useState(null);
  const [description, setDescripton] = useState(
    htmlToReactParser.parse(props.pItem.projectItemMetadata?.htmlContent)
  );
  const [showIcons, setShowIcons] = useState(false);
  const history = useHistory();
  const openIcons = () => {
    setShowIcons(!showIcons);
  };
  let hierarchyArray = [];
  const getParentBlocks = React.useCallback(
    (block) => {
      let currentBlockId;

      currentBlockId = hierarchyState[block]?.parentId
        ? hierarchyState[block].parentId
        : null;

      setActiveHeader(hierarchyState[block]?.key);
      currentBlockId && hierarchyArray.push(currentBlockId);
      if (currentBlockId) {
        getParentBlocks(currentBlockId);
      } else {
        setFinalArray([...hierarchyArray.reverse()]);
        hierarchyArray = [];
      }
    },
    [hierarchyState]
  );

  useEffect(() => {
    getParentBlocks(props.pItem && props.pItem.key);
    dispatch({
      type: ACTIONS.SET_GLOBAL_SEARCH_FILTER,
      payload: {
        header: activeHeader,
        bucketKey: clickedBucketKey,
        bucketId: clickedBucketId,
      },
    });
    dispatch({
      type: ACTIONS.EDITOR_ACTIVE_HEADER,
      payload: { activeHeaderKey: activeHeader },
    });
  }, [getParentBlocks, clickedBucketKey, clickedBucketId, activeHeader]);

  const goToScreen = (routeName, bucketKey, bucketId) => {
    history.push(
      `/${isSandbox() ? "sandbox" : "project"}/${
        state.activeProjectId
      }/${routeName}`
    );
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
    setClickedBucketKey(bucketKey);
    setClickedBucketId(bucketId);
  };

  function breadCrumbsClick(bucketKey) {
    setClickedBucketKey(bucketKey);
    history.push(
      `/${isSandbox() ? "sandbox" : "project"}/${state.activeProjectId}/editor`
    );
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  }

  return (
    <div>
      <div className="group relative w-full p-4 flex flex-col justify-between">
        <div className="items-center mb-1">
          <div className="text-base text-gray-400 flex inline mb-2">
            <ul className="text-grayBlue text-base mb-3 flex inline">
              {finalArray.map((block, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => breadCrumbsClick(hierarchyState[block]?.key)}
                    className="flex items-center cursor-pointer hover:text-darkBlue mr-1"
                  >
                    {hierarchyState[block]?.text}
                    <div className="ml-1">{"/"}</div>
                  </li>
                );
              })}
            </ul>

            <p
              onClick={() => breadCrumbsClick(props.pItem.key)}
              className="underline bold hover:text-darkBlue cursor-pointer"
            >
              {props.pItem.text}
            </p>
          </div>
          <div className="text-xs text-gray-400">
            {Object.values(hierarchyState).map((oItem) => {
              if (oItem.key === props.pItem && props.pItem.key) {
                return <div>{oItem.headerLevel}</div>;
              }
            })}
          </div>
          <div className="text-xl font-normal text-black">
            <Highlighter
              highlightStyle={{
                backgroundColor: "#FEF3C7",
                borderRadius: "5%",
                border: "solid 1px #FBBF24",
              }}
              searchWords={[props.filteredTitle]}
              autoEscape={true}
              textToHighlight={props.pItem.text}
            >
              <TaskTitleSearch
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                metaTitle={title}
                itemId={props.id}
              />
            </Highlighter>
          </div>
        </div>
        <div
          className={`${!props.showDetails && "line-clamp-3 h-12"}  w-11/12`}
        >
          {description}
        </div>
        {!props.showDetails && props.pItem.projectItemMetadata && (
          <div className="grid justify-items-center">
            <button
              onClick={() => {
                props.showBucketDetails(props.pItem.projectItemMetadata.id);
              }}
              className="grid justify-items-center text-xs text-chillBlue underline mt-2 w-20"
            >
              <a className="w-20 text-gray-400 group-hover:text-chillBlue">
                {"Show details"}
              </a>
            </button>
          </div>
        )}

        <div className="absolute right-0 bottom-0 mb-4 mr-4 flex justify-end items-center text-darkGray group-hover:text-chillBlue">
          <EyeIcon
            onClick={() => {
              openIcons();
            }}
            className={`${
              showIcons && "text-chillBlue"
            } cursor-pointer fill-current mr-1`}
          />
          {showIcons && (
            <div>
              <InfoIcon
                className="mb-0.5 cursor-pointer"
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SHOW_ITEM_DETAILS,
                    payload: {
                      selectedItemId: props.pItem.projectItemMetadata?.id,
                      sidebarVisible: true,
                    },
                  });
                }}
              />
              <ViewGantt
                className="mb-0.5 cursor-pointer"
                onClick={() => {
                  goToScreen("gantt", props.pItem.key);
                }}
              />
              <ViewWbs
                className="mb-0.5 cursor-pointer"
                onClick={() => {
                  goToScreen("wbs", props.pItem.key);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        {props.showDetails &&
          projectItems
            .filter(
              (pi) =>
                pi.itemType === ITEM_TYPES.SUBTASK &&
                pi.featureItemId === props.pItem.projectItemMetadata.id
            )
            .map((pi) => {
              return (
                <div className="my-2 mx-3">
                  <GlobalSearchTask
                    {...pi}
                    pItem={pi}
                    filteredTitle={props.filteredTitle}
                    showDetails={props.showDetails}
                  />
                </div>
              );
            })}
        <div className="mx-3">
          {props.showDetails && (
            <EmptyTaskSearchModal
              {...props}
              isEmpty={props.isEmpty}
              sandboxId="sandboxTask"
              pItem={props.pItem}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchBucket;
