import React from "react";

import { Accordion } from "@reach/accordion";
import { ReactComponent as CreateIcon } from "assets/plus-icon.svg";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import { getPageHeaderBlockKeys } from "utils/editor";
import { _insertSection } from "utils/editor/draft-utils";
import { scrollParentToChild } from "utils/scrolling";

import TOCAccordionItem from "./TOCAccordionItem";

import "@reach/accordion/styles.css";

const TOC = ({ scrollbar }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const [index, setIndex] = React.useState(0);

  const {
    hierarchyState,
    loadedProjects,
    activeProjectId,
    editorState,
    editorActiveHeader,
  } = state;

  React.useEffect(() => {
    if (!editorActiveHeader) return;

    if (
      getPageHeaderBlockKeys(editorState).indexOf(editorActiveHeader) === -1
    ) {
      dispatch({
        type: ACTIONS.EDITOR_ACTIVE_HEADER,
        payload: {
          activeHeaderKey: getPageHeaderBlockKeys(editorState).first(),
        },
      });
    }

    setIndex(getPageHeaderBlockKeys(editorState).indexOf(editorActiveHeader));
  }, [dispatch, editorActiveHeader, editorState]);

  if (!hierarchyState) return null;

  function scrollHeaderToView(key) {
    const element = document.querySelectorAll(
      `h1[data-offset-key='${key}-0-0'], h2[data-offset-key='${key}-0-0'], h3[data-offset-key='${key}-0-0'], h4[data-offset-key='${key}-0-0'], h5[data-offset-key='${key}-0-0'], h6[data-offset-key='${key}-0-0']`
    );

    if (element.length) {
      scrollParentToChild(scrollbar, element[0]);
    }
  }

  function onTocClick(key) {
    scrollHeaderToView(key);

    dispatch({
      type: ACTIONS.EDITOR_ACTIVE_HEADER,
      payload: { activeHeaderKey: key },
    });

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  function onTOCItemMove({ topLevelHierarchyBlock, changeObject }) {
    dispatch({
      type: ACTIONS.TOC_ITEM_MOVE,
      payload: {
        topLevelHierarchyBlock,
        changeObject,
      },
    });
  }

  let tempHeaderKey = "";
  let tempObj = {};

  Object.entries(hierarchyState).map(([key, value]) => {
    if (value.level === 1) {
      tempHeaderKey = key;
      tempObj[key] = {
        headerLevel: value.headerLevel,
        text: value.text,
        key: value.key,
        children: [],
      };

      return;
    }
    tempObj[tempHeaderKey].children.push({
      headerLevel: value.headerLevel,
      text: value.text,
      key: value.key,
    });
  });

  return (
    <React.Fragment>
      <h4 className="mb-8 text-xs text-grayBlue">TABLE OF CONTENTS</h4>
      <div>
        {hierarchyState && (
          <button className="flex text-grayBlue hover:text-darkBlue text-left text-xl mb-2">
            {loadedProjects[activeProjectId]?.name}
          </button>
        )}
      </div>
      <div>
        <Accordion
          className="w-11/12 ml-1"
          index={index}
          onChange={(value) => setIndex(value)}
        >
          {Object.entries(tempObj).map(([key, h1Block]) => {
            return (
              <TOCAccordionItem
                onTocClick={onTocClick}
                onTOCItemMove={onTOCItemMove}
                h1Block={h1Block}
                key={key}
              />
            );
          })}
        </Accordion>
      </div>
      <button
        onClick={() => {
          const { newEditorState } = _insertSection(
            editorState,
            hierarchyState,
            getPageHeaderBlockKeys(editorState).last(),
            "page"
          );
          dispatch({
            type: ACTIONS.RECREATE_HIERARCHY_STATE,
            payload: {
              newEditorState,
            },
          });
          dispatch({
            type: ACTIONS.EDITOR_CONTENTS_CHANGED_UPDATE_KEY,
            payload: {
              newEditorState,
            },
          });
          setTimeout(() => {
            dispatch({
              type: ACTIONS.EDITOR_ACTIVE_HEADER,
              payload: {
                activeHeaderKey: getPageHeaderBlockKeys(newEditorState).last(),
              },
            });
          }, 200);
        }}
        className="btn btn-utility w-11/12 ml-1"
      >
        <CreateIcon className="w-full fill-current text-chillBlue" />
      </button>
    </React.Fragment>
  );
};

export default TOC;
