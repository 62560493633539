import React, { useState } from "react";

import { createSubtask, createBlockItem } from "api";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import { EditorState } from "draft-js";
import { mergeBlockDataByKey } from "utils/draft";
import { createEditorStateHtmlSlice } from "utils/editor";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setMetadataPriority,
  setLabels,
  setMetadataTitleAndContent,
  ITEM_TYPES,
  metadataFactory,
  setItemType,
} from "utils/metadataModel";
import { getStatusId } from "utils/status";

import TaskAssignee from "./TaskAssignee";
import TaskDate from "./TaskDate";
import TaskLabels from "./TaskLabels";
import TaskPriority from "./TaskPriority";
import TaskSprint from "./TaskSprint";
import TaskStatus from "./TaskStatus";
import TaskTitle from "./TaskTitle";

const EmptyTask = (props) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { projectItems, activeProjectId, hierarchyState } = state;
  const statuses = props.blockProps.statuses;

  const openStatusId = getStatusId(statuses, "OPEN");

  const [metadata, setMetadata] = useState(
    setItemType(metadataFactory({ status: openStatusId }), ITEM_TYPES.SUBTASK)
  );

  // Metadata sanitizing
  const title = metadata && metadata.title ? metadata.title : "";
  const content = metadata ? metadata.htmlContent : null;

  const status = metadata ? metadata.status : null;
  const assignee = metadata ? metadata.assignee : null;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const sprint = metadata ? metadata.sprint : null;
  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;

  const setTitle = (title) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  function setStatus(status) {
    setMetadata(setMetadataStatus(metadata, status));
  }

  function setAssignee(assignee) {
    setMetadata(setMetadataAssignee(metadata, assignee));
  }

  function setPriority(priority) {
    setMetadata(setMetadataPriority(metadata, priority));
  }

  function setSprint(sprint) {
    setMetadata(setMetadataSprint(metadata, sprint));
  }

  function setMetaDueDate(date) {
    setMetadata(setMetadataDueDate(metadata, date));
  }

  function setMetaStartDate(date) {
    setMetadata(setMetadataStartDate(metadata, date));
  }

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labelIds) {
    setMetadata(setLabels(metadata, labelIds));
  }

  return (
    <div
      id={props.sandboxId && "sandboxTask"}
      className="w-full p-4 flex flex-col justify-between border shadow-singleTaskComponent rounded-xl"
    >
      <div className="flex justify-between items-center mb-1">
        <TaskTitle
          dispatch={dispatch}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          metadata={metadata}
          activeProjectId={activeProjectId}
          projectItems={projectItems}
          setMetaTitle={setTitle}
          metaTitle={title}
          editorStateForceSelection={props.editorStateForceSelection}
          itemId={props.id}
          isEmpty
        />
      </div>

      {title && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <TaskStatus
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                setMetaStatus={setStatus}
                metaStatus={status}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
              <TaskAssignee
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                setMetaAssignee={setAssignee}
                metaAssignee={assignee}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
              <TaskPriority
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                setMetaPriority={setPriority}
                metaPriority={priority}
                itemId={props.id}
              />
              <TaskDate
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                metaDueDate={dueDate}
                metaStartDate={startDate}
                setMetaStartDate={setMetaStartDate}
                setMetaDueDate={setMetaDueDate}
                itemId={props.id}
              />
              <TaskSprint
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                setMetaSprint={setSprint}
                metaSprint={sprint}
                itemId={props.id}
              />
              <TaskLabels
                dispatch={dispatch}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                setMetaLabels={setMetaLabels}
                metaLabels={labels}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
            </div>
            <button
              id="sandboxTaskButton"
              className="btn btn-primary ml-auto text-sm w-16 p-1"
              onClick={() => {
                let newEditorState = props.blockProps.editorState;
                const bucketContent = createEditorStateHtmlSlice(
                  newEditorState.getCurrentContent(),
                  hierarchyState[props.bucketBlockId].content
                );
                props.featureItemId
                  ? createSubtask({
                      data: {
                        ...metadata,
                        featureItemId: props.featureItemId,
                        projectId: state.activeProjectId,
                        itemType: "SUBTASK",
                        featureHtmlContent: bucketContent,
                      },
                      statuses,
                      statusId: metadata.status,
                      dispatch,
                      projectItems,
                      featureItemId: props.featureItemId,
                      featureItem: null,
                      createSubtaskSuccessCB: function () {
                        props.blockProps.setEditorState(
                          EditorState.forceSelection(
                            newEditorState,
                            newEditorState.getSelection()
                          )
                        );
                      },
                    })
                  : createBlockItem({
                      dispatch: props.blockProps.dispatch,
                      blockId: props.bucketBlockId,
                      projectId: props.blockProps.activeProjectId,
                      projectItems: [...props.blockProps.projectItems],
                      statuses,
                      statusId: metadata.status,
                      data: {
                        status: metadata.status,
                        title: hierarchyState[props.bucketBlockId].text,
                        assignee: null,
                        priority: null,
                        htmlContent: bucketContent,
                      },
                      createBlockSuccessCB: function (bucketId) {
                        const newContentState = mergeBlockDataByKey(
                          props.bucketBlockId,
                          props.contentState,
                          {
                            headerItemLinked: true,
                          }
                        );

                        newEditorState = EditorState.push(
                          props.blockProps.editorState,
                          newContentState,
                          "change-block-data"
                        );

                        createSubtask({
                          data: {
                            ...metadata,
                            featureItemId: bucketId,
                            projectId: state.activeProjectId,
                            itemType: "SUBTASK",
                            featureHtmlContent: bucketContent,
                          },
                          statuses,
                          statusId: metadata.status,
                          dispatch,
                          projectItems,
                          featureItemId: bucketId,
                          featureItem: {
                            id: bucketId,
                            data: {
                              status: metadata.status,
                              title: hierarchyState[props.bucketBlockId].text,
                              assignee: null,
                              priority: null,
                            },
                            blockId: props.bucketBlockId,
                            projectId: props.blockProps.activeProjectId,
                          },
                          createSubtaskSuccessCB: function () {
                            props.blockProps.dispatch({
                              type: ACTIONS.EDITOR_CONTENTS_CHANGED_UPDATE_KEY,
                              payload: {
                                newEditorState,
                              },
                            });
                          },
                        });
                      },
                    });

                setMetadata(setTitle(""));
                props.setIsEmpty();
              }}
            >
              Create
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmptyTask;
