function getBacklogProjectItems(projectItems, statuses = []) {
  const closedStatus = statuses.find((status) => status.slug === "CLOSED");

  // Backlog project items are items that are not a part of any sprint
  return projectItems.filter(
    (pi) => !pi.sprint && pi.itemType && pi.status !== closedStatus?.id
  );
}

function sortBacklogItems(backlogItems) {
  // const closedStatus = statuses.find((status) => status.slug === "CLOSED");

  const backlogSortedItems = backlogItems
    // .filter((bi) => bi.itemType && bi.status !== closedStatus?.id) // In case any items are closed
    .sort((a, b) => {
      if (a.orderPosition.backlogPosition > b.orderPosition.backlogPosition) {
        return 1;
      } else if (
        a.orderPosition.backlogPosition < b.orderPosition.backlogPosition
      ) {
        return -1;
      } else {
        return 0;
      }
    });

  return backlogSortedItems;
}

export function getSortedBacklogProjectItems(projectItems, statuses) {
  const backlogItems = getBacklogProjectItems(projectItems, statuses);
  const sorted = sortBacklogItems(backlogItems);

  return sorted;
}
