/**
 * DraftJS is extemely low level API and does not provide much when it comes to
 * some speficic actions when it comes to formatting content and working with
 * selection.
 *
 * Here we curate a list of common higher level utils
 */

import {
  addBlock,
  addAtomicBlock,
  isBlockWithEntityType,
} from "@jimmycode/draft-js-toolbox";
import { genKey, ContentBlock } from "draft-js";
import {
  EditorState,
  ContentState,
  SelectionState,
  convertToRaw,
} from "draft-js";
import { List } from "immutable";
import Immutable from "immutable";

import {
  normalizeRawBlocksToParentChild,
  flattenHeaderHierarchyArray,
  flattenHeaderHierarchyMap,
  HEADER_LEVELS_SORTED_ARRAY,
} from "../editor";

const CHANGE_TYPE_INSERT_SECTION = "insert-section";

export { CHANGE_TYPE_INSERT_SECTION };

/**
 * Create a new empty block before of after the target block.
 *
 * @param {String} direction
 * @param {ContentState} contentState
 * @param {ContentBlock} targetBlock
 */
export function insertEmptyBlock(
  direction = "after",
  contentState,
  targetBlock
) {
  const blockMap = contentState.getBlockMap();
  // Split the blocks
  const blocksBefore = blockMap.toSeq().takeUntil(function (v) {
    return v === targetBlock;
  });
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil(function (v) {
      return v === targetBlock;
    })
    .rest();
  const newBlockKey = genKey();

  let newBlocks =
    direction === "before"
      ? [
          [
            newBlockKey,
            new ContentBlock({
              key: newBlockKey,
              type: "unstyled",
              text: "",
              characterList: List(),
            }),
          ],
          [targetBlock.getKey(), targetBlock],
        ]
      : [
          [targetBlock.getKey(), targetBlock],
          [
            newBlockKey,
            new ContentBlock({
              key: newBlockKey,
              type: "unstyled",
              text: "",
              characterList: List(),
            }),
          ],
        ];
  const newBlockMap = blocksBefore
    .concat(newBlocks, blocksAfter)
    .toOrderedMap();

  const newContentState = contentState.merge({
    blockMap: newBlockMap,
  });

  return { newContentState, newBlockKey };
}

export function addBlockAfter(
  editorState,
  keyBefore,
  blockType,
  blockParams,
  text = ""
) {
  const newBlock = new ContentBlock({
    key: genKey(),
    type: blockType,
    text,
    ...blockParams,
  });
  const contentState = editorState.getCurrentContent();
  const oldBlockMap = contentState.getBlockMap();
  const newBlockMap = Immutable.OrderedMap().withMutations((map) => {
    for (let [k, v] of oldBlockMap.entries()) {
      map.set(k, v);

      if (keyBefore === k) {
        map.set(newBlock.key, newBlock);
      }
    }
  });

  // return EditorState.forceSelection(

  //   SelectionState.createEmpty(newBlock.key)
  // )
  const newEditorState = EditorState.forceSelection(
    EditorState.push(
      editorState,
      ContentState.createFromBlockArray(Array.from(newBlockMap.values()))
        .set("selectionBefore", contentState.getSelectionBefore())
        .set("selectionAfter", contentState.getSelectionAfter())
    ),
    SelectionState.createEmpty(newBlock.key)
  );

  return {
    editorState: newEditorState,
    blockKey: newBlock.key,
  };
}

export function getDeepChildKeys(children) {
  let result = [];

  if (children && children.length) {
    children.forEach((block) => {
      result.push(block.key);

      if (block.children && block.children.length) {
        result = [...result, ...getDeepChildKeys(block.children)];
      }
    });
  }

  return result;
}

export function getLastChildKey(childrenKeyArray) {
  const lastChildKey = childrenKeyArray[childrenKeyArray.length - 1];
  return lastChildKey;
}

export function insertSection(editorState, children, level) {
  const withHeader = addBlockAfter(
    editorState,
    getLastChildKey(getDeepChildKeys(children)),
    `header-${level}`
  );

  const withContent = addBlockAfter(
    withHeader.editorState,
    withHeader.blockKey,
    "unstyled"
  );
  const withTasks = addBlockAfter(
    withContent.editorState,
    withContent.blockKey,
    "unstyled"
  );
  const withAtomic = addAtomicBlock(withTasks.editorState, "tasksBlock", {
    bucketBlockId: withHeader.blockKey,
  });

  return withAtomic;
}

/**
 *
 * @param {string} referenceHeaderType blockType of the header from which we are
 *                                     creating a new section
 * @param {string} addType 'page' | 'section' | 'subsection'
 * @returns
 */
function getInsertingHeaderType(referenceHeaderType, addType) {
  const currentHeaderIndex =
    HEADER_LEVELS_SORTED_ARRAY.indexOf(referenceHeaderType);

  let insertingHeaderIndex;

  if (addType === "page") {
    // We are adding a new Page, basically new H1
    insertingHeaderIndex = 0;
  }

  if (addType === "section") {
    // We are adding a new header of the same type
    insertingHeaderIndex = currentHeaderIndex;
  }

  if (addType === "subsection") {
    if (referenceHeaderType === "header-six") {
      // Can't go lower than H6
      insertingHeaderIndex = HEADER_LEVELS_SORTED_ARRAY.indexOf("header-six");
    } else {
      // Get the next header type in order
      insertingHeaderIndex = currentHeaderIndex + 1;
    }
  }

  return HEADER_LEVELS_SORTED_ARRAY[insertingHeaderIndex];
}

/**
 *
 *
 * @param {*} referenceHeaderKey Header hey from which we are creating a new section
 * @param {*} hierarchyState     Hierarchy state
 *
 * @returns {string}             blockKey of the block AFTER which we need to add
 *                               a new section
 */
function getInsertKeyReference(referenceHeaderKey, hierarchyState) {
  // Set the initial needle to search the haystack to be the refenrece header itself
  let needleHeaderKey = referenceHeaderKey;

  if (
    hierarchyState[referenceHeaderKey].children &&
    hierarchyState[referenceHeaderKey].children.length
  ) {
    // If the reference header has children set that child's blockKey as the needle
    needleHeaderKey = getLastChildKey(
      getDeepChildKeys(hierarchyState[referenceHeaderKey].children)
    );
  }

  // Get the full block object
  const needleHeaderBlock = hierarchyState[needleHeaderKey];

  // If this block has content - return the last content key
  if (needleHeaderBlock.content && needleHeaderBlock.content.length) {
    return needleHeaderBlock.content[needleHeaderBlock.content.length - 1].key;
  } else {
    return referenceHeaderKey;
  }
}
function getInsertKeyReferenceForTasks(referenceHeaderKey, hierarchyState) {
  // Set the initial needle to search the haystack to be the refenrece header itself
  let needleHeaderKey = referenceHeaderKey;

  // Get the full block object
  const needleHeaderBlock = hierarchyState[needleHeaderKey];

  // If this block has content - return the last content key
  if (needleHeaderBlock.content && needleHeaderBlock.content.length) {
    return needleHeaderBlock.content[needleHeaderBlock.content.length - 1].key;
  } else {
    return referenceHeaderKey;
  }
}

/**
 * New insert section logic that covers inserting a 'page', 'section', or 'subsection'
 * based on the referenceKey
 *
 * @param {*} editorState Draft editor state
 * @param {*} hierarchyState
 * @param {*} referenceKey Key of the Header block (or section) we are inserting
 *                         after.
 * @param {*} addType      'section' | 'subsection' | 'page'
 */
export function _insertSection(
  editorState,
  hierarchyState,
  referenceKey,
  addType = "section",
  headerText = ""
) {
  // header-one, header-two etc
  const referenceHeaderType = hierarchyState[referenceKey].type;
  const newHeaderType = getInsertingHeaderType(referenceHeaderType, addType);

  // Block key after which we are adding a new section
  const referenceBlockKey = getInsertKeyReference(referenceKey, hierarchyState);

  const withHeader = addBlockAfter(
    editorState,
    referenceBlockKey,
    newHeaderType,
    null,
    headerText
  );

  const withContent = addBlockAfter(
    withHeader.editorState,
    withHeader.blockKey,
    "unstyled"
  );

  const withTasks = addBlockAfter(
    withContent.editorState,
    withContent.blockKey,
    "unstyled"
  );

  const withAtomic = addAtomicBlock(withTasks.editorState, "tasksBlock", {
    bucketBlockId: withHeader.blockKey,
  });

  const withSelection = EditorState.forceSelection(
    withAtomic,
    withAtomic.getSelection().merge({
      anchorKey: withHeader.blockKey,
      anchorOffset: 0,
    })
  );

  // Set the correct editor change type so we can use that for specific logic
  // that follows adding of the new section
  const withChangeType = EditorState.push(
    withSelection,
    ContentState.createFromBlockArray(
      withSelection.getCurrentContent().getBlocksAsArray()
    ),
    CHANGE_TYPE_INSERT_SECTION
  );

  return {
    newEditorState: withChangeType,
    newSectionBlockKey: withHeader.blockKey,
  };
}
export function insertPage(editorState) {
  const withHeader = addBlock(editorState, `header-one`);
  const withContent = addBlockAfter(
    withHeader,
    withHeader.getCurrentContent().getFirstBlock().getKey(),
    "unstyled"
  );
  const withTasks = addBlockAfter(
    withContent.editorState,
    withContent.blockKey,
    "unstyled"
  );
  const withAtomic = addAtomicBlock(withTasks.editorState, "tasksBlock", {
    bucketBlockId: withHeader.getCurrentContent().getFirstBlock().getKey(),
  });

  return withAtomic;
}

export function insertSubSection(editorState, key, level) {
  const levelToWords = () => {
    if (level + 1 === 2) {
      return "two";
    }
    if (level + 1 === 3) {
      return "three";
    }
    if (level + 1 === 4) {
      return "four";
    }
    if (level + 1 === 5) {
      return "five";
    }
    if (level + 1 === 6) {
      return "six";
    }
  };
  const withHeader = addBlockAfter(
    editorState,
    key,
    `header-${levelToWords()}`
  );

  const withContent = addBlockAfter(
    withHeader.editorState,
    withHeader.blockKey,
    "unstyled"
  );
  const withTasks = addBlockAfter(
    withContent.editorState,
    withContent.blockKey,
    "unstyled"
  );
  const withAtomic = addAtomicBlock(withTasks.editorState, "tasksBlock", {
    bucketBlockId: withHeader.blockKey,
  });

  return withAtomic;
}

export function insertSectionsForOldProjects(editorState, projectItems) {
  const rawContentState = convertToRaw(editorState.getCurrentContent());

  const hierarchyState = flattenHeaderHierarchyArray(
    normalizeRawBlocksToParentChild(rawContentState.blocks, projectItems)
  );
  const hierarchyStateObject = flattenHeaderHierarchyMap(
    normalizeRawBlocksToParentChild(rawContentState.blocks, projectItems)
  );

  let currentContentState = editorState;

  hierarchyState.forEach((block) => {
    if (
      !block.content.find((childBlock) =>
        isBlockWithEntityType(
          editorState,
          editorState.getCurrentContent().getBlockForKey(childBlock.key),
          "tasksBlock"
        )
      )
    ) {
      try {
        const withTasks = addBlockAfter(
          currentContentState || editorState,
          getInsertKeyReferenceForTasks(block.key, hierarchyStateObject),
          "unstyled"
        );
        const withAtomic = addAtomicBlock(withTasks.editorState, "tasksBlock", {
          bucketBlockId: block.key,
        });

        currentContentState = withAtomic;
      } catch (error) {
        // TODO - FIXME!
        // Could not insert sections in project
      }
    }
  });
  return currentContentState;
}

/**
 * Programatically update the section title. Section title being H element
 * of any level
 *
 * @param {*} sectionBlockKey
 * @param {*} text
 */
export function updateSectionTitle(sectionBlockKey, text = "") {
  debugger;
}
