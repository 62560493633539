import { useReducer } from "react";

import amplitude from "amplitude-js";
import produce from "immer";
import { createContainer } from "react-tracked";
import { addAnalyticsEvent, CATEGORIES } from "utils/analytics";

export const TOUR_ACTIONS = {
  TOGGLE_TOUR: "TOGGLE_TOUR",
  CURRENT_STEP: "CURRENT_STEP",
  NEXT_STEP: "NEXT_STEP",
  PREVIOUS_STEP: "PREVIOUS_STEP",
};
export const TOUR_STAGES = {
  VACANT: "VACANT",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
};

export const TOUR_STEPS = {
  TOUR_JADE_DOCUMENT: "TOUR_JADE_DOCUMENT",
  TOUR_PROCEED_INITIAL_CONTENT: "TOUR_PROCEED_INITIAL_CONTENT",
  TOUR_CLICK_EDITOR_HEADER: "TOUR_CLICK_EDITOR_HEADER",
  TOUR_CLICK_TASK_CREATE: "TOUR_CLICK_TASK_CREATE",
  TOUR_CLICK_TASK: "TOUR_CLICK_TASK",
  TOUR_ITEM_SIDEBAR: "TOUR_ITEM_SIDEBAR",
  TOUR_CHANGE_ASSIGNEE: "TOUR_CHANGE_ASSIGNEE",
  TOUR_ITEM_SIDEBAR_APPLY: "TOUR_ITEM_SIDEBAR_APPLY",
  TOUR_PROCEED_WBS: "TOUR_PROCEED_WBS",
  TOUR_VIEW_WBS: "TOUR_VIEW_WBS",
  TOUR_PROCEED_TIMELINE: "TOUR_PROCEED_TIMELINE",
  TOUR_VIEW_TIMELINE: "TOUR_VIEW_TIMELINE",
  TOUR_PROCEED_KANBAN: "TOUR_PROCEED_KANBAN",
  TOUR_VIEW_KANBAN: "TOUR_VIEW_KANBAN",
  TOUR_FINISH: "TOUR_FINISH",
};

/**
 * Sometimes don't show next button
 */
export function showNextButton(currentStep) {
  const skipNextForSteps = [
    TOUR_STEPS.TOUR_CLICK_TASK_CREATE,
    TOUR_STEPS.TOUR_CLICK_TASK,
    TOUR_STEPS.TOUR_CHANGE_ASSIGNEE,
    TOUR_STEPS.TOUR_ITEM_SIDEBAR_APPLY,
    TOUR_STEPS.TOUR_PROCEED_WBS,
    TOUR_STEPS.TOUR_PROCEED_TIMELINE,
    TOUR_STEPS.TOUR_PROCEED_KANBAN,
    TOUR_STEPS.TOUR_FINISH,
  ];

  if (skipNextForSteps.indexOf(currentStep) >= 0) {
    return false;
  }

  return true;
}

export function getMaskClassName(currentStep) {
  const hideForSteps = [
    TOUR_STEPS.TOUR_CLICK_EDITOR_HEADER,
    TOUR_STEPS.TOUR_VIEW_WBS,
    TOUR_STEPS.TOUR_VIEW_TIMELINE,
    TOUR_STEPS.TOUR_VIEW_KANBAN,
    TOUR_STEPS.TOUR_FINISH,
  ];

  if (hideForSteps.indexOf(currentStep) >= 0) {
    return "hidden";
  }

  return "";
}

export function getCurrentStepIndex(currentStep) {
  const stepsKeys = Object.keys(TOUR_STEPS);
  const currentIndex = stepsKeys.indexOf(currentStep);

  return currentIndex;
}

function getNextStep(currentStep) {
  const stepsKeys = Object.keys(TOUR_STEPS);
  const nextStep = stepsKeys[getCurrentStepIndex(currentStep) + 1];

  addAnalyticsEvent(CATEGORIES.TOUR, "Proceed to step", nextStep);

  amplitude.getInstance().logEvent("proceed to tour step", {
    step: nextStep,
  });

  return nextStep;
}

function getPreviousStep(currentStep) {
  const stepsKeys = Object.keys(TOUR_STEPS);
  const previousStep = stepsKeys[getCurrentStepIndex(currentStep) - 1];

  return previousStep;
}

const initialState = {
  tourStage: TOUR_STAGES.VACANT,
  currentStep: TOUR_STEPS.TOUR_JADE_DOCUMENT,
};

const reducer = produce((draft, action) => {
  // TODO - FIXME!
  // console.log("DISPATCH", action.type, { action, draft });

  switch (action.type) {
    // When dispatch type is not correctly set
    case undefined:
      console.error("You are sending an UNDEFINED ACTION");

      return {
        ...draft,
      };

    case TOUR_ACTIONS.TOGGLE_TOUR:
      const tourStage = action.payload.tourStage;
      if (tourStage === TOUR_STAGES.IN_PROGRESS) {
        addAnalyticsEvent(CATEGORIES.TOUR, "Tour started");
        amplitude.getInstance().logEvent("tour start");
      }

      if (tourStage === TOUR_STAGES.FINISHED) {
        const tourFinished = draft.currentStep === TOUR_STEPS.FINISHED;
        const label = tourFinished
          ? "Tour finished completely"
          : "Tour finished early";

        addAnalyticsEvent(CATEGORIES.TOUR, label);
        amplitude.getInstance().logEvent("tour end", {
          "is finished": tourFinished,
        });

        if (tourFinished) {
          const identify = new amplitude.Identify().setOnce(
            "finished tour",
            true
          );
          amplitude.getInstance().identify(identify);
        }
      }

      return {
        ...draft,
        tourStage: action.payload.tourStage,
      };

    case TOUR_ACTIONS.NEXT_STEP:
      return {
        ...draft,
        currentStep: getNextStep(draft.currentStep),
      };

    case TOUR_ACTIONS.PREVIOUS_STEP:
      return {
        ...draft,
        currentStep: getPreviousStep(draft.currentStep),
      };

    case TOUR_ACTIONS.CURRENT_STEP:
      return {
        ...draft,
        currentStep: action.payload,
      };

    default:
      return {
        ...draft,
      };
  }
}, initialState);

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider: TourProvider,
  useTrackedState: useTourTrackedState,
  useUpdate: useTourDispatch,
} = createContainer(useValue);
