import React from "react";

import { createInlineStyleButton } from "@draft-js-plugins/buttons";

export default createInlineStyleButton({
  style: "ITALIC",
  children: (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.72656 17.4961L10.1133 15.5039H6.43359L9.04688 1.99219H12.7266L13.1133 0H3.38672L3 1.99219H6.67969L4.05469 15.5039H0.386719L0 17.4961H9.72656Z" />
    </svg>
  ),
});
