import React from "react";

import { updateProjectItem } from "api";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as EmptyDate } from "assets/editor-tasks-icons/date.svg";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState } from "context";
import { format } from "date-fns";
import { EditorState } from "draft-js";
import useOnClickOutside from "hooks/useOnClickOutside";
import DatePicker from "react-datepicker";
import { parseDate } from "utils/date";
import { canUserEditMeta } from "utils/permissions";
import ReactTooltip from "react-tooltip";

import {
  PERMISSIONS_SUBJECTS,
  PERMISSIONS_ACTIONS,
} from "../../../../constants";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../datepicker.css";

const DateComponent = (props) => {
  const [dateBlockOpen, setDateBlockOpen] = React.useState(false);
  const {
    metaStartDate,
    metaDueDate,
    setMetaStartDate,
    setMetaDueDate,
    editorStateForceSelection,
  } = props;
  const [startDate, setStartDate] = React.useState(metaStartDate);
  const [endDate, setEndDate] = React.useState(metaDueDate);
  const [pickerType, setPickerType] = React.useState("range");

  const ref = React.useRef();

  useOnClickOutside(ref, () => setDateBlockOpen(false));

  let _startDate = null;
  let _dueDate = null;

  if (metaStartDate) {
    // Handle if we are getting metaStartDate from firestore. It comes in the Timestamp
    // format from Firestore
    if (metaStartDate.seconds) {
      _startDate = metaStartDate.toDate();
    } else {
      _startDate = metaStartDate;
    }
  }
  if (metaDueDate) {
    // Handle if we are getting metaDueDate from firestore. It comes in the Timestamp
    // format from Firestore
    if (metaDueDate.seconds) {
      _dueDate = metaDueDate.toDate();
    } else {
      _dueDate = metaDueDate;
    }
  }

  const onChange = (result, event) => {
    event.stopPropagation();
    if (pickerType === "range") {
      const [start, end] = result;
      if (!end) {
        setMetaStartDate(start);
        setStartDate(start);
        setEndDate(end);
      } else {
        setEndDate(end);
        setMetaDueDate(end);
      }

      updateProjectItem({
        dispatch: props.dispatch,
        itemId: props.itemId,
        projectId: props.activeProjectId,
        projectItems: props.projectItems,
        data: {
          ...props.metadata,
          startDate: start,
          dueDate: end,
        },
      });
    }

    if (pickerType === "singleStart") {
      setStartDate(result);

      setMetaStartDate(result);

      updateProjectItem({
        dispatch: props.dispatch,
        itemId: props.itemId,
        projectId: props.activeProjectId,
        projectItems: props.projectItems,
        data: {
          ...props.metadata,
          startDate: result,
        },
      });

      setPickerType("range");
    }
    if (pickerType === "singleEnd") {
      setEndDate(result);

      setMetaDueDate(result);

      updateProjectItem({
        dispatch: props.dispatch,
        itemId: props.itemId,
        projectId: props.activeProjectId,
        projectItems: props.projectItems,
        data: {
          ...props.metadata,
          dueDate: result,
        },
      });

      setPickerType("range");
      return;
    }
  };

  const selectSingleDate = (type) => {
    setPickerType(type);
  };
  return (
    <div className="relative">
      {!metaStartDate || !metaDueDate ? (
        <span
          className="flex items-center text-grayBlue btn-transition text-xs cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            canUserEditMeta() && setDateBlockOpen(!dateBlockOpen);
          }}
        >
          <ReactTooltip />
          <EmptyDate
            data-tip="Date"
            className={`${
              dateBlockOpen && "text-chillBlue"
            } text-gray-500 stroke-current hover:text-chillBlue`}
          />
        </span>
      ) : (
        <div className="flex items-center group cursor-pointer">
          <span
            className="flex items-center btn-transition text-xs text-darkGray"
            onClick={(event) => {
              event.stopPropagation();
              canUserEditMeta() && setDateBlockOpen(!dateBlockOpen);
            }}
          >
            {format(parseDate(_startDate), "dd MMM")} {" - "}
            {format(parseDate(_dueDate), "dd MMM")}
          </span>
          <Can I={PERMISSIONS_ACTIONS.MANAGE} a={PERMISSIONS_SUBJECTS.METADATA}>
            <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100">
              <ClearIcon
                className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                onClick={(e) => {
                  e.stopPropagation();

                  setMetaStartDate(null);
                  setMetaDueDate(null);

                  updateProjectItem({
                    dispatch: props.dispatch,
                    itemId: props.itemId,
                    projectId: props.activeProjectId,
                    projectItems: props.projectItems,
                    data: {
                      ...props.metadata,
                      startDate: null,
                      endDate: null,
                    },
                  });

                  if (editorStateForceSelection) {
                    editorStateForceSelection();
                  }
                }}
              />
            </div>
          </Can>
        </div>
      )}
      {dateBlockOpen && (
        <div
          ref={ref}
          className="absolute top-0 left-0 p-4 mt-8 border rounded z-50 bg-white"
        >
          <div className="relative text-xs">
            <div
              onClick={(event) => {
                event.stopPropagation();
                selectSingleDate("singleStart");
              }}
              className={`border p-2 mb-4 rounded-sm cursor-pointer hover:border-chillBlue hover:bg-chillBlue-50 ${
                pickerType === "singleStart"
                  ? "border-chillBlue bg-chillBlue-50"
                  : "border-cloudyGray"
              }`}
            >
              {_startDate
                ? format(parseDate(_startDate), "dd MMM")
                : "Start date"}
            </div>
            <div
              onClick={(event) => {
                event.stopPropagation();
                selectSingleDate("singleEnd");
              }}
              className={`border p-2 rounded-sm cursor-pointer hover:border-chillBlue hover:bg-chillBlue-50 ${
                pickerType === "singleEnd"
                  ? "border-chillBlue bg-chillBlue-50"
                  : "border-cloudyGray"
              }`}
            >
              {_dueDate ? format(parseDate(_dueDate), "dd MMM") : "End date"}
            </div>
            <DatePicker
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={pickerType === "range"}
              minDate={pickerType === "singleEnd" && _startDate}
              inline
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateComponent;
