import React, { useState, useEffect } from "react";

import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { ReactComponent as ViewBoard } from "assets/view_board.svg";
import { ReactComponent as ViewGantt } from "assets/view_gantt.svg";
import { ReactComponent as ViewWbs } from "assets/view_wbs.svg";
import { ReactComponent as InfoIcon } from "assets/info_icon.svg";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataPriority,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setLabels,
  setMetadataTitleAndContent,
} from "utils/metadataModel";

import TaskAssignee from "../DraftEditor/components/Tasks/TaskAssignee";
import TaskDate from "../DraftEditor/components/Tasks/TaskDate";
import LabelsGlobalSearch from "./Labels";
import TaskPriority from "../DraftEditor/components/Tasks/TaskPriority";
import TaskSprint from "../DraftEditor/components/Tasks/TaskSprint";
import TaskStatus from "../DraftEditor/components/Tasks/TaskStatus";
import TaskTitle from "../DraftEditor/components/Tasks/TaskTitle";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import { isSandbox } from "utils/isSandboxEnv";

const GlobalSearchTask = ({ ...props }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { selectedItemId, projectItems, activeProjectId, hierarchyState } =
    state;

  const [metadata, setMetadata] = useState(null);
  const title = metadata && metadata.title ? metadata.title : "";
  const content = metadata ? metadata.htmlContent : null;

  const status = metadata ? metadata.status : null;
  const assignee = metadata ? metadata.assignee : null;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const sprint = metadata ? metadata.sprint : null;
  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;

  const [activeHeader, setActiveHeader] = useState(null);
  const [clickedBucketKey, setClickedBucketKey] = useState(null);
  const [clickedBucketId, setClickedBucketId] = useState(null);
  const [clickedTaskId, setClickedTaskId] = useState(null);
  const [parentTitle, setParentTitle] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [firstParentKey, setFirstParentKey] = useState(null);
  const [firstParentId, setFirstParentId] = useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const history = useHistory();

  const openPage = () => {
    setShowIcons(!showIcons);
  };

  useEffect(() => {
    const _metadata = projectItems.find((pItem) => pItem.id === props.id);

    if (_metadata) {
      setMetadata({ ..._metadata });
      return;
    }
  }, [selectedItemId, projectItems, props.id]);

  const setTitle = (title) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  function setStatus(status) {
    setMetadata(setMetadataStatus(metadata, status));
  }

  function setAssignee(assignee) {
    setMetadata(setMetadataAssignee(metadata, assignee));
  }

  function setPriority(priority) {
    setMetadata(setMetadataPriority(metadata, priority));
  }

  function setSprint(sprint) {
    setMetadata(setMetadataSprint(metadata, sprint));
  }

  function setMetaDueDate(date) {
    setMetadata(setMetadataDueDate(metadata, date));
  }

  function setMetaStartDate(date) {
    setMetadata(setMetadataStartDate(metadata, date));
  }

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labelIds) {
    setMetadata(setLabels(metadata, labelIds));
  }

  let hierarchyArray = [];
  const getParentBlocks = React.useCallback(
    (block) => {
      let currentBlockId;

      currentBlockId = hierarchyState[block]?.parentId
        ? hierarchyState[block].parentId
        : null;

      setActiveHeader(hierarchyState[block]?.key);
      currentBlockId && hierarchyArray.push(currentBlockId);
      if (currentBlockId) {
        getParentBlocks(currentBlockId);
      } else {
        setFinalArray([...hierarchyArray.reverse()]);
        hierarchyArray = [];
      }
    },
    [hierarchyState]
  );

  useEffect(() => {
    Object.values(hierarchyState).map((hState) => {
      if (
        hState.projectItemMetadata &&
        hState.projectItemMetadata.id === props.pItem.featureItemId
      ) {
        setParentTitle(hState.projectItemMetadata.title);
        setFirstParentKey(hState.projectItemMetadata.blockId);
        setFirstParentId(hState.projectItemMetadata.id);
      }
    });
    dispatch({
      type: ACTIONS.SET_GLOBAL_SEARCH_FILTER,
      payload: {
        header: activeHeader,
        bucketKey: clickedBucketKey,
        taskId: clickedTaskId,
        bucketId: clickedBucketId,
      },
    });
    dispatch({
      type: ACTIONS.EDITOR_ACTIVE_HEADER,
      payload: { activeHeaderKey: activeHeader },
    });
    getParentBlocks(firstParentKey);
  }, [
    hierarchyState,
    getParentBlocks,
    firstParentKey,
    clickedBucketKey,
    clickedTaskId,
    clickedBucketId,
  ]);

  const goToScreen = (routeName, bucketKey, taskId, bucketId) => {
    history.push(
      `/${isSandbox() ? "sandbox" : "project"}/${
        state.activeProjectId
      }/${routeName}`
    );
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
    setClickedBucketKey(bucketKey);
    setClickedTaskId(taskId);
    setClickedBucketId(bucketId);
  };

  function breadCrumbsClick(bucketKey) {
    setClickedBucketKey(bucketKey);
    history.push(
      `/${isSandbox() ? "sandbox" : "project"}/${state.activeProjectId}/editor`
    );
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  }

  return (
    <div>
      <div className="text-base text-gray-400 mb-2">
        {!props.showDetails && (
          <div className="flex inline">
            <ul className=" text-grayBlue text-base mb-3 inline flex">
              {finalArray.map((block, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => breadCrumbsClick(hierarchyState[block]?.key)}
                    className="flex items-center cursor-pointer hover:text-darkBlue mr-1"
                  >
                    {hierarchyState[block]?.text}
                    <div className="ml-1">{"/"}</div>
                  </li>
                );
              })}
            </ul>
            <p
              onClick={() => breadCrumbsClick(firstParentKey)}
              className="underline bold hover:text-darkBlue cursor-pointer"
            >
              {parentTitle}
            </p>
          </div>
        )}
      </div>
      <div className="hover:shadow-itemCard relative w-full p-4 flex flex-col justify-between border shadow-singleTaskComponent rounded-xl">
        <div className="flex justify-between items-center mb-1">
          <Highlighter
            highlightStyle={{
              backgroundColor: "#FEF3C7",
              borderRadius: "5%",
              border: "solid 1px #FBBF24",
            }}
            searchWords={[props.filteredTitle]}
            autoEscape={true}
            textToHighlight={props.pItem.title}
          >
            <TaskTitle
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              setMetaTitle={setTitle}
              metaTitle={title}
              itemId={props.id}
            />
          </Highlighter>
        </div>
        <div className="h-full flex justify-between items-center">
          <div className="flex w-full items-center gap-1">
            <TaskStatus
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              setMetaStatus={setStatus}
              metaStatus={status}
              editorStateForceSelection={props.editorStateForceSelection}
              itemId={props.id}
            />
            <TaskAssignee
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              setMetaAssignee={setAssignee}
              metaAssignee={assignee}
              editorStateForceSelection={props.editorStateForceSelection}
              itemId={props.id}
            />
            <TaskPriority
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              editorStateForceSelection={props.editorStateForceSelection}
              setMetaPriority={setPriority}
              metaPriority={priority}
              itemId={props.id}
            />
            <TaskDate
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              editorStateForceSelection={props.editorStateForceSelection}
              metaDueDate={dueDate}
              metaStartDate={startDate}
              setMetaStartDate={setMetaStartDate}
              setMetaDueDate={setMetaDueDate}
              itemId={props.id}
            />
            <TaskSprint
              dispatch={dispatch}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              editorStateForceSelection={props.editorStateForceSelection}
              setMetaSprint={setSprint}
              metaSprint={sprint}
              itemId={props.id}
            />
            <LabelsGlobalSearch
              dispatch={dispatch}
              onBlur={props.onBlur}
              setMetaLabels={setMetaLabels}
              metaLabels={labels}
              metadata={metadata}
              activeProjectId={activeProjectId}
              projectItems={projectItems}
              editorStateForceSelection={props.editorStateForceSelection}
              itemId={props.id}
            />
          </div>

          <div className="absolute right-0 bottom-0 mb-4 mr-4 flex justify-end items-center text-darkGray hover:text-chillBlue">
            <EyeIcon
              onClick={() => {
                openPage();
              }}
              className={`${
                showIcons && "text-chillBlue"
              } cursor-pointer fill-current mr-1`}
            />
            {showIcons && (
              <div>
                <InfoIcon
                  className="mb-0.5 cursor-pointer"
                  onClick={() => {
                    dispatch({
                      type: ACTIONS.SHOW_ITEM_DETAILS,
                      payload: {
                        selectedItemId: props.id,
                        sidebarVisible: true,
                      },
                    });
                  }}
                />
                <ViewGantt
                  className="mb-0.5 cursor-pointer"
                  onClick={() => {
                    goToScreen("gantt", firstParentKey, props.pItem.id);
                  }}
                />
                <ViewWbs
                  className="mb-0.5 cursor-pointer"
                  onClick={() => {
                    goToScreen(
                      "wbs",
                      firstParentKey,
                      props.pItem.id,
                      firstParentId
                    );
                  }}
                />
                <ViewBoard
                  className="cursor-pointer"
                  onClick={() => {
                    goToScreen("kanban", firstParentKey, props.pItem.id);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchTask;
