import React from "react";

import { defaultDraftBlockTypes, BLOCKTYPES } from "../../draftConstants";
import createSlashPlugin, {
  defaultSuggestionsFilter,
} from "../../plugins/slash-toolbar/src";

const _suggestions = [
  {
    name: "Blockquote",
    description: "A Blocquote",
    type: defaultDraftBlockTypes[BLOCKTYPES.BLOCKQUOTE],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fformat_quote-24px.svg?alt=media&token=af54972b-e079-42f4-9fab-cbfba53e2b3b",
  },
  {
    name: "Code Block",
    description: "Showcase your code",
    type: defaultDraftBlockTypes[BLOCKTYPES.CODE_BLOCK],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fcode-24px.svg?alt=media&token=aabcacb3-edfc-470d-bb43-9e3636021278",
  },
  {
    name: "Unordered list",
    description: "A bullet point list",
    type: defaultDraftBlockTypes[BLOCKTYPES.UNORDEDED_LIST_ITEM],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fformat_list_bulleted-24px.svg?alt=media&token=cdff09a6-3a58-4722-9bcb-fa0db4098e30",
  },
  {
    name: "Ordered list",
    description: "A numbered list",
    type: defaultDraftBlockTypes[BLOCKTYPES.ORDERED_LIST_ITEM],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fformat_list_numbered-24px.svg?alt=media&token=59327cb3-bcad-4ebe-95df-267236cc25d4",
  },
  {
    name: "Embed",
    description: "Embed YouTube, Figma, CodeSandbox etc.. ",
    type: defaultDraftBlockTypes[BLOCKTYPES.EMBEDDER],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fembed-24px.svg?alt=media&token=26059652-84ae-4f03-9540-c3fb4741e3ef",
  },
  {
    name: "Table",
    description: "Insert a table ",
    type: defaultDraftBlockTypes[BLOCKTYPES.TABLE_GRID],
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/web-assets%2Fembed-24px.svg?alt=media&token=26059652-84ae-4f03-9540-c3fb4741e3ef",
  },
];

export function createSlashToolbar() {
  return createSlashPlugin();
}

const SlashToolbar = (props) => {
  const { MentionSuggestions } = props.slashToolbarPlugin;

  const [isSlashToolbarOpen, setSlashToolbarOpen] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState(_suggestions);

  const onAddMention = () => {
    // get the mention object selected
  };

  const onSearchChange = ({ value }) => {
    // get the mention object selected
    setSuggestions(defaultSuggestionsFilter(value, _suggestions));
  };

  const onOpenChange = (open) => {
    setSlashToolbarOpen(open);
  };

  return (
    <React.Fragment>
      <MentionSuggestions
        suggestions={
          props.enableTable
            ? suggestions
            : suggestions.filter((suggestion) => suggestion.name !== "Table")
        }
        onSearchChange={onSearchChange}
        onAddMention={onAddMention}
        open={isSlashToolbarOpen}
        onOpenChange={onOpenChange}
      />
    </React.Fragment>
  );
};

export default SlashToolbar;
