import React from "react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "App";
import { Provider } from "context";
import { QuickStartProvider } from "quickStartContext";
import { debugContextDevtool } from "react-context-devtool";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SandboxApp from "SandboxApp";
import * as serviceWorker from "serviceWorker";
import { TourProvider } from "tourContext";
import "react-toastify/dist/ReactToastify.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2387af00a1e14515acb6734dc706aa4e@o448790.ingest.sentry.io/5430712",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const rootElement = document.getElementById("root");

render(
  <Router>
    <Provider>
      <TourProvider>
        <QuickStartProvider>
          <ToastContainer />
          <Switch>
            <Route path="/sandbox">
              <SandboxApp />
            </Route>
            <Route path="*">
              <App />
            </Route>
          </Switch>
        </QuickStartProvider>
      </TourProvider>
    </Provider>
  </Router>,
  rootElement
);

if (window._REACT_CONTEXT_DEVTOOL) {
  debugContextDevtool(rootElement, { debugReducer: false });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
