import { useReducer } from "react";

import produce from "immer";
import { createContainer } from "react-tracked";

export const QUICK_START_ACTIONS = {
  FINISH_STEP: "FINISH_STEP",
  SET_STEPS: "SET_STEPS",
  TOGGLE_QUICK_START: "TOGGLE_QUICK_START",
  SELECT_STEP: "SELECT_STEP",
};

export const QUICK_START_STEPS = {
  CREATE_PROJECT: "CREATE_PROJECT",
  INVITE_USER: "INVITE_USER",
  CREATE_TASK: "CREATE_TASK",
  CREATE_HEADER: "CREATE_HEADER",
};

const initialState = {
  solvedSteps: null,
  toggled: false,
  selectedStep: null,
};

const reducer = produce((draft, action) => {
  // TODO - FIXME!
  // console.log("DISPATCH", action.type, { action, draft });

  switch (action.type) {
    // When dispatch type is not correctly set
    case undefined:
      console.error("You are sending an UNDEFINED ACTION");

      return {
        ...draft,
      };

    case QUICK_START_ACTIONS.FINISH_STEP:
      return {
        ...draft,
        solvedSteps: [...draft.solvedSteps, action.payload],
        selectedStep: action.payload,
      };
    case QUICK_START_ACTIONS.SET_STEPS:
      return {
        ...draft,
        solvedSteps: action.payload,
      };
    case QUICK_START_ACTIONS.TOGGLE_QUICK_START:
      return {
        ...draft,
        toggled: action.payload,
      };
    case QUICK_START_ACTIONS.SELECT_STEP:
      return {
        ...draft,
        selectedStep: action.payload,
      };

    default:
      return {
        ...draft,
      };
  }
}, initialState);

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider: QuickStartProvider,
  useTrackedState: useQuickStartState,
  useUpdate: useQuickStartDispatch,
} = createContainer(useValue);
