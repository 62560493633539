import amplitude from "amplitude-js";
import { convertToRaw } from "draft-js";
import immutableDiff from "immutablediff";
import { toast } from "react-toastify";
import { addAnalyticsEvent, CATEGORIES } from "utils/analytics";

import {
  NEW_PROJECT_WALKTROUGH_DOCUMENT_CONTENT,
  NEW_PROJECT_DOCUMENT_CONTENT,
  INITIAL_PRIORITIES,
  STATUSES_SLUGS,
  USER_ROLES,
  PERMISSIONS_ACTIONS,
  PERMISSIONS_SUBJECTS,
  SPRINT_STATE,
  ITEM_HISTORY_CHANGE_TYPES,
  MOCK_MEMBERS,
  SUGGESTED_LABELS,
} from "./constants";
import { ACTIONS } from "./context";
import {
  flattenHeaderHierarchyArray,
  normalizeRawBlocksToParentChild,
  createEditorStateHtmlSlice,
} from "./utils/editor";
import { firestoreAutoId } from "./utils/firestoreId";
import { getInitialOrderPosition } from "./utils/reorderKanban";

let editorUpdates = {};

function shouldUpdateHistory(editorUpdates, item) {
  if (!editorUpdates[item.id]) return false;

  // If last update time is more than 2 minutes
  if (Date.now() - editorUpdates[item.id].ts > 2 * 60 * 1000) {
    return false;
  }

  return true;
}

function reqisterHistoryUpdate({
  shouldUpdate,
  batchType,
  itemId,
  historyId,
  htmlContent,
}) {
  if (!itemId || !historyId) return;
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));

  const selectedItem = sandboxItems.filter((item) => item.id === itemId)[0];

  const historyEntry = createItemHistoryEntry(
    MOCK_MEMBERS.SANDBOX_MEMBER,
    "Sandbox User",
    ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION,
    {
      htmlContent: htmlContent,
    }
  );

  if (batchType === "SET") {
    selectedItem.itemHistory
      ? selectedItem.itemHistory.push(historyEntry)
      : (selectedItem.itemHistory = [historyEntry]);
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify(
        sandboxItems.map((item) => {
          return item.id === selectedItem.id ? selectedItem : item;
        })
      )
    );
  }

  if (batchType === "UPDATE") {
    selectedItem.itemHistory.push(historyEntry);
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify(
        sandboxItems.map((item) => {
          return item.id === selectedItem.id ? selectedItem : item;
        })
      )
    );
  }
}

function createItemHistoryEntry(uid, userName, type, data) {
  return {
    uid,
    userName, // Save user name in case user gets removed from the project
    type,
    data,
    createdTs: { seconds: Math.floor(Date.now() / 1000) },
  };
}
export function getSandboxWalktroughProject(dispatch, tourDispatch, history) {
  dispatch({
    type: ACTIONS.LOADING_PROJECT_INITIATED,
  });
  dispatch({
    type: ACTIONS.SHOW_ITEM_DETAILS,
    payload: {
      selectedItemId: null,
      sidebarVisible: false,
    },
  });
  localStorage.clear();
  localStorage.setItem(
    "sandboxProject",
    JSON.stringify({
      name: "Sandbox Project",
      id: "sandboxId",
      code: "sapr",
      owner: "sandboxUser",
      members: {
        sandboxMember: {
          id: "sandboxUser",
          role: USER_ROLES.OWNER,
          permissions: [
            {
              subject: PERMISSIONS_SUBJECTS.EDITOR,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.METADATA,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.PROJECT_SETTINGS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
          ],
        },
        Johnny: {
          id: "Johnny",
          role: USER_ROLES.OWNER,
          permissions: [
            {
              subject: PERMISSIONS_SUBJECTS.EDITOR,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.METADATA,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.PROJECT_SETTINGS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
          ],
        },
      },
      document: NEW_PROJECT_WALKTROUGH_DOCUMENT_CONTENT,
      statuses: [
        {
          slug: STATUSES_SLUGS.DRAFT,
          label: "Draft",
          color: "gray",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.OPEN,
          label: "Open",
          color: "blue",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.INPROGRESS,
          label: "In progress",
          color: "purple",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.DONE,
          label: "Done",
          color: "green",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.CLOSED,
          label: "Closed",
          color: "gray",
          id: firestoreAutoId(),
        },
      ],
      sprints: [],
      logicallyDeleted: false,
      priority: INITIAL_PRIORITIES,
      labels: SUGGESTED_LABELS.map((label) => ({
        ...label,
        id: firestoreAutoId(),
      })),
    })
  );

  const data = JSON.parse(localStorage.getItem("sandboxProject"));
  const sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));
  history.push(`/sandbox/sandboxId/editor`);
  dispatch({
    type: ACTIONS.LOAD_PROJECT_DATA,
    payload: {
      projectData: data,
      projectItems: sandboxProjectItems
        ? [
            ...sandboxProjectItems.map((item) => {
              return item;
            }),
          ]
        : [],
      projectMembers: {
        sandboxMember: {
          name: "Demo User",
          email: "DummySandboxJadeUser@gmail.com",
          id: MOCK_MEMBERS.SANDBOX_MEMBER,
        },
        Johnny: {
          name: "Johnny",
          email: "DummySandboxJohnnyJadeUser@gmail.com",
          id: "Johnny",
        },
      },
    },
  });
  dispatch({
    type: ACTIONS.SET_SELECTED_ITEM_PROJECT_ID,
    payload: {
      selectedItemProjectId: "sandboxId",
    },
  });
}

export function getSandboxProjectData(dispatch) {
  dispatch({
    type: ACTIONS.LOADING_PROJECT_INITIATED,
  });

  !localStorage.getItem("sandboxProject") &&
    localStorage.setItem(
      "sandboxProject",
      JSON.stringify({
        name: "Sandbox Project",
        id: "sandboxId",
        code: "sapr",
        owner: "sandboxUser",
        members: {
          sandboxMember: {
            id: "sandboxUser",
            role: USER_ROLES.OWNER,
            permissions: [
              {
                subject: PERMISSIONS_SUBJECTS.EDITOR,
                action: PERMISSIONS_ACTIONS.MANAGE,
              },
              {
                subject: PERMISSIONS_SUBJECTS.METADATA,
                action: PERMISSIONS_ACTIONS.MANAGE,
              },
              {
                subject: PERMISSIONS_SUBJECTS.PROJECT_SETTINGS,
                action: PERMISSIONS_ACTIONS.MANAGE,
              },
              {
                subject: PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
                action: PERMISSIONS_ACTIONS.MANAGE,
              },
            ],
          },
        },
        document: NEW_PROJECT_DOCUMENT_CONTENT,
        statuses: [
          {
            slug: STATUSES_SLUGS.DRAFT,
            label: "Draft",
            color: "gray",
            id: firestoreAutoId(),
          },
          {
            slug: STATUSES_SLUGS.OPEN,
            label: "Open",
            color: "blue",
            id: firestoreAutoId(),
          },
          {
            slug: STATUSES_SLUGS.INPROGRESS,
            label: "In progress",
            color: "purple",
            id: firestoreAutoId(),
          },
          {
            slug: STATUSES_SLUGS.DONE,
            label: "Done",
            color: "green",
            id: firestoreAutoId(),
          },
          {
            slug: STATUSES_SLUGS.CLOSED,
            label: "Closed",
            color: "gray",
            id: firestoreAutoId(),
          },
        ],
        sprints: [],
        logicallyDeleted: false,
        priority: INITIAL_PRIORITIES,
        labels: SUGGESTED_LABELS.map((label) => ({
          ...label,
          id: firestoreAutoId(),
        })),
      })
    );

  const data = JSON.parse(localStorage.getItem("sandboxProject"));
  const sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));

  dispatch({
    type: ACTIONS.LOAD_PROJECT_DATA,
    payload: {
      projectData: data,
      projectItems: sandboxProjectItems
        ? [
            ...sandboxProjectItems.map((item) => {
              return item;
            }),
          ]
        : [],
      projectMembers: { sandboxUser: { name: "Demo User" } },
    },
  });
  dispatch({
    type: ACTIONS.SET_SELECTED_ITEM_PROJECT_ID,
    payload: {
      selectedItemProjectId: "sandboxId",
    },
  });

  amplitude.getInstance().logEvent("open project", {
    "project id": "sandboxId",
  });
}

export function saveSandboxEditorState(
  projectId,
  newEditorState,
  lastSavedEditorState,
  projectItems,
  editorLoaded,
  dispatch
) {
  if (!editorLoaded || !projectId || !newEditorState) return;
  let oldData = JSON.parse(localStorage.getItem("sandboxProject"));
  const hasText = newEditorState
    .getCurrentContent()
    .getBlocksAsArray()
    .find((block) => {
      if (!block.text) return false;
      if (block.text.trim() !== "") return true;
    });

  if (!hasText) {
    // Editor state is empty, can not save!

    return;
  }

  const rawNewContentState = convertToRaw(newEditorState.getCurrentContent());

  const itemsToUpdate = [];

  const diffOldNew = immutableDiff(
    lastSavedEditorState.getCurrentContent().blockMap,
    newEditorState.getCurrentContent().blockMap
  );

  if (!diffOldNew.isEmpty()) {
    const flatParentChildHierarchy = flattenHeaderHierarchyArray(
      normalizeRawBlocksToParentChild(rawNewContentState.blocks, projectItems)
    );

    const headerBlockProjectItemsIds = projectItems
      .map((pItem) => pItem.blockId)
      .filter((pItem) => pItem);

    const changedBlockIds = diffOldNew
      .map((entry) => {
        return entry.toJSON().path.split("/")[1];
      })
      .toSet()
      .toList();

    // Which of those changed are header elements. If it's in projectItems it's a
    // header element that has some metadata assigned. Change the 'Title of that
    // element
    changedBlockIds
      .filter((cbi) => headerBlockProjectItemsIds.indexOf(cbi) >= 0)
      .forEach((cbi) => {
        const data = flatParentChildHierarchy.find(
          (block) => block.key === cbi
        );

        if (data) {
          itemsToUpdate.push({
            id: data.projectItemMetadata.id,
            title: data.text,
          });
        }
      });

    // Out of those that are not header element find the parent elements and see if
    // they are in projectItems. Change the content of that element
    changedBlockIds.forEach((cbi) => {
      // Dissregard header items, we have them already
      if (headerBlockProjectItemsIds.indexOf(cbi) >= 0) return;

      // Find the parent of this block
      const parent = flatParentChildHierarchy.find(
        (block) => block.content && block.content.find((bc) => bc.key === cbi)
      );

      if (!parent) {
        // Means that this block does still not have a firestore linked Item entry
        // dissregard
        return;
      }

      const blockActive = projectItems.find(
        (pItem) => pItem.blockId === parent.key
      );

      if (blockActive) {
        const data = flatParentChildHierarchy.find(
          (block) => block.key === parent.key
        );

        const htmlContentSlice = createEditorStateHtmlSlice(
          newEditorState.getCurrentContent(),
          data.content
        );

        itemsToUpdate.push({
          id: data.projectItemMetadata.id,
          htmlContent: htmlContentSlice,
        });
      }
    });
  }

  if (itemsToUpdate.length) {
    const itemsMerge = itemsToUpdate.reduce((memo, item) => {
      // Check if memo already contains the item with this id
      const memoItem = memo.find((mItem) => mItem.id === item.id);

      if (memoItem) {
        // Recreate memo array with merged contents
        const newMemo = memo.map((mItem) => {
          if (mItem.id === item.id) {
            return { ...mItem, ...item };
          }

          return mItem;
        });

        return newMemo;
      } else {
        memo.push(item);
      }

      return memo;
    }, []);

    /**
     * Temporary bucket to store reference to all history objects for changed
     * blocks
     */
    let batchBlocksUpdatesChanges = {};

    // Batch write the changes to items content
    if (itemsMerge.length) {
      itemsMerge.forEach((iMerge) => {
        try {
          const data = { ...iMerge };

          if (data.id) {
            delete data.id;
          }

          // Check if this item has been updated in the last 2 minutes.
          // If yes update the history entry, if not create a new history entry
          if (shouldUpdateHistory(editorUpdates, iMerge)) {
            batchBlocksUpdatesChanges = {
              ...batchBlocksUpdatesChanges,
              [iMerge.id]: {
                ts: { seconds: Math.floor(Date.now() / 1000) },
                historyId: editorUpdates[iMerge.id].historyId,
              },
            };

            /**
             * Update history Item
             */
            reqisterHistoryUpdate({
              shouldUpdate: true,
              batchType: "UPDATE",
              itemId: iMerge.id,
              historyId: editorUpdates[iMerge.id].historyId,
              htmlContent: iMerge.htmlContent,
            });
          } else {
            const fbHistoryId = firestoreAutoId();

            batchBlocksUpdatesChanges = {
              ...batchBlocksUpdatesChanges,
              [iMerge.id]: {
                ts: { seconds: Math.floor(Date.now() / 1000) },
                historyId: fbHistoryId,
              },
            };

            /**
             * Create new history Item
             */
            reqisterHistoryUpdate({
              shouldUpdate: false,
              batchType: "SET",
              itemId: iMerge.id,
              historyId: fbHistoryId,
              htmlContent: iMerge.htmlContent,
            });
          }
        } catch (e) {
          // TODO - FIXME!
        }
      });

      // Update the editorUpdates object with blocks that were updated
      // only after successful save
      // This keeps local reference of all blocks that were updated
      // with timestaps for later comparison
      editorUpdates = {
        ...editorUpdates,
        ...batchBlocksUpdatesChanges,
      };
      // Update the project items locally with new content
      dispatch({
        type: ACTIONS.SET_PROJECT_ITEMS,
        payload: {
          projectItems: [...projectItems].map((pItem) => {
            const match = itemsMerge.find((iMerge) => iMerge.id === pItem.id);

            if (match) {
              return { ...pItem, ...match };
            }

            return pItem;
          }),
        },
      });
    }
  }

  oldData.document = rawNewContentState;

  localStorage.setItem("sandboxProject", JSON.stringify(oldData));

  // Update the last database editor content in redux
  dispatch({
    type: ACTIONS.EDITOR_CONTENTS_SAVED,
    payload: {
      savedEditorState: newEditorState,
    },
  });
}

export function createSandboxBlockItem({
  projectId,
  blockId,
  projectItems,
  statuses,
  statusId,
  data,
  dispatch,
  createBlockSuccessCB,
}) {
  // TO DO: ADD CREATED TIMESTAMP
  const itemId = firestoreAutoId();
  let sandboxProjectItems = [];

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );

  if (!localStorage.getItem("sandboxItems")) {
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify([
        {
          ...data,
          blockId,
          projectId,
          id: itemId,
          orderPosition,
          discussions: [
            { name: "General", msgCount: 0, id: firestoreAutoId() },
          ],
          itemHistory: [
            createItemHistoryEntry(
              MOCK_MEMBERS.SANDBOX_MEMBER,
              "Sandbox User",
              ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION,
              {
                htmlContent: data.htmlContent,
              }
            ),
          ],
        },
      ])
    );
  } else {
    sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));
    sandboxProjectItems.push({
      ...data,
      blockId,
      projectId,
      id: itemId,
      orderPosition,
      discussions: [{ name: "General", msgCount: 0, id: firestoreAutoId() }],
      itemHistory: [
        createItemHistoryEntry(
          MOCK_MEMBERS.SANDBOX_MEMBER,
          "Sandbox User",
          ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION,
          {
            htmlContent: data.htmlContent,
          }
        ),
      ],
    });
    localStorage.setItem("sandboxItems", JSON.stringify(sandboxProjectItems));
  }

  /**
   * Item successfully created
   * Update the global state
   */
  dispatch({
    type: ACTIONS.SET_PROJECT_ITEMS,
    payload: {
      projectItems: [
        ...projectItems,
        {
          id: itemId,
          orderPosition,
          ...data,
          blockId,
          projectId,
        },
      ],
    },
  });

  if (createBlockSuccessCB) {
    createBlockSuccessCB(itemId);
  }

  amplitude.getInstance().logEvent("create task", {
    "project id": "sandboxId",
    "task id": blockId,
  });

  toast.success(`New item created: ${data.title}`);
}

export function getSandboxItemData(itemId, itemDataCb) {
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
  const selectedItem = sandboxItems.filter((item) => item.id === itemId);
  itemDataCb({ id: itemId, ...selectedItem });
}

export function updateSandboxProjectItem({
  projectId,
  itemId,
  projectItems,
  data,
  dispatch,
}) {
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems")) || [];

  // Don't store the id of the item explicitly
  // if (data.id) {
  //   delete data.id;
  // }

  const updatedSandboxItems = sandboxItems.map((item) => {
    return item.id === itemId
      ? (item = {
          ...data,
          discussions: item["discussions"],
          itemHistory: item["itemHistory"],
        })
      : item;
  });

  localStorage.setItem("sandboxItems", JSON.stringify(updatedSandboxItems));

  amplitude.getInstance().logEvent("update task", {
    "project id": "sandboxId",
    "task id": itemId,
  });

  dispatch({
    type: ACTIONS.SET_PROJECT_ITEMS,
    payload: {
      projectItems: updatedSandboxItems,
    },
  });
}

export function getSandboxDocumentVersionById({
  projectId,
  versionId,
  dispatch,
  onSuccessCb,
}) {
  const sandboxProject = JSON.parse(localStorage.getItem("sandboxProject"));
  const documentData = sandboxProject.documentVersions.filter(
    (version) => version.versionId === versionId
  )[0];

  dispatch({
    type: ACTIONS.LOAD_DOCUMENT_VERSION,
    payload: {
      projectId,
      documentData: {
        ...documentData,
        createdTs: documentData.createdTs,
      },
      versionId,
    },
  });
  onSuccessCb();
}

export function removeSandboxDocumentVersion({
  projectId,
  versionId,
  versions,
  dispatch,
}) {
  const sandboxProject = JSON.parse(localStorage.getItem("sandboxProject"));
  sandboxProject.versions = sandboxProject.versions.filter(
    (version) => version.versionId !== versionId
  );
  sandboxProject.documentVersions = sandboxProject.documentVersions.filter(
    (version) => version.versionId !== versionId
  );
  localStorage.setItem("sandboxProject", JSON.stringify(sandboxProject));

  amplitude.getInstance().logEvent("remove document version", {
    "version id": versionId,
    "project id": "sandboxId",
  });

  dispatch({
    type: ACTIONS.REMOVE_DOCUMENT_VERSION,
    payload: { projectId, versionId },
  });
}

export function createSandboxDocumentVersion({
  projectId,
  document,
  userId,
  label = "",
  dispatch,
  addVersionSuccessCB,
}) {
  const itemId = firestoreAutoId();
  const sandboxProject = JSON.parse(localStorage.getItem("sandboxProject"));
  const documentData = sandboxProject.documentVersions;
  documentData
    ? documentData.push({
        document,
        userId,
        label,
        versionId: itemId,
        createdTs: { seconds: Math.floor(Date.now() / 1000) },
      })
    : (sandboxProject.documentVersions = [
        {
          document,
          userId,
          label,
          versionId: itemId,
          createdTs: { seconds: Math.floor(Date.now() / 1000) },
        },
      ]);

  sandboxProject.versions
    ? sandboxProject.versions.push({
        versionId: itemId,
        label,
      })
    : (sandboxProject.versions = [
        {
          versionId: itemId,
          label,
        },
      ]);
  localStorage.setItem("sandboxProject", JSON.stringify(sandboxProject));
  dispatch({
    type: ACTIONS.ADD_DOCUMENT_VERSION,
    payload: {
      projectId,
      version: { label, versionId: itemId },
    },
  });

  addAnalyticsEvent(CATEGORIES.PROJECT, "Create version", projectId);
  amplitude.getInstance().logEvent("create document version", {
    label: label,
    "version id": itemId,
    "project id": "sandboxId",
  });

  if (addVersionSuccessCB) {
    addVersionSuccessCB();
  }
}

export function createSandboxAdHocItem({
  data,
  dispatch,
  projectItems,
  statuses,
  statusId,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create adhoc item data!");
  }
  const itemId = firestoreAutoId();
  let sandboxProjectItems = [];

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );
  if (!localStorage.getItem("sandboxItems")) {
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify([
        {
          orderPosition,
          ...data,

          id: itemId,

          discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
        },
      ])
    );
  } else {
    sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));
    sandboxProjectItems.push({
      orderPosition,
      ...data,
      id: itemId,
      discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
    });

    localStorage.setItem("sandboxItems", JSON.stringify(sandboxProjectItems));
  }

  amplitude.getInstance().logEvent("create ad-hoc task", {
    "project id": "sandboxId",
    "task id": itemId,
  });

  dispatch({
    type: ACTIONS.SET_PROJECT_ITEMS,
    payload: {
      projectItems: [
        ...projectItems,
        {
          id: itemId,
          orderPosition,
          ...data,
        },
      ],
    },
  });
}

export function createSandboxBugItem({
  data,
  dispatch,
  projectItems,
  featureItemId,
  statuses,
  statusId,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create bug item data!");
  }

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );

  const itemId = firestoreAutoId();
  let sandboxProjectItems = [];
  if (!localStorage.getItem("sandboxItems")) {
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify([
        {
          orderPosition,
          ...data,

          id: itemId,
          discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
        },
      ])
    );
  } else {
    sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));
    sandboxProjectItems.push({
      orderPosition,
      ...data,
      id: itemId,
      discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
    });
    localStorage.setItem("sandboxItems", JSON.stringify(sandboxProjectItems));
  }

  amplitude.getInstance().logEvent("create bug", {
    "project id": data.projectId,
    "bug id": itemId,
  });

  dispatch({
    type: ACTIONS.SET_PROJECT_ITEMS,
    payload: {
      projectItems: [
        ...projectItems,
        {
          featureItemId,
          id: itemId,
          orderPosition,
          ...data,
        },
      ],
    },
  });

  toast.success("Bug created");
}
export function createSandboxSubtaskItem({
  data,
  dispatch,
  projectItems,
  featureItemId,
  featureItem,
  statuses,
  statusId,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create bug item data!");
  }

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );

  const itemId = firestoreAutoId();

  let sandboxProjectItems = [];
  if (!localStorage.getItem("sandboxItems")) {
    localStorage.setItem(
      "sandboxItems",
      JSON.stringify([
        {
          orderPosition,
          ...data,
          featureItemId,
          id: itemId,
          discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
        },
      ])
    );
  } else {
    sandboxProjectItems = JSON.parse(localStorage.getItem("sandboxItems"));
    sandboxProjectItems.push({
      featureItemId,
      orderPosition,
      ...data,
      id: itemId,
      discussions: { name: "General", msgCount: 0, id: firestoreAutoId() },
    });
    localStorage.setItem("sandboxItems", JSON.stringify(sandboxProjectItems));
  }

  amplitude.getInstance().logEvent("create subtask", {
    "project id": data.projectId,
    "subtask id": itemId,
  });

  if (featureItem) {
    dispatch({
      type: ACTIONS.SET_PROJECT_ITEMS,
      payload: {
        projectItems: [
          ...projectItems,
          featureItem && featureItem,
          {
            featureItemId,
            id: itemId,
            orderPosition,
            ...data,
          },
        ],
      },
    });
  } else {
    dispatch({
      type: ACTIONS.SET_PROJECT_ITEMS,
      payload: {
        projectItems: [
          ...projectItems,
          {
            featureItemId,
            id: itemId,
            orderPosition,
            ...data,
          },
        ],
      },
    });
  }

  dispatch({
    type: ACTIONS.SHOW_ITEM_DETAILS,
    payload: {
      selectedItemId: itemId,
      selectedItemProjectId: "sandboxId",
      sidebarVisible: true,
    },
  });

  toast.success("Subtask created");
}

export function getSandboxDiscussions(selectedItemId, dispatch) {
  try {
    const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));

    const selectedItem = sandboxItems.filter(
      (item) => item.id === selectedItemId
    );

    const itemDiscussions = selectedItem[0]?.discussions?.map((discussion) => {
      const data = discussion;
      return data;
    });

    dispatch({
      type: ACTIONS.SET_ITEM_DISCUSSIONS,
      payload: itemDiscussions || [],
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.SET_ITEM_DISCUSSIONS,
      payload: [],
    });
  }
}

export function createSandboxDiscussion(selectedItemId, discussionName) {
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
  const selectedItem = sandboxItems.filter(
    (item) => item.id === selectedItemId
  );
  const itemDiscussions = selectedItem[0].discussions;
  itemDiscussions.push({
    name: discussionName,
    msgCount: 0,
    id: firestoreAutoId(),
  });
  selectedItem.discussions = itemDiscussions;
  const updatedSandboxItems = sandboxItems.map((item) =>
    item.id === selectedItem.id ? (item = selectedItem) : item
  );
  localStorage.setItem("sandboxItems", JSON.stringify(updatedSandboxItems));
  toast.success("Discussion started!");
}

export function getSandboxMessages(
  selectedItemId,
  activeDiscussionId,
  dispatch
) {
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
  const selectedItem = sandboxItems.filter(
    (item) => item.id === selectedItemId
  );
  const itemDiscussions = selectedItem[0].discussions;
  const discussionMessages =
    itemDiscussions.filter(
      (discussion) => discussion.id === activeDiscussionId
    )[0].messages || [];
  dispatch({
    type: ACTIONS.SET_DISCUSSION_MESSAGES,
    payload: discussionMessages,
  });
}

export function sendSandboxMessage(
  selectedItemId,
  activeDiscussionId,
  user,
  htmlContent,
  dispatch
) {
  // Batch send message and update msgCount
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
  const selectedItem = sandboxItems.filter(
    (item) => item.id === selectedItemId
  );
  const itemDiscussions = selectedItem[0].discussions;
  const discussionMessages = itemDiscussions.filter(
    (discussion) => discussion.id === activeDiscussionId
  )?.messages;
  const selectedDiscussion = itemDiscussions.filter(
    (disc) => disc.id === activeDiscussionId
  )[0];

  const updatedItemDiscussions = itemDiscussions.map((discussion) => {
    if (discussion.id === activeDiscussionId) {
      discussion.msgCount++;
      discussion.messages
        ? discussion.messages.push({
            discussionId: activeDiscussionId,
            user: user,
            htmlContent: htmlContent,
          })
        : (discussion.messages = [
            {
              discussionId: activeDiscussionId,
              user: user,
              htmlContent: htmlContent,
            },
          ]);
    }
    return discussion;
  });

  const updatedSandboxItems = sandboxItems.map((item) => {
    if (item.id === selectedItemId) {
      item.discussions = updatedItemDiscussions;
    }
    return item;
  });
  localStorage.setItem("sandboxItems", JSON.stringify(updatedSandboxItems));

  getSandboxDiscussions(selectedItemId, dispatch);
  getSandboxMessages(selectedItemId, activeDiscussionId, dispatch);
}

export function addNewSandboxStatus(projectId, statuses, status, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectStatuses = data.statuses;
  projectStatuses.push(status);

  data.statuses = projectStatuses;

  localStorage.setItem("sandboxProject", JSON.stringify(data));
  amplitude.getInstance().logEvent("add status", {
    "project id": projectId,
  });
  dispatch({
    type: ACTIONS.ADD_STATUS,
    payload: { projectId, status },
  });
}

export function removeSandboxStatus(projectId, statuses, id, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectStatuses = data.statuses;

  const updatedStatuses = projectStatuses.filter((st) => st.id !== id);

  data.statuses = updatedStatuses;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("remove status", {
    "project id": projectId,
  });

  dispatch({
    type: ACTIONS.DELETE_STATUS,
    payload: { projectId, id },
  });
}

export function editSandboxStatus(projectId, statuses, status, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectStatuses = data.statuses;

  const updatedStatuses = projectStatuses.map((st) =>
    st.id === status.id
      ? { ...status, label: status.label, color: status.color }
      : st
  );

  data.statuses = updatedStatuses;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("edit status", {
    "project id": projectId,
  });

  dispatch({
    type: ACTIONS.EDIT_STATUS,
    payload: { projectId, status },
  });
}

export function reorderSandboxStatuses(projectId, statusOrder, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const updatedStatuses = [...statusOrder];

  data.statuses = updatedStatuses;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  dispatch({
    type: ACTIONS.REORDER_STATUSES,
    payload: { projectId, statusOrder },
  });
}

export function createSandboxSprint(
  projectId,
  sprints,
  sprint,
  dispatch,
  createSprintCb
) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectSprints = data.sprints;
  projectSprints.push(sprint);

  data.sprints = projectSprints;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("create sprint", {
    "project id": projectId,
  });

  dispatch({
    type: ACTIONS.ADD_SPRINT,
    payload: { projectId, sprint },
  });

  if (createSprintCb) {
    createSprintCb();
  }
}

export function startSandboxSprint(projectId, sprints, sprint, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectSprints = data.sprints;

  const updatedSprints = projectSprints.map((sp) =>
    sp.id === sprint.id
      ? {
          ...sprint,
          name: sprint.name,
          goal: sprint.goal,
          startDate: Date.parse(sprint.startDate),
          endDate: Date.parse(sprint.endDate),
          sprintState: SPRINT_STATE.ACTIVE,
        }
      : sp
  );

  data.sprints = updatedSprints;

  amplitude.getInstance().logEvent("start sprint", {
    "project id": projectId,
    "sprint id": sprint.id,
  });

  localStorage.setItem("sandboxProject", JSON.stringify(data));
  dispatch({
    type: ACTIONS.EDIT_SPRINT,
    payload: { projectId, sprint },
  });
}

export function removeSandboxSprint(projectId, sprints, id, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectSprints = data.sprints;

  const updatedSprints = projectSprints.filter((sp) => sp.id !== id);

  data.sprints = updatedSprints;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("delete sprint", {
    "project id": projectId,
    "sprint id": id,
  });

  dispatch({
    type: ACTIONS.DELETE_SPRINT,
    payload: { projectId, id },
  });
}

export function editSandboxSprints(projectId, sprints, sprint, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectSprints = data.sprints;

  const updatedSprints = projectSprints.map((sp) =>
    sp.id === sprint.id
      ? {
          ...sprint,
          name: sprint.name,
          startDate: sprint.startDate,
          endDate: sprint.endDate,
        }
      : sprint
  );

  data.sprints = updatedSprints;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("edit sprint", {
    "project id": projectId,
    "sprint id": sprint.id,
  });

  dispatch({
    type: ACTIONS.EDIT_SPRINT,
    payload: { projectId, sprint },
  });
}

export function closeSandboxSprint(projectId, sprints, sprint, dispatch) {
  let data = JSON.parse(localStorage.getItem("sandboxProject"));

  const projectSprints = data.sprints;

  const updatedSprints = projectSprints.map((sp) =>
    sp.id === sprint.id
      ? {
          ...sprint,
          sprintState: SPRINT_STATE.CLOSED,
        }
      : sp
  );

  data.sprints = updatedSprints;

  localStorage.setItem("sandboxProject", JSON.stringify(data));

  amplitude.getInstance().logEvent("close sprint", {
    "project id": projectId,
    "sprint id": sprint.id,
  });

  dispatch({
    type: ACTIONS.CLOSE_SPRINT,
    payload: { projectId, sprint },
  });
}

export function sandboxBulkItemChange(
  items,
  projectItems,
  dispatch,
  successCb
) {
  // Batch write the changes to items content
  if (items.length) {
    const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
    const updatedItems = sandboxItems.map((sItem) => {
      const updatedItem = items.find((it) => it.id === sItem.id);

      if (updatedItem) {
        return {
          ...sItem,
          ...updatedItem,
        };
      }

      return { ...sItem };
    });
    localStorage.setItem("sandboxItems", JSON.stringify(updatedItems));

    dispatch({
      type: ACTIONS.SET_PROJECT_ITEMS,
      payload: {
        projectItems: projectItems.map((pItem) => {
          const updatedItem = items.find((it) => it.id === pItem.id);

          if (updatedItem) {
            return {
              ...pItem,
              ...updatedItem,
            };
          }

          return { ...pItem };
        }),
      },
    });
    if (successCb) {
      successCb();
    }
  }
}

export function changeSandboxProjectName(projectId, projectName, dispatch) {
  if (!projectId || !projectName) return;
  const data = JSON.parse(localStorage.getItem("sandboxProject"));
  data.name = projectName;
  localStorage.setItem("sandboxProject", JSON.stringify(data));

  dispatch({
    type: ACTIONS.RENAME_PROJECT,
    payload: {
      projectId,
      projectName,
    },
  });
}

export function getSandboxItemHistory(itemId, itemDataCb) {
  const sandboxItems = JSON.parse(localStorage.getItem("sandboxItems"));
  const selectedItem = sandboxItems.filter((item) => item.id === itemId)[0];
  if (selectedItem) {
    itemDataCb(selectedItem.itemHistory.map((doc) => ({ ...doc, id: doc.id })));
  }
}
