/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { useDispatch, useTrackedState, ACTIONS } from "context";
import { _insertSection } from "utils/editor/draft-utils";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import TitleContent from "../TitleContent";
import Modal, { MODAL_NAMES } from "./Modal";

const CreateSectionFromWBS = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { editorState, hierarchyState, activeModalData } = state;

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState(null);

  const onSubmit = () => {
    try {
      let insertResult = null;

      if (activeModalData.elementId === "root") {
        // Adding a new PAGE to the END. Same as adding a PAGE from TOC

        // FIXME
        return;
      } else {
        insertResult = _insertSection(
          editorState,
          hierarchyState,
          activeModalData.elementId,
          "section",
          title
        );
      }

      dispatch({
        type: ACTIONS.EDITOR_CONTENTS_CHANGED_UPDATE_KEY,
        payload: {
          newEditorState: insertResult.newEditorState,
        },
      });

      // Dispatch section creation
      dispatch({
        type: ACTIONS.CLOSE_MODAL,
      });
    } catch (error) {
      // TODO - FIXME!
      // console.log("Whoops", error);
    }
  };

  const onClose = () => {
    setTitle("");

    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  };

  return (
    <Modal
      closeFunctionCallback={onClose}
      name={MODAL_NAMES.CREATE_SECTION_FROM_WBS}
      style={{ height: "300px", width: "600px" }}
    >
      <div className="h-full overflow-hidden">
        <div className="w-full px-8 py-4 border-b border-grayBorder">
          <h2 className="text-2xl font-bold text-nearBlack">
            Create new section
          </h2>
          <div className="flex">
            <CloseIcon
              onClick={onClose}
              className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            />
          </div>
        </div>

        <div className="flex items-start justify-center w-full h-full px-8 overflow-x-visible">
          <div className="flex flex-col items-start justify-start w-full h-full py-6 pr-12 ">
            <TitleContent
              title={title}
              setMetadataTitle={setTitle}
              setMetadataContent={setContent}
              content={content}
              showContentInput={false}
            />

            <div className="flex justify-end w-full mt-8">
              <button
                className="relative w-24 mr-2 btn btn-primary"
                onClick={onSubmit}
              >
                Add
              </button>
              <button
                className="relative w-24 mr-5 btn btn-danger"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSectionFromWBS;
