import React from "react";

import { useDispatch, ACTIONS, useTrackedState } from "context";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import Modal, { MODAL_NAMES } from "./Modal";

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { activeModalData } = state;
  const { message, action, modalTitle } = activeModalData;

  return (
    <Modal
      style={{ height: "270px", width: "600px" }}
      name={MODAL_NAMES.CONFIRMATION_MODAL}
    >
      <div className="flex flex-col w-full h-full ">
        <div className="relative w-full px-8 py-4 border-b border-grayBorder">
          <CloseIcon
            className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            onClick={() =>
              dispatch({
                type: ACTIONS.CLOSE_MODAL,
              })
            }
          />
          <h2 className="w-full text-2xl text-nearBlack font-bold">
            {modalTitle}
          </h2>
        </div>
        <div className="mt-12 px-8 flex flex-col justify-start items-center">
          <h3 className="text-xl text-primaryText">{message}</h3>
          <div className="flex justify-center items-center mt-6">
            <button
              onClick={() => {
                /**
                 * Action is the callback that should happen after the user
                 * confirms the modal.
                 */
                if (action) {
                  action();
                }

                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                });
              }}
              className="relative w-24 mr-4 btn btn-primary"
            >
              Yes
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                })
              }
              className="relative w-24 btn btn-danger"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
