import React, { useRef, useEffect, useState, forwardRef } from "react";

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  // useAccordionItemContext,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { canUserEditDocument } from "utils/permissions";
import { ReactComponent as DocumentIcon } from "assets/document-icon.svg";
import { ReactComponent as ArrowDown } from "assets/arrow-down-icon.svg";
import { ReactComponent as ArrowUp } from "assets/arrow-up-icon.svg";

// import { ReactComponent as ArrowDown } from "../../assets/arrow-down-icon.svg";
// import { ReactComponent as ArrowUp } from "../../assets/arrow-up-icon.svg";
// import { ReactComponent as ZoomIcon } from "../../assets/center_focus_weak-24px.svg";

// function useDivHeight() {
//   const ref = useRef(null);
//   const [height, setHeight] = useState(0);

//   useEffect(() => {
//     const resizeObserver = new ResizeObserver(([entry]) => {
//       requestAnimationFrame(() => {
//         if (!entry) {
//           return;
//         }
//         setHeight(entry.target.getBoundingClientRect().height);
//       });
//     });

//     if (ref.current) {
//       resizeObserver.observe(ref.current);
//     }

//     return () => {
//       resizeObserver.disconnect();
//     };
//   }, []);

//   return { ref, height };
// }

const AnimatedPanel = forwardRef(({ children }, forwardedRef) => {
  // const { isExpanded } = useAccordionItemContext();
  // const { ref, height } = useDivHeight();
  return (
    <AccordionPanel
      // style={{
      //   opacity: `${isExpanded ? 1 : 0}`,
      //   height: isExpanded ? height : 0,
      //   overflow: "hidden",
      // }}
      // hidden={false}
      // ref={forwardedRef}
      className={
        "transition-all duration-300 transform focus:outline-none overflow-auto"
      }
    >
      <div /*>ref={ref}*/>{children}</div>
    </AccordionPanel>
  );
});

const TOCAccordionItem = ({ onTocClick, h1Block, onTOCItemMove }) => {
  function onDragEnd(result) {
    onTOCItemMove({
      topLevelHierarchyBlock: h1Block,
      changeObject: result,
    });
  }

  const getListStyle = (isDraggingOver) => ({
    padding: 0,
    paddingTop: 0,
  });

  // Sorting the block children by the length of their headerLevel to determine which need to have a different indentation icon
  const sortedChildren = [...h1Block.children].sort(
    (a, b) => a.headerLevel.length - b.headerLevel.length
  );
  return (
    <AccordionItem className="mb-3 rounded-xl">
      <AccordionButton
        className={`focus:outline-none w-full rounded-xl shadow-singleTaskComponent overflow-auto border border-gray-200`}
      >
        <div
          id="accordion_header"
          key={`${h1Block.headerLevel}${h1Block.text}`}
          onClick={() => onTocClick(h1Block.key)}
          className={`flex items-center justify-between w-full pr-4 text-sm font-medium group cursor-pointer`}
        >
          <div className="items-center flex ml-1 text-left focus:outline-none overflow-auto p-2">
            <DocumentIcon className="stroke-current text-gray-500 mr-2 " />

            <div>{h1Block.headerLevel} </div>
            <div className="ml-4 truncate pr-5">{h1Block.text}</div>
            <ArrowUp className="stroke-current absolute right-12 " />
            <ArrowDown className="stroke-current text-gray-500 absolute right-12 " />
          </div>
        </div>

        <AnimatedPanel>
          <div className={`${h1Block.children.length && "pb-4"}`}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={h1Block.key}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className={`border-l-2 border-blue-500 ml-4`}
                  >
                    {h1Block.children.map((child, index) => (
                      <div
                        className={`${
                          child.headerLevel.length === 6 ||
                          child.headerLevel.length === 8 ||
                          child.headerLevel.length === 10 ||
                          child.headerLevel.length === 12
                            ? "border-l border-blue-500 ml-4 flex"
                            : ""
                        }`}
                      >
                        <div
                          className={` ${
                            child.headerLevel.length === 8 ||
                            child.headerLevel.length === 10 ||
                            child.headerLevel.length === 12
                              ? "border-l border-blue-500 ml-4 flex"
                              : ""
                          }`}
                        >
                          <div
                            className={` ${
                              child.headerLevel.length === 10 ||
                              child.headerLevel.length === 12
                                ? "border-l border-blue-500 ml-4 flex"
                                : ""
                            }`}
                          >
                            <Draggable
                              key={child.key}
                              draggableId={child.key}
                              index={index}
                              isDragDisabled={!canUserEditDocument()}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // key={`${child.headerLevel}${child.text}`}
                                  className="drag relative ml-3 flex justify-start text-xs font-medium group p-1.5"
                                >
                                  {/* TODO, FIXME - Disable zoom until we can reliably hide all content types */}
                                  {/* <button
                          className="hidden group-hover:block z-50 absolute left-0"
                          onClick={() => onZoomClick(child.key)}
                        >
                          <ZoomIcon className="w-4 h-4" />
                        </button> */}

                                  <div
                                    className={`relative flex text-left`}
                                    onClick={() => onTocClick(child.key)}
                                  >
                                    <div>{child.headerLevel} </div>
                                    <div className={`ml-1 truncate`}>
                                      {child.text}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        </div>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </AnimatedPanel>
      </AccordionButton>
    </AccordionItem>
  );
};

export default TOCAccordionItem;
