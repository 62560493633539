import React from "react";

import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { ReactComponent as DropdownArrow } from "assets/arrow_right.svg";
import { ReactComponent as StatusesIcon } from "assets/statuses_icon.svg";
import { useTrackedState } from "context";
import { canUserEditMeta } from "utils/permissions";
import { getStatusById, getStatusBySlug, getStatusId } from "utils/status";

import { STATUSES_SLUGS } from "../../constants";

const Status = (props) => {
  const [statusBlockOpen, setStatusBlockOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const state = useTrackedState();
  const { loadedProjects, selectedItemProjectId } = state;
  let { status, setMetaStatus, isDashboard } = props;

  const statuses =
    loadedProjects && props.metadata && props.metadata.projectId
      ? loadedProjects[props.metadata.projectId].statuses
      : loadedProjects[selectedItemProjectId].statuses;
  const statusData =
    getStatusById(statuses, status) ||
    getStatusBySlug(statuses, STATUSES_SLUGS.OPEN);

  // We assign the status manually if the bug is being created on dashboard route.
  // This is the consequence of our fetching flow in dashboard component.
  // Since we were trying to reduce the number of fetches,
  // sometimes the modal receives data of previous project and is not able to find the statuses which belong to its project
  status = isDashboard
    ? getStatusBySlug(statuses, STATUSES_SLUGS.OPEN).id
    : status;
  React.useEffect(() => {
    if (!isDashboard) return;

    setMetaStatus(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const color = statusData ? statusData.color : "gray";
  const label = statusData ? statusData.label : "label";

  const handleCloseItem = () => {
    window.confirm(
      `Are you sure you want to set this items status to "Closed"?`
    );
    setMetaStatus(getStatusId(statuses, "CLOSED"));
    window.alert("Don't forget to apply changes!");
  };

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  const filteredStatuses = statuses?.filter((status) => {
    if (status.slug === "DRAFT") return false;
    if (inputValue === "") return true;
    if (
      status.label.toLowerCase().indexOf(inputValue.toLocaleLowerCase()) >= 0
    ) {
      return true;
    }

    return false;
  });

  return statusData ? (
    <div className="sidebar-item">
      <p className="w-3/12 font-normal text-darkBlue">Status</p>
      {statusBlockOpen ? (
        <Combobox
          openOnFocus
          aria-labelledby="demo"
          onSelect={(selectedItem) => {
            setMetaStatus(selectedItem);

            setStatusBlockOpen(false);
          }}
          onBlur={() =>
            setTimeout(() => {
              setStatusBlockOpen(false);
              setInputValue("");
            }, 200)
          }
        >
          <ComboboxInput
            selectOnClick
            placeholder="Search for status"
            className="sidebar-item-input"
            autoFocus
            autocomplete={false}
            onChange={handleChange}
          />

          <ComboboxPopover style={{ zIndex: "60", borderColor: "transparent" }}>
            <ComboboxList className="sidebar-item-dropdown-list">
              {filteredStatuses.map((status, index) => (
                <ComboboxOption
                  className="sidebar-item-dropdown-option"
                  key={index}
                  value={`${status.id}`}
                >
                  <div
                    className={`w-6 h-6 bg-${status.color}-600 rounded-lg mr-2`}
                  ></div>
                  {status.label}
                </ComboboxOption>
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      ) : (
        <span
          onClick={() => {
            canUserEditMeta() && setStatusBlockOpen(!statusBlockOpen);
          }}
          className={`flex items-center text-sm text-white rounded-lg px-3 py-1 w-2/3 md:w-1/2 leading-6 cursor-pointer border border-transparent btn-transition hover:bg-opacity-75 bg-${color}-600`}
        >
          <StatusesIcon className="mr-2 text-white fill-current stroke-current" />
          {label}
          <DropdownArrow className="ml-auto mr-1 text-white transform rotate-90 fill-current" />
        </span>
      )}
      {
        // This is missing in ddesigns
        /* getStatusBySlug(statuses, 'CLOSED') && (
          <button
            onClick={() => handleCloseItem()}
            className="ml-2 btn btn-danger"
          >
            Close item
          </button>
        )} */
      }
    </div>
  ) : null;
};

export default Status;
