import React from "react";

const sizes = {
  sm: 2,
  md: 4,
  lg: 8
};

const Loader = ({ size, className }) => {
  const borderSize = sizes[size];
  const whSize = sizes[size] * 2;

  return (
    <div
      className={`loader ease-linear rounded-full border-${borderSize} border-t-${borderSize} border-gray-200 h-${whSize} w-${whSize} border-top ${className}`}
    ></div>
  );
};

Loader.defaultProps = {
  size: "sm",
};

export default Loader;
