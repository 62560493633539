import React, { useState } from "react";

import { createSubtask } from "api";
import { useDispatch, useTrackedState } from "context";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setMetadataPriority,
  setLabels,
  setMetadataTitleAndContent,
  ITEM_TYPES,
  metadataFactory,
  setItemType,
} from "utils/metadataModel";
import { getStatusId } from "utils/status";

import TaskAssignee from "../DraftEditor/components/Tasks/TaskAssignee";
import TaskDate from "../DraftEditor/components/Tasks/TaskDate";
import TaskPriority from "../DraftEditor/components/Tasks/TaskPriority";
import TaskSprint from "../DraftEditor/components/Tasks/TaskSprint";
import TaskStatus from "../DraftEditor/components/Tasks/TaskStatus";
import LabelsGlobalSearch from "./Labels";
import TaskTitleSearch from "./TaskTitleSearch";

const EmptyTaskSearchModal = (props) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { projectItems, activeProjectId, loadedProjects } = state;

  const statuses = loadedProjects[activeProjectId]?.statuses;
  const openStatusId = getStatusId(statuses, "OPEN");

  const [metadata, setMetadata] = useState(
    setItemType(metadataFactory({ status: openStatusId }), ITEM_TYPES.SUBTASK)
  );

  const title = metadata && metadata.title ? metadata.title : "";
  const content = metadata ? metadata.htmlContent : null;
  const status = metadata ? metadata.status : null;
  const assignee = metadata ? metadata.assignee : null;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const sprint = metadata ? metadata.sprint : null;
  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;

  const setTitle = (title) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  function setStatus(status) {
    setMetadata(setMetadataStatus(metadata, status));
  }

  function setAssignee(assignee) {
    setMetadata(setMetadataAssignee(metadata, assignee));
  }

  function setPriority(priority) {
    setMetadata(setMetadataPriority(metadata, priority));
  }

  function setSprint(sprint) {
    setMetadata(setMetadataSprint(metadata, sprint));
  }

  function setMetaDueDate(date) {
    setMetadata(setMetadataDueDate(metadata, date));
  }

  function setMetaStartDate(date) {
    setMetadata(setMetadataStartDate(metadata, date));
  }

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labelIds) {
    setMetadata(setLabels(metadata, labelIds));
  }
  return (
    <div
      id={props.sandboxId && "sandboxTask"}
      className="w-full p-4 flex flex-col justify-between border shadow-singleTaskComponent rounded-xl"
    >
      <div className="flex justify-between items-center mb-1">
        <TaskTitleSearch
          dispatch={dispatch}
          metadata={metadata}
          activeProjectId={activeProjectId}
          projectItems={projectItems}
          setMetaTitle={setTitle}
          metaTitle={title}
          editorStateForceSelection={props.editorStateForceSelection}
          itemId={props.id}
          isEmpty
        />
      </div>

      {title && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <TaskStatus
                dispatch={dispatch}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                setMetaStatus={setStatus}
                metaStatus={status}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
              <TaskAssignee
                dispatch={dispatch}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                setMetaAssignee={setAssignee}
                metaAssignee={assignee}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
              <TaskPriority
                dispatch={dispatch}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                setMetaPriority={setPriority}
                metaPriority={priority}
                itemId={props.id}
              />
              <TaskDate
                dispatch={dispatch}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                metaDueDate={dueDate}
                metaStartDate={startDate}
                setMetaStartDate={setMetaStartDate}
                setMetaDueDate={setMetaDueDate}
                itemId={props.id}
              />
              <TaskSprint
                dispatch={dispatch}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                setMetaSprint={setSprint}
                metaSprint={sprint}
                itemId={props.id}
              />
              <LabelsGlobalSearch
                dispatch={dispatch}
                setMetaLabels={setMetaLabels}
                metaLabels={labels}
                metadata={metadata}
                activeProjectId={activeProjectId}
                projectItems={projectItems}
                editorStateForceSelection={props.editorStateForceSelection}
                itemId={props.id}
              />
            </div>
            <button
              id="sandboxTaskButton"
              className="btn btn-primary ml-auto text-sm w-16 p-1"
              onClick={() => {
                setTimeout(() => {
                  createSubtask({
                    data: {
                      ...metadata,
                      featureItemId: props.pItem.projectItemMetadata.id,
                      projectId: state.activeProjectId,
                      itemType: "SUBTASK",
                    },
                    statuses,
                    statusId: metadata.status,
                    dispatch,
                    projectItems,
                    featureItemId: props.pItem.projectItemMetadata.id,
                    featureItem: null,
                  });
                }, 200);

                setMetadata(setTitle(""));
              }}
            >
              Create
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmptyTaskSearchModal;
