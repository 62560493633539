import React, { useState } from "react";

import { createLabel } from "api";
import { updateProjectItem } from "api";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as EmptyLabels } from "assets/editor-tasks-icons/labels.svg";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState, useDispatch } from "context";
import useOnClickOutside from "hooks/useOnClickOutside";
import CreatableSelect from "react-select/creatable";
import { firestoreAutoId } from "utils/firestoreId";
import {
  getLoadedProjectData,
  getProjectDataByKey,
} from "utils/loadedProjects";

import { PERMISSIONS_SUBJECTS, PERMISSIONS_ACTIONS } from "../../constants";
// This component was created because an issue was happening when using the same component for inline
// editing in Draft editor and in Global search
// Problem was with onFocus()
// This needs to be fixed differently at some point

const LabelsGlobalSearch = (props) => {
  const state = useTrackedState();
  const dispatch = useDispatch();
  const { loadedProjects, selectedItemProjectId, activeProjectId } = state;
  const { editorStateForceSelection, metaLabels, setMetaLabels } = props;
  const [labelsDisplayed, setLabelsDisplayed] = useState(false);

  const ref = React.useRef();

  useOnClickOutside(ref, () => setLabelsDisplayed(false));

  const suggestedLabels =
    getProjectDataByKey(
      getLoadedProjectData(loadedProjects, selectedItemProjectId),
      "labels"
    ) ?? [];

  const existingTags = [
    {
      label: "Suggested (You may create your own)",
      options: suggestedLabels,
    },
  ];

  const labelsValues = loadedProjects[
    selectedItemProjectId || activeProjectId
  ].labels.filter(
    (projectLabel) =>
      metaLabels &&
      metaLabels.some((label) => projectLabel?.id?.includes(label))
  );

  const handleChange = (newValue, actionMeta) => {
    newValue.filter(
      (value) => value.hasOwnProperty("__isNew__") && delete value.__isNew__
    );

    const labelsIds = newValue.map((value) => value.id);

    updateProjectItem({
      dispatch: props.dispatch,
      itemId: props.itemId,
      projectId: props.activeProjectId,
      projectItems: props.projectItems,
      data: { ...props.metadata, labels: labelsIds },
    });

    setMetaLabels(labelsIds);

    if (editorStateForceSelection) {
      editorStateForceSelection();
    }
  };

  const handleCreate = (inputValue) => {
    const id = firestoreAutoId();

    metaLabels ? setMetaLabels([...metaLabels, id]) : setMetaLabels([id]);

    updateProjectItem({
      dispatch: props.dispatch,
      itemId: props.itemId,
      projectId: props.activeProjectId,
      projectItems: props.projectItems,
      data: {
        ...props.metadata,
        labels: metaLabels ? [...metaLabels, id] : [id],
      },
    });

    createLabel(
      activeProjectId,
      suggestedLabels,
      { label: inputValue, value: inputValue, id: id },
      dispatch
    );

    if (editorStateForceSelection) {
      editorStateForceSelection();
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      paddingTop: "0.25rem",
      "&:hover": {
        backgroundColor: "#E7E9EC",
      },
    }),

    container: (provided, state) => ({
      ...provided,
      width: "340px",
      padding: "0",
      borderRadius: "0.375rem",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      width: "288px",
    }),

    control: (provided, state) => ({
      ...provided,
      width: "100%",
      minHeight: "0",
      backgroundColor: "#FFFFFF",
      transition: "all 300ms ease-in-out",
      paddingRight: "0.25rem",
      border: `1px solid ${state.isFocused ? "#002c53" : "transparent"}`,
      boxShadow: "none",
      "&:hover": {
        borderColor: "#002c53",
      },
      width: "288px",
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: "#92A4B7",
    }),

    multiValue: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      backgroundColor: "#E0E3EC",
    }),

    multiValueRemove: (provided, state) => ({
      ...provided,
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
      "&:hover": {
        color: "white",
        backgroundColor: "#3B82F6",
        cursor: "pointer",
      },
    }),

    menu: (provided, state) => ({
      ...provided,
      margin: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
      borderRight: "1px solid rgba(0, 0, 0, 0.05)",

      boxShadow: " 0px 0px 2px 0px rgba(0, 0, 0, 0.5",
      borderRadius: "0px 0px 3px 3px",
      width: "288px",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      //paddingLeft: "0.75rem",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    }),
  };

  return (
    <div className="flex">
      <div className="relative inline-flex">
        <EmptyLabels
          onClick={() => setLabelsDisplayed(!labelsDisplayed)}
          className={`${
            labelsDisplayed && "text-chillBlue"
          } text-gray-500 stroke-current hover:text-chillBlue mr-2 cursor-pointer`}
        />
        {labelsValues.length > 0 && (
          <div className="flex items-center gap-1 py-1">
            {labelsValues.slice(0, 3).map((label) => (
              <span
                onClick={() => setLabelsDisplayed(!labelsDisplayed)}
                className="relative flex items-center text-xs rounded-2xl group px-2 bg-gray-200 text-darkGray"
              >
                <Can
                  I={PERMISSIONS_ACTIONS.MANAGE}
                  a={PERMISSIONS_SUBJECTS.METADATA}
                >
                  <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100 z-50">
                    <ClearIcon
                      className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                      onClick={(e) => {
                        e.stopPropagation();
                        const newLabels = metaLabels.filter(
                          (labelId) => labelId !== label.id
                        );

                        setMetaLabels([...newLabels]);

                        updateProjectItem({
                          dispatch: props.dispatch,
                          itemId: props.itemId,
                          projectId: props.activeProjectId,
                          projectItems: props.projectItems,
                          data: { ...props.metadata, labels: [...newLabels] },
                        });

                        if (editorStateForceSelection) {
                          editorStateForceSelection();
                        }
                      }}
                    />
                  </div>
                </Can>
                {label.label}
              </span>
            ))}
            {labelsValues.length > 3 ? (
              <div
                className="relative flex items-center text-xs mr-1 rounded-2xl group px-2 bg-gray-200 text-darkGray cursor-pointer"
                onClick={() => setLabelsDisplayed(!labelsDisplayed)}
              >
                {"+" + (labelsValues.length - 3)}
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {labelsDisplayed && (
          <div ref={ref} className="task-item-dropdown mt-8 w-72 ">
            <div className="m-2">
              {labelsValues.length > 0 && (
                <div className="flex flex-wrap items-center gap-2 p-1">
                  {labelsValues.map((label) => (
                    <span className="relative flex items-center text-xs rounded-2xl group px-2 bg-gray-200 text-darkGray min-w-max">
                      <Can
                        I={PERMISSIONS_ACTIONS.MANAGE}
                        a={PERMISSIONS_SUBJECTS.METADATA}
                      >
                        <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100 z-50">
                          <ClearIcon
                            className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                            onClick={(e) => {
                              e.stopPropagation();
                              const newLabels = metaLabels.filter(
                                (labelId) => labelId !== label.id
                              );

                              setMetaLabels([...newLabels]);

                              updateProjectItem({
                                dispatch: props.dispatch,
                                itemId: props.itemId,
                                projectId: props.activeProjectId,
                                projectItems: props.projectItems,
                                data: {
                                  ...props.metadata,
                                  labels: [...newLabels],
                                },
                              });

                              if (editorStateForceSelection) {
                                editorStateForceSelection();
                              }
                            }}
                          />
                        </div>
                      </Can>
                      {label.label}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className="border"></div>
            <CreatableSelect
              isMulti
              onChange={handleChange}
              onCreateOption={handleCreate}
              value={labelsValues}
              options={existingTags}
              styles={customStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              placeholder={"Create a label"}
              menuPlacement={"auto"}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                if (
                  inputValue.trim().length === 0 ||
                  selectOptions[0].options.find(
                    (option) => option.label === inputValue
                  )
                ) {
                  return false;
                }
                return true;
              }}
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelsGlobalSearch;
