import React from "react";

import { createInlineStyleButton } from "@draft-js-plugins/buttons";

export default createInlineStyleButton({
  style: "BOLD",
  children: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67969 16C11 16 12.6602 15.6289 13.6602 14.8867C14.6602 14.1445 15.1602 13.1289 15.1602 11.8398C15.1602 10.6914 14.7695 9.75977 13.9883 9.04492C13.207 8.33008 12.0352 7.83203 10.4727 7.55078C11.8008 7.19922 12.7949 6.71484 13.4551 6.09766C14.1152 5.48047 14.4453 4.72656 14.4453 3.83594C14.4453 2.65625 13.9453 1.72461 12.9453 1.04102C11.9453 0.357422 10.0703 0.015625 7.32031 0.015625H6.45312L0.347656 0.191406V1.49219C1.21484 1.49219 1.77734 1.62891 2.03516 1.90234C2.29297 2.17578 2.42188 2.6875 2.42188 3.4375V12.7539C2.42188 13.5273 2.28516 14.0449 2.01172 14.3066C1.73828 14.5684 1.18359 14.6992 0.347656 14.6992V16H8.67969ZM7.39062 6.87109H6.58203V1.39844C7.42578 1.39844 8.06445 1.46094 8.49805 1.58594C8.93164 1.71094 9.28125 1.98828 9.54688 2.41797C9.8125 2.84766 9.94531 3.43359 9.94531 4.17578C9.94531 5.16797 9.73438 5.86523 9.3125 6.26758C8.89062 6.66992 8.25 6.87109 7.39062 6.87109ZM7.24414 14.377C7.50586 14.5293 7.875 14.6055 8.35156 14.6055C9.09375 14.6055 9.65039 14.3438 10.0215 13.8203C10.3926 13.2969 10.5781 12.5117 10.5781 11.4648C10.5781 10.6289 10.457 9.97461 10.2148 9.50195C9.97266 9.0293 9.64062 8.71094 9.21875 8.54688C8.79688 8.38281 8.17188 8.30078 7.34375 8.30078H6.58203V12.8125C6.58203 13.25 6.62695 13.582 6.7168 13.8086C6.80664 14.0352 6.98242 14.2246 7.24414 14.377Z"
      />
    </svg>
  ),
});
