import React from "react";

import { createBlockStyleButton } from "@draft-js-plugins/buttons";

export default createBlockStyleButton({
  blockType: "ordered-list-item",
  children: (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8639 5V0.783997H2.0799C1.81857 1.216 1.38657 1.472 0.783898 1.552V2.408C1.25323 2.312 1.59457 2.18133 1.8079 2.016V5H2.8639ZM3.5039 13V12.128H1.7759C1.8399 12.0107 2.0239 11.832 2.3279 11.592C2.75457 11.256 3.04256 10.9827 3.1919 10.772C3.34123 10.5613 3.4159 10.3173 3.4159 10.04C3.4159 9.65066 3.28657 9.33733 3.0279 9.1C2.76923 8.86266 2.42123 8.744 1.9839 8.744C1.14123 8.744 0.623898 9.11733 0.431898 9.864L1.4719 10.032C1.5359 9.75466 1.68523 9.616 1.9199 9.616C2.03723 9.616 2.13457 9.65333 2.2119 9.728C2.28923 9.80266 2.3279 9.90133 2.3279 10.024C2.3279 10.184 2.2679 10.3413 2.1479 10.496C2.0279 10.6507 1.80657 10.856 1.4839 11.112C1.16123 11.368 0.894565 11.6587 0.683898 11.984C0.473232 12.3093 0.367898 12.648 0.367898 13H3.5039ZM3.0759 20.748C2.7799 20.98 2.39457 21.096 1.9199 21.096C1.50923 21.096 1.15323 20.9933 0.851898 20.788C0.550565 20.5827 0.365232 20.312 0.295898 19.976L1.2879 19.792C1.31457 19.9467 1.38523 20.0747 1.4999 20.176C1.61457 20.2773 1.75457 20.328 1.9199 20.328C2.07457 20.328 2.2039 20.28 2.3079 20.184C2.4119 20.088 2.4639 19.96 2.4639 19.8C2.4639 19.6133 2.40523 19.4653 2.2879 19.356C2.17057 19.2467 1.99457 19.192 1.7599 19.192L1.6079 19.2L1.6879 18.488H1.7919C1.93057 18.488 2.04523 18.444 2.1359 18.356C2.22657 18.268 2.2719 18.152 2.2719 18.008C2.2719 17.9013 2.2399 17.804 2.1759 17.716C2.1119 17.628 2.01857 17.584 1.8959 17.584C1.6879 17.584 1.54123 17.6853 1.4559 17.888L0.463898 17.728C0.698565 17.072 1.18923 16.744 1.9359 16.744C2.29857 16.744 2.61857 16.8413 2.8959 17.036C3.17323 17.2307 3.3119 17.512 3.3119 17.88C3.3119 18.28 3.11457 18.5733 2.7199 18.76C2.96523 18.84 3.1599 18.98 3.3039 19.18C3.4479 19.38 3.5199 19.6053 3.5199 19.856C3.5199 20.2187 3.3719 20.516 3.0759 20.748Z"
      />
      <rect opacity="0.4" x="6" y="18" width="21" height="3" rx="1.5" />
      <rect opacity="0.4" x="6" y="10" width="21" height="3" rx="1.5" />
      <rect opacity="0.4" x="6" y="2" width="21" height="3" rx="1.5" />
    </svg>
  ),
});
