import React from "react";

import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import { useDispatch, ACTIONS, useTrackedState } from "context";
import { convertToRaw } from "draft-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";

import { createDocumentVersion } from "../../api";
import { MOCK_MEMBERS } from "../../constants";
import { auth } from "../../firebaseSetup";
import { isEmpty } from "../../utils/emptyString";
import Modal, { MODAL_NAMES } from "./Modal";

const AddVersionModal = () => {
  const [versionName, setVersionName] = React.useState("");

  const [user] = useAuthState(auth);

  const state = useTrackedState();
  const dispatch = useDispatch();

  function versionNameChanged(event) {
    const versionName = event.target.value;
    setVersionName(versionName);
  }

  function submitAddVersion(e) {
    e.preventDefault();

    if (isEmpty(versionName)) {
      toast.error("Version label can't be empty");
      return;
    }

    if (state.loadedProjects[state.activeProjectId].versions) {
      const findVersionLabel = state.loadedProjects[
        state.activeProjectId
      ].versions.find((e) => e.label == versionName);
      if (findVersionLabel) {
        toast.warning("This version name already exsists.");
      } else {
        createDocumentVersion({
          projectId: state.activeProjectId,
          document: convertToRaw(state.editorState.getCurrentContent()),
          label: versionName,
          userId: user?.uid || MOCK_MEMBERS.SANDBOX_MEMBER,
          dispatch,
          addVersionSuccessCB: () => {
            toast.success("Version created");
            setVersionName("");
            dispatch({
              type: ACTIONS.CLOSE_MODAL,
            });
          },
        });
      }
    } else {
      createDocumentVersion({
        projectId: state.activeProjectId,
        document: convertToRaw(state.editorState.getCurrentContent()),
        label: versionName,
        userId: user?.uid || MOCK_MEMBERS.SANDBOX_MEMBER,
        dispatch,
        addVersionSuccessCB: () => {
          toast.success("Version created");
          setVersionName("");
          dispatch({
            type: ACTIONS.CLOSE_MODAL,
          });
        },
      });
    }
  }

  const onClose = () => {
    setVersionName("");
  };

  return (
    <Modal
      style={{ height: "280px", width: "530px" }}
      name={MODAL_NAMES.ADD_VERSION_MODAL}
      closeFunctionCallback={onClose}
    >
      <div className="w-full column-center">
        <div className="w-full px-8 py-4 border-b border-grayBorder">
          <h2 className="text-2xl font-bold text-nearBlack">
            Add document version
          </h2>
          <div className="flex">
            <CloseIcon
              onClick={() => {
                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                });
              }}
              className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            />
          </div>
        </div>
        <div className="p-6 w-full">
          <label
            className="w-full mt-4 mb-3 text-primaryText font-bold"
            htmlFor="project_name"
          >
            Version name
          </label>
          <form className="w-full" onSubmit={submitAddVersion}>
            <input
              className="w-full h-10 px-4 py-2 mb-10 mt-3 border"
              type="text"
              autoFocus
              name="project_name"
              id="project_name"
              value={versionName}
              onChange={versionNameChanged}
              placeholder="Version title"
            />

            <div className="flex w-full">
              <button
                className="relative w-24 mr-4 btn btn-primary"
                type="submit"
              >
                Apply
              </button>
              <button
                className="relative w-24 mr-5 btn btn-danger"
                onClick={() => {
                  setVersionName("");
                  dispatch({
                    type: ACTIONS.CLOSE_MODAL,
                  });
                }}
              >
                Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddVersionModal;
