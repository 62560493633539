/**
 * Hook to remove boilerplate when only loggedIn
 * userId is required.
 *
 * TODO - use this hook in all relevant places!
 */

import { auth } from "firebaseSetup";
import { useAuthState } from "react-firebase-hooks/auth";

function useUserId() {
  const [user] = useAuthState(auth);

  return user && user.uid;
}

export default useUserId;
