/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { useDispatch, useTrackedState, ACTIONS } from "context";
import { getStatusId } from "utils/status";

import { createAdHocItem, updateProjectItem } from "../../api";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setMetadataTitleAndContent,
  setItemType,
  setMetadataPriority,
  setLabels,
  ITEM_TYPES,
  metadataFactory,
} from "../../utils/metadataModel";
import Assignee from "../Assignee";
import Date from "../Date";
import Labels from "../Labels";
import Priority from "../Priority";
import Sprint from "../Sprint";
import Status from "../Status";
import TitleContent from "../TitleContent";
import Modal, { MODAL_NAMES } from "./Modal";

const CreateAdhocItemModal = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();

  const statuses =
    state.selectedItemProjectId && Object.keys(state.loadedProjects).length > 0
      ? state.loadedProjects[state.selectedItemProjectId].statuses
      : [];

  const openStatusId = getStatusId(statuses, "OPEN");
  const [metadata, setMetadata] = React.useState(
    setItemType(metadataFactory({ status: openStatusId }), ITEM_TYPES.ADHOC)
  );
  const assignee = metadata ? metadata.assignee : null;
  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const sprint = metadata ? metadata.sprint : null;
  const title = metadata && metadata.title ? metadata.title : "";
  const status = (metadata && metadata.status) || openStatusId;
  const content = metadata ? metadata.htmlContent : null;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;

  const setStatus = (statusId) => {
    setMetadata(setMetadataStatus(metadata, statusId));
  };

  const setAssignee = (assignee) => {
    setMetadata(setMetadataAssignee(metadata, assignee));
  };

  const setMetaDueDate = (date) => {
    setMetadata(setMetadataDueDate(metadata, date));
  };

  const setMetaStartDate = (date) => {
    setMetadata(setMetadataStartDate(metadata, date));
  };

  const setMetaSprint = (sprint) => {
    setMetadata(setMetadataSprint(metadata, sprint));
  };

  const setMetadataTitle = (title) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  const setMetadataContent = (content) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  const setMetaPriority = (priority) => {
    setMetadata(setMetadataPriority(metadata, priority));
  };

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labels) {
    setMetadata(setLabels(metadata, labels));
  }

  React.useEffect(() => {
    if (
      !state.activeModalData?.isEditing ||
      state.activeModal !== "ADHOC_ITEM_MODAL"
    )
      return;
    // Check if this item is a part of all active items in currenttly
    // selected project's items
    const _metadata = state.projectItems.find(
      (pItem) => pItem.id === state.selectedItemId
    );

    if (_metadata) {
      setMetadata({ ..._metadata });
      return;
    }
  }, [state.projectItems, state.selectedItemId, state.activeModalData]);

  const onSubmit = () => {
    if (!metadata.title || metadata.title === "") {
      alert("Item must have a title");
      return;
    }

    if (!metadata.htmlContent || metadata.htmlContent === "") {
      alert("Item must have a content");
      return;
    }
    if (state.activeModalData?.isEditing) {
      updateProjectItem({
        itemId: state.selectedItemId,
        data: metadata,
        projectItems: state.projectItems,
        dispatch,
      });
    } else {
      createAdHocItem({
        data: { ...metadata, projectId: state.activeProjectId },
        projectItems: state.projectItems,
        statuses,
        statusId: metadata.status,
        dispatch,
      });
    }
    setMetadata(
      setItemType(metadataFactory({ status: openStatusId }), ITEM_TYPES.ADHOC)
    );
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  };

  const onClose = () => {
    setMetadata(
      setItemType(metadataFactory({ status: openStatusId }), ITEM_TYPES.ADHOC)
    );
  };

  return (
    <Modal
      closeFunctionCallback={onClose}
      name={MODAL_NAMES.ADHOC_ITEM_MODAL}
      style={{ height: "670px", width: "1240px" }}
    >
      <div className="h-full overflow-hidden">
        <div className="w-full px-8 py-4 border-b border-grayBorder">
          <h2 className="text-2xl font-bold text-nearBlack">
            {" "}
            {state.activeModalData?.isEditing
              ? "Edit item"
              : "Create a new AdHoc item"}
          </h2>
          <div className="flex">
            <CloseIcon
              onClick={() => {
                setMetadata(
                  setItemType(
                    metadataFactory({ status: openStatusId }),
                    ITEM_TYPES.ADHOC
                  )
                );
                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                });
              }}
              className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            />
          </div>
        </div>

        <div className="flex items-start justify-center w-full h-full px-8 overflow-x-visible">
          <div className="flex flex-col items-start justify-start w-8/12 h-full py-6 pr-12 border-r border-grayBorder">
            {/* TODO - ADD DESCRIPTION

            <p className="mt-4 mb-6 text-sm leading-5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
            sit, facere vel praesentium eaque repudiandae obcaecati nostrum!
            Quia hic similique, perferendis laboriosam saepe repellat illo
            veniam, natus nesciunt, nisi quo! </p> */}

            <TitleContent
              title={title}
              setMetadataTitle={setMetadataTitle}
              setMetadataContent={setMetadataContent}
              activeProjectId={state.selectedItemProjectId}
              content={state.activeModalData && content}
            />

            <div className="flex justify-end w-full mt-2">
              <button
                className="relative w-24 mr-2 btn btn-primary"
                onClick={onSubmit}
              >
                {state.activeModalData?.isEditing ? "Edit" : "Create"}
              </button>
              <button
                className="relative w-24 mr-5 btn btn-danger"
                onClick={() => {
                  setMetadata(
                    setItemType(
                      metadataFactory({ status: openStatusId }),
                      ITEM_TYPES.ADHOC
                    )
                  );
                  dispatch({
                    type: ACTIONS.CLOSE_MODAL,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="relative flex flex-col justify-start w-4/12 py-8 pl-8">
            <Status status={status} setMetaStatus={setStatus} />
            <Assignee assignee={assignee} setMetaAssignee={setAssignee} />
            <Date
              date={startDate}
              setMetaDate={setMetaStartDate}
              label="Start date"
            />
            <Date
              date={dueDate}
              setMetaDate={setMetaDueDate}
              label="Due date"
            />
            <Sprint sprint={sprint} setMetaSprint={setMetaSprint} />
            <Priority
              priority={priority}
              type="priority"
              label="Priority"
              setMetaPriority={setMetaPriority}
            />
            <Labels
              labels={labels}
              type="labels"
              label="Labels"
              setMetaLabels={setMetaLabels}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAdhocItemModal;
