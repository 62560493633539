/**
 * Log the time it takes to perfom a function.
 *
 * @param {fn} Function to call
 * @apram {argsArray} Array of parameters to use when calling the function
 *
 */
export default function profileFunction(fn, argsArray = []) {
  if (argsArray.length === 0) {
    console.info(
      "You are profiling a function with no parameters, are you sure?"
    );
  }

  const startTime = performance.now();

  const result = fn.apply(null, argsArray);

  const endTime = performance.now();
  const elapsed = endTime - startTime;

  console.info(`${fn.name} took ${elapsed} to finish`);

  return result;
}
