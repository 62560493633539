import { getSortedBacklogProjectItems } from "utils/backlogItems";
import { getKeyBetween } from "utils/lex";

export const sortByStatusPosition = (array) => {
  return array.sort((a, b) => {
    if (
      (a?.orderPosition?.statusPosition ||
        a.data.orderPosition.statusPosition) >
      (b?.orderPosition?.statusPosition || b.data.orderPosition.statusPosition)
    ) {
      return 1;
    } else if (
      (a?.orderPosition?.statusPosition ||
        a.data.orderPosition.statusPosition) <
      (b?.orderPosition?.statusPosition || b.data.orderPosition.statusPosition)
    ) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const reorderInBacklog = (array, source, destination) => {
  const removed = array.splice(source.index, 1);

  const reorderedItem = removed[0];

  array.splice(destination.index, 0, reorderedItem);

  const range = [
    array[destination.index - 1]?.orderPosition?.backlogPosition || null,
    array[destination.index + 1]?.orderPosition?.backlogPosition || null,
  ];

  return { reorderedItem, range };
};

export const reorderInStatusLane = (array, source, destination) => {
  const columnsClone = { ...array };

  const newArray = sortByStatusPosition([
    ...array[source.droppableId].columnItems,
  ]);
  const removed = newArray.splice(source.index, 1);

  const reorderedItem = removed[0];

  newArray.splice(destination.index, 0, reorderedItem);

  const range = [
    newArray[destination.index - 1]?.data?.orderPosition?.statusPosition ||
      null,
    newArray[destination.index + 1]?.data?.orderPosition?.statusPosition ||
      null,
  ];
  const rank = getKeyBetween(range[0], range[1]);

  reorderedItem.data.orderPosition.statusPosition = rank;

  columnsClone[source.droppableId].columnItems = newArray;

  return { columnsClone, reorderedItem, range };
};

export const reorderToBacklog = (
  columns,
  backlog,
  source,
  destination,
  result,
  metadata
) => {
  const reorderedItem = columns[source.droppableId].columnItems.find(
    (item) => item.id === result.draggableId
  );

  const arr = [...backlog];

  arr.splice(destination.index, 0, reorderedItem.data);

  const range = [
    arr[destination.index - 1]?.orderPosition?.backlogPosition || null,
    arr[destination.index + 1]?.orderPosition?.backlogPosition || null,
  ];

  const rank = getKeyBetween(range[0], range[1]);

  metadata.orderPosition.backlogPosition = rank;

  return { reorderedItem, range };
};

export const reorderFromBacklog = (columns, backlog, destination, result) => {
  const columnsClone = { ...columns };

  const newArray = sortByStatusPosition([
    ...columns[destination.droppableId].columnItems,
  ]);

  const reorderedItem = backlog.find((item) => item.id === result.draggableId);

  newArray.splice(destination.index, 0, reorderedItem);

  const range = [
    newArray[destination.index - 1]?.data?.orderPosition?.statusPosition ||
      null,
    newArray[destination.index + 1]?.data?.orderPosition?.statusPosition ||
      null,
  ];
  const rank = getKeyBetween(range[0], range[1]);

  reorderedItem.orderPosition.statusPosition = rank;

  columnsClone[destination.droppableId].columnItems = newArray;

  return { columnsClone, reorderedItem, range };
};

export const reorderStatusToStatus = (columns, source, destination, result) => {
  const columnsClone = { ...columns };

  const newArray = sortByStatusPosition([
    ...columns[destination.droppableId].columnItems,
  ]);

  const reorderedItem = columnsClone[source.droppableId].columnItems.find(
    (item) => item.id === result.draggableId
  );

  newArray.splice(destination.index, 0, reorderedItem);

  const range = [
    newArray[destination.index - 1]?.data?.orderPosition?.statusPosition ||
      null,
    newArray[destination.index + 1]?.data?.orderPosition?.statusPosition ||
      null,
  ];

  const rank = getKeyBetween(range[0], range[1]);

  reorderedItem.data.orderPosition.statusPosition = rank;

  columnsClone[destination.droppableId].columnItems = newArray;

  return { columnsClone, reorderedItem, range };
};

export const getBacklogInitialPosition = (projectItems, statuses) => {
  const sortedBacklogProjectItems = getSortedBacklogProjectItems(
    projectItems,
    statuses
  );

  const itemPosition =
    sortedBacklogProjectItems[sortedBacklogProjectItems.length - 1]
      ?.orderPosition; // Finish me projectItems[projectItems.length - 1]?.orderPosition

  let calculatedPosition = null;
  if (!itemPosition) {
    calculatedPosition = "a0";
  } else {
    calculatedPosition = getKeyBetween(itemPosition.backlogPosition, null);
  }

  return calculatedPosition;
};

export const getStatusInitialPosition = (projectItems, statuses, statusId) => {
  /**
   * Take into consideration only items that are in this status already
   */
  const sortedStatusProjectItems = getSortedStatusProjectItems(
    projectItems,
    statuses,
    statusId
  );

  const itemPosition =
    sortedStatusProjectItems[sortedStatusProjectItems.length - 1]
      ?.orderPosition; // FINISH ME

  let calculatedPosition = null;

  if (!itemPosition) {
    calculatedPosition = "a0";
  } else {
    calculatedPosition = getKeyBetween(itemPosition.statusPosition, null);
  }

  return calculatedPosition;
};

function getProjectItemsForStatus(projectItems, statuses, statusId) {
  const closedStatus = statuses.find((status) => status.slug === "CLOSED");

  return projectItems.filter(
    (pi) => pi.status === statusId && pi.itemType && pi.status !== closedStatus
  );
}

function getSortedStatusProjectItems(projectItems, statuses, statusId) {
  const itemsForStatus = getProjectItemsForStatus(
    projectItems,
    statuses,
    statusId
  );
  const sorted = sortByStatusPosition(itemsForStatus);

  return sorted;
}

export function getInitialOrderPosition(projectItems, statuses, statusId) {
  return {
    statusPosition: getStatusInitialPosition(projectItems, statuses, statusId),
    backlogPosition: getBacklogInitialPosition(projectItems, statuses),
  };
}
