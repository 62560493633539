/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Editor from "@draft-js-plugins/editor";
import CustomHeaderComponent from "components/DraftEditor/components/CustomHeaderComponent";
import CustomTasksContainerComponent from "components/DraftEditor/components/Tasks/CustomTasksContainerComponent";
import createPlugins from "components/DraftEditor/plugins/createPlugins";
import { Can } from "components/RoleManagement/Can";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import { KeyBindingUtil, RichUtils, EditorState, Modifier } from "draft-js";
import usePrevious from "hooks/usePrevious";
import useSaveDraftState from "hooks/useSaveDraftState";
import useUserId from "hooks/useUserId";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { getActiveBlock, isHeaderBlock } from "utils/draft";
import {
  contentChangeAllowed,
  getVisibleBlocksForPage,
  getPageHeaderBlockKeys,
  forceEditorRender,
} from "utils/editor";
import { getDeepChildKeys } from "utils/editor/draft-utils";
import { canUserEditDocument } from "utils/permissions";
import profileFunction from "utils/profile";

import { PERMISSIONS_ACTIONS, PERMISSIONS_SUBJECTS } from "../../constants";
import * as Sync from "./sync";
import "draft-js/dist/Draft.css";

function updateContextEditorState(dispatch, newEditorState) {
  dispatch({
    type: ACTIONS.EDITOR_CONTENTS_CHANGED,
    payload: {
      newEditorState,
    },
  });
}

function recreateHierarchyState(dispatch, newEditorState) {
  dispatch({
    type: ACTIONS.RECREATE_HIERARCHY_STATE,
    payload: {
      newEditorState,
    },
  });
}

function updateContext(dispatch, newEditorState) {
  updateContextEditorState(dispatch, newEditorState);

  // Hierarchy state needs ti be recreated so that we can show content placeholders on blocks; TO DO: check how it impacts performance and if it does, imrpove it
  recreateHierarchyState(dispatch, newEditorState);
}

const deferUpdateContext = debounce(updateContext, 0);

const DraftEditor = ({ getHeaders }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  useSaveDraftState();

  const {
    editorLoaded,
    editorState,
    editorActiveHeader,
    hierarchyState,
    projectItems,
    loadedProjects,
    activeProjectId,
    editorContentStateKeyLocal,
    editorContentStateKeyRemote,
    editorDisable,
  } = state;

  const statuses = loadedProjects && loadedProjects[activeProjectId].statuses;
  const [localEditorState, setEditorState] = React.useState(editorState);
  const [editorKey, setEditorKey] = React.useState(Math.random());
  const [taskInputFocused, setTaskInputFocused] = React.useState(false);
  const editorRef = React.useRef();
  const contentStateKeyRefLocal = React.useRef(editorContentStateKeyLocal);
  const contentStateKeyRefRemote = React.useRef(editorContentStateKeyRemote);
  const previousRemote = usePrevious(contentStateKeyRefRemote);
  const [initialSyncEntitiesLoaded, setInitialSyncEntitiesLoaded] =
    React.useState(false);
  const userId = useUserId();

  // TODO - Re-evaluate how breadcrumbs are created and working
  // FIXME - BREADCRUMBS
  // React.useEffect(() => {
  //   // When we get headers trough queryselectorall, the refference to them gets lost somewhere in the process of initializing of this component
  //   getHeaders();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    // @FIXME: For some weird reason, this fixes this issue:
    // if there is / (slash) in content, and you refresh the screen,
    // slash command will no longer work until you delete all slashes and refresh
    setEditorState(editorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    /**
     * When Editor is first created initialize the sync process
     * with other clients that might be working on the same
     * document.
     */
    if (!activeProjectId) {
      Sync.destroy();
    } else {
      Sync.initialize(activeProjectId, dispatch, userId);
    }

    return () => {
      /**
       * When component is closed clean up the connection to
       * other clients.
       */
      Sync.destroy();
    };
  }, [activeProjectId, userId, dispatch]);

  /**
   * Using a ref here because we are passing this plugin to the component that
   * maintains the plugin logic, and it needs to be passed to the editor instance
   * as well.
   *
   * It seems that the reference needs to be maintainer and we're using Refs since
   * they are immutable until changed explicitly, they don't change on every render
   */
  const pluginsRef = React.useRef(createPlugins());

  React.useEffect(() => {
    if (editorContentStateKeyLocal !== contentStateKeyRefLocal.current) {
      contentStateKeyRefLocal.current = editorContentStateKeyLocal;

      // Broadcast this change to the other clients as well!
      // Changing the editor state from other parts of the application or adding
      // the new section does not trigger sync as it should
      Sync.broadcastDiff({
        newEditorState: editorState,
        localEditorState,
      });

      setEditorState(editorState);
    }
    if (editorContentStateKeyRemote !== contentStateKeyRefRemote.current) {
      contentStateKeyRefRemote.current = editorContentStateKeyRemote;
      setEditorState(editorState);
    }
  }, [
    editorState,
    editorContentStateKeyLocal,
    editorContentStateKeyRemote,
    contentStateKeyRefLocal,
    contentStateKeyRefRemote,
  ]);

  // TODO - profile this using useMemo for performance
  const blocksToShow = profileFunction(getVisibleBlocksForPage, [
    editorActiveHeader,
    localEditorState,
  ]);

  /**
   * For one reason or another Draft instance loses its decorators that
   * have been created by plugins.
   *
   * To make sure decorators are there change the 'key' and that
   * will cause Draft to re-mount everything and re-create
   * the decorators.
   */
  React.useEffect(() => {
    if (!editorState.getDecorator()) {
      setEditorKey(Math.random());
    }
  }, [editorState]);

  /**
   * When activePage is changed editor needs to be re-rendered
   */
  React.useEffect(() => {
    setEditorState(forceEditorRender(localEditorState));
  }, [editorActiveHeader]);

  React.useEffect(() => {
    if (editorLoaded && !editorActiveHeader) {
      // Set the first H1 in the content state as the currently selected Page
      try {
        dispatch({
          type: ACTIONS.EDITOR_ACTIVE_HEADER,
          payload: {
            activeHeaderKey: getPageHeaderBlockKeys(editorState).first(),
          },
        });
      } catch (error) {
        // FIXME - Error handle!
      }
    }
  }, [editorLoaded, editorState, editorActiveHeader]);

  React.useEffect(() => {
    /**
     * We are setting initial entities to the Sync module. We need to keep a
     * manual reference to the entities that are synched
     */
    if (editorLoaded && !initialSyncEntitiesLoaded) {
      Sync.loadInitialEntities(editorState);
      setInitialSyncEntitiesLoaded(true);
    }
  }, [initialSyncEntitiesLoaded, editorState, editorLoaded]);

  function onChange(newEditorState) {
    const currentContent = localEditorState.getCurrentContent();
    const newContent = newEditorState.getCurrentContent();

    if (!contentChangeAllowed(newEditorState, localEditorState)) {
      /**
       * Re-update the editorState with existing state to reset SelectionState
       */
      setEditorState(localEditorState);

      return;
    }

    setEditorState(newEditorState);

    // Determines if the content actually changed or just some selection, e.g moving
    // of carret
    const contentChanged = currentContent !== newContent;

    if (contentChanged) {
      deferUpdateContext(dispatch, newEditorState);

      /**
       * TODO SYNC
       *
       * Find a way to reliably start broadcasting sync changes when other
       * peers connect.
       *
       * Attempted previously but some clients did not get all relevant changes.
       *
       */
      if (previousRemote === contentStateKeyRefRemote) {
        Sync.broadcastDiff({
          newEditorState,
          localEditorState,
        });
      }
    }
  }

  const { editorPlugins, instances } = pluginsRef.current;
  const { SlashToolbarComponent, StaticToolbarComponent } = instances;

  const plugins = [...editorPlugins];

  /**
   * TODO: It seems that myBlockRenderer is triggered on every backspace and some
   * other editor events. In larger documents this might cause lags on there events
   * Investigate options.
   *
   * @param {*} contentBlock
   * @returns
   */
  function myBlockRenderer(contentBlock) {
    const type = contentBlock.getType();

    /**
     * If we have a header selected in the TOC render only the blocks
     * that are the children of that block, and their content...and that block
     */
    if (editorActiveHeader && blocksToShow.indexOf(contentBlock.key) === -1) {
      return {
        component: () => null,
        editable: false,
      };
    }

    if (type.indexOf("header") === 0) {
      return {
        component: CustomHeaderComponent,
        editable: true,
        props: {
          projectItems,
          statuses,
          hierarchyState,
          activeProjectId,
          dispatch,
          editorState: localEditorState,
        },
      };
    }
    if (type === "atomic") {
      const contentState = localEditorState.getCurrentContent();
      const entity = contentBlock.getEntityAt(0);

      if (entity && contentState.getEntity(entity).getType() === "tasksBlock") {
        return {
          component: CustomTasksContainerComponent,
          editable: false,
          props: {
            projectItems,
            statuses,
            hierarchyState,
            activeProjectId,
            dispatch,
            editorState: localEditorState,
            setEditorState: (newEditorState) => setEditorState(newEditorState),
            onFocus: () => {
              setTaskInputFocused(true);
            },
            onBlur: () => {
              setTaskInputFocused(false);
            },
          },
        };
      }
    }
  }

  // TODO: remove after sync test
  window.changeEditor = (character = "x") => {
    const characterToInsert = character;
    const editorState = localEditorState;

    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    onChange(newEditorState);
    // return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  };

  const { hasCommandModifier } = KeyBindingUtil;

  const keyBindingFn = (e) => {
    if (e.keyCode === 66 && hasCommandModifier(e)) {
      return "bold";
    }
    if (e.keyCode === 85 && hasCommandModifier(e)) {
      return "underline";
    }
    if (e.keyCode === 73 && hasCommandModifier(e)) {
      return "italic";
    }

    //When this is enabled slash toolbar doesn't allow using arrows to iterate trough slash toolbar options. Should be tested furtherly
    // return getDefaultKeyBinding(e);
  };

  function handleKeyCommand(command, editorState, eventTimestamp) {
    try {
      if (command === "split-block") {
        const activeBlock = getActiveBlock(editorState);
        const isHeader = isHeaderBlock(activeBlock);

        if (isHeader) {
          return "handled";
        }
        return "not-handled";
      }
      if (command === "backspace") {
        const activeBlock = getActiveBlock(editorState);
        /**
         * Means that the current block is a Header and is linked to an Item
         */
        const isLinkedHeader =
          isHeaderBlock(activeBlock) &&
          activeBlock.getData().get("headerItemLinked");

        const blockBackspace =
          activeBlock.getText().trim() === "" ||
          editorState.getSelection().getStartOffset() === 0;

        if (isLinkedHeader && blockBackspace) {
          /**
           * This prevents any further processing by DraftJS. onChange will not
           * be called, it will be as though nothing happened.
           *
           * We want this because we want to prevent the deletion of a Header
           * that has an open and active item linked to it.
           */

          toast.warn("Can not remove a Header with a linked Item");

          return "handled";
        }
        return "not-handled";
      }

      if (command === "bold") {
        let newState = RichUtils.toggleInlineStyle(localEditorState, "BOLD");
        if (newState) {
          onChange(newState);
          return "handled";
        }
        return "not-handled";
      }
      if (command === "underline") {
        let newState = RichUtils.toggleInlineStyle(
          localEditorState,
          "UNDERLINE"
        );
        if (newState) {
          onChange(newState);
          return "handled";
        }
        return "not-handled";
      }
      if (command === "italic") {
        let newState = RichUtils.toggleInlineStyle(localEditorState, "ITALIC");
        if (newState) {
          onChange(newState);
          return "handled";
        }
        return "not-handled";
      }
    } catch (e) {
      console.error("ERROR", e);
      return "not-handled";
    }
  }

  function myBlockStyleFn(contentBlock) {
    try {
      const key = contentBlock.getKey();

      // Hide the blocks with CSS for extra mesure since sometimes they are still shown
      if (editorActiveHeader && blocksToShow.indexOf(key) === -1) {
        return "hidden";
      }

      const type = contentBlock.getType();
      const blockKey = contentBlock.getKey();
      const contentState = localEditorState.getCurrentContent();

      const previousBlockKey = contentState.getKeyBefore(blockKey);
      const previousBlock = contentState.getBlockForKey(previousBlockKey);
      const previousBlockType = previousBlock && previousBlock.getType();
      const prevBlockTypeSplit =
        previousBlockType && previousBlockType.split("-")[0];

      let shouldShowPlaceholder = false;

      if (prevBlockTypeSplit === "header") {
        const unstyledBlocksKeys = getDeepChildKeys(
          hierarchyState[previousBlockKey].content.filter(
            (block) => block.type === "unstyled"
          )
        );
        const unstyledBlocks = unstyledBlocksKeys.map((key) =>
          contentState.getBlockForKey(key)
        );

        shouldShowPlaceholder = !unstyledBlocks.some(
          (block) => block?.getText().length > 0
        );
      }

      // Hide the blocks with CSS for extra mesure since sometimes they are still shown
      if (editorActiveHeader && !blocksToShow.includes(key)) {
        return "hidden";
      }
      if (type.indexOf("header") === 0 && contentBlock.getText().length === 0) {
        return "DraftEditor-header-placeholder";
      }
      if (type.indexOf("header") === 0) {
        return "DraftEditor-header";
      }

      if (
        type === "unstyled" &&
        contentBlock.getText().length === 0 &&
        prevBlockTypeSplit === "header" &&
        shouldShowPlaceholder
      ) {
        return "DraftEditor-unstyled-placeholder";
      }
    } catch (error) {
      console.log(error);
    }
  }

  return editorLoaded ? (
    <div className="relative">
      <div className="w-full pb-3 sticky top-0 bg-white z-40 border-b">
        <StaticToolbarComponent />
      </div>
      <div
        className="pl-10 prose"
        id="editorWrapperTour"
        onClick={() => {
          /**
           * Clicking on the 'Open'/'Close' in CustomHeader and opening a sidebar
           * causes the Editor focus handling to cause all sorts of questionable
           * results.
           *
           * Manually set editor focus
           */
          if (!localEditorState.getSelection().hasFocus) {
            editorRef.current.focus();
          }
        }}
      >
        <Can
          I={PERMISSIONS_ACTIONS.MANAGE}
          an={PERMISSIONS_SUBJECTS.EDITOR}
          passThrough
        >
          <Editor
            ref={(element) => {
              editorRef.current = element;
            }}
            key={editorKey}
            editorState={localEditorState}
            onChange={onChange}
            plugins={plugins}
            blockRendererFn={myBlockRenderer}
            blockStyleFn={myBlockStyleFn}
            handleKeyCommand={handleKeyCommand}
            activeProjectId={activeProjectId}
            keyBindingFn={keyBindingFn}
            readOnly={
              !canUserEditDocument() || editorDisable || taskInputFocused
            }
          />
        </Can>
      </div>
      <SlashToolbarComponent enableTable />
    </div>
  ) : null;
};

export default DraftEditor;
