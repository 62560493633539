import React, { useState, useEffect } from "react";

import { updateProjectItem } from "api";
import { useTrackedState } from "context";

const TaskTitle = (props) => {
  const { metaTitle, setMetaTitle, onFocus, onBlur, dispatch, isEmpty } = props;
  const state = useTrackedState();

  const { activeProjectId, projectItems } = state;
  const [isActive, setIsActive] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    updateProjectItem({
      dispatch: dispatch,
      itemId: props.itemId,
      projectId: activeProjectId,
      projectItems: projectItems,
      data: { ...props.metadata, title: metaTitle },
    });
    setIsActive(false);
  }

  return (
    <>
      {isActive || isEmpty ? (
        <form className="w-full" onSubmit={handleSubmit} onBlur={handleSubmit}>
          <input
            onBlur={(e) => {
              e.stopPropagation();
              onBlur();
              setIsActive(false);
            }}
            onChange={(e) => {
              setMetaTitle(e.target.value);
            }}
            onFocus={() => {
              onFocus();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={metaTitle}
            type="text"
            placeholder="Type here for new task..."
            className={`text-sm font-bold focus:outline-none w-1/2 border-b ${
              isEmpty ? "border-transparent" : "border-chillBlue"
            }`}
          />
        </form>
      ) : (
        <span
          className="text-sm font-bold text-charcoalBlack border-b border-transparent"
          onClick={(event) => {
            event.stopPropagation();
            setIsActive(!isActive);
          }}
        >
          {metaTitle}
        </span>
      )}
    </>
  );
};

export default TaskTitle;
