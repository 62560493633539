import React from "react";

import { ReactComponent as HighPriorityIcon } from "assets/priority-icons/high-priority.svg";
import { ReactComponent as LowPriorityIcon } from "assets/priority-icons/low-priority.svg";
import { ReactComponent as MidPriorityIcon } from "assets/priority-icons/medium-priority.svg";
import { ReactComponent as TrivialPriorityIcon } from "assets/priority-icons/trivial-priority.svg";

const prioIcons = [
  {
    number: 4,
    icon: (
      <TrivialPriorityIcon className="fill-current text-gray-600 flex justify-center items-center" />
    ),
  },
  {
    number: 3,
    icon: (
      <LowPriorityIcon className="fill-current text-yellow-600 flex justify-center items-center" />
    ),
  },
  {
    number: 1,
    icon: (
      <HighPriorityIcon className="fill-current text-red-600 flex justify-center items-center mt-1" />
    ),
  },
  {
    number: 2,
    icon: <MidPriorityIcon className="flex justify-center items-center" />,
  },
];

const PrioIcon = ({ number }) => {
  return prioIcons.find((icon) => icon.number === number).icon;
};

export default PrioIcon;
