import React from "react";

import { updateProjectItem } from "api";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as EmptyAssignee } from "assets/editor-tasks-icons/assignee.svg";
import { Can } from "components/RoleManagement/Can";
import UserAvatar from "components/UserAvatar";
import { useTrackedState } from "context";
import useOnClickOutside from "hooks/useOnClickOutside";
import get from "lodash.get";
import ReactTooltip from "react-tooltip";

import {
  useTourDispatch,
  useTourTrackedState,
  TOUR_ACTIONS,
  TOUR_STAGES,
  TOUR_STEPS,
} from "tourContext";
import {
  getLoadedProjectData,
  getProjectDataByKey,
} from "utils/loadedProjects";
import { canUserEditMeta } from "utils/permissions";

import {
  PERMISSIONS_SUBJECTS,
  PERMISSIONS_ACTIONS,
} from "../../../../constants";
import "@reach/listbox/styles.css";

const Assignee = (props) => {
  const [assigneeBlockOpen, setAssigneeOpen] = React.useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => setAssigneeOpen(false));
  const state = useTrackedState();
  const tourState = useTourTrackedState();
  const tourDispatch = useTourDispatch();
  const { loadedProjects, loadedMembers, selectedItemProjectId } = state;
  const { editorStateForceSelection, setMetaAssignee, metaAssignee } = props;
  const projectMemberMeta = metaAssignee
    ? loadedMembers[metaAssignee.id]
    : null;

  const projectId = props.metadata?.projectId
    ? props.metadata.projectId
    : selectedItemProjectId;

  const projectMemberIds =
    getProjectDataByKey(
      getLoadedProjectData(loadedProjects, projectId),
      "members"
    ) ?? [];

  const projectMembers = Object.keys(projectMemberIds)
    .filter((id) => id in loadedMembers)
    .map((id) => loadedMembers[id]);

  return (
    <div className="flex-row items-center relative">
      <span
        className={
          "relative flex items-center btn-transition cursor-pointer w-6 h-6 group"
        }
        onClick={(event) => {
          event.stopPropagation();
          canUserEditMeta() && setAssigneeOpen(!assigneeBlockOpen);
        }}
      >
        {projectMemberMeta?.img ? (
          <UserAvatar className="m-0 avatar-img" img={projectMemberMeta?.img} />
        ) : (
          <div>
            <ReactTooltip />
            <EmptyAssignee
              data-tip="Assignee"
              className={`${
                assigneeBlockOpen && "text-chillBlue"
              } text-gray-500 stroke-current hover:text-chillBlue`}
            />
          </div>
        )}
        {metaAssignee && metaAssignee.id && (
          <Can I={PERMISSIONS_ACTIONS.MANAGE} a={PERMISSIONS_SUBJECTS.METADATA}>
            <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100">
              <ClearIcon
                className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                onClick={(e) => {
                  e.stopPropagation();

                  setMetaAssignee(null);

                  updateProjectItem({
                    dispatch: props.dispatch,
                    itemId: props.itemId,
                    projectId: props.activeProjectId,
                    projectItems: props.projectItems,
                    data: { ...props.metadata, assignee: null },
                  });

                  if (editorStateForceSelection) {
                    editorStateForceSelection();
                  }
                }}
              />
            </div>
          </Can>
        )}
      </span>
      {assigneeBlockOpen && (
        <div
          ref={ref}
          className="task-item-dropdown absolute top-0 left-0 p-2 mt-8 border rounded z-50 bg-white w-48"
        >
          {projectMembers.map((user) => (
            <div
              onClick={(event) => {
                event.stopPropagation();
                const selectedMeta = `${get(user, "name", "-")}, ${
                  user.email
                }`.split(",");
                const selectedEmail = selectedMeta[1].trim();
                const userMeta = projectMembers.find(
                  (user) => user.email === selectedEmail
                );

                if (!userMeta) {
                  // No user Meta
                  return;
                }

                const projectMemberMeta = {
                  email: userMeta.email,
                  id: userMeta.id,
                };

                setMetaAssignee(projectMemberMeta);

                updateProjectItem({
                  dispatch: props.dispatch,
                  itemId: props.itemId,
                  projectId: props.activeProjectId,
                  projectItems: props.projectItems,
                  data: { ...props.metadata, assignee: projectMemberMeta },
                });

                if (editorStateForceSelection) {
                  editorStateForceSelection();
                }

                setAssigneeOpen(false);

                // React-tour related dispatch
                if (tourState.tourStage === TOUR_STAGES.IN_PROGRESS) {
                  tourDispatch({
                    type: TOUR_ACTIONS.CURRENT_STEP,
                    payload: TOUR_STEPS.TOUR_ITEM_SIDEBAR_APPLY,
                  });
                }
              }}
              key={user.id}
              value={`${get(user, "name", "-")}, ${user.email}`}
              className="task-item-dropdown-option inline-flex"
            >
              <div className={`w-6 h-6 mr-2`}>
                <UserAvatar className="avatar-img" img={user.img} />
              </div>
              {`${get(user, "name")}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Assignee;
