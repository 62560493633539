import React from "react";

import createProjectImg from "assets/quickStart_createProject.png";
import { MODAL_NAMES } from "components/Modal/Modal";
import { useDispatch, ACTIONS, useTrackedState } from "context";

import { ReactComponent as CheckIcon } from "../../assets/circle-check_icon.svg";
import { ReactComponent as EllipseIcon } from "../../assets/ellipse.svg";

const QuickStartStep = ({ itemOpen, itemSelected, steps, solvedSteps }) => {
  const dispatch = useDispatch();
  const step = steps.find((step) => step.slug === itemSelected);
  return (
    <div
      className={`absolute transform transition-all duration-300 ease-in-out mt-16 px-4 z-20 pb-10 rounded-b-md ${
        itemOpen ? "max-h-screen delay-300" : "max-h-0"
      } bg-darkBlue text-white overflow-hidden`}
    >
      <h3 className="text-white text-xl font-semibold mb-6 flex items-center justify-start">
        {solvedSteps?.includes(itemSelected) ? (
          <CheckIcon className="stroke-current text-white mr-3" />
        ) : (
          <EllipseIcon className="stroke-current text-white mr-3" />
        )}

        {step?.title}
      </h3>
      <p className="text-sm mb-6">{step?.description}</p>
      <div
        onClick={() => {
          dispatch({
            type: ACTIONS.OPEN_MODAL,
            payload: {
              name: MODAL_NAMES.IMAGE_MODAL,
              activeModalData: { image: createProjectImg },
            },
          });
        }}
        className="w-2/3 mx-auto cursor-pointer mb-6"
      >
        <img src={createProjectImg} alt="" />
      </div>
      {step?.function && !solvedSteps?.includes(itemSelected) && (
        <button
          onClick={() => step.function()}
          className="btn btn-primary w-full"
        >
          {step?.title}
        </button>
      )}
    </div>
  );
};

export default QuickStartStep;
