import { EditorState, ContentState } from "draft-js";
import { fbStorage } from "firebaseSetup";
import { toast } from "react-toastify";

import { readFiles } from "./file";

const _fbStorage = fbStorage;

export default function handleFilePlugin(config) {
  return function onFileInner(
    selection,
    files,
    { getEditorState, setEditorState, getProps }
  ) {
    const timestamp = Date.now();
    // Get upload function from config or editor props
    const { handleUpload } = config;

    function forceContentStateChange(editorState) {
      const oldContentState = editorState.getCurrentContent();
      const newContentState = ContentState.createFromBlockArray(
        oldContentState.getBlocksAsArray(),
        oldContentState.getEntityMap()
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );
      return newEditorState;
    }

    if (handleUpload) {
      const formData = new FormData();

      // Set data {files: [Array of files], formData: FormData}
      const data = { files: [], formData };
      for (const key in files) {
        // eslint-disable-line no-restricted-syntax
        if (files[key] && files[key] instanceof File) {
          data.formData.append("files", files[key]);
          data.files.push(files[key]);
        }
      }

      setEditorState(EditorState.acceptSelection(getEditorState(), selection));

      const createdEntityIds = [];

      // Read files on client side
      readFiles(data.files).then((loadedFiles) => {
        // Add blocks for each image before uploading
        let editorState = getEditorState();

        const filteredFiles = loadedFiles.filter((fFile) => {
          // Filter out files larger than 1MB!
          return fFile.size < 1048576;
        });

        if (filteredFiles.length !== loadedFiles.length) {
          toast.warning("Some files are larger than 1MB and can not be loaded");
        }

        filteredFiles.forEach((lFile) => {
          const name = lFile.name + timestamp;

          editorState = config.addImage(
            editorState,
            "https://via.placeholder.com/400X200?text=Uploading...", // Placeholder image while uploading
            {
              imgUploadPending: true,
              imgName: name,
            }
          );

          // Store all created entities, use this later to update blocks that
          // have been successfully uploaded
          createdEntityIds.push(
            editorState.getCurrentContent().getLastCreatedEntityKey()
          );
        });

        setEditorState(editorState);
      });

      setTimeout(() => {
        const { activeProjectId } = getProps();
        if (!activeProjectId) {
          return;
        }
        data.files.forEach((file) => {
          const name = file.name + timestamp;
          _fbStorage
            .ref()
            .child("projects")
            .child(activeProjectId)
            .child(name)
            .put(file)
            .then((snapshot) => {
              // Update the block with this image name

              createdEntityIds.forEach((entityId) => {
                const entityData = getEditorState()
                  .getCurrentContent()
                  .getEntity(entityId).data;

                if (entityData.imgName === snapshot.metadata.name) {
                  snapshot.ref.getDownloadURL().then((url) => {
                    getEditorState()
                      .getCurrentContent()
                      .replaceEntityData(entityId, {
                        src: url,
                      });

                    // Force editor render to show the uploaded image
                    setEditorState(
                      EditorState.forceSelection(
                        forceContentStateChange(getEditorState()),
                        getEditorState().getSelection()
                      )
                    );
                  });
                }
              });
            });
        });
      }, 50);

      return "handled";
    }

    return undefined;
  };
}
