import React from "react";

import { MODAL_NAMES } from "components/Modal/Modal";
import { useDispatch, ACTIONS } from "context";

import { ReactComponent as ExpandIcon } from "../../../assets/expand.svg";

const CustomImageComponent = (props) => {
  const dispatch = useDispatch();
  const { block, className, theme = {}, ...otherProps } = props;
  // leveraging destructuring to omit certain properties from props
  const { contentState, ...elementProps } = otherProps;

  const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
  return (
    <div
      {...elementProps}
      className={`${theme.image} ${className} relative group`}
    >
      <img src={src} role="presentation" />
      <div
        className="absolute hidden group-hover:block top-0 right-0 w-6 h-6 m-0 bg-white rounded hover:bg-linkBlue2 hover:text-white fill-current cursor-pointer"
        onClick={() => {
          dispatch({
            type: ACTIONS.OPEN_MODAL,
            payload: {
              name: MODAL_NAMES.CONTENT_IMAGE_MODAL,
              activeModalData: { src },
            },
          });
        }}
      >
        <ExpandIcon />
      </div>
    </div>
  );
};

export default CustomImageComponent;
