export function scrollParentToChild(parent, child) {
  // Where is the parent on page
  var parentRect = parent.current.getValues();

  // Where is the child
  var childRect = child.getBoundingClientRect();

  // Is the child viewable?
  var isInFocus = childRect.top >= 110 && childRect.top <= 330;

  // if you can't see the child try to scroll parent
  if (!isInFocus) {
    // scroll by offset relative to parent
    parent.current.scrollTop(
      childRect.top + parent.current.getScrollTop() - parentRect.top - 220
    );
  }
}

export function scrollParentToValue(parent, value, isForced) {
  // Where is the parent on page
  var parentRect = parent.current.getValues();

  // Is the value in focus?
  var isInFocus = value >= 110 && value <= 330;

  if (isForced || !isInFocus) {
    // scroll by offset relative to parent
    parent.current.scrollTop(
      value + parent.current.getScrollTop() - parentRect.top - 220
    );
  }
}
