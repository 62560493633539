import React from "react";

import get from "lodash.get";

const StatusIndicator = ({ status, projectStatuses }) => {
  const taskStatus =
    projectStatuses && projectStatuses.find((st) => st.id === status);

  const color = get(taskStatus, "color", "gray");
  const label = get(taskStatus, "label", "");

  return (
    <div
      className={`text-xs text-white py-2 px-1 rounded-2xl text-center font-bold bg-${color}-600 truncate w-32`}
    >
      {label}
    </div>
  );
};

export default StatusIndicator;
