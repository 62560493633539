import React from "react";

import compareDesc from "date-fns/compareDesc";
import { Parser } from "html-to-react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import VisualDiff from "react-visual-diff";
import { formatDateTime } from "utils/date";

import { ITEM_HISTORY_CHANGE_TYPES } from "../../../constants";

const htmlToReactParser = new Parser();

function TimelineItem({ historyEntry, currentContent }) {
  const [compare, showCompare] = React.useState(false);
  switch (historyEntry.type) {
    case ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION:
      var reactElement = htmlToReactParser.parse(historyEntry.data.htmlContent);
      const originalElement = htmlToReactParser.parse(currentContent);

      return (
        <TimelineEvent
          title={`${historyEntry.userName} changed item description`}
          createdAt={formatDateTime(
            // Since this field is also used in sandbox env we dont get the time with native .toDate() method
            new Date(historyEntry.createdTs.seconds * 1000)
          )}
          icon={<i></i>}
        >
          {compare ? (
            <span onClick={() => showCompare(false)}>
              <VisualDiff
                right={<span>{originalElement}</span>}
                left={<span>{reactElement}</span>}
              />
            </span>
          ) : (
            <span onClick={() => showCompare(true)}>{reactElement}</span>
          )}
        </TimelineEvent>
      );

    default:
      return null;
  }
}

const ItemHistory = ({ itemHistory, historyVisible, currentContent }) => {
  if (!itemHistory || !itemHistory.length || !historyVisible) {
    return null;
  }

  const filterItemHistory = itemHistory.filter((item) => {
    try {
      // Filter out any entries that don't have
      // createdTs set correctly

      return new Date(item.createdTs.seconds * 1000);
    } catch (error) {
      return false;
    }
  });

  if (!filterItemHistory.length) {
    return null;
  }

  return (
    <Timeline>
      {filterItemHistory
        .sort((h1, h2) => {
          return compareDesc(
            new Date(h1?.createdTs?.seconds * 1000),
            new Date(h2?.createdTs?.seconds * 1000)
          );
        })
        .map((historyEntry) => {
          return (
            <TimelineItem
              key={historyEntry.id}
              historyEntry={historyEntry}
              currentContent={currentContent}
            />
          );
        })}
    </Timeline>
  );
};

export default ItemHistory;
