import amplitude from "amplitude-js";
import TagManager from "react-gtm-module";

export const CATEGORIES = {
  MODAL: "Modal",
  AUTH: "Auth",
  TASK: "Task",
  PROJECT: "Project", // Create, Open, Delete
  TEXT_EDITOR: "Text Editor",
  TOUR: "Tour",
};

export const addAnalyticsEvent = (category, action, label, value) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventTracking",
      category,
      action,
      label,
      value,
    },
  });
};

export const trackLoginInfo = (authResult) => {
  // Google analytics
  addAnalyticsEvent(CATEGORIES.AUTH, `Log in success`);

  let eventName = "log in";

  if (authResult?.additionalUserInfo?.isNewUser) {
    eventName = "create account";
  }

  amplitude.getInstance().setUserId(null);
  amplitude.getInstance().setUserId(authResult?.user?.uid);

  amplitude.getInstance().logEvent(eventName, {
    provider: authResult?.additionalUserInfo?.providerId || "",
  });
};
