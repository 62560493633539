import handleDroppedFiles from "./handleFiles";

const createDndFileUploadPlugin = (config = {}) => ({
  // Handle file drops
  handleDroppedFiles: handleDroppedFiles(config),

  handlePastedFiles: (files, draftApi) => {
    const selection = draftApi.getEditorState().getSelection();

    const cb = handleDroppedFiles(config);
    cb(selection, files, draftApi);
  },
});

export default createDndFileUploadPlugin;
