import React from "react";

const defaultEntryComponent = (props) => {
  const {
    mention,
    theme,
    isFocused, // eslint-disable-line no-unused-vars
    searchValue, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className="flex">
        <div className="mr-5">
          {mention.avatar && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={mention.avatar}
              role="presentation"
              className="w-8 h-8 rounded-lg"
            />
          )}
        </div>
        <div>
          <div>{mention.name}</div>
          <div className="text-sm text-gray-700">{mention.description}</div>
        </div>
      </div>
    </div>
  );
};

export default defaultEntryComponent;
