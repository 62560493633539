import React from "react";

// import Separator from "./components/Separator";
import Toolbar from "../../../components/Toolbar";
import buttonStyles from "./buttonStyles.module.css";
import toolbarStyles from "./toolbarStyles.module.css";
import createStore from "./utils/createStore";

export default (config = {}) => {
  const store = createStore({
    isVisible: false,
  });
  const defaultTheme = { buttonStyles, toolbarStyles };
  const theme = defaultTheme;

  const InlineToolbar = (props) => (
    <Toolbar {...props} store={store} theme={theme} />
  );

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.updateItem("getEditorState", getEditorState);
      store.updateItem("setEditorState", setEditorState);
      store.updateItem("getEditorRef", getEditorRef);
    },
    // Re-Render the text-toolbar on selection change
    onChange: (editorState) => {
      store.updateItem("selection", editorState.getSelection());
      return editorState;
    },
    InlineToolbar,
  };
};
