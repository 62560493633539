import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import { convertToRaw } from "draft-js";
import { auth } from "firebaseSetup";
import immutableDiff from "immutablediff";
import {
  createSandboxBlockItem,
  saveSandboxEditorState,
  getSandboxProjectData,
  getSandboxItemData,
  updateSandboxProjectItem,
  getSandboxDocumentVersionById,
  removeSandboxDocumentVersion,
  createSandboxDocumentVersion,
  createSandboxAdHocItem,
  createSandboxBugItem,
  createSandboxSubtaskItem,
  getSandboxDiscussions,
  createSandboxDiscussion,
  getSandboxMessages,
  sendSandboxMessage,
  addNewSandboxStatus,
  removeSandboxStatus,
  editSandboxStatus,
  reorderSandboxStatuses,
  createSandboxSprint,
  startSandboxSprint,
  removeSandboxSprint,
  editSandboxSprints,
  closeSandboxSprint,
  sandboxBulkItemChange,
  changeSandboxProjectName,
  getSandboxItemHistory,
  getSandboxWalktroughProject,
} from "localStorageApi";
import get from "lodash.get";
import uniq from "lodash.uniq";
import { toast } from "react-toastify";
import { addAnalyticsEvent, CATEGORIES } from "utils/analytics";
import { getSortedBacklogProjectItems } from "utils/backlogItems";
import httpClient from "utils/httpClient";
import { isSandbox } from "utils/isSandboxEnv";
import { getKeyBetween } from "utils/lex";

import {
  ITEM_HISTORY_CHANGE_TYPES,
  NEW_PROJECT_DOCUMENT_CONTENT,
  SPRINT_STATE,
  INITIAL_PRIORITIES,
  STATUSES_SLUGS,
  USER_ROLES,
  PERMISSIONS_ACTIONS,
  PERMISSIONS_SUBJECTS,
  SUGGESTED_LABELS,
} from "./constants";
import { ACTIONS } from "./context";
import { db, firebaseLib } from "./firebaseSetup";
import { TOUR_ACTIONS, TOUR_STAGES } from "./tourContext";
import {
  flattenHeaderHierarchyArray,
  normalizeRawBlocksToParentChild,
  createEditorStateHtmlSlice,
} from "./utils/editor";
import { firestoreAutoId } from "./utils/firestoreId";
import { getInitialOrderPosition } from "./utils/reorderKanban";
import { getStatusBySlug } from "./utils/status";

/**
 * Keep a reference of all updates to all blocks done to the editor in this sesson
 * Not the best place but fuck it
 */
let editorUpdates = {};

function shouldUpdateHistory(editorUpdates, item) {
  if (!editorUpdates[item.id]) return false;

  // If last update time is more than 2 minutes
  if (Date.now() - editorUpdates[item.id].ts > 2 * 60 * 1000) {
    return false;
  }

  return true;
}

function reqisterHistoryUpdate({
  batch,
  batchType,
  itemId,
  historyId,
  htmlContent,
}) {
  if (!itemId || !historyId) return;

  const docHistoryCollectionRef = db
    .collection("items")
    .doc(itemId)
    .collection("itemHistory")
    .doc(historyId);

  const historyEntry = createItemHistoryEntry(
    auth.currentUser.uid,
    auth.currentUser.displayName,
    ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION,
    {
      htmlContent: htmlContent,
    }
  );

  if (batchType === "SET") {
    batch.set(docHistoryCollectionRef, historyEntry);
  }

  if (batchType === "UPDATE") {
    batch.update(docHistoryCollectionRef, historyEntry);
  }
}

function createItemHistoryEntry(uid, userName, type, data) {
  return {
    uid,
    userName, // Save user name in case user gets removed from the project
    type,
    data,
    createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
  };
}

export function createDocumentVersion({
  projectId,
  document,
  userId,
  label = "",
  dispatch,
  addVersionSuccessCB,
}) {
  if (isSandbox()) {
    createSandboxDocumentVersion({
      projectId,
      document,
      userId,
      label,
      dispatch,
      addVersionSuccessCB,
    });
    return;
  }

  db.collection("projects")
    .doc(projectId)
    .collection("documentVersions")
    .add({
      document,
      userId,
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
      label,
    })
    .then((item) => {
      db.collection("projects")
        .doc(projectId)
        .update({
          versions: firebaseLib.firestore.FieldValue.arrayUnion({
            versionId: item.id,
            label,
          }),
        });
      dispatch({
        type: ACTIONS.ADD_DOCUMENT_VERSION,
        payload: {
          projectId,
          version: { label, versionId: item.id },
        },
      });

      addAnalyticsEvent(CATEGORIES.PROJECT, "Create version", projectId);
      amplitude.getInstance().logEvent("create document version", {
        label: label,
        "version id": item.id,
        "project id": projectId,
      });

      if (addVersionSuccessCB) {
        addVersionSuccessCB();
      }
    });
}

export function removeDocumentVersion({
  projectId,
  versionId,
  versions,
  dispatch,
}) {
  if (isSandbox()) {
    removeSandboxDocumentVersion({ projectId, versionId, versions, dispatch });
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .collection("documentVersions")
    .doc(versionId)
    .delete()
    .then(() => {
      db.collection("projects")
        .doc(projectId)
        .set(
          {
            versions: versions.filter(
              (version) => version.versionId !== versionId
            ),
          },
          { merge: true }
        )
        .then(() => {
          amplitude.getInstance().logEvent("remove document version", {
            "version id": versionId,
            "project id": projectId,
          });

          dispatch({
            type: ACTIONS.REMOVE_DOCUMENT_VERSION,
            payload: { projectId, versionId },
          });
        });
    });
}

export function getDocumentVersionById({
  projectId,
  versionId,
  dispatch,
  onSuccessCb,
}) {
  if (isSandbox()) {
    getSandboxDocumentVersionById({
      projectId,
      versionId,
      dispatch,
      onSuccessCb,
    });
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .collection("documentVersions")
    .doc(versionId)
    .get()
    .then((querySnapshot) => {
      const documentData = querySnapshot.data();
      dispatch({
        type: ACTIONS.LOAD_DOCUMENT_VERSION,
        payload: { projectId, documentData, versionId },
      });
      onSuccessCb();
    });
}

export async function getBasicProjectsData(projectIds, dispatch) {
  try {
    const projects = await getBasicProjects(projectIds);
    const members = await getMembers(uniq(projects?.allMembers));
    dispatch({
      type: ACTIONS.LOAD_BASIC_PROJECTS_DATA,
      payload: { projects: projects?.projects, members },
    });
  } catch (error) {
    Sentry.captureException(error);
    toast.warning(
      "Something went wrong while trying to get basic project data"
    );
  }
}

async function getBasicProjects(projectIds) {
  if (!Array.isArray(projectIds) || !projectIds.length) {
    return;
  }

  // @TODO: Add restriction to 10 projects per user.
  let projectRefs = projectIds.map((id) => {
    return db.collection("projects").doc(id).get();
  });
  const allMembers = [];
  const projectsSnapshot = await Promise.all(projectRefs);
  let projects = projectsSnapshot.reduce((result, doc) => {
    if (!doc.exists) {
      // For some reason we are trying to load a project
      // information where project is deleted
      return result;
    }

    const data = doc.data();
    data.id = doc.id;
    result[doc.id] = data;

    allMembers.push(...Object.values(data.members));

    return result;
  }, {});

  return { projects, allMembers };
}

async function getMembers(membersArr) {
  if (!Array.isArray(membersArr) || !membersArr.length) {
    return;
  }
  let membersRefs = membersArr.map((member) => {
    return db.collection("users").doc(member.id).get();
  });
  // @TODO: Add restriction to 10 projects per user.
  const membersSnapshot = await Promise.all(membersRefs);
  let members = membersSnapshot.reduce((result, doc) => {
    if (doc.data() && Object.keys(doc.data()).length > 0) {
      result[doc.id] = doc.data();
    }
    return result;
  }, {});
  return members;
}

export function getWalktroughProjectData({ dispatch, tourDispatch, history }) {
  if (isSandbox()) {
    getSandboxWalktroughProject(dispatch, tourDispatch, history);
    return;
  }

  const currentUserId = get(auth, "currentUser.uid", false);
  db.collection("users").doc("dummyJohnny").set({
    name: "Johnny",
    email: "DummySandboxJohnnyJadeUser@gmail.com",
    id: "dummyJohnny",

    invitationPending: false,
  });
  createNewProject(currentUserId, "Tour Project", "tprj", history, true);
  localStorage.setItem("returningUser", JSON.stringify(true));
  setTimeout(() => {
    tourDispatch({
      type: TOUR_ACTIONS.TOGGLE_TOUR,
      payload: {
        tourStage: TOUR_STAGES.IN_PROGRESS,
      },
    });
  }, 1000);
}

export function getProjectData({
  projectId,
  dispatch,
  onAccessDenied,
  getDataCb,
}) {
  if (isSandbox()) {
    getSandboxProjectData(dispatch);
    return;
  }
  if (!projectId) return;

  const currentUserId = get(auth, "currentUser.uid", false);

  if (!currentUserId) {
    // Do not fetch project data in case
    // user is not logged in
    return;
  }

  function getStatusUpdateNecessary(dbStatuses) {
    let statuses = [...dbStatuses];
    let statusUpdateNecessary = false;

    const hasDraft = getStatusBySlug(dbStatuses, STATUSES_SLUGS.DRAFT);
    const hasBacklog = getStatusBySlug(dbStatuses, STATUSES_SLUGS.BACKLOG);
    const hasClosed = getStatusBySlug(dbStatuses, STATUSES_SLUGS.CLOSED);

    // Project MUST have DRAFT status
    if (!hasDraft) {
      statusUpdateNecessary = true;

      // Add DRAFT status
      statuses = [
        ...statuses,
        {
          slug: STATUSES_SLUGS.DRAFT,
          label: "Draft",
          color: "gray",
          id: firestoreAutoId(),
        },
      ];
    }

    // Project MUST have CLOSED status
    if (!hasClosed) {
      statusUpdateNecessary = true;
      statuses = [
        ...statuses,
        {
          slug: STATUSES_SLUGS.CLOSED,
          label: "Closed",
          color: "gray",
          id: firestoreAutoId(),
        },
      ];
    }

    // Project MUST NOT have BACKLOG status
    if (hasBacklog) {
      statusUpdateNecessary = true;
      statuses = statuses.filter((s) => s.slug !== STATUSES_SLUGS.BACKLOG);
    }

    return {
      statuses,
      statusUpdateNecessary,
    };
  }

  function fixedBacklogPosition(allSortedBacklogItems, backlogItem) {
    // const item = { ...backlogItem };

    // Get the index of the item to calculate positions of items below and above
    const filteredItemIndex = allSortedBacklogItems.findIndex(
      (item) => item.id === backlogItem.id
    );

    const itemAbovePosition =
      filteredItemIndex === 0
        ? null
        : allSortedBacklogItems[filteredItemIndex - 1]?.orderPosition
            .backlogPosition;

    const itemBelowPosition = allSortedBacklogItems[filteredItemIndex + 1]
      ? allSortedBacklogItems[filteredItemIndex + 1].orderPosition
          .backlogPosition
      : null;

    /**
     * THIS IS DIRECTLY CHANGING THE REFERENCE OF THE BACKLOG ITEM PASSED
     * TO THE FUNCTION. IT WILL ALSO CHANGE THAT ITEM IN THE allSortedBacklogItems
     * AS WELL.
     *
     * Basically we need to re-calculate the backlog position and update the
     * sorted items since we need the newly calculated position later on.
     *
     * Since JS is pass-by-reference it's utilized here as an exception to the
     * rule of always creating a new object reference to prevent bugs.
     */
    backlogItem.orderPosition = {
      backlogPosition: getKeyBetween(itemAbovePosition, itemBelowPosition),
      statusPosition: backlogItem.orderPosition.statusPosition,
    };

    return backlogItem;
  }

  function getItemsUpdateNecessary(dbStatuses, dbItems) {
    let items = [...dbItems];
    let itemsToUpdateStatus = [];
    let itemsToUpdatePositions = [];
    let itemsUpdateNecessary = false;
    let itemsStatusUpdateNecessary = false;
    let itemsPositionsUpdateNecessary = false;

    const statusIds = dbStatuses.map((s) => s.id);

    const sortedBacklogItems = getSortedBacklogProjectItems(items, dbStatuses);
    const duplicateBacklogKeys = [];
    const tempBacklogKeys = [];

    sortedBacklogItems.forEach((bItem) => {
      const bPosition = bItem.orderPosition.backlogPosition;

      if (tempBacklogKeys.includes(bPosition)) {
        // Save the duplicate
        duplicateBacklogKeys.push(bItem.orderPosition.backlogPosition);
      }

      tempBacklogKeys.push(bPosition);
    });

    console.log({ duplicateBacklogKeys });

    const openStatusId = dbStatuses.find(
      (s) => s.slug === STATUSES_SLUGS.OPEN
    ).id;

    items = items.map((item, i) => {
      if (!statusIds.includes(item.status)) {
        // Item has a status that's not in the database
        itemsStatusUpdateNecessary = true;
        itemsUpdateNecessary = true;

        // Set the item default OPEN status
        item.status = openStatusId;

        itemsToUpdateStatus.push(item);
      }

      if (!item.hasOwnProperty("orderPosition")) {
        // Item does not have a position set
        itemsUpdateNecessary = true;
        itemsPositionsUpdateNecessary = true;

        // Set item positions based on the previous item positions
        item.orderPosition = getInitialOrderPosition(
          items,
          dbStatuses,
          openStatusId
        );

        // Mark this item to be updated in the database
        itemsToUpdatePositions.push(item);
      } else if (
        duplicateBacklogKeys.includes(item.orderPosition.backlogPosition)
      ) {
        itemsUpdateNecessary = true;
        itemsPositionsUpdateNecessary = true;

        // Check if this item is in the active list of backlog items
        const inBacklog = sortedBacklogItems.find(
          (bItem) => bItem.id === item.id
        );

        if (inBacklog) {
          try {
            item = fixedBacklogPosition(sortedBacklogItems, item);
          } catch (error) {
            // Better to fail and report this positioning error than to prevent full
            // project loading
          }
        }

        // Mark this item to be updated in the database
        itemsToUpdatePositions.push(item);
      }

      return item;
    });

    return {
      items,
      itemsUpdateNecessary,
      itemsStatusUpdateNecessary,
      itemsPositionsUpdateNecessary,
      itemsToUpdateStatus,
      itemsToUpdatePositions,
    };
  }

  dispatch({
    type: ACTIONS.LOADING_PROJECT_INITIATED,
  });
  // TO DO: create a function to clean up the code. It should return the flag if db needs to be updated and status array
  const projectDocPromise = db
    .collection("projects")
    .doc(projectId)
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.data();

      // Need to set it manually
      data.id = projectId;

      if (data && !data.hasOwnProperty("logicallyDeleted")) {
        // Temporary solution until we are able to get the data from DB based on logicallyDeleted field
        db.collection("projects").doc(projectId).update({
          logicallyDeleted: false,
        });
      }

      const { statusUpdateNecessary, statuses } = getStatusUpdateNecessary(
        data.statuses
      );

      if (statusUpdateNecessary) {
        // Update database
        db.collection("projects")
          .doc(projectId)
          .update({
            statuses: [...statuses],
          });

        // prepare for context
        data.statuses = [...statuses];
      }

      if (!data.priority) {
        db.collection("projects").doc(projectId).update({
          priority: INITIAL_PRIORITIES,
        });

        data.priority = INITIAL_PRIORITIES;
      }
      if (!data.labels) {
        const suggestedLabelsWithId = SUGGESTED_LABELS.map((label) => ({
          ...label,
          id: firestoreAutoId(),
        }));
        db.collection("projects").doc(projectId).update({
          labels: suggestedLabelsWithId,
        });

        data.labels = suggestedLabelsWithId;
      }
      return data;
    });

  const projectItemsPromise = db
    .collection("items")
    .where("projectId", "==", projectId)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;

        return data;
      });
    });

  return Promise.all([projectDocPromise, projectItemsPromise])
    .then((res) => {
      const projectDocQuerySnapshot = res[0];
      let projectItemsQuerySnapshot = res[1];

      /**
       * Check if all project items have the correct status set. If not set that
       * status to the default OPEN status.
       */
      const {
        itemsUpdateNecessary,
        itemsStatusUpdateNecessary,
        itemsPositionsUpdateNecessary,
        items,
        itemsToUpdateStatus,
        itemsToUpdatePositions,
      } = getItemsUpdateNecessary(
        projectDocQuerySnapshot.statuses,
        projectItemsQuerySnapshot
      );

      if (itemsUpdateNecessary) {
        // Update the items in the database
        const batch = db.batch();

        if (itemsStatusUpdateNecessary) {
          itemsToUpdateStatus.forEach((item) => {
            try {
              const docRef = db.collection("items").doc(item.id);
              const data = {
                status: item.status,
              };

              batch.update(docRef, data);
            } catch (e) {
              // TODO - FIXME!
            }
          });
        }
        if (itemsPositionsUpdateNecessary) {
          itemsToUpdatePositions.forEach((item) => {
            try {
              const docRef = db.collection("items").doc(item.id);
              const data = {
                orderPosition: item.orderPosition,
              };

              batch.update(docRef, data);
            } catch (e) {
              // TODO - FIXME!
            }
          });
        }

        batch.commit();

        // Set the correct items context value
        projectItemsQuerySnapshot = items;
      }

      // Get all members data for this project
      // This supports only 10 items fetch!! Firestore, go figure
      const projectMembers = Object.keys(projectDocQuerySnapshot.members) || [];
      if (!projectMembers.includes(currentUserId) && onAccessDenied) {
        // TODO: Boris: this check has to be done in firebase rules
        console.warn("Current user is not in project members list!");
        onAccessDenied();
        return;
      }

      const membersSlice = projectMembers.slice(0, 10);
      // TODO: if you try to fetch more than 10 items, firebase will thrown an error. Pagination is needed.
      getPendingUsers(projectId, dispatch);
      db.collection("users")
        .where("id", "in", membersSlice)
        .get()
        .then((querySnapshot) => {
          const projectMembers = querySnapshot.docs.reduce((result, doc) => {
            result[doc.id] = doc.data();
            return result;
          }, {});
          dispatch({
            type: ACTIONS.LOAD_PROJECT_DATA,
            payload: {
              projectData: projectDocQuerySnapshot,
              projectItems: projectItemsQuerySnapshot,
              projectMembers,
            },
          });

          dispatch({
            type: ACTIONS.SET_SELECTED_ITEM_PROJECT_ID,
            payload: {
              selectedItemProjectId: projectId,
            },
          });
          getDataCb && getDataCb();

          amplitude.getInstance().logEvent("open project", {
            "project id": projectId,
            "project members count": Object.keys(projectMembers).length,
          });
        });
    })

    .catch((error) => {
      Sentry.captureException(error);
      console.error("Could not load project!", error);
    });
}

async function getPendingUsers(projectId, dispatch) {
  const snapshot = await db
    .collection("invitations")
    .where("invitedUserId", "==", null)
    .where("projectId", "==", projectId)
    .get();

  const pendingUsers = snapshot.docs.map((doc) => doc.data());

  dispatch({
    type: ACTIONS.SET_ACTIVE_PROJECT_PENDING_USERS,
    payload: pendingUsers,
  });
}

export function saveEditorState(
  projectId,
  newEditorState,
  lastSavedEditorState,
  projectItems,
  editorLoaded,
  dispatch
) {
  if (!editorLoaded || !projectId || !newEditorState) return;
  if (isSandbox()) {
    saveSandboxEditorState(
      projectId,
      newEditorState,
      lastSavedEditorState,
      projectItems,
      editorLoaded,
      dispatch
    );
    return;
  }
  const hasText = newEditorState
    .getCurrentContent()
    .getBlocksAsArray()
    .find((block) => {
      if (!block.text) return false;
      if (block.text.trim() !== "") return true;
    });

  if (!hasText) {
    // Editor state is empty can not save!
    return;
  }

  const rawNewContentState = convertToRaw(newEditorState.getCurrentContent());

  const itemsToUpdate = [];

  const diffOldNew = immutableDiff(
    lastSavedEditorState.getCurrentContent().blockMap,
    newEditorState.getCurrentContent().blockMap
  );

  if (!diffOldNew.isEmpty()) {
    const flatParentChildHierarchy = flattenHeaderHierarchyArray(
      normalizeRawBlocksToParentChild(rawNewContentState.blocks, projectItems)
    );

    const headerBlockProjectItemsIds = projectItems
      .map((pItem) => pItem.blockId)
      .filter((pItem) => pItem);

    const changedBlockIds = diffOldNew
      .map((entry) => {
        return entry.toJSON().path.split("/")[1];
      })
      .toSet()
      .toList();

    // Which of those changed are header elements. If it's in projectItems it's a
    // header element that has some metadata assigned. Change the 'Title of that
    // element
    changedBlockIds
      .filter((cbi) => headerBlockProjectItemsIds.indexOf(cbi) >= 0)
      .forEach((cbi) => {
        const data = flatParentChildHierarchy.find(
          (block) => block.key === cbi
        );
        if (data) {
          itemsToUpdate.push({
            id: data.projectItemMetadata.id,
            title: data.text,
          });
        }
      });

    // Out of those that are not header element find the parent elements and see if
    // they are in projectItems. Change the content of that element
    changedBlockIds.forEach((cbi) => {
      // Dissregard header items, we have them already
      if (headerBlockProjectItemsIds.indexOf(cbi) >= 0) return;

      // Find the parent of this block
      const parent = flatParentChildHierarchy.find(
        (block) => block.content && block.content.find((bc) => bc.key === cbi)
      );

      if (!parent) {
        // Means that this block does still not have a firestore linked Item entry
        // dissregard
        return;
      }

      const blockActive = projectItems.find(
        (pItem) => pItem.blockId === parent.key
      );

      if (blockActive) {
        const data = flatParentChildHierarchy.find(
          (block) => block.key === parent.key
        );

        const htmlContentSlice = createEditorStateHtmlSlice(
          newEditorState.getCurrentContent(),
          data.content
        );

        itemsToUpdate.push({
          id: data.projectItemMetadata.id,
          htmlContent: htmlContentSlice,
        });

        const tasks = projectItems.filter(
          (pI) => pI.featureItemId === data.projectItemMetadata.id
        );

        if (tasks) {
          tasks.forEach((task) =>
            itemsToUpdate.push({
              id: task.id,
              featureHtmlContent: htmlContentSlice,
            })
          );
        }
      }
    });
  }

  if (itemsToUpdate.length) {
    const itemsMerge = itemsToUpdate.reduce((memo, item) => {
      // Check if memo already contains the item with this id
      const memoItem = memo.find((mItem) => mItem.id === item.id);

      if (memoItem) {
        // Recreate memo array with merged contents
        const newMemo = memo.map((mItem) => {
          if (mItem.id === item.id) {
            return { ...mItem, ...item };
          }

          return mItem;
        });

        return newMemo;
      } else {
        memo.push(item);
      }

      return memo;
    }, []);

    /**
     * Temporary bucket to store reference to all history objects for changed
     * blocks
     */
    let batchBlocksUpdatesChanges = {};

    // Batch write the changes to items content
    if (itemsMerge.length) {
      const batch = db.batch();

      itemsMerge.forEach((iMerge) => {
        try {
          const docRef = db.collection("items").doc(iMerge.id);
          const data = { ...iMerge };

          if (data.id) {
            delete data.id;
          }
          batch.update(docRef, data);

          // Check if this item has been updated in the last 2 minutes.
          // If yes update the history entry, if not create a new history entry
          if (shouldUpdateHistory(editorUpdates, iMerge)) {
            batchBlocksUpdatesChanges = {
              ...batchBlocksUpdatesChanges,
              [iMerge.id]: {
                ts: Date.now(),
                historyId: editorUpdates[iMerge.id].historyId,
              },
            };

            /**
             * Update history Item
             */
            reqisterHistoryUpdate({
              batch,
              batchType: "UPDATE",
              itemId: iMerge.id,
              historyId: editorUpdates[iMerge.id].historyId,
              htmlContent: iMerge.htmlContent,
            });
          } else {
            const fbHistoryId = firestoreAutoId();

            batchBlocksUpdatesChanges = {
              ...batchBlocksUpdatesChanges,
              [iMerge.id]: {
                ts: Date.now(),
                historyId: fbHistoryId,
              },
            };

            /**
             * Create new history Item
             */
            reqisterHistoryUpdate({
              batch,
              batchType: "SET",
              itemId: iMerge.id,
              historyId: fbHistoryId,
              htmlContent: iMerge.htmlContent,
            });
          }
        } catch (e) {
          // TODO - FIXME!
        }
      });

      batch
        .commit()
        .then(() => {
          // Update the editorUpdates object with blocks that were updated
          // only after successful save
          // This keeps local reference of all blocks that were updated
          // with timestaps for later comparison
          editorUpdates = {
            ...editorUpdates,
            ...batchBlocksUpdatesChanges,
          };

          // Update the project items locally with new content
          dispatch({
            type: ACTIONS.SET_PROJECT_ITEMS,
            payload: {
              projectItems: [...projectItems].map((pItem) => {
                const match = itemsMerge.find(
                  (iMerge) => iMerge.id === pItem.id
                );

                if (match) {
                  return { ...pItem, ...match };
                }

                return pItem;
              }),
            },
          });
        })
        .catch(() => {
          // TODO - FIXME!
        });
    }
  }

  db.collection("projects")
    .doc(projectId)
    .update({
      document: rawNewContentState,
    })
    .then(() => {
      // Update the last database editor content in redux
      dispatch({
        type: ACTIONS.EDITOR_CONTENTS_SAVED,
        payload: {
          savedEditorState: newEditorState,
        },
      });
    });
}

export function getItemData(itemId, itemDataCb) {
  if (isSandbox()) {
    getSandboxItemData(itemId, itemDataCb);
    return;
  }
  db.collection("items")
    .doc(itemId)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot) {
        itemDataCb({
          id: querySnapshot.id,
          ...querySnapshot.data(),
        });
      }
    });
}

export function getDiscussions(selectedItemId, dispatch) {
  if (isSandbox()) {
    getSandboxDiscussions(selectedItemId, dispatch);
    return;
  }
  db.collection("items")
    .doc(selectedItemId)
    .collection("discussions")
    .orderBy("createdTs")
    .get()
    .then((querySnapshot) => {
      const itemDiscussions = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;

        return data;
      });

      dispatch({
        type: ACTIONS.SET_ITEM_DISCUSSIONS,
        payload: itemDiscussions,
      });
    });
}

export function removeBlockItem({
  dispatch,
  blockId,
  projectItems,
  data,
  projectId,
  removeBlockItemSuccessCB,
}) {
  addAnalyticsEvent(CATEGORIES.TASK, "Remove feature item", blockId);

  amplitude.getInstance().logEvent("remove task", {
    "project id": projectId,
    "task id": blockId,
  });

  const blockProjectItem = projectItems.find(
    (pItem) => pItem.blockId === blockId
  );

  if (!blockProjectItem || !blockProjectItem.id) {
    // Toast? Analytics?  Sentry?
    return;
  }

  db.collection("items")
    .doc(blockProjectItem.id)
    .delete()
    .then(() => {
      /**
       * Item successfully deleted
       * Update the global state
       */

      const updateProjectItems = [...projectItems].filter(
        (pItem) => pItem.id !== blockProjectItem.id
      );

      dispatch({
        type: ACTIONS.SET_PROJECT_ITEMS,
        payload: {
          projectItems: updateProjectItems,
        },
      });

      dispatch({
        type: ACTIONS.SHOW_ITEM_DETAILS,
        payload: {
          selectedItemId: null,
          sidebarVisible: false,
        },
      });

      if (removeBlockItemSuccessCB) {
        removeBlockItemSuccessCB();
      }

      toast.success(`You have successfully deleted a feature item.`);
    });
}

export function createBlockItem({
  projectId,
  blockId,
  projectItems,
  data,
  statuses,
  statusId,
  dispatch,
  createBlockSuccessCB,
}) {
  if (isSandbox()) {
    createSandboxBlockItem({
      projectId,
      blockId,
      projectItems,
      data,
      statuses,
      statusId,
      dispatch,
      createBlockSuccessCB,
    });
    return;
  }

  addAnalyticsEvent(CATEGORIES.TASK, "Create task", blockId);

  amplitude.getInstance().logEvent("create task", {
    "task id": blockId,
    "project id": projectId,
  });

  db.collection("items")
    .add({
      ...data,
      orderPosition: getInitialOrderPosition(projectItems, statuses, statusId),
      blockId,
      projectId,
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
    })
    .then((item) => {
      // Create a discussions collection
      db.collection("items").doc(item.id).collection("discussions").add({
        name: "General",
        msgCount: 0,
        createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
      });

      /**
       * Create initial item description history entry
       */
      db.collection("items")
        .doc(item.id)
        .collection("itemHistory")
        .add(
          createItemHistoryEntry(
            auth.currentUser.uid,
            auth.currentUser.displayName,
            ITEM_HISTORY_CHANGE_TYPES.CHANGE_ITEM_DESCRIPTION,
            {
              htmlContent: data.htmlContent,
            }
          )
        );

      /**
       * Item successfully created
       * Update the global state
       */
      // dispatch({
      //   type: ACTIONS.SET_PROJECT_ITEMS,
      //   payload: {
      //     projectItems: [
      //       ...projectItems,
      //       { id: item.id, ...data, blockId, projectId },
      //     ],
      //   },
      // });

      if (createBlockSuccessCB) {
        createBlockSuccessCB(item.id);
      }
      // dispatch({
      //   type: ACTIONS.SHOW_ITEM_DETAILS,
      //   payload: {
      //     selectedItemId: item.id,
      //     selectedItemProjectId: projectId,
      //     sidebarVisible: true,
      //   },
      // });

      toast.success(`New item created: ${data.title}`);
    });
}

export function createDiscussion(selectedItemId, discussionName) {
  if (isSandbox()) {
    createSandboxDiscussion(selectedItemId, discussionName);
    return;
  }

  db.collection("items")
    .doc(selectedItemId)
    .collection("discussions")
    .add({
      name: discussionName,
      msgCount: 0,
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => toast.success("Discussion started!"));
}
export function sendMessage(
  selectedItemId,
  activeDiscussionId,
  user,
  htmlContent,
  dispatch
) {
  if (isSandbox()) {
    sendSandboxMessage(
      selectedItemId,
      activeDiscussionId,
      user,
      htmlContent,
      dispatch
    );
    return;
  }
  // Batch send message and update msgCount
  const batch = db.batch();

  const msgRef = db
    .collection("items")
    .doc(selectedItemId)
    .collection("discussions")
    .doc(activeDiscussionId)
    .collection("messages")
    .doc(firestoreAutoId());

  batch.set(msgRef, {
    discussionId: activeDiscussionId,
    user: user,
    htmlContent: htmlContent,
    createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
  });

  const activeDiscRef = db
    .collection("items")
    .doc(selectedItemId)
    .collection("discussions")
    .doc(activeDiscussionId);

  batch.update(activeDiscRef, {
    msgCount: firebaseLib.firestore.FieldValue.increment(1),
  });

  batch
    .commit()
    .then(() => {
      getDiscussions(selectedItemId, dispatch);
      getMessages(selectedItemId, activeDiscussionId, dispatch);
      toast.success(`You sent a message`);
    })
    .catch(() => toast.error("Something went wrong"));
}

export function getMessages(selectedItemId, activeDiscussionId, dispatch) {
  if (isSandbox()) {
    getSandboxMessages(selectedItemId, activeDiscussionId, dispatch);
    return;
  }
  db.collection("items")
    .doc(selectedItemId)
    .collection("discussions")
    .doc(activeDiscussionId)
    .collection("messages")
    .orderBy("createdTs", "desc")
    .get()
    .then((querySnapshot) => {
      const discussionMessages = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
      dispatch({
        type: ACTIONS.SET_DISCUSSION_MESSAGES,
        payload: discussionMessages,
      });
    });
}
export function updateProjectItem({
  projectId,
  itemId,
  projectItems,
  data,
  dispatch,
}) {
  if (!itemId) return;

  if (isSandbox()) {
    updateSandboxProjectItem({
      projectId,
      itemId,
      projectItems,
      data,
      dispatch,
    });
    return;
  }

  addAnalyticsEvent(CATEGORIES.TASK, "Update task", itemId);
  amplitude.getInstance().logEvent("update task", {
    "project id": projectId,
    "task id": itemId,
  });

  const item = projectItems.find((item) => {
    return item.id === itemId;
  });

  // Don't store the id of the item explicitly
  if (data.id) {
    delete data.id;
  }

  // Update changed timestamp
  data.changedTs = firebaseLib.firestore.FieldValue.serverTimestamp();

  if (item) {
    db.collection("items")
      .doc(item.id)
      .update({ ...data })
      .then(() => {
        // Update successful on the server
        dispatch({
          type: ACTIONS.SET_PROJECT_ITEMS,
          payload: {
            projectItems: projectItems.map((pItem) => {
              if (pItem.id === itemId) {
                return { ...pItem, ...data };
              }

              return { ...pItem };
            }),
          },
        });
      });
  }
}

export function createAdHocItem({
  data,
  dispatch,
  statuses,
  statusId,
  projectItems,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create adhoc item data!");
  }
  if (isSandbox()) {
    createSandboxAdHocItem({
      data,
      dispatch,
      projectItems,
      statuses,
      statusId,
    });
    return;
  }

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );
  db.collection("items")
    .add({
      ...data,
      orderPosition,
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
    })
    .then((item) => {
      addAnalyticsEvent(CATEGORIES.TASK, "Create ad-hoc task", item.id);
      amplitude.getInstance().logEvent("create ad-hoc task", {
        "project id": data.projectId,
        "task id": item.id,
      });

      db.collection("items")
        .doc(item.id)
        .collection("discussions")
        .add({ name: "General", msgCount: 0 });

      dispatch({
        type: ACTIONS.SET_PROJECT_ITEMS,
        payload: {
          projectItems: [
            ...projectItems,
            {
              id: item.id,
              orderPosition,
              ...data,
            },
          ],
        },
      });
    });
}

export function getUserData(uid, setUserDataCb, setLoadingCb = () => {}) {
  // Get data from firestore
  const userProjects = db
    .collection("projects")
    .where("owner", "==", uid)
    .where("logicallyDeleted", "==", false)
    .get()
    .then((querySnapshot) => {
      const itemsData = [];

      querySnapshot.forEach(function (doc) {
        const data = doc.data();
        data.id = doc.id;
        itemsData.push(data);
      });

      return itemsData;
    });

  const userMemberProjects = db
    .collection("projects")
    .where(`members.${uid}.id`, "==", uid) // query all documents that relate to a certain member (id). Basically checking if this member exists https://stackoverflow.com/questions/54439471/firestore-query-by-object-key
    .where("logicallyDeleted", "==", false)
    .get()
    .then((querySnapshot) => {
      const itemsData = [];

      querySnapshot.forEach(function (doc) {
        const data = doc.data();
        data.id = doc.id;
        itemsData.push(data);
      });
      return itemsData;
    });

  const userItems = db
    .collection("items")
    .where("assignee.id", "==", uid)
    .get()
    .then((querySnapshot) => {
      const itemsData = [];

      querySnapshot.forEach(function (doc) {
        const data = doc.data();
        data.id = doc.id;
        itemsData.push(data);
      });

      return itemsData;
    });

  Promise.all([userProjects, userItems, userMemberProjects]).then((res) => {
    const userProjectsData = res[0];
    const userItemsData = res[1];
    const userMemberProjectsData = res[2];

    let allProjects = [];

    if (userProjectsData) {
      allProjects = allProjects.concat(userProjectsData);
    }

    if (userMemberProjectsData) {
      allProjects = allProjects.concat(userMemberProjectsData);
    }

    const allProjectsFiltered = Array.from(
      new Set(allProjects.map((a) => a.id))
    ).map((id) => {
      return allProjects.find((a) => a.id === id);
    });

    setUserDataCb({ userProjectsData: allProjectsFiltered, userItemsData });
    setLoadingCb(false);
  });
}

export function createNewProject(
  uid,
  name,
  code,
  history,
  blankProject,
  editorState,
  dispatch
) {
  db.collection("projects")
    .add({
      name,
      code,
      owner: uid,
      members: {
        [uid]: {
          id: uid,
          role: USER_ROLES.OWNER,
          permissions: [
            {
              subject: PERMISSIONS_SUBJECTS.EDITOR,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.METADATA,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.PROJECT_SETTINGS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
            {
              subject: PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
              action: PERMISSIONS_ACTIONS.MANAGE,
            },
          ],
        },
      },
      document: !blankProject
        ? NEW_PROJECT_DOCUMENT_CONTENT
        : {
            blocks: [
              {
                key: "asvrm",
                text: "",
                type: "header-one",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
              {
                key: "6k6hp",
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
              {
                key: "2s2fg",
                text: " ",
                type: "atomic",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [
                  {
                    offset: 0,
                    length: 1,
                    key: 0,
                  },
                ],
                data: {},
              },
            ],
            entityMap: {
              0: {
                type: "tasksBlock",
                mutability: "IMMUTABLE",
                data: {
                  bucketBlockId: "asvrm",
                },
              },
            },
          },
      statuses: [
        {
          slug: STATUSES_SLUGS.DRAFT,
          label: "Draft",
          color: "gray",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.OPEN,
          label: "Open",
          color: "blue",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.INPROGRESS,
          label: "In progress",
          color: "purple",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.DONE,
          label: "Done",
          color: "green",
          id: firestoreAutoId(),
        },
        {
          slug: STATUSES_SLUGS.CLOSED,
          label: "Closed",
          color: "gray",
          id: firestoreAutoId(),
        },
      ],
      sprints: [],
      logicallyDeleted: false,
      priority: INITIAL_PRIORITIES,
      labels: SUGGESTED_LABELS.map((label) => ({
        ...label,
        id: firestoreAutoId(),
      })),
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
    })
    .then((item) => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Create project", item.id);
      amplitude.getInstance().logEvent("create project", {
        "project id": item.id,
        "project name": name,
      });

      history.push(`/project/${item.id}/editor`);

      toast.success(`Awesome! You've created a new project called ${name}`);
    });
}

export function deleteProject(projectId, projectName, deleteProjectCb) {
  if (projectId === "WWZ1Klq2YzkJ5zKk0Lqs") {
    alert("Can't delete this project!");
    return;
  }

  db.collection("projects")
    .doc(projectId)
    .update({
      logicallyDeleted: true,
    })
    .then(() => {
      toast.success("Project successfully deleted");

      addAnalyticsEvent(CATEGORIES.PROJECT, "Delete project", projectId);
      amplitude.getInstance().logEvent("delete project", {
        "project id": projectId,
        "project name": projectName,
      });
    });
}

export function changeProjectName(projectId, projectName, dispatch) {
  if (!projectId || !projectName) return;
  if (isSandbox()) {
    changeSandboxProjectName(projectId, projectName, dispatch);
    return;
  }

  db.collection("projects")
    .doc(projectId)
    .update({
      name: projectName,
    })
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Rename project", projectId);
      amplitude.getInstance().logEvent("rename project", {
        "project id": projectId,
        "project name": projectName,
      });

      dispatch({
        type: ACTIONS.RENAME_PROJECT,
        payload: {
          projectId,
          projectName,
        },
      });
    });
}

/**
 * Invite user via email to a project
 * @param {*} email
 * @param {*} projectId
 */
export async function inviteUser(email, project, dispatch, inviterPermissions) {
  try {
    dispatch({
      type: ACTIONS.LOADING_MEMBERS,
      payload: true,
    });

    ///  /inviteUser is the needed url
    await httpClient.post("/inviteUser", {
      email,
      projectId: project.id,
      projectName: project.name,
      inviterId: get(auth, "currentUser.uid"),
      inviterName: get(auth, "currentUser.displayName", "Team member"),
      inviterPermissions,
    });

    addAnalyticsEvent(CATEGORIES.PROJECT, "Invite user to project", project.id);
    amplitude.getInstance().logEvent("invite user", {
      "project id": project.id,
      "project name": project.name,
      permissions: inviterPermissions,
    });

    // Reload basic project data ( including members )

    // TODO: we have to reload only members and pending users
    await getBasicProjectsData([project.id], dispatch);
    getPendingUsers(project.id, dispatch);
    dispatch({
      type: ACTIONS.LOADING_MEMBERS,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.LOADING_MEMBERS,
      payload: false,
    });
    // TODO - FIXME!
    throw e;
  }
}

/**
 * Revoke user's access to a project
 * @param {*} userId
 * @param {*} projectId
 */
export async function revokeMembersAccess(userId, projectId, dispatch) {
  // @TODO: Dodati firebase rule da se user koji nije project owner, ne moze promjeniti membere.
  try {
    const _ = await db
      .collection("projects")
      .doc(projectId)
      .update({
        [`members.${userId}`]: firebaseLib.firestore.FieldValue.delete(),
      });

    amplitude.getInstance().logEvent("revoke user access", {
      "project id": projectId,
      "revoked user id": userId,
    });

    // Reload basic project data ( including members )
    getBasicProjectsData([projectId], dispatch);
  } catch (e) {
    // TODO - FIXME!
    throw e;
  }
}

export function addNewStatus(projectId, statuses, status, dispatch) {
  if (isSandbox()) {
    addNewSandboxStatus(projectId, statuses, status, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .update({ statuses: [...statuses, status] })
    .then(() => {
      amplitude.getInstance().logEvent("add status", {
        "project id": projectId,
      });

      dispatch({
        type: ACTIONS.ADD_STATUS,
        payload: { projectId, status },
      });
    });
}

export function removeStatus(projectId, statuses, id, dispatch) {
  if (isSandbox()) {
    removeSandboxStatus(projectId, statuses, id, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .set({ statuses: statuses.filter((st) => st.id !== id) }, { merge: true })
    .then(() => {
      dispatch({
        type: ACTIONS.DELETE_STATUS,
        payload: { projectId, id },
      });

      amplitude.getInstance().logEvent("remove status", {
        "project id": projectId,
      });

      toast.success("Status succesfully deleted");
    });
}

export function editStatus(projectId, statuses, status, dispatch) {
  if (isSandbox()) {
    editSandboxStatus(projectId, statuses, status, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .set(
      {
        statuses: statuses.map((st) =>
          st.id === status.id
            ? { ...status, label: status.label, color: status.color }
            : st
        ),
      },
      { merge: true }
    )
    .then(() => {
      amplitude.getInstance().logEvent("edit status", {
        "project id": projectId,
      });

      dispatch({
        type: ACTIONS.EDIT_STATUS,
        payload: { projectId, status },
      });
    });
}

export function reorderStatuses(projectId, statusOrder, dispatch) {
  if (isSandbox()) {
    reorderSandboxStatuses(projectId, statusOrder, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .set(
      {
        statuses: [...statusOrder],
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: ACTIONS.REORDER_STATUSES,
        payload: { projectId, statusOrder },
      });
    });
}

export function createSprint(
  projectId,
  sprints,
  sprint,
  dispatch,
  createSprintCb
) {
  if (isSandbox()) {
    createSandboxSprint(projectId, sprints, sprint, dispatch, createSprintCb);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .update({ sprints: [...sprints, sprint] })
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Create sprint", projectId);
      amplitude.getInstance().logEvent("create sprint", {
        "project id": projectId,
      });

      dispatch({
        type: ACTIONS.ADD_SPRINT,
        payload: { projectId, sprint },
      });
      if (createSprintCb) {
        createSprintCb();
      }
    });
}

export function startSprint(projectId, sprints, sprint, dispatch) {
  if (isSandbox()) {
    startSandboxSprint(projectId, sprints, sprint, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .update({
      sprints: sprints.map((sp) =>
        sp.id === sprint.id
          ? {
              ...sprint,
              name: sprint.name,
              goal: sprint.goal,
              startDate: sprint.startDate,
              endDate: sprint.endDate,
              sprintState: SPRINT_STATE.ACTIVE,
            }
          : sp
      ),
    })
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Start sprint", projectId);
      amplitude.getInstance().logEvent("start sprint", {
        "project id": projectId,
        "sprint id": sprint.id,
      });

      dispatch({
        type: ACTIONS.EDIT_SPRINT,
        payload: { projectId, sprint },
      });
    });
}

export function removeSprint(projectId, sprints, id, dispatch) {
  if (isSandbox()) {
    removeSandboxSprint(projectId, sprints, id, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .set({ sprints: sprints.filter((sp) => sp.id !== id) }, { merge: true })
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Delete sprint", projectId);

      amplitude.getInstance().logEvent("delete sprint", {
        "project id": projectId,
        "sprint id": id,
      });

      dispatch({
        type: ACTIONS.DELETE_SPRINT,
        payload: { projectId, id },
      });
    });
}

export function editSprint(projectId, sprints, sprint, dispatch) {
  if (isSandbox()) {
    editSandboxSprints(projectId, sprints, sprint, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .set(
      {
        sprints: sprints.map((sp) =>
          sp.id === sprint.id
            ? {
                ...sprint,
                name: sprint.name,
                startDate: sprint.startDate,
                endDate: sprint.endDate,
              }
            : sp
        ),
      },
      { merge: true }
    )
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Edit sprint", projectId);
      amplitude.getInstance().logEvent("edit sprint", {
        "project id": projectId,
        "sprint id": sprint.id,
      });

      dispatch({
        type: ACTIONS.EDIT_SPRINT,
        payload: { projectId, sprint },
      });
    });
}

export function closeSprint(projectId, sprints, sprint, dispatch) {
  if (isSandbox()) {
    closeSandboxSprint(projectId, sprints, sprint, dispatch);
    return;
  }
  db.collection("projects")
    .doc(projectId)
    .update({
      sprints: sprints.map((sp) =>
        sp.id === sprint.id
          ? {
              ...sprint,
              sprintState: SPRINT_STATE.CLOSED,
            }
          : sp
      ),
    })
    .then(() => {
      addAnalyticsEvent(CATEGORIES.PROJECT, "Close sprint", projectId);

      amplitude.getInstance().logEvent("close sprint", {
        "project id": projectId,
        "sprint id": sprint.id,
      });

      dispatch({
        type: ACTIONS.CLOSE_SPRINT,
        payload: { projectId, sprint },
      });
    });
}
export function bulkItemChange(items, projectItems, dispatch, successCb) {
  // Batch write the changes to items content
  if (isSandbox()) {
    sandboxBulkItemChange(items, projectItems, dispatch, successCb);
    return;
  }

  if (items.length) {
    const batch = db.batch();

    items.forEach((item) => {
      try {
        const docRef = db.collection("items").doc(item.id);
        const data = { ...item };

        if (data.id) {
          delete data.id;
        }

        batch.update(docRef, data);
      } catch (e) {
        // TODO - FIXME!
      }
    });

    batch
      .commit()
      .then(() => {
        dispatch({
          type: ACTIONS.SET_PROJECT_ITEMS,
          payload: {
            projectItems: projectItems.map((pItem) => {
              const updatedItem = items.find((it) => it.id === pItem.id);

              if (updatedItem) {
                return {
                  ...pItem,
                  ...updatedItem,
                };
              }

              return { ...pItem };
            }),
          },
        });

        successCb();
      })
      .catch(() => {
        // TODO - FIXME!
      });
  }
}

export function createBug({
  data,
  dispatch,
  projectItems,
  statuses,
  statusId,
  featureItemId,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create bug item data!");
  }
  if (isSandbox()) {
    createSandboxBugItem({
      data,
      dispatch,
      projectItems,
      featureItemId,
      statuses,
      statusId,
    });
    return;
  }

  const orderPosition = getInitialOrderPosition(
    projectItems,
    statuses,
    statusId
  );
  db.collection("items")
    .add({
      ...data,
      orderPosition,
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
      featureItemId,
    })
    .then((item) => {
      addAnalyticsEvent(CATEGORIES.TASK, "Create bug", featureItemId);
      amplitude.getInstance().logEvent("create bug", {
        "project id": data.projectId,
        "feature item id": featureItemId,
        "bug id": item.id,
      });

      db.collection("items")
        .doc(item.id)
        .collection("discussions")
        .add({ name: "General", msgCount: 0 });

      dispatch({
        type: ACTIONS.SET_PROJECT_ITEMS,
        payload: {
          projectItems: [
            ...projectItems,
            {
              featureItemId,
              id: item.id,
              orderPosition,
              ...data,
            },
          ],
        },
      });

      toast.success("Bug created");
    });
}
export function createSubtask({
  data,
  dispatch,
  projectItems,
  featureItemId,
  featureItem,
  statuses,
  statusId,
  createSubtaskSuccessCB,
}) {
  if (!data || !data.projectId || !dispatch || !projectItems) {
    throw new Error("Missing create subtask item data!");
  }
  if (isSandbox()) {
    createSandboxSubtaskItem({
      data,
      dispatch,
      projectItems,
      featureItemId,
      featureItem,
      statuses,
      statusId,
      createSubtaskSuccessCB,
    });
    return;
  }

  db.collection("items")
    .add({
      ...data,
      orderPosition: getInitialOrderPosition(projectItems, statuses, statusId),
      createdTs: firebaseLib.firestore.FieldValue.serverTimestamp(),
      featureItemId,
    })
    .then((item) => {
      addAnalyticsEvent(CATEGORIES.TASK, "Create subtask", featureItemId);
      amplitude.getInstance().logEvent("create subtask", {
        "project id": data.projectId,
        "feature item id": featureItemId,
        "subtask id": item.id,
      });

      db.collection("items")
        .doc(item.id)
        .collection("discussions")
        .add({ name: "General", msgCount: 0 });

      if (createSubtaskSuccessCB) {
        createSubtaskSuccessCB();
      }

      toast.success("Subtask created");
    });
}

export function getItemHistory(itemId, itemDataCb) {
  if (isSandbox()) {
    getSandboxItemHistory(itemId, itemDataCb);
    return;
  }

  db.collection("items")
    .doc(itemId)
    .collection("itemHistory")
    .get()
    .then((querySnapshot) => {
      if (querySnapshot) {
        itemDataCb(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    });
}

export function updateUserPermissions(
  projectId,
  members,
  memberId,
  permissions,
  currentUserId,
  ability,
  toastContent
) {
  db.collection("projects")
    .doc(projectId)
    .update({
      [`members.${memberId}`]: { ...members[memberId], permissions },
    })
    .then(() => {
      currentUserId === memberId && ability.update(permissions);

      amplitude.getInstance().logEvent("update permissions", {
        "project id": projectId,
        "user id": memberId,
      });

      toast.success(
        `Changed member's permission on ${toastContent.subject} to "${toastContent.action}"`
      );
    })
    .catch(() => toast.error("Something went wrong"));
}

export function listenToProjectItems(dispatch, activeProjectId) {
  const unsubscribe = db
    .collection("items")
    .where("projectId", "==", activeProjectId)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        dispatch({
          type: ACTIONS.ITEM_SYNC_UPDATE,
          payload: change,
        });
      });
    });
  return unsubscribe;
}

export function reorderBoardItems(reorderedItem, orderType, range) {
  const rank = getKeyBetween(range[0], range[1]);

  db.collection("items")
    .doc(reorderedItem.id)
    .update({
      [`orderPosition.${orderType}`]: rank,
    });
}

export function createLabel(projectId, existingLabels, newLabel, dispatch) {
  db.collection("projects")
    .doc(projectId)
    .update({ labels: [...existingLabels, newLabel] })
    .then(() => {
      dispatch({
        type: ACTIONS.ADD_LABEL,
        payload: { projectId: projectId, label: newLabel },
      });
    });
}
