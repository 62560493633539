/**
 * One place to define all standalone modals and their logic. In theory might be
 * easier than having them all over the codebase.
 */

import React from "react";

// import DocumentVersionModal from "components/Modal/DocumentVersionModal";
import AddVersionModal from "components/Modal/AddVersionModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ContentImageModal from "components/Modal/ContentImageModal";
import CreateAdhocItemModal from "components/Modal/CreateAdhocItemModal";
import CreateBugModal from "components/Modal/CreateBugModal";
import CreateNewProjectModal from "components/Modal/CreateNewProject";
import CreateSectionFromWBS from "components/Modal/CreateSectionFromWBS";
import CreateSubtaskModal from "components/Modal/CreateSubtaskModal";
import ImageModal from "components/Modal/ImageModal";
import ProjectTemplateModal from "components/Modal/ProjectTemplateModal";
import WelcomeModal from "components/Modal/WelcomeModal";
import { useTrackedState } from "context";
import SearchProjectModal from "./SearchProjectModal";

const ModalsContainer = () => {
  const state = useTrackedState();
  const { selectedItemProjectId } = state;
  return (
    <div>
      <CreateNewProjectModal />
      <ConfirmationModal />
      <ContentImageModal />
      {selectedItemProjectId && (
        <>
          <CreateAdhocItemModal />
          <CreateBugModal />
          <CreateSubtaskModal />
          <AddVersionModal />
        </>
      )}
      <ContentImageModal />
      <SearchProjectModal />
      <WelcomeModal />
      <ProjectTemplateModal />
      <ImageModal />
      <CreateSectionFromWBS />
    </div>
  );
};

export default ModalsContainer;
