/**
 * Create plugins here and pass them to the editor and whereever else they
 * are needed.
 */

import React from "react";
// import Prism from "prismjs";

import { composeDecorators } from "@draft-js-plugins/editor";
import createFocusPlugin from "@draft-js-plugins/focus";
import createImagePlugin from "@draft-js-plugins/image";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createResizeablePlugin from "@draft-js-plugins/resizeable";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin";
import createLinkDetectionPlugin from "draft-js-link-detection-plugin";
import createMarkdownShortcutsPlugin from "draft-js-markdown-shortcuts-plugin";

import CustomImageComponent from "../components/CustomImageComponent";
import SlashToolbar, { createSlashToolbar } from "../components/SlashToolbar";
// import createCodeEditorPlugin from "draft-js-code-editor-plugin";
// import createPrismPlugin from "draft-js-prism-plugin";
import createDndFileUploadPlugin from "../plugins/dnd-upload";
import createEmbedPlugin from "../plugins/embed";
import insertTablePlugin from "../plugins/table";
import Toolbar from "../Toolbar";
import createInlineToolbarPlugin from "./inline-toolbar/src";
import buttonStyles from "./static-toolbar/buttonStyles.module.css";
import StaticToolbar from "./static-toolbar/StaticToolbar";
import toolbarStyles from "./static-toolbar/toolbarStyles.module.css";
// import Toolbar from "./inline-toolbar/src/components/Toolbar";
import "@draft-js-plugins/image/lib/plugin.css";
import "@draft-js-plugins/focus/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";

// import "prismjs/themes/prism.css";

function createPlugins({ viewPluginsOnly = false } = {}) {
  const linkifyPlugin = createLinkifyPlugin({
    target: "_blank",
    rel: null,
    component: (props) => {
      // Extract only relevant props to pass on
      const _props = (({ target, className, children }) => ({
        target,
        className,
        children,
      }))(props);
      return (
        // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
        <a {..._props} onClick={() => window.open(props.href)} />
      );
    },
  });
  const linkDetectionPlugin = createLinkDetectionPlugin();
  const blockBreakoutPlugin = createBlockBreakoutPlugin();
  const slashToolbarPlugin = createSlashToolbar();
  const inlineToolbarPlugin = createInlineToolbarPlugin();
  const staticToolbarPlugin = createToolbarPlugin({
    theme: { buttonStyles, toolbarStyles },
  });
  const embedPlugin = createEmbedPlugin();
  const tablePlugin = insertTablePlugin();
  const resizeablePlugin = createResizeablePlugin();
  const focusPlugin = createFocusPlugin();
  const markdownShortcutsPlugin = createMarkdownShortcutsPlugin();
  // const codeEditorPlugin = createCodeEditorPlugin();
  // const prismPlugin = createPrismPlugin({
  //   // It's required to provide your own instance of Prism
  //   prism: Prism,
  // });

  const decorator = composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator
  );

  const imagePlugin = createImagePlugin({
    decorator,
    imageComponent: CustomImageComponent,
  });
  const dragNDropFileUploadPlugin = createDndFileUploadPlugin({
    // Upload the files dropped to the editor and then add them to the Editor state
    handleUpload: true,
    addImage: imagePlugin.addImage,
  });

  function SlashToolbarComponent(props) {
    return (
      <SlashToolbar
        slashToolbarPlugin={slashToolbarPlugin}
        enableTable={props.enableTable}
      />
    );
  }

  function StaticToolbarComponent() {
    const buttons = [embedPlugin.EmbedButton];
    return (
      <StaticToolbar
        buttons={buttons}
        staticToolbarPlugin={staticToolbarPlugin}
      />
    );
  }

  const editingPlugins = [
    blockBreakoutPlugin,
    slashToolbarPlugin,
    markdownShortcutsPlugin,
    staticToolbarPlugin,
    // codeEditorPlugin,
    // prismPlugin,
  ];

  let plugins = [
    dragNDropFileUploadPlugin,
    linkifyPlugin,
    embedPlugin,
    tablePlugin,
    focusPlugin,
    resizeablePlugin,
    imagePlugin,
    linkDetectionPlugin,
  ];

  if (!viewPluginsOnly) {
    plugins = [...plugins, ...editingPlugins];
  }

  const instances = {
    SlashToolbarComponent,
    StaticToolbarComponent,
  };

  return {
    editorPlugins: plugins,
    instances,
  };
}

export default createPlugins;
