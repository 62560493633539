import React from "react";

import { ReactComponent as DesktopOnly } from "assets/desktop_only.svg";
import logo from "assets/logo.svg";
import useIsDesktop from "hooks/useIsDesktop";
import get from "lodash.get";
import { useAuthState } from "react-firebase-hooks/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect, useLocation } from "react-router-dom";

import { auth, uiConfig } from "../../firebaseSetup";
import Card from "./Card";

const Login = () => {
  const [user, initialising] = useAuthState(auth);

  const location = useLocation();
  const isDesktop = useIsDesktop();

  if (!initialising && user) {
    return (
      <Redirect
        to={{
          pathname: get(location, "state.referer.pathname", "/dashboard"),
        }}
      />
    );
  }

  if (user || initialising) {
    return null;
  }

  function LogInSection() {
    return (
      <div className="flex-1 bg-gray1">
        <div className="max-w-sm mx-auto mt-16">
          <h1 className="mb-8 text-4xl leading-10">
            Join and get your team on the same page
          </h1>
          <StyledFirebaseAuth
            className="w-full"
            uiConfig={uiConfig}
            firebaseAuth={auth}
          />

          <p className="text-sm text-grayBlue">
            By signing in, you agree to our{" "}
            <a
              className="text-linkBlue"
              rel="noopener noreferrer"
              target="_blank"
              href="https://jadealm.com/terms-and-conditions/"
            >
              Terms of Service
            </a>
            .
          </p>
        </div>
      </div>
    );
  }

  function NotDesktopSection() {
    return (
      <div className="flex-1 bg-gray1">
        <div className="max-w-md md:max-w-lg mx-auto mt-16">
          <DesktopOnly className="w-full" />

          <div className="pr-10 pl-10 flex flex-col">
            <h1 className="mb-8 text-center text-2xl md:text-3xl leading-8">
              JadeALM is optimized to work on Desktop environments only
            </h1>

            <p className="text-justify">
              We know this might be an inconvenience for some but we want to
              provide an exceptional experience to our users and the vast
              majority use JadeALM on Desktop environments only.
            </p>

            <p className="text-justify pt-5">
              Mobile and Tablet views are not optimal for a tool like JadeALM at
              the moment so we decided to temporarily disable them to prevent
              sub-par experience.
            </p>

            {/* <button> */}
            <a
              href="https://jadealm.com"
              className="bg-jadeGreen mt-5 pb-2 pt-2 rounded text-center text-white w-40 m-auto font-bold"
            >
              Go to Homepage
            </a>
            {/* </button> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      {/* Left Side */}
      <div className="flex-1 hidden lg:block  bg-darkBlue">
        <div className="max-w-md mx-auto mt-16">
          <img className="h-12 mb-12" src={logo} alt="JadeALM" />

          <div className="flex flex-col">
            <Card title="Requirements Managment" className="mb-8">
              Easily document your evolving product features and organise the
              complete project execution based on that foundation.
            </Card>
            <Card title="Timeline" className="mb-8">
              Timeline (Gantt) is an essencial tool to visually represent your
              project delivery dates, who is working on what and most
              importantly - When!
            </Card>
            <Card title="Project Board" className="mb-8">
              High level overview of your work across phases of your project
              development process.
            </Card>
            <Card title="Work Breakdown Structure" className="mb-8">
              A birds eye view of your complete project, generated automatically
              based on the content from your Document.
            </Card>
          </div>
        </div>
      </div>

      {/*  Right Side */}
      {isDesktop ? <LogInSection /> : <NotDesktopSection />}
    </div>
  );
};

export default Login;
