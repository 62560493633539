/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Can } from "components/RoleManagement/Can";
import { useDispatch, useTrackedState, ACTIONS } from "context";

import { ReactComponent as AddItemIcon } from "../../assets/add_box-24px.svg";
import settingsIcon from "../../assets/cog-solid.svg";
import editorIcon from "../../assets/editor-icon.svg";
import ganntIcon from "../../assets/gannt-icon.svg";
import kanbanIcon from "../../assets/kanban-icon.svg";
import wbsIcon from "../../assets/wbs-icon.svg";
import { PERMISSIONS_ACTIONS, PERMISSIONS_SUBJECTS } from "../../constants";
import { MODAL_NAMES } from "../Modal/Modal";
import NavItem from "./NavItem";

const SidebarNav = ({ className }) => {
  const state = useTrackedState();

  const { activeProjectId } = state;
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <ul className="">
        <NavItem
          color="orangePeel"
          icon={editorIcon}
          routeName="editor"
          itemName="Editor"
        />
        <NavItem
          color="orangeInt"
          icon={ganntIcon}
          routeName="gantt"
          itemName="Timeline"
          id="tourTimeline"
        />
        <NavItem
          color="chillBlue"
          icon={wbsIcon}
          routeName="wbs"
          itemName="WBS"
          id="tourWBS"
        />
        <NavItem
          color="purple-600"
          icon={kanbanIcon}
          routeName="kanban"
          itemName="Board"
          id="tourBoard"
        />

        <li className="m-2 ">
          <a
            href=""
            style={{ borderRadius: 16 }}
            className="flex flex-col items-center p-4 border border-transparent hover:shadow-md hover:border-gray1"
            onClick={(event) => {
              event.preventDefault();

              dispatch({
                type: ACTIONS.SET_SELECTED_ITEM_PROJECT_ID,
                payload: {
                  selectedItemProjectId: activeProjectId,
                },
              });
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.ADHOC_ITEM_MODAL,
                },
              });
            }}
          >
            <div className="flex items-center justify-center w-8 h-8 text-white bg-green-500 rounded-md">
              <AddItemIcon className="w-3 h-3 text-white fill-current" />
            </div>
            <span className="mt-1 text-xs font-bold text-center text-grayBlue">
              Add
            </span>
          </a>
        </li>
        <Can
          I={PERMISSIONS_ACTIONS.MANAGE}
          a={PERMISSIONS_SUBJECTS.PROJECT_SETTINGS}
        >
          <NavItem
            color="pink-600"
            icon={settingsIcon}
            routeName="settings"
            itemName="Project Settings"
          />
        </Can>
      </ul>
      <div className="absolute bottom-0 ml-2 text-xs text-grayBlue">
        ver 0.1.1
      </div>
    </div>
  );
};

export default SidebarNav;
