/* eslint-disable react/no-multi-comp */
import React, { Fragment, Component } from "react";

import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from "@draft-js-plugins/buttons";

import BlockquoteButton from "../../components/Buttons/BlockquoteButton";
import BoldButton from "../../components/Buttons/BoldButton";
import HeadlineFiveButton from "../../components/Buttons/HeadlineFiveButton";
import HeadlineFourButton from "../../components/Buttons/HeadlineFourButton";
import HeadlineSixButton from "../../components/Buttons/HeadlineSixButton";
import ItalicButton from "../../components/Buttons/ItalicButton";
import OrderedListButton from "../../components/Buttons/OrderedListButton";
import UnderlineButton from "../../components/Buttons/UnderlineButton";
import UnorderedListButton from "../../components/Buttons/UnorderedListButton";
import "../../editorStyles.css";

const InternalToolbar = ({ buttons = [], staticToolbarPlugin }) => {
  const { Toolbar } = staticToolbarPlugin;
  return (
    <Toolbar>
      {
        // may be use React.Fragment instead of div to improve perfomance after React 16
        (externalProps) => (
          <Fragment>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            <BlockquoteButton {...externalProps} />
            {buttons.map((Button, index) => (
              <Button key={index.toString()} {...externalProps} />
            ))}
          </Fragment>
        )
      }
    </Toolbar>
  );
};

export default InternalToolbar;
