import React, { useState, useEffect } from "react";

import { ReactComponent as SearchIcon } from "assets/search-icon.svg";
import { ReactComponent as EmptySearch } from "assets/empty-search-icon.svg";
import { ReactComponent as ClearIcon } from "assets/close-icon-white.svg";
import { ReactComponent as ArrowLeft } from "assets/arrow_left.svg";

import get from "lodash.get";
import Modal, { MODAL_NAMES } from "./Modal";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import GlobalSearchTask from "components/GlobalSearch/GlobalSearchTask";
import GlobalSearchBucket from "components/GlobalSearch/GlobalSearchBucket";
// import { Parser } from "html-to-react";
const HtmlToReactParser = require("html-to-react").Parser;

function SearchProjectModal(props, { htmlContent }) {
  const htmlToReactParser = new HtmlToReactParser();

  const dispatch = useDispatch();
  const state = useTrackedState();
  const [metadata, setMetadata] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsBucketId, setDetailsBucketId] = useState(null);
  const { item } = props;
  const {
    projectItems,
    filters,
    selectedItemId,
    hierarchyState,
    loadedProjects,
    activeProjectId,
  } = state;
  const filteredTitle = get(filters, "title");

  const statuses =
    loadedProjects &&
    loadedProjects[activeProjectId] &&
    loadedProjects[activeProjectId].statuses;
  const closedItem =
    statuses && statuses.filter((status) => status.slug === "CLOSED");

  useEffect(() => {
    if (item) {
      const _metadata = projectItems.find((pItem) => pItem.id === item.id);

      if (_metadata) {
        setMetadata({ ..._metadata });

        return;
      }
    }
    if (state.activeModal === "" || !filteredTitle) {
      setShowDetails(false);
    }
  }, [selectedItemId, projectItems, item, state.activeModal, filteredTitle]);

  const setFilterTitle = (value) => {
    dispatch({
      type: ACTIONS.SET_FILTERS,
      payload: {
        ...filters,
        title: value,
      },
    });
  };

  let countBuckets = 0;
  const lengthBuckets = hierarchyState.length;
  const filteredBucketsCount = Object.values(hierarchyState).map(
    (pItem, index) => {
      if (
        pItem.text &&
        filteredTitle &&
        pItem.text.toLowerCase().includes(filteredTitle.toLowerCase())
      ) {
        if (pItem) {
          countBuckets++;
          if (index === lengthBuckets - 1) {
            return countBuckets;
          }
        }
      }
    }
  );
  let countTasks = 0;
  const lengthTasks = projectItems.length;
  const filteredTasksCount = projectItems.map((pItem, index) => {
    if (
      pItem.title &&
      filteredTitle &&
      pItem.title.toLowerCase().includes(filteredTitle.toLowerCase()) &&
      pItem.itemType &&
      pItem.status !== closedItem[0].id
    ) {
      if (pItem) {
        countTasks++;
        if (index === lengthTasks - 1) {
          return countTasks;
        }
      }
    }
  });

  const showBucketDetails = (id) => {
    setShowDetails(true);
    setDetailsBucketId(id);
  };
  const showResults = () => {
    setShowDetails(false);
  };
  const results = countBuckets + countTasks;
  const height =
    filters.title && filteredTitle
      ? results !== 0
        ? "605px"
        : "310px"
      : "50px";

  const details = Object.values(hierarchyState).find(
    (pItem) =>
      pItem.projectItemMetadata &&
      pItem.projectItemMetadata.id === detailsBucketId
  );
  return (
    <Modal
      name={MODAL_NAMES.SEARCH_PROJECT_MODAL}
      style={{
        height: height,
        width: "700px",
        marginTop: "60px",
        overflow: "hidden",
      }}
    >
      <div>
        <FilterTitle title={filters.title} setFilterTitle={setFilterTitle} />
        <div>
          {results !== 0 ? (
            <div>
              <div>
                {countBuckets !== 0 && filteredTitle ? (
                  !showDetails ? (
                    <div className="bg-gray-200 pl-4 pt-1.5 h-8 mt-2 text-sm text-black">
                      {"Buckets " + "(" + countBuckets + ")"}
                    </div>
                  ) : (
                    <div>
                      <div className="bg-gray-200 pl-4 pt-1.5 h-8 mt-2 text-sm text-black">
                        {"Buckets " + "(" + countBuckets + ")"}
                      </div>
                      <button
                        onClick={() => {
                          showResults();
                        }}
                        className="text-xs text-gray-300 mt-2 inline-flex ml-4"
                      >
                        <ArrowLeft className="inline-flex mr-2 mt-0.5" />
                        <a>{"Back to search results"}</a>
                      </button>
                    </div>
                  )
                ) : (
                  <div className="bg-gray-200 pl-4 pt-1.5 h-8 mt-2 text-sm text-black">
                    {"Buckets (0)"}
                  </div>
                )}
                <div
                  className={`${
                    !showDetails ? "h-56" : "h-112"
                  }  overflow-auto`}
                >
                  {showDetails ? (
                    <GlobalSearchBucket
                      pItem={details}
                      showDetails={showDetails}
                      filteredTitle={filteredTitle}
                    />
                  ) : (
                    filteredTitle &&
                    Object.values(hierarchyState).map((pItem) => {
                      if (
                        pItem.text &&
                        pItem.text
                          .toLowerCase()
                          .includes(filteredTitle.toLowerCase())
                      ) {
                        return (
                          <div className="mx-3">
                            <div className="border-b border-gray-300">
                              <GlobalSearchBucket
                                {...pItem}
                                filteredTitle={filteredTitle}
                                pItem={pItem}
                                showBucketDetails={showBucketDetails}
                                showResults={showResults}
                                showDetails={showDetails}
                              />
                            </div>
                          </div>
                        );
                      }
                    })
                  )}
                </div>
              </div>
              <div>
                {!showDetails && (
                  <div>
                    {countTasks !== 0 && filteredTitle ? (
                      <div className="bg-gray-200 pl-4 pt-1.5 mb-2 h-8 text-sm text-black">
                        {"Tasks " + "(" + countTasks + ")"}
                      </div>
                    ) : (
                      <div className="bg-gray-200 pl-4 pt-1.5 mb-2 h-8 text-sm text-black">
                        {"Tasks (0)"}
                      </div>
                    )}
                    <div className="overflow-auto h-56">
                      {filteredTitle &&
                        projectItems
                          .filter((pI) => pI.status !== closedItem[0].id)
                          .map((pItem) => {
                            if (
                              pItem.title &&
                              pItem.title
                                .toLowerCase()
                                .includes(filteredTitle.toLowerCase()) &&
                              pItem.itemType
                            ) {
                              return (
                                <div className="my-2 mx-3">
                                  <div className="border-b border-gray-300 pb-4">
                                    <GlobalSearchTask
                                      {...pItem}
                                      filteredTitle={filteredTitle}
                                      pItem={pItem}
                                      showDetails={showDetails}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-8 flex flex-col items-center justify-center">
              <EmptySearch className="mb-2" />
              <p>No results</p>
              <p className="text-gray-400">Try different search terms</p>
            </div>
          )}
        </div>
        <div>
          {results !== 0 && (
            <div className="absolute h-7 w-full border-t bottom-0 px-3 pt-1.5 text-xs text-gray-400">
              {results + (results === 1 ? " result" : " results")}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

function FilterTitle({ setFilterTitle, title }) {
  const state = useTrackedState();
  if (state.activeModal === "") {
    setFilterTitle("");
  }
  return (
    <div className="border-b border-gray-300">
      <div className="mr-2 items-center flex bg-white rounded-md transition duration-300 focus-within:border-darkBlue">
        <div className="flex w-15 h-12 ">
          <span className="flex items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-2xl text-gray-600">
            <SearchIcon />
          </span>
        </div>
        <input
          value={title}
          autoFocus
          type="text"
          className="h-10 bg-white outline-none w-full "
          placeholder="Search project..."
          onChange={(event) => setFilterTitle(event.target.value)}
        />
        {title && (
          <ClearIcon
            className="text-white fill-current p-0.5 cursor-pointer"
            onClick={() => {
              setFilterTitle("");
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SearchProjectModal;
