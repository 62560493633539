import React from "react";

import { ReactComponent as AddIcon } from "assets/plus-icon.svg";
import { ACTIONS } from "context";
import { EditorBlock } from "draft-js";
import { _insertSection } from "utils/editor/draft-utils";
import { canUserEditDocument } from "utils/permissions";

function CustomHeaderComponent(props) {
  const bucketLevel =
    props.blockProps.hierarchyState[props.block.getKey()]?.level;

  return (
    <div className="relative flex flex-col items-start group">
      <div className="flex items-center justify-between flex-grow w-full">
        {/* This is the basic editor block, use the same thing since we don't 
        want the default behaviour there */}

        <span className="flex items-center flex-grow w-full">
          <span
            className={`relative break-words`}
            contentEditable={canUserEditDocument()}
            readOnly={!canUserEditDocument()}
            suppressContentEditableWarning={true}
          >
            <span
              contentEditable={false}
              className="text-sm font-black opacity-25 pointer-events-none select-none"
              style={{
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {props.blockProps?.hierarchyState[props.block.key]?.headerLevel}
            </span>
            <EditorBlock {...props} />
          </span>
        </span>
        {bucketLevel !== 6 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              const { newEditorState } = _insertSection(
                props.blockProps.editorState,
                props.blockProps.hierarchyState,
                props.block.getKey(),
                "subsection"
              );

              props.blockProps.dispatch({
                type: ACTIONS.RECREATE_HIERARCHY_STATE,
                payload: {
                  newEditorState,
                },
              });

              props.blockProps.dispatch({
                type: ACTIONS.EDITOR_CONTENTS_CHANGED_UPDATE_KEY,
                payload: {
                  newEditorState,
                },
              });
            }}
            className="ml-2 btn btn-utility items-center gap-1 whitespace-no-wrap text-chillBlue inline-flex w-36"
            contentEditable={false}
          >
            <AddIcon className="fill-current text-chillBlue" /> Add subsection
          </button>
        )}
      </div>
    </div>
  );
}

export default CustomHeaderComponent;
