/**
 *
 * @param {array} statuses
 * @param {string} id
 */
export function getStatusById(statuses, statusId) {
  try {
    return statuses.find((s) => s.id === statusId);
  } catch (error) {
    return;
  }
}

/**
 * Find the statusId from loaded statuses array
 *
 * @param {array} statuses
 * @param {string} statusSlug
 */
export function getStatusId(statuses, statusSlug) {
  try {
    const status = getStatusBySlug(statuses, statusSlug);

    return status["id"];
  } catch (error) {
    return;
  }
}

export function getStatusBySlug(statuses, statusSlug) {
  try {
    return statuses.find((st) => st.slug === statusSlug);
  } catch (error) {
    return;
  }
}

/**
 * Get one value from status object based on dataKey, like 'color', 'label' etc
 *
 * @param {array} statuses
 * @param {string} statusSlug
 * @param {string} dataKey
 */
export function getStatusData(statuses, statusId, dataKey) {
  if (!statuses || !statusId || !dataKey) return;
  try {
    const status = getStatusById(statuses, statusId);

    return status[dataKey];
  } catch (error) {
    return;
  }
}
