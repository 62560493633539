import React, { useState } from "react";

import Header from "components/Header";
import { auth } from "firebaseSetup";
import { useAuthState } from "react-firebase-hooks/auth";

const EmailVerification = ({ children }) => {
  const [user, initialising] = useAuthState(auth);

  const [emailSent, setEmailSent] = useState(false);

  // User initialization in progress
  if (initialising) {
    return null;
  }

  // User initialization is done
  // if user does not exist, child (PrivateRoute) will redirect to Login
  // if user exists and his email is verified, he can proceed
  if (!user || user.emailVerified) {
    return children;
  }

  const handleSendEmailClick = () => {
    user.sendEmailVerification();
    setEmailSent(true);
  };

  return (
    <>
      <Header isDashboard={true} />
      <section className="w-full mt-40 column-center">
        <h1 className="w-1/2 text-center">
          {" "}
          Your email address is not verified. <br /> Please click the link in
          the verification email to activate your account{" "}
        </h1>
        <button
          onClick={handleSendEmailClick}
          disabled={emailSent}
          className="mt-4 btn btn-primary"
        >
          {!emailSent
            ? "Re-send verification E-mail"
            : "E-mail sent. Please check your inbox"}
        </button>
      </section>
    </>
  );
};

export default EmailVerification;
