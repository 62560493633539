import React from "react";

import { useDispatch, useTrackedState, ACTIONS } from "context";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import Modal, { MODAL_NAMES } from "./Modal";

const ContentImageModal = () => {
  const state = useTrackedState();
  const dispatch = useDispatch();
  return (
    <Modal
      shouldReturnFocusAfterClose={false}
      name={MODAL_NAMES.CONTENT_IMAGE_MODAL}
    >
      <div className="w-full h-full overflow-scroll flex">
        <img className="m-auto" src={state.activeModalData?.src} alt="" />
        <CloseIcon
          onClick={() => {
            dispatch({
              type: ACTIONS.CLOSE_MODAL,
            });
          }}
          className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer"
        />
      </div>
    </Modal>
  );
};

export default ContentImageModal;
