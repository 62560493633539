/* eslint-disable jsx-a11y/iframe-has-title */
/**
 * Custom Embed plugin based on https://github.com/jimmycodesocial/draft-js-iframely-plugin/blob/master/src/createIframelyPlugin.js
 *
 */
import React from "react";

import {
  isBlockWithEntityType,
  addBlock,
  addAtomicBlock,
  removeBlock,
} from "@jimmycode/draft-js-toolbox";
import decorateComponentWithProps from "decorate-component-with-props";
import isUrl from "is-url";

import { ReactComponent as EmbedIcon } from "../../../../assets/embed-24px.svg";
import { defaultDraftBlockTypes, BLOCKTYPES } from "../../draftConstants";

const defaultOptions = {};
const EMBED_TYPE = "embed-type";
const EMBEDDER_TYPE = defaultDraftBlockTypes[BLOCKTYPES.EMBEDDER];

function EmbedButtonComponent(props) {
  const { theme } = props;

  function onClick(event) {
    event.preventDefault();
    props.setEditorState(props.addEmbedder(props.getEditorState()));
  }

  function onMouseDown(event) {
    event.preventDefault();
  }

  return (
    <div className={theme.buttonWrapper} onMouseDown={onMouseDown}>
      <button className={theme.button} onClick={onClick} type="button">
        <EmbedIcon />
      </button>
    </div>
  );
}

function EmbedComponent({ block, contentState }) {
  const { src } = contentState.getEntity(block.getEntityAt(0)).getData();

  return (
    <div className="w-full h-64">
      <iframe src={src} className="w-full h-64" allowFullScreen />
    </div>
  );
}

function EmbedderComponent(props) {
  const [text, setText] = React.useState("");
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current.focus();
  }, []);

  function onBlur() {
    props.blockProps.onCancel(props.block);
    props.blockProps.setReadOnly(false);
  }

  function onFocus() {
    props.blockProps.setReadOnly(true);
  }

  function onChange(event) {
    setText(event.target.value);
  }

  function onKeyDown(event) {
    // Cancel on Escape or Del.
    if (event.keyCode === 27 || (event.keyCode === 46 && text === 0)) {
      onBlur();
    }
  }

  function onKeyPress(event) {
    if (event.key === "Enter") {
      const urlText = text.trim();

      if (!isUrl(urlText)) {
        return;
      }

      props.blockProps.onConfirm(props.block, urlText);
      props.blockProps.setReadOnly(false);
    }
  }

  return (
    <div onBlur={onBlur} onFocus={onFocus}>
      <input
        ref={inputRef}
        value={text}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onChange={onChange}
        type="text"
        placeholder="Add the link you want to Embed"
        className="w-full h-16"
      />
    </div>
  );
}

export default function ({
  embedComponent = EmbedComponent,
  embedType = EMBED_TYPE,
  embedderType = EMBEDDER_TYPE,
  options = {},
} = {}) {
  /**
   * Modifiers
   */

  /** Add the Embedder element that will show the input for the Embed URL */
  function addEmbedder(editorState, data = {}) {
    return addBlock(editorState, embedderType, data);
  }

  /** Add the Embed component that will show the embedded content */
  function addEmbed(editorState, data) {
    return addAtomicBlock(editorState, embedType, data);
  }

  // Plugin.
  const pluginOptions = Object.assign({}, defaultOptions, options);

  return {
    blockRendererFn: function (
      block,
      { getEditorState, setEditorState, setReadOnly }
    ) {
      if (isBlockWithEntityType(getEditorState(), block, embedType)) {
        return {
          component: EmbedComponent,
          editable: false,
        };
      }

      // Embedding?
      else if (block.getType() === embedderType) {
        return {
          component: EmbedderComponent,
          editable: false,
          props: {
            placeholder: pluginOptions.placeholder,
            setReadOnly,

            onCancel: (block) => {
              setEditorState(removeBlock(getEditorState(), block.key));
            },

            onConfirm: (block, text) => {
              let editorState = removeBlock(getEditorState(), block.key);

              if (text && text !== "") {
                editorState = addEmbed(editorState, { src: text });
              }

              setEditorState(editorState);
            },
          },
        };
      }

      return null;
    },

    handlePastedText: function (text, html, editorState, { setEditorState }) {
      /**
       * In the original plugin this makes sense since it's integrating with
       * iframely, a service that creates HTML embed based on URL metadata.
       *
       * In the future we might consider running our own iframely server since it's
       * open source, but for now pass.
       */
      return "not-handled";
    },

    handleReturn: function () {
      /**
       * In the original plugin this makes sense since it's integrating with
       * iframely, a service that creates HTML embed based on URL metadata.
       *
       * In the future we might consider running our own iframely server since it's
       * open source, but for now pass.
       */
      return "not-handled";
    },

    EmbedButton: decorateComponentWithProps(EmbedButtonComponent, {
      embedderType,
      addEmbedder,
    }),

    addEmbedder,
    addEmbed,
  };
}
