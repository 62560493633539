import { RichUtils, EditorState, Modifier } from "draft-js";
import getSearchText from "../utils/getSearchText";

function changeBlockType(editorState, mention, mentionTrigger) {
  const currentSelectionState = editorState.getSelection();

  const { begin, end } = getSearchText(
    editorState,
    currentSelectionState,
    mentionTrigger
  );

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end,
  });

  /**
   * Action that changes the currently active block, the block where the
   * cursor is, to a type of our choosing.
   */
  const changedBlockEditorState = RichUtils.toggleBlockType(
    editorState,
    mention.type
  );

  /**
   * Strip the trigger key from the text of the block
   */
  let mentionReplacedContent = Modifier.replaceText(
    changedBlockEditorState.getCurrentContent(),
    mentionTextSelection,
    "",
    null, // no inline style needed
    null // no entity needed
  );

  /**
   * Apply the new Content and create a new EditorState that will
   * be applied to the active Editor instance.
   */
  const newEditorState = EditorState.push(
    changedBlockEditorState,
    mentionReplacedContent,
    "change-block-type"
  );

  return EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter()
  );
}

export default changeBlockType;
