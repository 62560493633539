// Define data structure factories.
// TODO - Make this use immutable

export const ITEM_TYPES = {
  ADHOC: "ADHOC",
  BUG: "BUG",
  SUBTASK: "SUBTASK",
};

export function metadataFactory(data) {
  return {
    status: null,
    assignee: null,
    conditions: null,
    dueDate: null,
    startDate: null,
    sprint: null,
    workEstimate: null,
    doneEstimate: null,
    priority: null,
    title: null,
    htmlContent: null, // HTML String extracted from Draft Editor content
    blockId: null, // [String] Draft editor content blockId
    itemType: null, // Enum ITEM_TYPES
    labels: null,
    ...data,
  };
}

// FIXME - blockId is missing, it's added on item create, see how to change that

export function setMetadataConditions(metadata, conditions) {
  // Why do it this way? Cause it's immutable and safe
  return {
    ...metadata,
    conditions,
  };
}

export function setMetadataStatus(metadata, status) {
  return {
    ...metadata,
    status,
  };
}

export function setMetadataAssignee(metadata, assignee) {
  return {
    ...metadata,
    assignee,
  };
}

export function setMetadataDueDate(metadata, dueDate) {
  return {
    ...metadata,
    dueDate,
  };
}

export function setMetadataStartDate(metadata, startDate) {
  return {
    ...metadata,
    startDate,
  };
}

export function setMetadataSprint(metadata, sprint) {
  return {
    ...metadata,
    sprint,
  };
}
export function setMetadataWorkEstimate(metadata, workEstimate) {
  return {
    ...metadata,
    workEstimate,
  };
}
export function setMetadataDoneEstimate(metadata, doneEstimate) {
  return {
    ...metadata,
    doneEstimate,
  };
}

export function setMetadataTitleAndContent(
  metadata,
  title = null,
  htmlContent = null
) {
  return {
    ...metadata,
    title,
    htmlContent,
  };
}

export function setMetadataPriority(metadata, priority) {
  return {
    ...metadata,
    priority,
  };
}

export function setItemType(metadata, itemType) {
  return {
    ...metadata,
    itemType,
  };
}

/**
 * @param {array} labels an array of IDs where each ID is unique to a certain label
 */
export function setLabels(metadata, labels) {
  return {
    ...metadata,
    labels,
  };
}
