import React from "react";

import { createBlockStyleButton } from "@draft-js-plugins/buttons";

export default createBlockStyleButton({
  blockType: "unordered-list-item",
  children: (
    <svg
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3ZM3 9.5C3 10.3284 2.32843 11 1.5 11C0.671573 11 0 10.3284 0 9.5C0 8.67157 0.671573 8 1.5 8C2.32843 8 3 8.67157 3 9.5ZM3 17.5C3 18.3284 2.32843 19 1.5 19C0.671573 19 0 18.3284 0 17.5C0 16.6716 0.671573 16 1.5 16C2.32843 16 3 16.6716 3 17.5Z"
      />
      <rect opacity="0.4" x="6" y="16" width="21" height="3" rx="1.5" />
      <rect opacity="0.4" x="6" y="8" width="21" height="3" rx="1.5" />
      <rect opacity="0.4" x="6" width="21" height="3" rx="1.5" />
    </svg>
  ),
});
