import React, { useState, useEffect } from "react";

import { getDiscussions } from "api.js";
import { useDispatch, useTrackedState, ACTIONS } from "context";

import CreateDiscussion from "./CreateDiscussion";
import DiscussionContainer from "./DiscussionContainer";

const Discussions = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { selectedItemId, itemDiscussions } = state;

  const [discussions, setDiscussions] = useState([]);

  const [discussionName, setDiscussionName] = useState("");
  const [discussionId, setDiscussionId] = useState("-");

  useEffect(() => {
    if (!selectedItemId) {
      return;
    }

    getDiscussions(selectedItemId, dispatch);
  }, [dispatch, selectedItemId]);

  useEffect(() => {
    setDiscussions(itemDiscussions);
  }, [itemDiscussions]);

  useEffect(() => {
    if (discussions.length) {
      setDiscussionId(discussions[discussions.length - 1].id);
      setDiscussionName(discussions[discussions.length - 1].name);
    }
    return () => {
      setDiscussionName("");
    };
  }, [discussions.length]);

  return (
    <div className="my-10">
      <div className="flex items-center justify-between mb-8 px-8">
        <h4 className="mb-1 sidebar-section-title">Discussions</h4>
      </div>

      <ul>
        {discussions.map((disc, index) => {
          return (
            <li
              onClick={() => {
                setDiscussionId(disc.id);
                setDiscussionName(disc.name);
              }}
              key={index}
              className="mt-2 px-8"
            >
              <div
                className={`${
                  disc.id === discussionId && "shadow-itemCard border-blue-500"
                } flex shadow-sm items-center justify-between w-full p-2 border rounded-lg cursor-pointer text-grayBlue border-gray-200 hover:shadow-itemCard hover:border-blue-400 btn-transition`}
              >
                <span className="font-body cursor-pointer">{disc.name}</span>
                <span>{`${disc.msgCount} comments`}</span>
              </div>
            </li>
          );
        })}
      </ul>

      <CreateDiscussion
        selectedItemId={selectedItemId}
        itemDiscussions={itemDiscussions}
      />
      <DiscussionContainer id={discussionId} name={discussionName} />
    </div>
  );
};

export default Discussions;
