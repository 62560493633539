import { useReducer } from "react";

import * as Sentry from "@sentry/react";
import {
  fallbackOnAppyPatchException,
  amIMaster,
} from "components/DraftEditor/sync";
import { broadcastFallbackFromMaster } from "components/DraftEditor/sync";
import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  SelectionState,
  ContentState,
} from "draft-js";
import produce, { setAutoFreeze } from "immer";
import Immutable from "immutable";
import immutablePatch from "immutablepatch";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { createContainer } from "react-tracked";
import { addAnalyticsEvent, CATEGORIES } from "utils/analytics";
import {
  moveEditorContent,
  mergeEntityMap,
  parseRawDiffToDraftData,
  mergeAddedBlocks,
  getPageHeaderBlockKeys,
} from "utils/editor";
import { saveSyncErrorData } from "utils/saveSyncErrorData";

import { SPRINT_STATE } from "./constants";
import {
  normalizeRawBlocksToParentChild,
  flattenHeaderHierarchyMap,
  createSyncSelectionState,
} from "./utils/editor";
import { insertSectionsForOldProjects } from "./utils/editor/draft-utils";

setAutoFreeze(false);

export const ACTIONS = {
  SHOW_ITEM_DETAILS: "SHOW_ITEM_DETAILS",
  EDITOR_CONTENTS_CHANGED: "EDITOR_CONTENTS_CHANGED",
  EDITOR_CONTENTS_CHANGED_UPDATE_KEY: "EDITOR_CONTENTS_CHANGED_UPDATE_KEY",
  EDITOR_CONTENTS_SAVED: "EDITOR_CONTENTS_SAVED",
  EDITOR_ACTIVE_HEADER: "EDITOR_ACTIVE_HEADER",
  EDITOR_CONTENT_APPLY_PATCH: "EDITOR_CONTENT_APPLY_PATCH",
  EDITOR_CONTENT_MASTER_RESTORE: "EDITOR_CONTENT_MASTER_RESTORE",
  EDITOR_CONTENT_IS_MASTER_FALLBACK: "EDITOR_CONTENT_IS_MASTER_FALLBACK",
  EDITOR_CONTENT_IS_MASTER_CORRUPT_FALLBACK:
    "EDITOR_CONTENT_IS_MASTER_CORRUPT_FALLBACK",
  EDITOR_SYNC_COLLABORATION_META: "EDITOR_SYNC_COLLABORATION_META",
  SET_EDITOR_MASTER: "SET_EDITOR_MASTER",
  TOC_ITEM_MOVE: "TOC_ITEM_MOVE",
  SET_PROJECT_ITEMS: "SET_PROJECT_ITEMS",
  SET_ITEM_DISCUSSIONS: "SET_ITEM_DISCUSSIONS",
  SET_DISCUSSION_MESSAGES: "SET_DISCUSSION_MESSAGES",
  SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT",
  SET_ACTIVE_PROJECT_PENDING_USERS: "SET_ACTIVE_PROJECT_PENDING_USERS",
  LOADING_PROJECT_INITIATED: "LOADING_PROJECT_INITIATED",
  LOAD_PROJECT_DATA: "LOAD_PROJECT_DATA",
  LOAD_BASIC_PROJECTS_DATA: "LOAD_BASIC_PROJECTS_DATA",
  LOAD_DASHBOARD_PROJECT_DATA: "LOAD_DASHBOARD_PROJECT_DATA",
  SET_FILTERS: "SET_FILTERS",
  SET_GLOBAL_SEARCH_FILTER: "SET_GLOBAL_SEARCH_FILTER",
  SET_FILTER_SPRINT: "SET_FILTER_SPRINT",
  ADD_SPRINT: "ADD_SPRINT",
  DELETE_SPRINT: "DELETE_SPRINT",
  EDIT_SPRINT: "EDIT_SPRINT",
  CLOSE_SPRINT: "CLOSE_SPRINT",
  ADD_STATUS: "ADD_STATUS",
  DELETE_STATUS: "DELETE_STATUS",
  EDIT_STATUS: "EDIT_STATUS",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  REORDER_STATUSES: "REORDER_STATUSES",
  TOGGLE_BOARD_VIEW: "TOGGLE_BOARD_VIEW",
  SET_SELECTED_ITEM_PROJECT_ID: "SET_SELECTED_ITEM_PROJECT_ID",
  LOADING_MEMBERS: "LOADING_MEMBERS",
  RECREATE_HIERARCHY_STATE: "RECREATE_HIERARCHY_STATE",
  ADD_DOCUMENT_VERSION: "ADD_DOCUMENT_VERSION",
  REMOVE_DOCUMENT_VERSION: "REMOVE_DOCUMENT_VERSION:",
  LOAD_DOCUMENT_VERSION: "LOAD_DOCUMENT_VERSION",
  USER_LOGOUT: "USER_LOGOUT",
  RENAME_PROJECT: "RENAME_PROJECT",
  SET_EDITOR_BLOCKS_POSITION: "SET_EDITOR_BLOCKS_POSITION",
  CLEAR_DEEPLINK: "CLEAR_DEEPLINK",
  SELECT_ITEM_TO_SCROLL: "SELECT_ITEM_TO_SCROLL",
  ITEM_SYNC_UPDATE: "ITEM_SYNC_UPDATE",
  ADD_LABEL: "ADD_LABEL",
};

const initialState = {
  /* Basic projects info */
  loadedProjects: {},

  /* TODO: EXPLAIN */

  loadedMembers: {},
  loadingMembers: false,
  selectedItemId: null,

  /***** 
  - Active project details 
  ******/

  activeProjectId: null,
  activeProjectPendingUsers: [],
  loadedVersions: {},
  /**
   * projectItems - an array of items tied to a particular project (document).
   *                Relevant only when a particular project is active to provide
   *                item metadata information in various views (Editor, Kanban, Gantt)
   */
  projectItems: [],
  deepLinkItemId: null,
  itemToScroll: null,
  sidebarVisible: false,
  activeModal: "",
  activeModalData: {},
  selectedItemProjectId: "",
  itemDiscussions: [],
  discussionMessages: [],

  /***
   * Filters
   */
  filters: {
    sprint: null,
    label: null,
    assignees: [],
    title: "",
    priority: null,
  },
  /***
   * Global search filters
   */
  globalSearchFilter: {
    header: null,
    bucketKey: null,
    taskId: null,
    bucketId: null,
  },
  /***** 
   - Editor related fields 
   ******/
  editorState: EditorState.createEmpty(), // old used from local storage
  lastFirestoreEditorState: EditorState.createEmpty(),
  rawContentState: null,
  /**
   * Parent child representation of current editor state
   */
  hierarchyState: {},
  editorLoaded: false,
  editorUsersCount: 1,
  editorMembers: [],
  editorActiveHeader: null,
  editorContentStateKeyLocal: createEditorContentKey(),
  editorContentStateKeyRemote: createEditorContentKey(),
  editorDisable: false,
  isEditorMaster: false,
  editorBlocksPosition: [],
  conditionsApplied: [], // There can be only one condition key at all times,
  conditions: [
    {
      name: "foo",
      label: "Foo",
    },
    {
      name: "bar",
      label: "Bar",
    },
    {
      name: "baz",
      label: "Baz",
    },
    {
      name: "flaz",
      label: "Flaz",
    },
  ],
};

function createEditorContentKey() {
  return Math.random();
}

const reducer = produce((draft, action) => {
  // TODO - FIXME! Only on DEV Mode
  // console.log("DISPATCH", action.type, { action, draft });

  switch (action.type) {
    // When dispatch type is not correctly set
    case undefined:
      console.error("You are sending an UNDEFINED ACTION");

      return {
        ...draft,
      };

    case ACTIONS.SHOW_ITEM_DETAILS:
      return {
        ...draft,
        selectedItemId: action.payload.selectedItemId,
        sidebarVisible: action.payload.sidebarVisible,
      };

    case ACTIONS.SET_SELECTED_ITEM_PROJECT_ID:
      let query = new URLSearchParams(useLocation().search);

      let deepLinkItemId = query.get("deepLinkItemId");
      return {
        ...draft,

        selectedItemProjectId: action.payload.selectedItemProjectId,
        deepLinkItemId:
          deepLinkItemId &&
          action.payload.selectedItemProjectId === draft.activeProjectId
            ? deepLinkItemId
            : null,
        selectedItemId:
          deepLinkItemId &&
          action.payload.selectedItemProjectId === draft.activeProjectId
            ? deepLinkItemId
            : null,
        sidebarVisible:
          deepLinkItemId &&
          action.payload.selectedItemProjectId === draft.activeProjectId
            ? true
            : false,
      };

    case ACTIONS.CLEAR_DEEPLINK:
      return {
        ...draft,
        deepLinkItemId: null,
      };

    case ACTIONS.SELECT_ITEM_TO_SCROLL:
      return {
        ...draft,
        itemToScroll: action.payload,
      };

    case ACTIONS.EDITOR_CONTENTS_SAVED:
      return {
        ...draft,
        lastFirestoreEditorState: action.payload.savedEditorState,
      };

    case ACTIONS.EDITOR_CONTENTS_CHANGED: {
      const newEditorState = action.payload.newEditorState;

      return {
        ...draft,
        editorState: newEditorState,
      };
    }
    case ACTIONS.EDITOR_CONTENTS_CHANGED_UPDATE_KEY: {
      const newEditorState = action.payload.newEditorState;

      const rawContentState = convertToRaw(newEditorState.getCurrentContent());

      const hierarchyState = flattenHeaderHierarchyMap(
        normalizeRawBlocksToParentChild(rawContentState.blocks, [
          ...draft.projectItems,
        ])
      );

      return {
        ...draft,
        editorState: newEditorState,
        hierarchyState,
        editorContentStateKeyLocal: createEditorContentKey(),
      };
    }

    case ACTIONS.EDITOR_SYNC_COLLABORATION_META: {
      const editorMembers = action.payload.editorMemberIds.map((memberId) => {
        return draft.loadedMembers[memberId];
      });
      const editorUsersCount = action.payload.editorUsersCount;

      return {
        ...draft,
        editorMembers,
        editorUsersCount,
      };
    }

    case ACTIONS.RECREATE_HIERARCHY_STATE: {
      const rawContentState = convertToRaw(
        action.payload.newEditorState.getCurrentContent()
      );

      const hierarchyState = flattenHeaderHierarchyMap(
        normalizeRawBlocksToParentChild(rawContentState.blocks, [
          ...draft.projectItems,
        ])
      );

      return {
        ...draft,
        rawContentState,
        hierarchyState,
      };
    }

    case ACTIONS.SET_PROJECT_ITEMS:
      return {
        ...draft,
        projectItems: action.payload.projectItems,
      };

    case ACTIONS.ITEM_SYNC_UPDATE:
      let projectItems = [...draft.projectItems];

      if (action.payload.type === "modified") {
        projectItems = projectItems.map((pItem) => {
          if (pItem.id === action.payload.doc.id) {
            return { ...pItem, ...action.payload.doc.data() };
          }
          return { ...pItem };
        });
      } else if (action.payload.type === "removed") {
        projectItems = projectItems.filter(
          (pItem) => pItem.id !== action.payload.doc.id
        );
      } else {
        projectItems = [
          ...projectItems,
          {
            id: action.payload.doc.id,
            ...action.payload.doc.data(),
          },
        ];
      }

      return {
        ...draft,
        projectItems: projectItems,
      };

    case ACTIONS.SET_ACTIVE_PROJECT:
      return {
        ...draft,
        activeProjectId: action.payload.projectId,
      };

    case ACTIONS.SET_ACTIVE_PROJECT_PENDING_USERS:
      draft.activeProjectPendingUsers = action.payload;
      return;

    case ACTIONS.LOADING_PROJECT_INITIATED: {
      return {
        ...draft,
        editorLoaded: false,
      };
    }

    case ACTIONS.LOAD_BASIC_PROJECTS_DATA: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          ...action.payload.projects,
        },

        loadedMembers: {
          ...draft.loadedMembers,
          ...action.payload.members,
        },
      };
    }

    case ACTIONS.LOADING_MEMBERS: {
      return {
        ...draft,
        loadingMembers: action.payload,
      };
    }

    case ACTIONS.LOAD_PROJECT_DATA: {
      const { projectMembers, projectItems, projectData } = action.payload;

      const loadingRawContentState = projectData.document;
      const projectId = projectData.id;

      const blocks = loadingRawContentState
        ? loadingRawContentState.blocks
        : [];

      const loadingNewEditorState = loadingRawContentState
        ? EditorState.createWithContent(convertFromRaw(loadingRawContentState))
        : EditorState.createEmpty();

      const newEditorState = insertSectionsForOldProjects(
        loadingNewEditorState,
        projectItems
      );

      const contentBlockMapWithTasks = convertToRaw(
        newEditorState.getCurrentContent()
      ).blocks;

      const hierarchyState = flattenHeaderHierarchyMap(
        normalizeRawBlocksToParentChild(contentBlockMapWithTasks, projectItems)
      );

      return {
        ...draft,
        editorState: newEditorState,
        lastFirestoreEditorState: newEditorState,
        rawContentState: loadingRawContentState,
        editorLoaded: true,
        editorContentStateKeyLocal: createEditorContentKey(),
        loadedMembers: {
          ...draft.loadedMembers,
          ...projectMembers,
        },
        loadedProjects: {
          ...draft.loadedProjects,
          [projectId]: {
            ...projectData,
            id: projectId,
            boardView: "ALL" || localStorage.getItem("sprintsViewEnabled"),
          },
        },

        projectItems,
        hierarchyState,
      };
    }

    case ACTIONS.SET_ITEM_DISCUSSIONS: {
      return {
        ...draft,
        itemDiscussions: action.payload,
      };
    }

    case ACTIONS.SET_DISCUSSION_MESSAGES: {
      return {
        ...draft,
        discussionMessages: action.payload,
      };
    }

    case ACTIONS.EDITOR_ACTIVE_HEADER: {
      let editorActiveHeader = draft.editorActiveHeader;
      const newActiveHeader = action.payload.activeHeaderKey;
      const allPageHeaderBlocks =
        getPageHeaderBlockKeys(draft.editorState) || [];

      if (!newActiveHeader) {
        editorActiveHeader = allPageHeaderBlocks.first();
      } else if (allPageHeaderBlocks.includes(newActiveHeader)) {
        editorActiveHeader = newActiveHeader;
      }

      return {
        ...draft,
        editorActiveHeader,
      };
    }

    case ACTIONS.SET_FILTERS: {
      return {
        ...draft,
        filters: action.payload,
      };
    }

    case ACTIONS.SET_GLOBAL_SEARCH_FILTER: {
      return {
        ...draft,
        globalSearchFilter: action.payload,
      };
    }

    case ACTIONS.SET_FILTER_SPRINT: {
      draft.filters.sprint = action.payload;
      return;
    }

    case ACTIONS.TOGGLE_BOARD_VIEW:
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            boardView: action.payload.boardView,
          },
        },
      };

    case ACTIONS.ADD_SPRINT: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            sprints: [
              ...draft.loadedProjects[action.payload.projectId].sprints,
              action.payload.sprint,
            ],
          },
        },
      };
    }

    case ACTIONS.DELETE_SPRINT: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            sprints: draft.loadedProjects[
              action.payload.projectId
            ].sprints.filter((sprint) => sprint.id !== action.payload.id),
          },
        },
      };
    }

    case ACTIONS.EDIT_SPRINT: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            sprints: draft.loadedProjects[action.payload.projectId].sprints.map(
              (sp) =>
                sp.id === action.payload.sprint.id
                  ? // transform the one with a matching id
                    {
                      ...sp,
                      name: action.payload.sprint.name,
                      startDate: action.payload.sprint.startDate,
                      endDate: action.payload.sprint.endDate,
                      goal: action.payload.sprint.goal,
                      sprintState: action.payload.sprint.sprintState,
                    }
                  : // otherwise return original sprint
                    sp
            ),
          },
        },
      };
    }

    case ACTIONS.CLOSE_SPRINT: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            sprints: draft.loadedProjects[action.payload.projectId].sprints.map(
              (sp) =>
                sp.id === action.payload.sprint.id
                  ? // transform the one with a matching id
                    {
                      ...sp,
                      sprintState: SPRINT_STATE.CLOSED,
                    }
                  : // otherwise return original status
                    sp
            ),
          },
        },
      };
    }

    case ACTIONS.ADD_STATUS: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            statuses: [
              ...draft.loadedProjects[action.payload.projectId].statuses,
              action.payload.status,
            ],
          },
        },
      };
    }
    case ACTIONS.DELETE_STATUS: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            statuses: draft.loadedProjects[
              action.payload.projectId
            ].statuses.filter((status) => status.id !== action.payload.id),
          },
        },
      };
    }
    case ACTIONS.EDIT_STATUS: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            statuses: draft.loadedProjects[
              action.payload.projectId
            ].statuses.map((st) =>
              st.id === action.payload.status.id
                ? // transform the one with a matching id
                  {
                    ...st,
                    label: action.payload.status.label,
                    color: action.payload.status.color,
                  }
                : // otherwise return original status
                  st
            ),
          },
        },
      };
    }

    case ACTIONS.REORDER_STATUSES: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            statuses: [...action.payload.statusOrder],
          },
        },
      };
    }

    case ACTIONS.ADD_LABEL: {
      return {
        ...draft,
        loadedProjects: {
          ...draft.loadedProjects,
          [action.payload.projectId]: {
            ...draft.loadedProjects[action.payload.projectId],
            labels: [
              ...draft.loadedProjects[action.payload.projectId].labels,
              action.payload.label,
            ],
          },
        },
      };
    }
    case ACTIONS.TOC_ITEM_MOVE: {
      const { topLevelHierarchyBlock, changeObject } = action.payload;

      const movedNewEditorState = moveEditorContent({
        topLevelHierarchyBlock,
        changeObject,
        editorState: draft.editorState,
        hierarchyState: draft.hierarchyState,
      });

      let movedEditorState = movedNewEditorState
        ? movedNewEditorState
        : draft.editorState;

      const rawContentState = convertToRaw(
        movedEditorState.getCurrentContent()
      );

      const hierarchyState = flattenHeaderHierarchyMap(
        normalizeRawBlocksToParentChild(rawContentState.blocks, [
          ...draft.projectItems,
        ])
      );

      return {
        ...draft,
        editorState: movedEditorState,
        rawContentState,
        hierarchyState,
        editorContentStateKeyLocal: createEditorContentKey(),
      };
    }

    case ACTIONS.SET_EDITOR_MASTER: {
      return {
        ...draft,
        isEditorMaster: action.payload.isMaster,
      };
    }

    case ACTIONS.SET_EDITOR_BLOCKS_POSITION:
      return {
        ...draft,
        editorBlocksPosition: action.payload,
      };

    case ACTIONS.EDITOR_CONTENT_IS_MASTER_FALLBACK: {
      /**
       * I am master. I am not corrupt.
       *
       * Broadcast my rawContentState to others
       *
       */

      const masterRawContentState = convertToRaw(
        draft.editorState.getCurrentContent()
      );
      broadcastFallbackFromMaster(
        masterRawContentState,
        action.payload.lastCorruptState
      );

      return { ...draft };
    }

    case ACTIONS.EDITOR_CONTENT_IS_MASTER_CORRUPT_FALLBACK: {
      /**
       * I am master. I am causing the corrupt state in one of the clients.
       * Revert to the uncorrupted state, for now  just UNDO and set that as the
       *  new editor state.
       *
       * Broadcast my new rawContentState to all others!
       *
       */

      const undoEditorState = EditorState.undo(draft.editorState);
      const masterRawContentState = convertToRaw(
        undoEditorState.getCurrentContent()
      );

      broadcastFallbackFromMaster(
        masterRawContentState,
        action.payload.lastCorruptState
      );

      return {
        ...draft,
        editorState: undoEditorState,
        editorContentStateKey: createEditorContentKey(),
      };
    }

    case ACTIONS.EDITOR_CONTENT_MASTER_RESTORE: {
      /**
       * Master has set a new state. All clients need to update to that state!
       * This is basically the same functionality as the original sync!
       *
       * acttion.payload.lastMasterRestore
       */
      try {
        // We are getting updated editor content from somewhere
        const masterEditorState = EditorState.push(
          draft.editorState,
          convertFromRaw(action.payload.lastMasterRestore.masterRawContentState)
        );

        let newSelectionState = draft.editorState.getSelection();

        /**
         * We are forcing a selection from the previous EditorState
         * to be the selection of the incoming and recreated editor draft.
         *
         * When we are initializing the document or switching projects
         * it can happen that the block key from the previous State Selection
         * does not exist in new Editor Content.
         *
         * If that is the case we create a new State Selection with a focus
         * on one of the existing blockIds.
         *
         * FIXME - see if you can reuse mergeSelections method here!
         */
        if (
          !masterEditorState
            .getCurrentContent()
            .getBlockForKey(draft.editorState.getSelection().getAnchorKey())
        ) {
          newSelectionState = SelectionState.createEmpty(
            masterEditorState.getSelection().getAnchorKey()
          );
        }

        const realDbEditorState = EditorState.acceptSelection(
          masterEditorState,
          newSelectionState
        );

        toast.warn(`Content refreshed from master`);

        // FIXME - FOR FUTURE INCOMING DIFFS!!
        // setLastAppliedTS(lastMasterRestore.ts);

        return {
          ...draft,
          editorState: realDbEditorState,
          editorContentStateKey: createEditorContentKey(),
        };
      } catch (error) {
        console.error("Error while updating content from peer!");

        toast.error(`Content refresh from master fail!`);

        return {
          ...draft,
        };
      }
    }

    case ACTIONS.EDITOR_CONTENT_APPLY_PATCH: {
      // Don't apply any more diffs once we are in an error state
      // FIXME - Find a good fallback - discuss!
      if (draft.editorDisable) {
        return { ...draft };
      }

      try {
        console.time("SYNC TIME - full process");
        // TODO - Think of how to avoid putting this change in the undo stack?
        // This can also be handled via yjs

        const currentContentState = draft.editorState.getCurrentContent();
        const purgedDiffsArray = action.payload.diffs;

        const diffs = Immutable.fromJS(purgedDiffsArray);

        /**
         * What happens when we have to apply multiple diffs where blocks are added!
         */
        const [parseDiff, parsedEntities, blocksAdded] =
          parseRawDiffToDraftData(diffs);

        console.time("SYNC TIME - diffPatch");
        let rawContentStatePatched = immutablePatch(
          currentContentState.blockMap,
          parseDiff
        );

        console.timeEnd("SYNC TIME - diffPatch");

        if (blocksAdded) {
          /**
           * Run parse parseDiff.toJS()!!
           */
          rawContentStatePatched = mergeAddedBlocks(
            rawContentStatePatched,
            purgedDiffsArray
          );
        }

        const newContentStatePatched = ContentState.createFromBlockArray(
          rawContentStatePatched.toArray(),
          mergeEntityMap(currentContentState, parsedEntities)
        );

        // TODO -- see how we can push to editor state, or create new
        // EditorState without affecting undo stack for this client
        const dbEditorState = EditorState.push(
          draft.editorState,
          newContentStatePatched
        );

        const newSelectionState = createSyncSelectionState(
          draft.editorState,
          newContentStatePatched,
          parseDiff.toJS()
        );

        const finalEditorState = EditorState.acceptSelection(
          dbEditorState,
          newSelectionState
        );

        console.timeEnd("SYNC TIME - full process");

        return {
          ...draft,
          editorState: finalEditorState,
          editorContentStateKeyRemote: createEditorContentKey(),
        };
      } catch (error) {
        console.error("error: ", error);
        /**
         * Save the stack trace that caused the error to firebase storage.
         */
        saveSyncErrorData({
          diffs: action.payload.diffs,
          lastDiff: action.payload.lastDiff,
          rawContentState: convertToRaw(draft.editorState.getCurrentContent()),
        }).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            // Attach the URL of the error stack to the sentry metadata

            Sentry.captureException(error, {
              syncErrorDataUrl: url,
            });
          });
        });

        // Initiate the fallback mechanism!
        fallbackOnAppyPatchException({ lastDiff: action.payload.lastDiff });

        return { ...draft };

        /**
         * FIXME - track if error persists X times .. if it does then disable editor
         *
         */
        // return { ...draft, editorDisable: true };
      }
    }

    case ACTIONS.ADD_DOCUMENT_VERSION: {
      const { projectId, version } = action.payload;

      const { versions = [] } = draft.loadedProjects[projectId];
      versions.push(version);

      draft.loadedProjects[projectId].versions = versions;
      return;
    }

    case ACTIONS.REMOVE_DOCUMENT_VERSION: {
      const { projectId, versionId } = action.payload;

      const project = draft.loadedProjects[projectId];

      const nextVersions = project.versions.filter(
        (version) => version.versionId !== versionId
      );

      draft.loadedProjects[action.payload.projectId].versions = nextVersions;
      return;
    }

    case ACTIONS.LOAD_DOCUMENT_VERSION: {
      const { projectId, documentData, versionId } = action.payload;
      const { createdTs, label, document } = documentData;

      const editorState = EditorState.createWithContent(
        convertFromRaw(document)
      );

      draft.loadedVersions[versionId] = {
        editorState,
        versionId,
        createdTs,
        label,
        projectId,
      };
      return;
    }

    case ACTIONS.RENAME_PROJECT: {
      const { projectId, projectName } = action.payload;
      draft.loadedProjects[projectId].name = projectName;
      return;
    }

    case ACTIONS.OPEN_MODAL:
      addAnalyticsEvent(CATEGORIES.MODAL, `Open ${action.payload.name}`);

      return {
        ...draft,
        activeModal: action.payload.name,
        activeModalData: action.payload.activeModalData || {},
      };

    case ACTIONS.CLOSE_MODAL:
      return {
        ...draft,
        activeModal: "",
        activeModalData: {},
      };

    case ACTIONS.USER_LOGOUT:
      // Completely reset the initial state on logout
      return {
        ...initialState,
      };

    default:
      return {
        ...draft,
      };
  }
}, initialState);

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
