import React from "react";
import PrioIcon from "components/PrioIcon/PrioIcon";

const PriorityIndicator = ({ label, number, isSmall }) => {
  return label && number ? (
    <div
      className={`${
        isSmall ? "py-1 text-tiny" : "w-32 py-2 text-xs"
      } flex items-center px-2 rounded-2xl text-center font-bold text-transform: uppercase
       bg-opacity-25 text-black`}
    >
      <div className="mr-1">
        <PrioIcon number={number} />
      </div>
      {label}
    </div>
  ) : null;
};

export default PriorityIndicator;
