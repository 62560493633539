import React from "react";

import { updateProjectItem } from "api";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as EmptyPrio } from "assets/editor-tasks-icons/priorities.svg";
import PrioIcon from "components/PrioIcon/PrioIcon";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState } from "context";
import useOnClickOutside from "hooks/useOnClickOutside";
import ReactTooltip from "react-tooltip";

import {
  getLoadedProjectData,
  getProjectDataByKey,
} from "utils/loadedProjects";
import { canUserEditMeta } from "utils/permissions";

import {
  PERMISSIONS_SUBJECTS,
  PERMISSIONS_ACTIONS,
} from "../../../../constants";

const Priority = (props) => {
  const state = useTrackedState();
  const { loadedProjects, activeProjectId } = state;
  const { editorStateForceSelection, metaPriority, setMetaPriority } = props;
  const [prioBlockOpen, setPrioBlockOpen] = React.useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => setPrioBlockOpen(false));

  const priorities =
    getProjectDataByKey(
      getLoadedProjectData(loadedProjects, activeProjectId),
      "priority"
    ) ?? [];

  return (
    <div className="relative">
      {!metaPriority ? (
        <span
          className="flex items-center btn-transition cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            canUserEditMeta() && setPrioBlockOpen(!prioBlockOpen);
          }}
        >
          <ReactTooltip />
          <EmptyPrio
            data-tip="Priority"
            className={`${
              prioBlockOpen && "text-chillBlue"
            } text-gray-500 stroke-current hover:text-chillBlue`}
          />
        </span>
      ) : (
        <div
          className="relative flex items-center btn-transition uppercase text-tiny font-bold group cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            canUserEditMeta() && setPrioBlockOpen(!prioBlockOpen);
          }}
        >
          <span className="mr-1">
            <PrioIcon number={metaPriority.number} />
          </span>
          <span className="h-3 flex items-center justify-center">
            {metaPriority.label}
          </span>

          <Can I={PERMISSIONS_ACTIONS.MANAGE} a={PERMISSIONS_SUBJECTS.METADATA}>
            <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100">
              <ClearIcon
                className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                onClick={(e) => {
                  e.stopPropagation();
                  setMetaPriority(null);

                  updateProjectItem({
                    dispatch: props.dispatch,
                    itemId: props.itemId,
                    projectId: props.activeProjectId,
                    projectItems: props.projectItems,
                    data: { ...props.metadata, priority: null },
                  });

                  if (editorStateForceSelection) {
                    editorStateForceSelection();
                  }
                }}
              />
            </div>
          </Can>
        </div>
      )}
      {prioBlockOpen && (
        <div
          ref={ref}
          className="task-item-dropdown absolute top-0 left-0 p-2 mt-8 border rounded z-50 bg-white"
        >
          {priorities.map((priority, index) => (
            <div
              onClick={(event) => {
                event.stopPropagation();

                setMetaPriority(priority);

                updateProjectItem({
                  dispatch: props.dispatch,
                  itemId: props.itemId,
                  projectId: props.activeProjectId,
                  projectItems: props.projectItems,
                  data: {
                    ...props.metadata,
                    priority: priority,
                  },
                });

                if (editorStateForceSelection) {
                  editorStateForceSelection();
                }

                setPrioBlockOpen(false);
              }}
              key={index}
              value={`${priority.label}`}
              className="task-item-dropdown-option py-2"
            >
              <div className="flex items-center uppercase">
                <div className="mr-2">
                  <PrioIcon number={priority.number} />
                </div>
                {priority.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Priority;
