import React from "react";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { ReactComponent as DropdownArrow } from "assets/arrow_right.svg";
import { ReactComponent as AssigneeIcon } from "assets/assignee_icon.svg";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { Can } from "components/RoleManagement/Can";
import UserAvatar from "components/UserAvatar";
import { useTrackedState } from "context";
import useUserId from "hooks/useUserId";
import get from "lodash.get";
import {
  useTourDispatch,
  useTourTrackedState,
  TOUR_ACTIONS,
  TOUR_STAGES,
  TOUR_STEPS,
} from "tourContext";
import {
  getLoadedProjectData,
  getProjectDataByKey,
} from "utils/loadedProjects";
import { canUserEditMeta } from "utils/permissions";

import { PERMISSIONS_SUBJECTS, PERMISSIONS_ACTIONS } from "../../constants";
import "@reach/combobox/styles.css";

const Assignee = (props) => {
  const [assigneeBlockOpen, setAssigneeOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const loggedUserId = useUserId();
  const handleChange = function (event) {
    setInputValue(event.target.value);
  };

  const state = useTrackedState();
  const tourState = useTourTrackedState();
  const tourDispatch = useTourDispatch();
  const { loadedProjects, loadedMembers, selectedItemProjectId } = state;
  const { assignee, setMetaAssignee } = props;
  const assigneeMeta = assignee ? loadedMembers[assignee.id] : null;

  const projectId = props.metadata?.projectId
    ? props.metadata.projectId
    : selectedItemProjectId;

  const projectMemberIds =
    getProjectDataByKey(
      getLoadedProjectData(loadedProjects, projectId),
      "members"
    ) ?? [];
  const projectMembers = Object.keys(projectMemberIds)
    .filter((id) => id in loadedMembers)
    .map((id) => loadedMembers[id]);

  const filterProjectMembers = projectMembers.filter((pMember) => {
    if (inputValue === "") return true;

    if (
      pMember.name.toLowerCase().indexOf(inputValue.toLocaleLowerCase()) >= 0
    ) {
      return true;
    }

    return false;
  });
  return (
    <div
      id="assigneeSelect"
      className="flex-row flex-wrap items-center sidebar-item"
    >
      <p className="w-full font-normal text-darkBlue">Assignee</p>
      {assigneeBlockOpen ? (
        <Combobox
          openOnFocus
          className="w-1/2"
          aria-labelledby="demo"
          onSelect={(selectedItem) => {
            const selectedMeta = selectedItem.split(",");
            const selectedEmail = selectedMeta[1].trim();
            const userMeta = projectMembers.find(
              (user) => user.email === selectedEmail
            );

            if (!userMeta) {
              // No user Meta
              return;
            }

            const assigneeMeta = {
              email: userMeta.email,
              id: userMeta.id,
            };

            setMetaAssignee(assigneeMeta);

            setAssigneeOpen(false);
            if (tourState.tourStage === TOUR_STAGES.IN_PROGRESS) {
              tourDispatch({
                type: TOUR_ACTIONS.CURRENT_STEP,
                payload: TOUR_STEPS.TOUR_ITEM_SIDEBAR_APPLY,
              });
            }
          }}
          onBlur={() =>
            setTimeout(() => {
              setAssigneeOpen(false);
              setInputValue("");
            }, 200)
          }
        >
          <ComboboxInput
            selectOnClick
            placeholder="Search for user"
            autoFocus
            className="w-full sidebar-item-input"
            onChange={handleChange}
          />

          <ComboboxPopover style={{ zIndex: "60", borderColor: "transparent" }}>
            <ComboboxList className="sidebar-item-dropdown-list">
              {filterProjectMembers.map((user) => (
                <ComboboxOption
                  key={user.id}
                  value={`${get(user, "name", "-")}, ${user.email}`}
                  className="sidebar-item-dropdown-option"
                >
                  <div className={`w-6 h-6 mr-2`}>
                    <UserAvatar img={user.img} />
                  </div>
                  {`${get(user, "name")}`}
                </ComboboxOption>
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      ) : assignee && assignee.id ? (
        <span
          className="flex items-center sidebar-item-selection sidebar-item-selection--filled btn-transition"
          onClick={() => {
            canUserEditMeta() && setAssigneeOpen(!assigneeBlockOpen);
          }}
        >
          <AssigneeIcon className="mr-2 stroke-current text-chillBlue" />{" "}
          {get(assigneeMeta, "name", "-")}{" "}
          {assignee && assignee.id && (
            <Can
              I={PERMISSIONS_ACTIONS.MANAGE}
              a={PERMISSIONS_SUBJECTS.METADATA}
            >
              <ClearIcon
                className="w-5 h-5 p-1 ml-auto bg-gray-400 border border-transparent rounded-full cursor-pointer hover:border-darkBlue"
                onClick={(e) => {
                  e.stopPropagation();
                  setMetaAssignee(null);
                }}
              />
            </Can>
          )}
        </span>
      ) : (
        <span
          className="flex items-center sidebar-item-selection sidebar-item-selection--empty btn-transition"
          onClick={() => {
            canUserEditMeta() && setAssigneeOpen(!assigneeBlockOpen);
          }}
        >
          <AssigneeIcon className="mr-2 stroke-current text-grayBlue" /> Choose
          assignee{" "}
          <DropdownArrow className="ml-auto transform rotate-90 fill-current text-grayBlue" />
        </span>
      )}
      {!assigneeBlockOpen && (
        <Can I={PERMISSIONS_ACTIONS.MANAGE} a={PERMISSIONS_SUBJECTS.METADATA}>
          <button
            onClick={() => {
              const userMeta = projectMembers.find(
                (user) => user.id === loggedUserId
              );

              if (!userMeta) {
                // No user meta

                return;
              }

              const assigneeMeta = {
                email: userMeta.email,
                id: userMeta.id,
              };

              setMetaAssignee(assigneeMeta);

              setAssigneeOpen(false);
            }}
            className="text-left underline btn xl:text-center xl:ml-2 hover:text-linkBlue focus:outline-none"
          >
            Assign to me
          </button>
        </Can>
      )}
    </div>
  );
};

export default Assignee;
