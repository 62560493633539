import React, { useState, useEffect } from "react";

import QuickStart from "components/QuickStart/QuickStart";
import { getQuickStartSolvedSteps } from "quickStartApi";
import {
  QUICK_START_STEPS,
  useQuickStartState,
  useQuickStartDispatch,
  QUICK_START_ACTIONS,
} from "quickStartContext";

const QuickStartBar = ({ user }) => {
  const quickStartSate = useQuickStartState();
  const [completion, setCompletion] = useState(0);
  const { solvedSteps, toggled } = quickStartSate;
  const quickStartDispatch = useQuickStartDispatch();

  useEffect(() => {
    return function cleanup() {
      quickStartDispatch({
        type: QUICK_START_ACTIONS.SET_STEPS,
        payload: null,
      });
    };
  }, [quickStartDispatch]);
  useEffect(() => {
    if (!solvedSteps) return;
    const progressPercentage = Math.round(
      (solvedSteps.length / Object.keys(QUICK_START_STEPS).length) * 100
    );
    setCompletion(progressPercentage);
  }, [solvedSteps]);

  useEffect(() => {
    if (solvedSteps || !user) return;
    getQuickStartSolvedSteps(user.uid, quickStartDispatch);
  }, [quickStartDispatch, solvedSteps, user]);

  return (
    <div
      onClick={() => {
        quickStartDispatch({
          type: QUICK_START_ACTIONS.TOGGLE_QUICK_START,
          payload: !toggled,
        });
      }}
      className="relative flex justify-between items-baseline gap-3 py-2 px-3 ml-5 text-white cursor-pointer rounded-sm hover:bg-darkBlueHover hover:bg-opacity-50"
    >
      <div className="border-l absolute left-0 top-0 bottom-0 mt-3 mb-3 -ml-2"></div>
      <div className="border-r absolute right-0 top-0 bottom-0 mt-3 mb-3 -mr-2"></div>
      <span>Quick start</span>
      <div className="w-32 h-2 bg-gray2 rounded-md">
        <div
          style={{ width: completion + "%" }}
          className="h-full bg-green2 rounded-md"
        ></div>
      </div>
      <span className="text-xs">{`${completion}%`}</span>
      <QuickStart />
    </div>
  );
};

export default QuickStartBar;
