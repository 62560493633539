import React from "react";

import { useDispatch, useTrackedState, ACTIONS } from "context";
import Modal from "react-modal";

const defaultContentStyle = {
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  padding: "0px",
  margin: "auto",
  width: "980px",
  height: "512px",
};

const defaultOverlayStyle = {
  zIndex: 60,
  backgroundColor: "rgb(0, 0, 0, 0.75)",
};

const ModalComponent = ({
  children,
  name,
  style = {},
  overlayStyle = {},
  shouldCloseOnOverlayClick = true,
  closeFunctionCallback,
  shouldReturnFocusAfterClose = true,
}) => {
  const dispatch = useDispatch();
  const state = useTrackedState();

  const modalStyles = {
    content: { ...defaultContentStyle, ...style },
    overlay: { ...defaultOverlayStyle, ...overlayStyle },
  };

  function closeModal() {
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
    if (closeFunctionCallback) {
      closeFunctionCallback();
    }
  }

  return (
    <Modal
      isOpen={name === state.activeModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      ariaHideApp={false}
      isEditing={state.activeModalData?.isEditing}
    >
      <div className="w-full h-full">{children}</div>
    </Modal>
  );
};

export function ModalHeader({ children }) {
  return (
    <div>
      <span>{children}</span>
      <CloseModal />
    </div>
  );
}

export function ModalContent({ children }) {
  return <div>{children}</div>;
}

export const CloseModal = () => {
  const dispatch = useDispatch();
  return (
    <button
      onClick={() =>
        dispatch({
          type: ACTIONS.CLOSE_MODAL,
          payload: "",
        })
      }
    >
      <i />
    </button>
  );
};

export const MODAL_NAMES = {
  ADHOC_ITEM_MODAL: "ADHOC_ITEM_MODAL",
  CREATE_PROJECT_MODAL: "CREATE_PROJECT_MODAL",
  SHARE_MODAL: "SHARE_MODAL",
  SPRINTS_MODAL: "SPRINTS_MODAL",
  CREATE_SPRINT_MODAL: "CREATE_SPRINT_MODAL",
  START_SPRINT_MODAL: "START_SPRINT_MODAL",
  CLOSE_SPRINT_MODAL: "CLOSE_SPRINT_MODAL",
  CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
  STATUSES_MODAL: "STATUSES_MODAL",
  BUGS_MODAL: "BUGS_MODAL",
  SUBTASKS_MODAL: "SUBTASKS_MODAL",
  DISCUSSION_MODAL: "DISCUSSION_MODAL",
  CREATE_DISCUSSION_MODAL: "CREATE_DISCUSSION_MODAL",
  ADD_VERSION_MODAL: "ADD_VERSION_MODAL",
  DOCUMENT_VERSION_MODAL: "DOCUMENT_VERSION_MODAL",
  GANTT_SAVE_DISCARD_MODAL: "GANTT_SAVE_DISCARD_MODAL",
  CONTENT_IMAGE_MODAL: "CONTENT_IMAGE_MODAL",
  EDIT_BUG_ADHOC_MODAL: "EDIT_BUG_ADHOC_MODAL",
  MANAGE_SPRINTS_MODAL: "MANAGE_SPRINTS_MODAL",
  PROJECT_TEMPLATE_MODAL: "PROJECT_TEMPLATE_MODAL",
  IMAGE_MODAL: "IMAGE_MODAL",
  CREATE_SECTION_FROM_WBS: "CREATE_SECTION_FROM_WBS",
  SEARCH_PROJECT_MODAL: "SEARCH_PROJECT_MODAL",
};

export default ModalComponent;
