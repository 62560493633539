import React from "react";

import { useAbility } from "@casl/react";
import Loader from "components/Loader";
import { AbilityContext } from "components/RoleManagement/Can";
import { useTrackedState, useDispatch, ACTIONS } from "context";
import { auth } from "firebaseSetup";
import get from "lodash.get";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { useTourDispatch } from "tourContext";
import { TOUR_STAGES, useTourTrackedState } from "tourContext";
import { isSandbox } from "utils/isSandboxEnv";

import { getProjectData } from "../../api";
import { MOCK_MEMBERS } from "../../constants";
import ShareOption from "../Header/ShareOption";
import ReactModal, { MODAL_NAMES } from "../Modal/Modal";
import SidebarNav from "../SidebarNav";

const ProjectLayout = ({ children, noPadding, closeModal }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  // const tourDispatch = useTourDispatch();

  const {
    activeProjectId,
    loadedProjects,
    loadedMembers,
    loadingMembers,
    activeProjectPendingUsers,
    selectedItemProjectId,
    selectedItemId,
    sidebarVisible,
    // editorLoaded,
  } = state;

  const history = useHistory();
  const { projectId } = useParams();
  const tourState = useTourTrackedState();
  const location = useLocation();
  const currentUserId = isSandbox()
    ? MOCK_MEMBERS.SANDBOX_MEMBER
    : get(auth, "currentUser.uid", false);
  const ability = useAbility(AbilityContext);
  // Close sidebar if active project id is different from active item's project id
  React.useEffect(() => {
    if (
      selectedItemId &&
      selectedItemProjectId !== projectId &&
      sidebarVisible &&
      !isSandbox
    ) {
      dispatch({
        type: ACTIONS.SHOW_ITEM_DETAILS,
        payload: {
          selectedItemId: null,
          sidebarVisible: false,
        },
      });
    }
  }, [
    selectedItemProjectId,
    selectedItemId,
    dispatch,
    sidebarVisible,
    projectId,
  ]);

  React.useEffect(() => {
    if (!ability || !activeProjectId || !currentUserId || !loadedProjects) {
      return;
    }
    // We update abilities here since the projectlayout component only wraps those views which are bound to a certain project
    function updateAbility(ability, userId) {
      const projMember = loadedProjects[activeProjectId]?.members[userId];
      if (projMember) {
        ability.update(projMember.permissions);
      }
    }
    updateAbility(ability, currentUserId);
  }, [ability, activeProjectId, currentUserId, loadedProjects]);

  React.useEffect(() => {
    // If this is the active project already do NOT fetch the data again
    if (activeProjectId && activeProjectId === projectId) return;

    if (!currentUserId && !isSandbox) {
      // User not logged in
      return;
    }

    const onAccessDenied = () => {
      toast.error(
        "Access denied. Please contact the project owner in order to get an invitation."
      );
      history.push(`/`);
    };

    getProjectData({ projectId, dispatch, onAccessDenied });

    dispatch({
      type: ACTIONS.SET_ACTIVE_PROJECT,
      payload: { projectId },
    });
  }, [
    activeProjectId,
    dispatch,
    projectId,
    currentUserId,
    history,
    // tourDispatch,
  ]);

  if (!activeProjectId || Object.keys(loadedProjects).length === 0) {
    return (
      <div className="w-full min-h-screen pt-32">
        <Loader size="md" className="mx-auto" />
      </div>
    );
  }

  const activeProject = get(loadedProjects, activeProjectId);
  const ownerUserId = get(activeProject, "owner", null);
  const hasRevokeRights = ownerUserId === currentUserId;

  const projectMembers = Object.values(get(activeProject, "members", []))
    .filter((member) => member.id in loadedMembers)
    .map((member) => loadedMembers[member.id]);
  return (
    <div
      className={`absolute inset-0 flex ${
        tourState.tourStage === TOUR_STAGES.FINISHED &&
        isSandbox() &&
        location.pathname !== "/"
          ? "mt-24"
          : "mt-14"
      }`}
    >
      <SidebarNav className="w-24 bg-white border-r" />
      <div className={"flex-grow overflow-x-auto"}>{children}</div>

      <ReactModal name={MODAL_NAMES.SHARE_MODAL}>
        <ShareOption
          members={projectMembers}
          pendingUsers={activeProjectPendingUsers}
          hasRevokeRights={hasRevokeRights}
          closeModal={closeModal}
          project={activeProject}
          loading={loadingMembers}
          loadedProjects={loadedProjects}
          activeProjectId={activeProjectId}
        />
      </ReactModal>
    </div>
  );
};

export default ProjectLayout;
