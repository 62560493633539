import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as HistoryIcon } from "assets/history-24px.svg";
import { MODAL_NAMES } from "components/Modal/Modal";
import Subtasks from "components/Subtasks/Subtasks";
import ItemHistory from "components/TaskSidebar/ItemHistory";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import isEqual from "lodash/isEqual";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { getURL } from "utils/getAppURL";
import { canUserEditMeta } from "utils/permissions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { updateProjectItem, getItemData, getItemHistory } from "../../api";
import { ReactComponent as BackIcon } from "../../assets/arrow_back.svg";
import { ReactComponent as CopyIcon } from "../../assets/copyToClipboard.svg";
import { ReactComponent as EditIcon } from "../../assets/create-24px.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/description_icon.svg";
import { ReactComponent as HideIcon } from "../../assets/icons-hide.svg";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setMetadataWorkEstimate,
  setMetadataDoneEstimate,
  setMetadataPriority,
  setLabels,
  ITEM_TYPES,
} from "../../utils/metadataModel";
import Assignee from "../Assignee";
import Date from "../Date";
import Labels from "../Labels";
import Priority from "../Priority";
import Sprint from "../Sprint";
import Status from "../Status";
import Bugs from "./Bugs/Bugs";
import DetailsContent from "./DetailsContent";
import Discussions from "./Discussions/Discussions";

const TaskSidebar = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const {
    selectedItemId,
    selectedItemProjectId,
    projectItems,
    activeProjectId,
    sidebarVisible,
  } = state;

  const [metadata, setMetadata] = useState(null);
  const [sidebarCollapsed, setSidebarCollapse] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [itemHistory, setItemHistory] = useState([]);
  const [historyVisible, setHistoryVisible] = useState(false);
  const textAreaRef = useRef(null);

  const location = useLocation();
  const history = useHistory();

  // Metadata sanitizing
  const assignee = metadata ? metadata.assignee : null;

  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const sprint = metadata ? metadata.sprint : null;
  const workEstimate =
    metadata && metadata.workEstimate ? metadata.workEstimate : 0;
  const doneEstimate =
    metadata && metadata.doneEstimate ? metadata.doneEstimate : 0;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;
  const htmlContent =
    metadata && metadata.htmlContent ? metadata.htmlContent : "";
  const featureHtmlContent =
    metadata && metadata.featureHtmlContent
      ? metadata.featureHtmlContent
      : null;
  const title = metadata && metadata.title ? metadata.title : "";

  function setAndUpdateMetadata(metadata) {
    setMetadata(metadata);

    updateProjectItem({
      dispatch: dispatch,
      itemId: selectedItemId,
      projectId: activeProjectId,
      projectItems: projectItems,
      data: { ...metadata },
    });
  }

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    toast.success("You copied the item URL to clipboard");
  }

  function openDocumentSection(itemId) {
    if (!location.pathname.includes("/editor")) {
      history.push(`/project/${activeProjectId}/editor`);
    }
    setTimeout(() => {
      dispatch({
        type: ACTIONS.SELECT_ITEM_TO_SCROLL,
        payload: itemId,
      });
    }, 0);
  }

  useEffect(() => {
    if (sidebarVisible) {
      setTabIndex(0);
    }
  }, [sidebarVisible]);

  useEffect(() => {
    if (!selectedItemId) {
      setMetadata(null);
      // Stop fetching from DB early
      return;
    }
    // Check if this item is a part of all active items in currenttly
    // selected project's items
    const _metadata = projectItems.find((pItem) => pItem.id === selectedItemId);

    if (_metadata) {
      setMetadata({ ..._metadata });
      return;
    }

    // If metadata does not exist fetch the item metadata from db
    getItemData(selectedItemId, (itemMetadata) => {
      if (itemMetadata) {
        setMetadata({ ...itemMetadata });
      }
    });
  }, [selectedItemId, projectItems]);

  useEffect(() => {
    setItemHistory(null);
  }, [htmlContent]);

  function setStatus(status) {
    setAndUpdateMetadata(setMetadataStatus(metadata, status));
  }
  function setAssignee(assignee) {
    setAndUpdateMetadata(setMetadataAssignee(metadata, assignee));
  }

  function setMetaDueDate(date) {
    setAndUpdateMetadata(setMetadataDueDate(metadata, date));
  }

  function setMetaStartDate(date) {
    setAndUpdateMetadata(setMetadataStartDate(metadata, date));
  }

  function setMetaSprint(sprint) {
    setAndUpdateMetadata(setMetadataSprint(metadata, sprint));
  }

  function setMetaWorkEstimate(estimate) {
    setAndUpdateMetadata(setMetadataWorkEstimate(metadata, estimate));
  }
  function setMetaDoneEstimate(estimate) {
    setAndUpdateMetadata(setMetadataDoneEstimate(metadata, estimate));
  }

  function setMetaPriority(priority) {
    setAndUpdateMetadata(setMetadataPriority(metadata, priority));
  }

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labelIds) {
    setAndUpdateMetadata(setLabels(metadata, labelIds));
  }

  const toggleSidebarVisibility = () => {
    setSidebarCollapse(!sidebarCollapsed);
  };

  const status = metadata ? metadata.status : null;
  return metadata && sidebarVisible ? (
    <div
      id="taskSidebar"
      className={`flex flex-col text-nearBlack ${
        !sidebarVisible ? "w-0" : "w-1/3"
      } fixed shadow-md right-0 top-14 bottom-0 min-h-full mt-0.5 bg-white z-40 transition-all ease-in-expo duration-300 overflow-x-visible`}
    >
      <div
        className={`overflow-y-scroll  max-h-full border-l border-gray1 relative ${
          !sidebarVisible ? "opacity-0 duration-50" : "opacity-1 duration-2000"
        } transition-all ease-in-expo `}
      >
        <div
          onClick={() => {
            dispatch({
              type: ACTIONS.SHOW_ITEM_DETAILS,
              payload: {
                selectedItemId: null,
                sidebarVisible: !sidebarVisible,
              },
            });
            state.activeModal !== "" &&
              dispatch({
                type: ACTIONS.CLOSE_MODAL,
              });
          }}
          className="sticky top-0 z-10 flex items-center justify-start mb-6 bg-white cursor-pointer px-8"
        >
          <div className="flex items-center justify-center w-5 h-5 p-1 border rounded border-darkBlue hover:border-chillBlue group">
            <ReactTooltip />
            <HideIcon
              data-tip="Hide sidebar"
              data-place="left"
              className="w-full stroke-current text-darkBlue hover:text-chillBlue group-hover:text-chillBlue"
            />
          </div>
        </div>
        <div>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <div className="sticky top-5 z-10 items-center justify-start mb-6 bg-white ">
              <TabList>
                <div className="flex justify-between px-8">
                  <Tab>{"Info"}</Tab>
                  <Tab>{"Discussions"}</Tab>
                  <Tab>{"History"}</Tab>
                </div>
              </TabList>
            </div>
            <TabPanel className="px-8">
              <div className="flex items-center justify-start mb-6 my-10">
                <h3 className="max-w-xs text-2xl font-bold">{title}</h3>
                <div className="ml-auto">
                  <div className="flex items-center justify-center">
                    <button
                      className="flex items-center justify-center ml-auto"
                      onClick={copyToClipboard}
                    >
                      <ReactTooltip />
                      <CopyIcon
                        data-tip="Copy the URL to this item to clipboard"
                        className="cursor-pointer fill-current hover:text-chillBlue"
                      />
                    </button>
                    {metadata.itemType !== ITEM_TYPES.BUG &&
                      metadata.itemType !== ITEM_TYPES.ADHOC &&
                      metadata.itemType !== ITEM_TYPES.SUBTASK && (
                        <button
                          className="flex items-center justify-center ml-auto"
                          onClick={() => openDocumentSection(selectedItemId)}
                        >
                          <ReactTooltip />
                          <DescriptionIcon
                            data-tip="Open document section for this Feature"
                            className="cursor-pointer fill-current hover:chillBlue"
                          />
                        </button>
                      )}
                  </div>
                  <form>
                    <textarea
                      readOnly
                      aria-hidden="true"
                      className="opacity-0 offscreen"
                      ref={textAreaRef}
                      value={getURL(
                        `/project/${activeProjectId}/editor?deepLinkItemId=${selectedItemId}`
                      )}
                    />
                  </form>
                </div>
                {metadata.itemType === ITEM_TYPES.BUG ||
                  (metadata.itemType === ITEM_TYPES.SUBTASK && (
                    <button
                      onClick={() => {
                        dispatch({
                          type: ACTIONS.SHOW_ITEM_DETAILS,
                          payload: {
                            selectedItemId: state.projectItems.find(
                              (pi) => pi.id === metadata.featureItemId
                            ).id,
                            sidebarVisible: true,
                          },
                        });
                      }}
                    >
                      <ReactTooltip />
                      <BackIcon
                        data-tip="Return to the item in which this bug was created"
                        className="cursor-pointer fill-current hover:text-chillBlue"
                      />
                    </button>
                  ))}
              </div>
              <div>
                <div className="flex items-center justify-end w-full">
                  {canUserEditMeta() && metadata.itemType === "BUG" && (
                    <button
                      className="flex items-center justify-center w-5 h-5 mb-1 border rounded border-darkBlue hover:border-chillBlue"
                      onClick={() =>
                        dispatch({
                          type: ACTIONS.OPEN_MODAL,
                          payload: {
                            name: MODAL_NAMES.BUGS_MODAL,
                            activeModalData: { isEditing: true },
                          },
                        })
                      }
                    >
                      <EditIcon className="w-4 fill-current text-darkBlue hover:text-chillBlue" />
                    </button>
                  )}{" "}
                  {canUserEditMeta() && metadata.itemType === "ADHOC" && (
                    <button
                      className="flex items-center justify-center w-5 h-5 mb-1 border rounded border-linkBlue2"
                      onClick={() =>
                        dispatch({
                          type: ACTIONS.OPEN_MODAL,
                          payload: {
                            name: MODAL_NAMES.ADHOC_ITEM_MODAL,
                            activeModalData: { isEditing: true },
                          },
                        })
                      }
                    >
                      <EditIcon className="w-4 fill-current text-linkBlue2" />
                    </button>
                  )}
                </div>

                <div className="mb-8 prose relative w-full sidebar-content">
                  <div className="w-full flex items-center justify-between">
                    {metadata.itemType && (
                      <h3 id="taskDescription" className="taskDescription">
                        Task description
                      </h3>
                    )}
                    {canUserEditMeta() && metadata.itemType === "SUBTASK" && (
                      <button
                        className="flex items-center justify-center w-5 h-5 mb-1 border rounded border-darkBlue hover:border-chillBlue"
                        onClick={() =>
                          dispatch({
                            type: ACTIONS.OPEN_MODAL,
                            payload: {
                              name: MODAL_NAMES.SUBTASKS_MODAL,
                              activeModalData: {
                                isEditing: true,
                                selectedItemId,
                                selectedItemProjectId,
                              },
                            },
                          })
                        }
                      >
                        <EditIcon className="w-4 fill-current text-darkBlue hover:text-chillBlue" />
                      </button>
                    )}
                  </div>
                  {htmlContent ? (
                    <DetailsContent
                      htmlContent={htmlContent}
                      editorState={state.editorState}
                      isOwnContent
                    />
                  ) : (
                    <p className="text-sm text-midGray">
                      There is no task description yet.
                    </p>
                  )}
                </div>
                <div>
                  <div className="mb-6">
                    <p className="text-xl font-semibold mb-6">
                      Task related content
                    </p>
                    {/* FIXME, HARDCODED! */}
                    {/* <div>
            <span className="text-midGray text-xs">1.1.</span>
            <h2 className="text-base "> Improvements to editor</h2>
          </div> */}
                  </div>
                  {metadata.itemType !== ITEM_TYPES.BUG &&
                    metadata.itemType !== ITEM_TYPES.ADHOC &&
                    metadata.itemType !== ITEM_TYPES.SUBTASK && (
                      <Bugs status={status} />
                    )}
                  {metadata.itemType !== ITEM_TYPES.BUG &&
                    metadata.itemType !== ITEM_TYPES.ADHOC &&
                    metadata.itemType !== ITEM_TYPES.SUBTASK && (
                      <Subtasks status={status} />
                    )}
                  <p className="text-base">Description</p>
                  <div className="mb-8 prose relative w-full sidebar-content">
                    {featureHtmlContent ? (
                      <DetailsContent
                        htmlContent={featureHtmlContent}
                        editorState={state.editorState}
                        isFeature
                      />
                    ) : (
                      <p className="text-sm text-midGray">
                        There is no task description yet.
                      </p>
                    )}
                  </div>
                </div>
                <h4 className="mt-6 mb-8 sidebar-section-title">Summary</h4>
                {metadata.itemType && (
                  <div>
                    <Status status={status} setMetaStatus={setStatus} />
                    <Assignee
                      assignee={assignee}
                      setMetaAssignee={setAssignee}
                    />
                  </div>
                )}
                <Date
                  metaDueDate={dueDate}
                  date={startDate}
                  setMetaDate={setMetaStartDate}
                  label="Start date"
                />
                <Date
                  metaStartDate={startDate}
                  date={dueDate}
                  setMetaDate={setMetaDueDate}
                  label="Due date"
                />
                {metadata.itemType && (
                  <Sprint sprint={sprint} setMetaSprint={setMetaSprint} />
                )}
                {/* <WorkEstimate
              estimate={workEstimate}
              type="workEstimate"
              label="Work Estimate"
              setMetaEstimate={setMetaWorkEstimate}
            />
            <WorkEstimate
              estimate={doneEstimate}
              type="doneEstimate"
              label="Done Estimate"
              setMetaEstimate={setMetaDoneEstimate}
            /> */}
                {metadata.itemType && (
                  <Priority
                    priority={priority}
                    type="priority"
                    label="Priority"
                    setMetaPriority={setMetaPriority}
                  />
                )}
                {metadata.itemType && (
                  <Labels
                    labels={labels}
                    type="labels"
                    label="Labels"
                    setMetaLabels={setMetaLabels}
                  />
                )}
              </div>
              <hr className="mt-2 mb-10" />
            </TabPanel>
            <TabPanel>
              <Discussions />
            </TabPanel>
            <TabPanel className="px-8">
              <div className="pb-6">
                <h4 className="flex justify-between mt-10 mb-4 text-sm font-light text-gray2">
                  <span className="sidebar-section-title">History</span>
                  <span>
                    <button
                      className="flex btn btn-utility"
                      onClick={() => {
                        if (!historyVisible) {
                          if (!itemHistory) {
                            getItemHistory(metadata.id, (itemHistory) => {
                              setItemHistory(itemHistory);
                              setHistoryVisible(true);
                            });
                          }
                          setHistoryVisible(true);
                          return;
                        }

                        setHistoryVisible(false);
                      }}
                    >
                      <HistoryIcon className="w-4 h-4 mr-2 fill-current" />{" "}
                      {!historyVisible ? "Show history" : "Hide history"}
                    </button>
                  </span>
                </h4>
                <ItemHistory
                  itemHistory={itemHistory}
                  historyVisible={historyVisible}
                  currentContent={htmlContent}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  ) : null;
};

export default TaskSidebar;
