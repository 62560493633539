import React, { Suspense } from "react";

import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import { MODAL_NAMES } from "components/Modal/Modal";
import { AbilityContext, Can } from "components/RoleManagement/Can";
import ability from "components/RoleManagement/defineAbility";
import { SandboxStrip } from "components/Tour/SandboxStrip";
import { useDispatch, ACTIONS } from "context";
import TagManager from "react-gtm-module";
import { PrerenderedComponent } from "react-prerendered-component";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import "./index.css";
import { toast } from "react-toastify";

import { PERMISSIONS_ACTIONS, PERMISSIONS_SUBJECTS } from "../src/constants";
import Header from "./components/Header";
import Login from "./components/Login";
import ModalsContainer from "./components/Modal/ModalsContainer";
import ProjectEditor from "./components/ProjectEditor";
import ProjectLayout from "./components/ProjectLayout";
import TaskSidebar from "./components/TaskSidebar";
import OnboardingTour from "./components/Tour/OnboardingTour";
import { auth } from "./firebaseSetup";

const prerenderedLoadable = (dynamicImport) => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo((props) => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const WBS = prerenderedLoadable(() => import("./components/WBS"));
const Kanban = prerenderedLoadable(() => import("./routes/Kanban"));
const Gantt = prerenderedLoadable(() => import("./components/Gantt"));
const ProjectSettings = prerenderedLoadable(() =>
  import("./components/ProjectSettings/ProjectSettings")
);

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH || undefined,
    preview: process.env.REACT_APP_GTM_PREVIEW || undefined,
  };

  TagManager.initialize(tagManagerArgs);
}

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";

  /**
   * Moving Header outside route makes it render at all times, we don't want that.
   * This is not a good way of solving this, will have to re-asses to whole
   * application layout issues
   */
  return (
    <React.Fragment>
      <SandboxStrip />
      <Header isDashboard={isDashboard} />
      <Route
        {...rest}
        render={({ location }) => (
          <React.Fragment>
            {children}

            <TaskSidebar />
            <ModalsContainer />
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

function SandboxApp() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: ACTIONS.OPEN_MODAL,
      payload: {
        name: MODAL_NAMES.WELCOME_MODAL,
      },
    });

    amplitude.getInstance().logEvent("open sandbox");
  }, [dispatch]);

  return (
    <Suspense fallback={<div></div>}>
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <AbilityContext.Provider value={ability}>
          <OnboardingTour />
          <Switch>
            <PrivateRoute exact path="/sandbox/:projectId/editor">
              <ProjectLayout>
                <ProjectEditor className="w-full" />
              </ProjectLayout>
            </PrivateRoute>
            <PrivateRoute path="/sandbox/:projectId/kanban">
              <ProjectLayout>
                <section>
                  <Kanban />
                </section>
              </ProjectLayout>
            </PrivateRoute>
            <PrivateRoute path="/sandbox/:projectId/gantt/">
              <ProjectLayout>
                <section className="w-full">
                  <Gantt />
                </section>
              </ProjectLayout>
            </PrivateRoute>
            <PrivateRoute path="/sandbox/:projectId/wbs">
              <ProjectLayout>
                <section className="w-full">
                  <WBS />
                </section>
              </ProjectLayout>
            </PrivateRoute>
            <PrivateRoute path="/sandbox/:projectId/settings/">
              <ProjectLayout>
                <section className="w-full">
                  <Can
                    I={PERMISSIONS_ACTIONS.MANAGE}
                    a={PERMISSIONS_SUBJECTS.PROJECT_SETTINGS}
                  >
                    <ProjectSettings />
                  </Can>
                  <Can
                    not
                    I={PERMISSIONS_ACTIONS.MANAGE}
                    a={PERMISSIONS_SUBJECTS.PROJECT_SETTINGS}
                  >
                    Sorry, you dont have the permission to be here
                  </Can>
                </section>
              </ProjectLayout>
            </PrivateRoute>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </AbilityContext.Provider>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
}

function FallbackComponent() {
  toast.error(
    "There was an issue with your last action. The issue has been reported and our busy bees are already looking into it. If the issue persists please contact support@jadealm.com for a more immediate action."
  );

  /**
   * When an uncaught error boundary happens the whole React tree is deleted
   * so rerouting counts for nothing :(
   *
   * So we reload after 3 seconds.
   */
  if (process.env.NODE_ENV === "production") {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  return <Redirect to="/dashboard" />;
}

export default Sentry.withErrorBoundary(SandboxApp, {
  fallback: FallbackComponent,
});
