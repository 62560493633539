export const BLOCKTYPES = {
  HEADER_ONE: "HEADER_ONE",
  HEADER_TWO: "HEADER_TWO",
  HEADER_THREE: "HEADER_THREE",
  HEADER_FOUR: "HEADER_FOUR",
  HEADER_FIVE: "HEADER_FIVE",
  HEADER_SIX: "HEADER_SIX",
  BLOCKQUOTE: "BLOCKQUOTE",
  CODE_BLOCK: "CODE_BLOCK",
  UNORDEDED_LIST_ITEM: "UNORDEDED_LIST_ITEM",
  ORDERED_LIST_ITEM: "ORDERED_LIST_ITEM",
  UNSTYLED: "UNSTYLED",
  EMBEDDER: "EMBEDDER",
  TABLE_CELL: "TABLE_CELL",
  TABLE_GRID: "TABLE_GRID",
};

export const defaultDraftBlockTypes = {
  [BLOCKTYPES.HEADER_ONE]: "header-one",
  [BLOCKTYPES.HEADER_TWO]: "header-two",
  [BLOCKTYPES.HEADER_THREE]: "header-three",
  [BLOCKTYPES.HEADER_FOUR]: "header-four",
  [BLOCKTYPES.HEADER_FIVE]: "header-five",
  [BLOCKTYPES.HEADER_SIX]: "header-six",
  [BLOCKTYPES.BLOCKQUOTE]: "blockquote",
  [BLOCKTYPES.CODE_BLOCK]: "code-block",
  [BLOCKTYPES.UNORDEDED_LIST_ITEM]: "unordered-list-item",
  [BLOCKTYPES.ORDERED_LIST_ITEM]: "ordered-list-item",
  [BLOCKTYPES.BLOCKQUOTE]: "blockquote",
  [BLOCKTYPES.UNSTYLED]: "unstyled",
  // Custom block types
  [BLOCKTYPES.EMBEDDER]: "embedder-type",
  [BLOCKTYPES.TABLE_CELL]: "table-cell-type",
  [BLOCKTYPES.TABLE_GRID]: "table-grid-type",
};
