import React from "react";

import Discussion from "./Discussion";

const DiscussionContainer = ({ id, name }) => {
  return (
    <div className="w-full h-full mx-auto">
      <Discussion id={id} name={name} />
    </div>
  );
};

export default DiscussionContainer;
