import React from "react";

import { useDispatch, ACTIONS, useTrackedState } from "context";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import Modal, { MODAL_NAMES } from "./Modal";
const WelcomeModal = () => {
  const dispatch = useDispatch();

  const state = useTrackedState();
  return (
    <Modal name={MODAL_NAMES.IMAGE_MODAL} style={{ width: "860px" }}>
      <CloseIcon
        onClick={() => {
          dispatch({
            type: ACTIONS.CLOSE_MODAL,
          });
        }}
        className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer"
      />
      <div className="w-full h-full flex justify-center items-center">
        <img src={state.activeModalData.image} alt="" />
      </div>
    </Modal>
  );
};

export default WelcomeModal;
