import React, { useState } from "react";

import UserAvatar from "components/UserAvatar";
import { useTrackedState } from "context";
import { Parser } from "html-to-react";
import get from "lodash.get";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import { ReactComponent as ReplyIcon } from "assets/reply.svg";
import { ReactComponent as LikeIcon } from "assets/like-icon.svg";

import { isSandbox } from "utils/isSandboxEnv";

import { formatDateTime } from "../../../utils/date";

const htmlToReactParser = new Parser();

const Message = ({ text, id, user, htmlContent, msgDate, source }) => {
  const state = useTrackedState();
  const { loadedMembers } = state;

  const [htmlContentSlice, setEditorState] = useState(htmlContent);
  // const [replyInput, setReplyInput] = useState(false);

  React.useEffect(() => {
    setEditorState(htmlContent);
  }, [htmlContent]);

  const msgToDate = msgDate && msgDate.toDate();
  var reactElement = htmlToReactParser.parse(htmlContentSlice);

  const senderName = get(loadedMembers, `${user}.name`, "N/A");
  const senderImg = get(loadedMembers, `${user}.img`, "N/A");

  return (
    <div className="flex mt-3 border border-gray-100 shadow-sm rounded-lg px-4 py-2">
      <div className="flex flex-col w-full mt-2">
        <div className="flex justify-between">
          <div className="flex inline">
            <div className="w-5 h-5 mr-2 rounded-full">
              <UserAvatar img={!isSandbox() ? senderImg : source} />
            </div>
            <span className="text-sm flex-none text-gray-600">
              {!isSandbox() ? senderName : "Sandbox User"}
            </span>
          </div>
          <span className="text-center w-48 text-sm text-gray-600">
            {msgToDate && formatDateTime(msgToDate, "dd MMM yyyy, kk:mm")}
          </span>
        </div>
        <div className="mt-4">
          <span className="text-sm">{reactElement}</span>
        </div>
        {/* <div className="items-center flex justify-between">
          <ReplyIcon onClick={() => setReplyInput(true)} />
          <LikeIcon />
        </div> */}
      </div>
    </div>
  );
};

export default Message;
