import {
  useQuickStartDispatch,
  useQuickStartState,
  QUICK_START_ACTIONS,
} from "quickStartContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";

import { auth } from "../firebaseSetup";
import { quickStartAdd } from "../quickStartApi";

function useQuickStartAction() {
  const [user] = useAuthState(auth);

  const dispatch = useQuickStartDispatch();
  const state = useQuickStartState();

  return (action) => {
    if (state?.solvedSteps && !state.solvedSteps.includes(action)) {
      quickStartAdd(action, user.uid);

      dispatch({
        type: QUICK_START_ACTIONS.FINISH_STEP,
        payload: action,
      });
      dispatch({
        type: QUICK_START_ACTIONS.TOGGLE_QUICK_START,
        payload: true,
      });

      toast.success(
        "You have succesfully completed a quick start step. Congratulations!"
      );
    }
  };
}

export default useQuickStartAction;
