import React from "react";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { ReactComponent as DropdownArrow } from "assets/arrow_right.svg";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as PriorityIcon } from "assets/priority_icon.svg";
import PrioIcon from "components/PrioIcon/PrioIcon";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState } from "context";
import {
  getLoadedProjectData,
  getProjectDataByKey,
} from "utils/loadedProjects";
import { canUserEditMeta } from "utils/permissions";

import { PERMISSIONS_SUBJECTS, PERMISSIONS_ACTIONS } from "../../constants";

import "@reach/combobox/styles.css";

const Priority = (props) => {
  const state = useTrackedState();
  const { loadedProjects, selectedItemProjectId } = state;
  const { priority, setMetaPriority } = props;
  const [prioBlockOpen, setPrioBlockOpen] = React.useState(false);

  const priorities =
    getProjectDataByKey(
      getLoadedProjectData(loadedProjects, selectedItemProjectId),
      "priority"
    ) ?? [];

  return (
    <div className="sidebar-item">
      <p className="w-3/12 font-normal text-darkBlue">Priority</p>
      {!priorities ? (
        <b>None available</b>
      ) : prioBlockOpen ? (
        <Combobox
          openOnFocus
          aria-labelledby="demo"
          onSelect={(selectedItem) => {
            setMetaPriority(
              priorities.find((priority) => priority.label === selectedItem)
            );
            setPrioBlockOpen(false);
          }}
          onBlur={() => setTimeout(() => setPrioBlockOpen(false), 200)}
        >
          <ComboboxInput
            autoFocus
            placeholder="Select priority"
            className="sidebar-item-input"
          />
          <ComboboxPopover style={{ zIndex: "60", borderColor: "transparent" }}>
            <ComboboxList className="sidebar-item-dropdown-list">
              {priorities.map((priority, index) => (
                <ComboboxOption
                  key={index}
                  value={`${priority.label}`}
                  className="sidebar-item-dropdown-option"
                >
                  <div className="flex items-center">
                    <div className="mr-2">
                      <PrioIcon number={priority.number} />
                    </div>
                    {priority.label}
                  </div>
                </ComboboxOption>
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      ) : !priority ? (
        <span
          className="flex items-center sidebar-item-selection sidebar-item-selection--empty btn-transition"
          onClick={() => {
            canUserEditMeta() && setPrioBlockOpen(!prioBlockOpen);
          }}
        >
          <PriorityIcon className="mr-2 fill-current stroke-current text-grayBlue" />{" "}
          Choose priority
          <DropdownArrow className="ml-auto mr-1 transform rotate-90 fill-current text-grayBlue" />
        </span>
      ) : (
        <div className="flex items-center">
          <div
            className="flex items-center sidebar-item-selection sidebar-item-selection--filled btn-transition"
            onClick={() => {
              canUserEditMeta() && setPrioBlockOpen(!prioBlockOpen);
            }}
          >
            <PriorityIcon className="mr-2 fill-current stroke-current text-chillBlue" />{" "}
            {priority.label}
            <Can
              I={PERMISSIONS_ACTIONS.MANAGE}
              a={PERMISSIONS_SUBJECTS.METADATA}
            >
              <ClearIcon
                className="w-5 h-5 p-1 ml-auto bg-gray-400 border border-transparent rounded-full cursor-pointer hover:border-darkBlue"
                onClick={(e) => {
                  e.stopPropagation();
                  setMetaPriority(null);
                }}
              />
            </Can>
          </div>
        </div>
      )}
    </div>
  );
};

export default Priority;
