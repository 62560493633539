export function getLoadedProjectData(loadedProjects, projectId) {
  if (!loadedProjects || !projectId) return;
  try {
    return loadedProjects[projectId];
  } catch (error) {
    return;
  }
}

export function getProjectDataByKey(projectObj, key) {
  if (!projectObj || !key) return;

  try {
    return projectObj[key];
  } catch (error) {
    return;
  }
}