import React from "react";

import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";
import { ACTIONS } from "context";
import {
  EditorBlock,
  Modifier,
  EditorState,
  ContentBlock,
  genKey,
  ContentState,
  SelectionState,
} from "draft-js";
import { Map } from "immutable";
import { camelCase } from "lodash";
import { createPortal } from "react-dom";
import ReactTooltip from "react-tooltip";

const CustomTableComponent = (props) => {
  const insertRow = (rowPos) => {
    const incrementBlocksPositions = () => {
      const initialBlocksArray = initialContentState.getBlocksAsArray();

      //Increment rows position
      // Blocks that come after the position of new row
      const blocksForIncrement = initialBlocksArray.filter(
        (block) =>
          block.getData().get("tableKey") === tableKey &&
          block.getData().get("tablePosition").split("-")[1] > rowPos
      );

      blocksForIncrement.forEach((block, i) => {
        const newTablePos = block.getData().get("tablePosition").split("-");
        newTablePos.splice(
          1,
          1,
          parseInt(block.getData().get("tablePosition").split("-")[1]) + 1
        );

        if (i === 0) {
          updatedContentState = Modifier.mergeBlockData(
            props.blockProps.editorState.getCurrentContent(),
            SelectionState.createEmpty(block.getKey()),
            Map({ tablePosition: newTablePos.join("-") })
          );
        } else {
          updatedContentState = Modifier.mergeBlockData(
            updatedContentState,
            SelectionState.createEmpty(block.getKey()),
            Map({
              tablePosition: newTablePos.join("-"),
            })
          );
        }
      });
    };

    const updateTableShape = () => {
      tableShape.splice(rowPos + 1, 0, { ...tableShape[1] });
      newContentState = Modifier.mergeBlockData(
        updatedContentState ? updatedContentState : initialContentState,
        selectionState,
        Map({
          tableShape: tableShape,
        })
      );

      newEditorState = EditorState.push(
        props.blockProps.editorState,
        newContentState,
        "change-block-data"
      );

      props.blockProps.setEditorState(newEditorState);
    };
    let newBlocks = [];
    const tableShape = props.block.getData().get("tableShape");
    const tableKey = props.block.getData().get("tableKey");
    const selectionState = SelectionState.createEmpty(props.block.getKey());

    let initialContentState = props.blockProps.editorState.getCurrentContent();

    let updatedContentState = null;
    let newEditorState;
    let newContentState;
    // Iterate trough all the blocks which come after the inserted row and increment their y coordinate (i) by 1
    incrementBlocksPositions();

    //Update tableShape
    updateTableShape();

    //Adding new blocks
    Object.values(tableShape[rowPos])[0].forEach((cell, j) => {
      let data = Map({
        tableKey,
        tablePosition: `${tableKey}-${rowPos + 1}-${j}`,
        "text-align": "center",
        newCell: true,
      });

      const newBlock = new ContentBlock({
        key: genKey(),
        type: "table-cell-type",
        text: " ",
        data,
      });

      newBlocks.push(newBlock);
    });

    const blockArray = newContentState.getBlocksAsArray();

    const currBlock = newContentState.getBlockForKey(
      selectionState.getAnchorKey()
    );
    const index = blockArray.findIndex((block) => block === currBlock);

    newBlocks.push(new ContentBlock({ key: genKey() }));

    blockArray.splice(index + 1, 0, ...newBlocks);

    // Update editor
    const entityMap = newContentState.getEntityMap();
    newContentState = ContentState.createFromBlockArray(blockArray, entityMap);
    newEditorState = EditorState.push(
      props.blockProps.editorState,
      newContentState,
      "change-block-data"
    );

    const key = newBlocks[0].getKey();
    const selection = SelectionState.createEmpty(key);

    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    // Wrapping this in setTimeout since we want this dispatch to wait for the previous one in update tableShape
    setTimeout(() => {
      props.blockProps.setEditorState(newEditorState);
    }, 0);
  };

  // if this is not the first table block, then we target the <td> element in the applicable position
  // to render the current block into.

  if (
    props.block.getData().get("tablePosition") &&
    !props.block.getData().get("tableShape")
  ) {
    const position = props.block.getData().get("tablePosition");
    const target = document.querySelector(`[data-position='${position}']`);
    if (target) {
      return createPortal(<EditorBlock {...props} />, target);
    }

    /**
     * If we get here then the target wasn't in the DOM yet. The reset and paste/insert-related
     * functions use blur() and focus() to trigger a rerender, at which time the DOM will have been
     * updated with the framework of the table structure.
     */
    return null;
  }
  // If we get here we know we are rendering the first block of the table and will render the whole DOM structure of the table
  const data = props.block.getData();
  const tableKey = data.get("tableKey");
  const tableShape = data.get("tableShape");
  const colgroup = data.get("tableColgroup");

  if (Array.isArray(tableShape)) {
    return (
      <table key={tableKey} id={tableKey} className="w-11/12">
        {colgroup && (
          <colgroup dangerouslySetInnerHTML={{ __html: colgroup }}></colgroup>
        )}
        <tbody>
          {tableShape.map((row, i) => (
            <tr
              key={i}
              css={
                data.get("rowStyle")[i] &&
                Map(data.get("rowStyle")[i])
                  .mapKeys((k) => camelCase(k))
                  .toJS()
              }
              className="relative group border-none"
            >
              <td
                data-row-position={`${tableKey}-${i}`}
                className="w-8 rounded-tl-sm border-none relative opacity-0 group-hover:opacity-100"
              >
                <div
                  onClick={() => {
                    insertRow(i);
                  }}
                  className="flex justify-center items-center w-5 h-5 rounded-full text-white bg-green1 absolute bottom-0 ml-2 -mb-1 cursor-pointer"
                >
                  <ReactTooltip />
                  <PlusIcon
                    data-tip="Insert a new row"
                    className="fill-current text-white w-1/2"
                  />
                </div>
              </td>

              {Object.values(row)[0].map((cell, j) => {
                const cellStyle = Map(cell.style)
                  .mapKeys((k) => camelCase(k))
                  .toJS();
                if (cell.element === "th") {
                  return (
                    <th
                      key={j}
                      className="mb-1 p-1 border bg-darkBlue text-white border-white z-100"
                      colSpan={cell.colspan}
                      rowSpan={cell.rowspan}
                      data-position={`${tableKey}-${i}-${j}`}
                    >
                      {!!((i === 0) & (j === 0)) && <EditorBlock {...props} />}
                    </th>
                  );
                }
                return (
                  <td
                    key={j}
                    style={cellStyle}
                    colSpan={cell.colspan}
                    rowSpan={cell.rowspan}
                    data-position={`${tableKey}-${i}-${j}`}
                  >
                    {!!((i === 0) & (j === 0)) && <EditorBlock {...props} />}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <EditorBlock {...props} />;
  }
};

export default CustomTableComponent;
