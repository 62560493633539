import React from "react";

import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import { ReactComponent as CrossIcon } from "assets/cross_icon.svg";
import { ReactComponent as LogoIcon } from "assets/logo_no_text.svg";
import { useDispatch, ACTIONS } from "context";

import Modal, { MODAL_NAMES } from "./Modal";

const ProjectTemplateModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal name={MODAL_NAMES.PROJECT_TEMPLATE_MODAL} style={{ width: "860px" }}>
      <div className={`flex flex-col w-full h-full mx-auto`}>
        <div className="w-full px-8 py-4 border-b border-grayBorder">
          <h2 className="text-2xl font-bold text-nearBlack">
            Choose project template
          </h2>
          <div className="flex">
            <CloseIcon
              onClick={() => {
                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                });
              }}
              className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            />
          </div>
        </div>

        <div className="flex justify-center items-center gap-4 h-full">
          <div className="column-center w-1/2">
            <div
              onClick={() => {
                dispatch({
                  type: ACTIONS.OPEN_MODAL,
                  payload: {
                    name: MODAL_NAMES.CREATE_PROJECT_MODAL,
                    activeModalData: { blankProject: true },
                  },
                });
              }}
              className="w-32 h-32 flex justify-center items-center shadow-sm rounded-lg border border-grayBorder hover:border-linkBlue cursor-pointer"
            >
              <CrossIcon />
            </div>
            <h3 className="font-bold text-2xl text-primaryText mt-4">
              Blank project
            </h3>
            <p className="text-sm mt-2">Start from scratch</p>
          </div>
          <div className="column-center w-1/2">
            <div
              onClick={() => {
                dispatch({
                  type: ACTIONS.OPEN_MODAL,
                  payload: {
                    name: MODAL_NAMES.CREATE_PROJECT_MODAL,
                    activeModalData: { blankProject: false },
                  },
                });
              }}
              className="w-32 h-32 flex justify-center items-center bg-white shadow-sm rounded-lg border border-grayBorder hover:border-linkBlue cursor-pointer"
            >
              <LogoIcon className="w-full" />
            </div>
            <h3 className="font-bold text-2xl text-primaryText mt-4">
              Example project
            </h3>
            <p className="text-sm mt-2">
              Edit and organize existing tasks to your liking
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectTemplateModal;
