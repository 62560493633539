import React, { useState, useEffect } from "react";

import { MODAL_NAMES } from "components/Modal/Modal";
import { useDispatch, ACTIONS } from "context";
import {
  QUICK_START_STEPS,
  useQuickStartState,
  QUICK_START_ACTIONS,
  useQuickStartDispatch,
} from "quickStartContext";

import { ReactComponent as CheckIcon } from "../../assets/circle-check_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as EllipseIcon } from "../../assets/ellipse.svg";
import { ReactComponent as BackIcon } from "../../assets/icons-hide.svg";
import QuickStartStep from "./QuickStartStep";

const QuickStart = () => {
  const [itemOpen, setItemOpen] = useState(false);
  const quickStartState = useQuickStartState();
  const { solvedSteps, toggled, selectedStep } = quickStartState;
  const quickStartDispatch = useQuickStartDispatch();
  const dispatch = useDispatch();

  const steps = [
    {
      title: "Create project",
      slug: QUICK_START_STEPS.CREATE_PROJECT,
      description:
        "Since JadeALM is a tool which helps you organize your projects it would be a problem helping you with a non existing project. Why don't you create one?",
      solved: false,
      function: () => {
        dispatch({
          type: ACTIONS.OPEN_MODAL,
          payload: {
            name: MODAL_NAMES.PROJECT_TEMPLATE_MODAL,
          },
        });
      },
    },
    {
      title: "Create a new header",
      slug: QUICK_START_STEPS.CREATE_HEADER,
      description:
        "Now that we have our project set up it is the right time to make a first step toward utilising JadeALM functionalities. Once in editor, try writing a new header by selecting it from the menu by writing the '/' (slash). ",
      solved: false,
    },
    {
      title: "Create a task",
      slug: QUICK_START_STEPS.CREATE_TASK,
      description:
        "Headers in our document can become very powerful in a moment with single click. By creating a task from header all of a sudden we have a timeline entry, kanban card and more. Try creating a task from your header by clicking 'Create' below the header in editor. ",
      solved: false,
    },
    {
      title: "Invite user",
      slug: QUICK_START_STEPS.INVITE_USER,
      description:
        "Getting new people to work with you on your project adds another layer of complexity. Also, if done right, new team members help you do things faster and better. Let's try inviting new team members.",
      solved: false,
      function: () => {
        dispatch({
          type: ACTIONS.OPEN_MODAL,
          payload: {
            name: MODAL_NAMES.SHARE_MODAL,
          },
        });
      },
    },
  ];

  useEffect(() => {
    if (!selectedStep) return;
    setItemOpen(true);
  }, [selectedStep]);

  useEffect(() => {
    if (!toggled) {
      setItemOpen(false);
    }
  }, [toggled]);

  return (
    <div
      className={`${
        toggled ? "fixed" : "hidden"
      } right-0 top-0 w-64 mt-16 mr-2 rounded-md shadow-md overflow-visible z-50`}
    >
      <QuickStartStep
        itemOpen={itemOpen}
        itemSelected={selectedStep}
        steps={steps}
        solvedSteps={solvedSteps}
      />
      <div
        className={`relative text-white bg-darkBlue flex flex-col items-start pt-8 px-4 pb-3 rounded-t-md transition-all duration-300 z-50 overflow-y-hidden ${
          itemOpen ? "h-16" : "h-32 delay-300"
        }`}
      >
        <div className="flex">
          <CloseIcon
            onClick={() => {
              setItemOpen(false);
              quickStartDispatch({
                type: QUICK_START_ACTIONS.TOGGLE_QUICK_START,
                payload: false,
              });
            }}
            className="absolute top-0 right-0 w-4 h-4 mt-4 mr-4 cursor-pointer text-white fill-current"
          />
        </div>

        <p
          onClick={() => {
            setItemOpen(false);
          }}
          className={`flex items-center absolute top-0 mt-8 text-sm cursor-pointer transition-opacity duration-200 ${
            itemOpen ? "opacity-1 z-50" : "opacity-0"
          }`}
        >
          <BackIcon className="stroke-current text-white transform rotate-180 mr-2" />
          Back to list
        </p>

        <div
          className={`absolute top-0 mt-8 transition-opacity duration-200 ${
            itemOpen ? "opacity-0" : "opacity-1 z-50"
          }`}
        >
          {" "}
          <h5 className="text-xl text-white font-semibold mb-2">Quick start</h5>
          <p className="text-sm">
            Here are 10 things to help you get started with JadeALM.
          </p>{" "}
        </div>
      </div>
      <div className="relative text-darkBlue">
        <ul className="flex flex-col bg-white py-3 rounded-b-md">
          {steps.map((step) => {
            return (
              <li
                onClick={() => {
                  setItemOpen(true);
                  quickStartDispatch({
                    type: QUICK_START_ACTIONS.SELECT_STEP,
                    payload: step.slug,
                  });
                }}
                className="px-4 py-3 hover:bg-gray2 cursor-pointer flex items-center"
              >
                {solvedSteps && solvedSteps?.includes(step.slug) ? (
                  <CheckIcon className="w-4 h-4 mr-3" />
                ) : (
                  <EllipseIcon className="w-4 h-4 mr-3 stroke-current text-darkBlue" />
                )}
                {step.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default QuickStart;
