import React from "react";

import { ReactComponent as SandboxIcon } from "assets/sandbox_icon.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  TOUR_STAGES,
  useTourTrackedState,
  useTourDispatch,
  TOUR_ACTIONS,
} from "tourContext";
import { isSandbox } from "utils/isSandboxEnv";

import { auth } from "../../firebaseSetup";

export const SandboxStrip = () => {
  const [user] = useAuthState(auth);
  const userLoggedIn = user && !user.isAnonymous;

  const tourState = useTourTrackedState();
  const location = useLocation();
  const tourDispatch = useTourDispatch();
  let history = useHistory();

  if (
    tourState.tourStage !== TOUR_STAGES.FINISHED ||
    !isSandbox() ||
    location.pathname === "/"
  ) {
    return null;
  }

  return (
    <div className="flex items-center justify-center w-full py-1 text-sm text-white bg-chillBlue">
      <span className="flex items-center">
        <SandboxIcon className="mr-2" /> You are currently in the sandbox mode.
      </span>
      <Link
        to="/"
        style={{ backgroundColor: "#0C6CB9" }}
        className="px-3 py-1 mx-3 transition-transform duration-300 ease-in-out transform rounded-lg hover:scale-105"
      >
        {userLoggedIn ? "Exit Sandbox" : "Set up on the Free Plan"}
      </Link>
      <span> or</span>
      <button
        onClick={() => {
          history.push("/sandbox/sandboxId/editor");
          tourDispatch({
            type: TOUR_ACTIONS.TOGGLE_TOUR,
            payload: {
              tourStage: TOUR_STAGES.IN_PROGRESS,
            },
          });
        }}
        style={{ backgroundColor: "#0C6CB9" }}
        className="px-3 py-1 mx-3 transition-transform duration-300 ease-in-out transform rounded-lg hover:scale-105"
      >
        Start tour
      </button>
    </div>
  );
};
