import React, { useState, useEffect } from "react";

import amplitude from "amplitude-js";
import { ReactComponent as CreateIcon } from "assets/plus-icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash-icon.svg";
import Loader from "components/Loader";

import classnames from "classnames";
import DocumentVersionModal from "components/Modal/DocumentVersionModal";
import { MODAL_NAMES } from "components/Modal/Modal";
import UserAvatar from "components/UserAvatar";
import { useTrackedState, useDispatch, ACTIONS } from "context";
// import debounce from "lodash.debounce";
import Scrollbars from "react-custom-scrollbars";
import { scrollParentToValue } from "utils/scrolling";

import { removeDocumentVersion, getDocumentVersionById } from "../../api";
import DraftEditor from "../DraftEditor";
// import Breadcrumbs from "../DraftEditor/components/Breadcrumbs/Breadcrumbs";
import TOC from "../TOC";
import { scrollParentToChild } from "utils/scrolling";

function AppHeading() {
  const state = useTrackedState();
  const [nameVal, setNameVal] = React.useState("");

  React.useEffect(() => {
    if (!state.loadedProjects) return;

    setNameVal(state.loadedProjects[state.activeProjectId].name);
  }, [state.activeProjectId, state.loadedProjects]);

  return (
    <div className="columnd-center">
      {state.loadedProjects &&
        state.loadedProjects[state.activeProjectId].name && (
          <div className="flex items-start justify-start w-full mb-10 text-4xl font-black">
            <h4>{nameVal}</h4>
          </div>
        )}
    </div>
  );
}

const Versions = ({ loadedProjects }) => {
  const state = useTrackedState();
  const [selectedVersionId, setSelectedVersionId] = useState("");

  const dispatch = useDispatch();

  const versions = loadedProjects
    ? loadedProjects[state.activeProjectId].versions
    : null;

  const loadedVersions = state.loadedVersions || {};

  const onSuccess = () => {
    dispatch({
      type: ACTIONS.OPEN_MODAL,
      payload: {
        name: MODAL_NAMES.DOCUMENT_VERSION_MODAL,
      },
    });
  };

  const openVersionModal = (versionId) => {
    setSelectedVersionId(versionId);
    const data = {
      versionId,
      projectId: state.activeProjectId,
      dispatch,
      onSuccessCb: onSuccess,
    };
    loadedVersions[versionId] ? onSuccess() : getDocumentVersionById(data);
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-between pr-8">
        <h4 className="my-4 text-xs text-grayBlue">DOCUMENT VERSIONS</h4>

        <button
          onClick={() => {
            dispatch({
              type: ACTIONS.OPEN_MODAL,
              payload: {
                name: MODAL_NAMES.ADD_VERSION_MODAL,
              },
            });
          }}
          className="p-2 border rounded-lg cursor-pointer text-darkBlue shadow-sidebarItem border-grayBorder transition duration-300 hover:border-darkBlue focus:outline-none"
        >
          <CreateIcon className="fill-current text-chillBlue" />
        </button>
      </div>
      {versions ? (
        <div className="overflow-y-auto ">
          <div>
            {versions.map((version) => {
              return (
                <div
                  onClick={() => openVersionModal(version.versionId)}
                  className="flex items-center justify-between w-full px-1 py-2 pr-8 cursor-pointer group hover:bg-gray-100"
                  key={version.versionId}
                >
                  <p className="text-sm text-left text-grayBlue">
                    {version.label}
                  </p>
                  <button
                    className="hidden w-5 h-5 group-hover:block"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch({
                        type: ACTIONS.OPEN_MODAL,
                        payload: {
                          name: MODAL_NAMES.CONFIRMATION_MODAL,
                          activeModalData: {
                            message:
                              "Are you sure you want to delete this document version?",
                            modalTitle: "Delete Document Version",
                            action: () => {
                              removeDocumentVersion({
                                projectId: state.activeProjectId,
                                versions,
                                versionId: version.versionId,
                                dispatch,
                              });
                            },
                          },
                        },
                      });
                      setSelectedVersionId(version.versionId);
                    }}
                  >
                    <TrashIcon className="h-4 fill-current" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <p className="mt-2 text-sm text-grayBlue">
          No versions for now.{" "}
          <a
            onClick={() => {
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.ADD_VERSION_MODAL,
                },
              });
            }}
            className="text-linkBlue"
            href="#"
          >
            Create one
          </a>
        </p>
      )}

      <DocumentVersionModal version={loadedVersions[selectedVersionId]} />
    </React.Fragment>
  );
};
const ProjectEditor = () => {
  const state = useTrackedState();
  const dispatch = useDispatch();

  const scrollbar = React.useRef(null);
  const {
    loadedProjects,
    editorLoaded,
    editorBlocksPosition,
    editorMembers,
    editorUsersCount,
    isEditorMaster,
    projectItems,
    deepLinkItemId,
    itemToScroll,
    globalSearchFilter,
  } = state;
  const [focusedHeader, setFocusedHeader] = useState();

  // FIXME - BREADCRUMBS
  // const [queriedHeaders, setQueriedHeaders] = useState([]);

  // const getHeaders = () => {
  //   setQueriedHeaders([
  //     ...document.querySelectorAll(
  //       ".DraftEditor-editorContainer h1, .DraftEditor-editorContainer h2, .DraftEditor-editorContainer h3, .DraftEditor-editorContainer h4, .DraftEditor-editorContainer h5, .DraftEditor-editorContainer h6"
  //     ),
  //   ]);
  // };

  useEffect(() => {
    amplitude.getInstance().logEvent("document view");
  }, []);

  // useEffect(() => {
  //   getHeaders();
  // }, []);

  // useEffect(() => {
  //   dispatch({
  //     type: ACTIONS.SET_EDITOR_BLOCKS_POSITION,
  //     payload: queriedHeaders.map((header) => {
  //       return {
  //         headerKey: header.dataset.offsetKey.split("-")[0],
  //         position: header.getBoundingClientRect().top,
  //       };
  //     }),
  //   });
  // }, [dispatch, queriedHeaders]);

  useEffect(() => {
    if (editorBlocksPosition.length === 0) return;

    if (focusedHeader) {
      const filteredHeaders = editorBlocksPosition.filter(
        (block) => block.position > 110 && block.position < 330
      );

      if (filteredHeaders.length > 0) {
        setFocusedHeader(filteredHeaders[0].headerKey);
      } else {
        return;
      }
    } else {
      setFocusedHeader(editorBlocksPosition[0].headerKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBlocksPosition]);

  useEffect(() => {
    if (!deepLinkItemId) return;

    scrollParentToValue(
      scrollbar,
      editorBlocksPosition.find(
        (item) =>
          item.headerKey ===
          projectItems.find((pItem) => pItem.id === deepLinkItemId)?.blockId
      )?.position
    );

    dispatch({
      type: ACTIONS.CLEAR_DEEPLINK,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deepLinkItemId, projectItems]);

  function scrollHeaderToView() {
    const element = document.querySelectorAll(
      `h1[data-offset-key='${globalSearchFilter.bucketKey}-0-0'], h2[data-offset-key='${globalSearchFilter.bucketKey}-0-0'], h3[data-offset-key='${globalSearchFilter.bucketKey}-0-0'], h4[data-offset-key='${globalSearchFilter.bucketKey}-0-0'], h5[data-offset-key='${globalSearchFilter.bucketKey}-0-0'], h6[data-offset-key='${globalSearchFilter.bucketKey}-0-0']`
    );

    if (element.length) {
      scrollParentToChild(scrollbar, element[0]);
    }
  }
  useEffect(() => {
    setTimeout(() => scrollHeaderToView(), 100);
  }, [globalSearchFilter]);

  useEffect(() => {
    if (!itemToScroll) return;

    scrollParentToValue(
      scrollbar,
      editorBlocksPosition.find(
        (item) =>
          item.headerKey ===
          projectItems.find((pItem) => pItem.id === itemToScroll)?.blockId
      )?.position,
      true
    );
    dispatch({
      type: ACTIONS.SELECT_ITEM_TO_SCROLL,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToScroll]);

  // const debouncedGetHeaders = debounce(getHeaders, 500);

  return (
    <div className="w-full">
      {editorLoaded ? (
        <section className="flex w-full">
          <div className="w-3/12 pt-6 pl-6 bg-white border-r-2 border-gray2">
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMin={`calc(100vh - 5rem)`}
            >
              <AppHeading />
              <TOC scrollbar={scrollbar} />
              <Versions loadedProjects={loadedProjects} />
            </Scrollbars>
          </div>
          <div className="w-9/12 pt-6 pl-6 bg-gray1">
            {editorUsersCount > 1 && (
              <div className="w-9/12">
                <div className="sticky top-0 z-10 flex justify-end bg-gray1">
                  {editorMembers.map((member) => {
                    const imgCss = classnames({
                      "border-4": isEditorMaster,
                      "border-green-300": isEditorMaster,
                    });

                    return (
                      <div className="w-8 h-8 pr-2" key={member.id}>
                        <UserAvatar
                          img={member.img}
                          className={imgCss}
                        ></UserAvatar>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="w-11/12 pt-4 px-4 bg-white editor-document xl:w-9/12 rounded-xl text-center ">
              {/* 
                DISABLE BREADCRUMBS FOR NOW. NOT WORKING WITH NEW PAGE CONCEPT 
                AND STILL NOT CLEAR HOW THEY WILL BE SHOWN 
              */}

              {/* <Breadcrumbs
                focusedHeader={focusedHeader}
                scrollbar={scrollbar}
                queriedHeaders={queriedHeaders}
              /> */}
              <Scrollbars
                ref={scrollbar}
                // onScroll={() => {
                //   dispatch({
                //     type: ACTIONS.SET_EDITOR_BLOCKS_POSITION,
                //     payload: queriedHeaders.map((header) => {
                //       return {
                //         headerKey: header.dataset.offsetKey.split("-")[0],
                //         position: header.getBoundingClientRect().top,
                //       };
                //     }),
                //   });
                // }}
                autoHide
                autoHeight
                autoHeightMin={`calc(100vh - 5rem)`}
              >
                <DraftEditor /*>getHeaders={() => debouncedGetHeaders()}*/ />
              </Scrollbars>
            </div>
          </div>
        </section>
      ) : (
        <div className="w-full min-h-screen pt-16 mt-2 pr-20">
          <Loader size="md" className="mx-auto" />
        </div>
      )}
    </div>
  );
};

export default ProjectEditor;
