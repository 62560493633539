import { db } from "./firebaseSetup";
import { QUICK_START_ACTIONS } from "./quickStartContext";

export function getQuickStartSolvedSteps(userId, quickStartDispatch) {
  db.collection("users")
    .doc(userId)
    .get()
    .then((doc) => {
      const documentData = doc.data();
      if (!documentData.hasOwnProperty("quickStartActions")) {
        db.collection("users")
          .doc(userId)
          .update({
            quickStartActions: [],
          })
          .then((documentData.quickStartActions = []));
      }
      quickStartDispatch({
        type: QUICK_START_ACTIONS.SET_STEPS,
        payload: documentData.quickStartActions,
      });
    });
}

export function quickStartAdd(action, userId) {
  db.collection("users")
    .doc(userId)
    .get()
    .then((doc) => {
      const documentData = doc.data();
      if (!documentData?.quickStartActions.includes(action)) {
        db.collection("users")
          .doc(userId)
          .update({
            quickStartActions: [...documentData?.quickStartActions, action],
          })
          .then(() => {});
      }
    });
}
