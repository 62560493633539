import React, { useState, useEffect } from "react";

import Editor from "@draft-js-plugins/editor";
import Loader from "components/Loader";
import { useDispatch, useTrackedState } from "context";
import { EditorState } from "draft-js";
import Scrollbars from "react-custom-scrollbars";
import { useAuthState } from "react-firebase-hooks/auth";

import { sendMessage, getMessages } from "../../../api";
import { auth } from "../../../firebaseSetup";
import { createEditorStateHTMLContent } from "../../../utils/editor";
import createPlugins from "../../DraftEditor/plugins/createPlugins";
import Message from "./Message";

const Discussion = ({ name, id, discussionExists }) => {
  const [workingEditorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const [htmlContent, setHtmlContent] = useState(undefined);

  function onChange(newState) {
    setEditorState(newState);

    setHtmlContent(createEditorStateHTMLContent(newState.getCurrentContent()));

    setMessage(htmlContent);
  }

  const pluginsRef = React.useRef(createPlugins());
  const { editorPlugins, instances } = pluginsRef.current;
  const plugins = [...editorPlugins];
  const { StaticToolbarComponent } = instances;

  const [user] = useAuthState(auth);
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { selectedItemId, discussionMessages, itemDiscussions } = state;
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessages(discussionMessages);
  }, [discussionMessages]);
  useEffect(() => {
    setLoading(false);
  }, [messages]);
  useEffect(() => {
    if (!selectedItemId) {
      return;
    }
    setLoading(true);
    getMessages(selectedItemId, id, dispatch);
  }, [dispatch, id, selectedItemId]);

  const handleSendMessage = () => {
    if (!workingEditorState.getCurrentContent().hasText()) {
      return;
    }
    sendMessage(selectedItemId, id, user?.uid, message, dispatch);

    setEditorState(EditorState.createEmpty());
  };
  const source = user?.photoURL;
  return (
    <div className="flex flex-col items-center justify-between h-full py-6">
      <h6 className="w-full py-2 text-2xl px-8">{name}</h6>
      {loading ? (
        <div className="w-2/12 p-2 ml-10">
          <Loader size="sm" className="mx-auto" />
        </div>
      ) : (
        <ul className="w-full h-full my-4 overflow-y-scroll border-gray5 px-8">
          {messages.length > 0 &&
            messages.map((msg, index) => {
              return (
                <li key={index}>
                  <Message
                    source={source}
                    text={msg.text}
                    id={msg.id}
                    user={msg.user}
                    msgDate={msg.createdTs}
                    htmlContent={msg.htmlContent}
                  />
                </li>
              );
            })}
        </ul>
      )}

      <div className="w-full">
        {itemDiscussions.length ? (
          <div className="items-center w-full mt-2">
            <div className="border-b shadow"></div>
            <div className="w-full p-2 mr-2 border-b">
              <Scrollbars
                autoHide
                autoHeight
                autoHeightMin={`calc(100% - 5rem)`}
              >
                <div class="h-24">
                  <Editor
                    placeholder="Add a comment..."
                    editorState={workingEditorState}
                    onChange={onChange}
                    plugins={plugins}
                    activeProjectId={state.selectedItemProjectId}
                  />
                </div>
              </Scrollbars>
            </div>
            <div className="flex items-center justify-between p-4">
              <StaticToolbarComponent />

              <button
                onClick={() => {
                  handleSendMessage();
                }}
                className="btn btn-primary w-20 ml-1"
              >
                Comment
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Discussion;
