import React from "react";

import { useDispatch, ACTIONS, useTrackedState } from "context";
import { useHistory } from "react-router-dom";
import { useTourDispatch, TOUR_ACTIONS, TOUR_STAGES } from "tourContext";
import { isSandbox } from "utils/isSandboxEnv";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as WelcomeIcon } from "../../assets/welcome_icon.svg";
import Modal, { MODAL_NAMES } from "./Modal";
const WelcomeModal = () => {
  const dispatch = useDispatch();
  const tourDispatch = useTourDispatch();
  const state = useTrackedState();
  let history = useHistory();

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      name={MODAL_NAMES.WELCOME_MODAL}
      style={{ width: "860px" }}
    >
      <div className="relative flex flex-col items-center justify-around w-full h-full pt-16 pb-8 text-center">
        <CloseIcon
          onClick={() => {
            dispatch({
              type: ACTIONS.CLOSE_MODAL,
            });
            tourDispatch({
              type: TOUR_ACTIONS.TOGGLE_TOUR,
              payload: {
                tourStage: TOUR_STAGES.FINISHED,
              },
            });
          }}
          className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer fill-current text-gray2 hover:text-nearBlack"
        />
        <div>
          <WelcomeIcon />
        </div>
        <h3 className="mt-6 text-2xl font-bold text-nearBlack">
          Welcome to JadeALM!
        </h3>
        <p className="mt-6 text-lg">
          Let’s get started. Explore our quick guide and see how <br />
          JadeALM can boost your development process.
        </p>

        <button
          onClick={() => {
            history.push(
              `${
                isSandbox()
                  ? `/sandbox/sandboxId/editor`
                  : `/project/${state.activeProjectId}/editor`
              }`
            );
            tourDispatch({
              type: TOUR_ACTIONS.TOGGLE_TOUR,
              payload: {
                tourStage: TOUR_STAGES.IN_PROGRESS,
              },
            });
            dispatch({
              type: ACTIONS.CLOSE_MODAL,
            });
          }}
          className="relative w-auto px-8 py-4 mt-8 text-base btn btn-primary"
        >
          Start the tour
        </button>

        <button
          className="mt-4"
          onClick={() => {
            dispatch({
              type: ACTIONS.CLOSE_MODAL,
            });
            tourDispatch({
              type: TOUR_ACTIONS.TOGGLE_TOUR,
              payload: {
                tourStage: TOUR_STAGES.FINISHED,
              },
            });
          }}
        >
          No thanks, I'll do it later
        </button>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
