import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
  SelectionState,
  CharacterMetadata,
  ContentBlock,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { getSelectedBlocksMap } from "draftjs-utils";
import Immutable from "immutable";
import immutableDiff from "immutablediff";
import immutablePatch from "immutablepatch";
import isEmpty from "lodash.isempty";
import { toast } from "react-toastify";
import { isHeaderBlock } from "utils/draft";
import { CHANGE_TYPE_INSERT_SECTION } from "utils/editor/draft-utils";

import {
  BLOCKTYPES,
  defaultDraftBlockTypes,
} from "../components/DraftEditor/draftConstants";

const HEADER_ONE = defaultDraftBlockTypes[BLOCKTYPES.HEADER_ONE];
const HEADER_TWO = defaultDraftBlockTypes[BLOCKTYPES.HEADER_TWO];
const HEADER_THREE = defaultDraftBlockTypes[BLOCKTYPES.HEADER_THREE];
const HEADER_FOUR = defaultDraftBlockTypes[BLOCKTYPES.HEADER_FOUR];
const HEADER_FIVE = defaultDraftBlockTypes[BLOCKTYPES.HEADER_FIVE];
const HEADER_SIX = defaultDraftBlockTypes[BLOCKTYPES.HEADER_SIX];

const headerLevels = {
  [HEADER_ONE]: 1,
  [HEADER_TWO]: 2,
  [HEADER_THREE]: 3,
  [HEADER_FOUR]: 4,
  [HEADER_FIVE]: 5,
  [HEADER_SIX]: 6,
};

export const HEADER_LEVELS_SORTED_ARRAY = [
  HEADER_ONE,
  HEADER_TWO,
  HEADER_THREE,
  HEADER_FOUR,
  HEADER_FIVE,
  HEADER_SIX,
];
/**
 * Force draft editor render by faking selection state change and return the
 * new EditorState.
 *
 * @param {EditorState} editorState
 */
export function forceEditorRender(editorState) {
  const newEditorState = EditorState.forceSelection(
    editorState,
    editorState.getSelection()
  );
  return newEditorState;
}

export function getContentBlockData(contentBlock) {
  return contentBlock ? contentBlock.data.toJS() || {} : {};
}

/**
 * Take the conditions and filter out the blocks that don't apply to those conditions
 *
 * @param {*} rawContentState
 * @param {*} conditionsApplied
 *
 * @returns {ContentState} New content state with blocks filtered out that don't
 *                         match the conditions selected
 */
export function applyConditionsToRawContentState(
  rawContentState,
  projectItems,
  conditionsApplied
) {
  if (conditionsApplied.length === 0) {
    return rawContentState;
  }

  // Here, merge blocks metadata with the block in question and check if there
  // are any conditions applied to it
  const blocks = rawContentState.blocks.filter((block) =>
    conditionsApplied.some(
      (condition) =>
        projectItems[block.key] &&
        projectItems[block.key].conditions &&
        projectItems[block.key].conditions.includes(condition)
    )
  );

  const newRawContentState = { ...rawContentState, blocks };

  return newRawContentState;
}

/**
 *
 * @param {*} parentChildBlocks Normalized editor state to parent-child blocks tree
 * @param {*} headerKey          The ID of the header to find
 */
export function findHeaderBlockInTree(parentChildBlocks, headerKey) {
  for (let i = 0; i < parentChildBlocks.length; i++) {
    if (parentChildBlocks[i].key === headerKey) {
      return parentChildBlocks[i];
    }

    if (parentChildBlocks[i].children) {
      const found = findHeaderBlockInTree(
        parentChildBlocks[i].children,
        headerKey
      );
      if (found) return found;
    }
  }
}

/**
 *
 * @param {*} previousBlockType
 * @param {*} currentBlockType
 *
 * @returns -1 if parent, 0 if same, 1 if child
 */
function getHeaderBlockRelation(previousBlockType, currentBlockType) {
  // Blocks are the same level
  if (previousBlockType === currentBlockType) return 0;

  const headers = {
    [HEADER_ONE]: [
      HEADER_TWO,
      HEADER_THREE,
      HEADER_FOUR,
      HEADER_FIVE,
      HEADER_SIX,
    ],
    [HEADER_TWO]: [HEADER_THREE, HEADER_FOUR, HEADER_FIVE, HEADER_SIX],
    [HEADER_THREE]: [HEADER_FOUR, HEADER_FIVE, HEADER_SIX],
    [HEADER_FOUR]: [HEADER_FIVE, HEADER_SIX],
    [HEADER_FIVE]: [HEADER_SIX],
    [HEADER_SIX]: [],
  };

  if (headers[previousBlockType].includes(currentBlockType)) {
    // This is a child of the previous block
    return 1;
  }

  // Lastly, this is a parent of a previous block
  return -1;
}

/**
 * Create a list of header blocks from the node all the way up to the last parent (h1)
 * @param {*} headerBlocks
 * @param {*} headerBlock
 */
function createBranchFromTree(headerBlocks, headerBlock) {
  const branch = [];

  function parse(headerBlocks, headerBlock) {
    const parent = findHeaderBlockInTree(headerBlocks, headerBlock.parentId);

    branch.push(parent);

    if (parent.parentId) {
      parse(headerBlocks, parent);
    }
  }

  parse(headerBlocks, headerBlock);

  return branch;
}

function findBlockByKey(blocks, key) {
  if (!key) return blocks;

  let foundIt;

  for (let block of blocks) {
    if (block.key === key) {
      foundIt = block;
      break;
    }

    if (block.children.length > 0) {
      foundIt = findBlockByKey(block.children, key);
    }
  }

  return foundIt;
}

/**
 *
 * @param {Array} headerBlocksArray List of the current blocks parsed in a hierarchical structure
 * @param {Object} lastHeaderBlockAdded Last block added to headerBlocks, will let determine what branch we are in
 * @param {Object} newHeaderBlock Current block we are trying to find a parent for
 */
function findParentInTree(
  headerBlocksArray,
  lastHeaderBlockAdded,
  newHeaderBlock
) {
  // 1. find the branch we are in
  const branch = createBranchFromTree(headerBlocksArray, lastHeaderBlockAdded);

  // 2. Find the first parent in the branch that is above the newBlock.type level.
  // e.g. if last block added is 'header-five' and newBlock is 'header-three'
  // find the first 'header-two' in this branch

  /**
   *
   * @param {array} branch Array of the branch we want to update
   * @param {string} headerType 'header-one', 'header-two' etc
   */
  function findParentInBranch(branch, blockLevel) {
    const parentLevel = --blockLevel;

    if (parentLevel === 0) {
      // Something is wrong
      return;
    }

    const parent = branch.find((b) => b.level === parentLevel);

    if (parent) {
      return parent;
    }
    return findParentInBranch(branch, parentLevel);
  }

  const parent = findParentInBranch(branch, headerLevels[newHeaderBlock.type]);

  return parent;
}

function getHeaderNumbersCloj() {
  let h1 = 0;
  let h2 = 0;
  let h3 = 0;
  let h4 = 0;
  let h5 = 0;
  let h6 = 0;

  return function (headerType) {
    let res = null;

    switch (headerType) {
      case HEADER_ONE:
        res = `${++h1}.`;

        h2 = 0;
        h3 = 0;
        h4 = 0;
        h5 = 0;
        h6 = 0;
        break;
      case HEADER_TWO:
        res = `${h1}.${++h2}.`;

        h3 = 0;
        h4 = 0;
        h5 = 0;
        h6 = 0;
        break;
      case HEADER_THREE:
        res = `${h1}.${h2}.${++h3}.`;

        h4 = 0;
        h5 = 0;
        h6 = 0;
        break;
      case HEADER_FOUR:
        res = `${h1}.${h2}.${h3}.${++h4}.`;

        h5 = 0;
        h6 = 0;
        break;
      case HEADER_FIVE:
        res = `${h1}.${h2}.${h3}.${h4}.${++h5}.`;

        h6 = 0;
        break;
      case HEADER_SIX:
        res = `${h1}.${h2}.${h3}.${h4}.${h5}.${++h6}.`;
        h6++;
        break;

      default:
        break;
    }

    return res;
  };
}

/**
 *
 * @param {*} blocks
 * @param {*} projectItems
 */
export function normalizeRawBlocksToParentChild(blocks, projectItems) {
  const getHeaderNumbers = getHeaderNumbersCloj();

  if (!blocks) {
    throw new Error("Provide blocks!");
  }

  if (!projectItems) {
    throw new Error("Provide projectItems");
  }
  // TODO - Reselect/memoize this guy to prevent unnecessary expensive recomputation if the
  // blocks don't change.
  // Parent, Child, Sibling, Descendant, Ancestor

  /**
   * Blocks that come before the first found Header element are dissregarded.
   * This enables the user to add any content that they don't want to be actionable
   * like document explanation, summary, whatever
   *
   * @param {array} blocks
   */
  function parse(blocks, projectItems) {
    const headerBlocks = [];
    const _projectItems = [...projectItems];
    const _blocks = [...blocks];

    let firstBlockAdded = false;
    let lastBlockAdded = null;

    // Use 'for' loop cause it's a fast MOFO
    for (let i = 0; i < _blocks.length; i++) {
      let headerBlockType = _blocks[i].type.includes("header");
      const projectItemMetadata = _projectItems.find(
        (pItem) => pItem.blockId === _blocks[i].key
      );

      if (!firstBlockAdded && headerBlockType) {
        let _block = {
          children: [],
          content: [],
          ..._blocks[i],
          level: 1,
          projectItemMetadata,
          headerLevel: getHeaderNumbers(_blocks[i].type),
        };
        headerBlocks.push(_block);

        firstBlockAdded = true;
        lastBlockAdded = _block;
        continue;
      }

      if (headerBlocks.length === 0) {
        // We still don't have a first header block
        continue;
      }

      // If block is NOT of type header-* it goes into the 'content' of the last block
      if (!headerBlockType) {
        lastBlockAdded.content.push(_blocks[i]);
        continue;
      }

      /**
       * header-one is the top level blockType, no parent possible
       */
      if (_blocks[i].type === HEADER_ONE) {
        let _block = {
          children: [],
          content: [],
          ..._blocks[i],
          level: 1,
          projectItemMetadata,
          headerLevel: getHeaderNumbers(_blocks[i].type),
        };
        headerBlocks.push(_block);
        lastBlockAdded = _block;
        continue;
      }

      /**
       * First block added, is next block child or parent or same relation. If the
       * second block is of a higher 'relation' than the previous block, make the
       * second block the same relation as the previous block, that is downgrade it.
       *
       * This is a naive solution not to break the hierarchy that is top-down.
       * This is to be reviewed but don't want to be blocked by it now.
       *
       */

      let relation = getHeaderBlockRelation(
        lastBlockAdded.type,
        _blocks[i].type
      );

      let newBlock = null;

      switch (relation) {
        case -1:
          // This block is a higher level than the previous block. Find the parent
          // block and add it as a child

          const parentLevelAbove = findParentInTree(
            headerBlocks,
            lastBlockAdded,
            {
              ..._blocks[i],
            }
          );

          if (parentLevelAbove) {
            newBlock = {
              parentId: parentLevelAbove.key,
              children: [],
              content: [],
              ..._blocks[i],
              level: headerLevels[_blocks[i].type],
              projectItemMetadata,
              headerLevel: getHeaderNumbers(_blocks[i].type),
            };

            parentLevelAbove.children.push(newBlock);
          } else {
            newBlock = {
              children: [],
              content: [],
              ..._blocks[i],
              level: headerLevels[_blocks[i].type],
              projectItemMetadata,
              headerLevel: getHeaderNumbers(_blocks[i].type),
            };

            headerBlocks.push(newBlock);
          }

          lastBlockAdded = newBlock;

          break;
        case 0:
          // Same level. Find the parent of the last block added and add it to it's
          // children
          let parentId;
          let lastBlockParent;

          if (lastBlockAdded.parentId) {
            parentId = lastBlockAdded.parentId;
            lastBlockParent = findBlockByKey(headerBlocks, parentId);
          }

          newBlock = {
            parentId: lastBlockParent ? lastBlockParent.key : undefined,
            children: [],
            content: [],
            ..._blocks[i],
            level: headerLevels[_blocks[i].type],
            projectItemMetadata,
            headerLevel: getHeaderNumbers(_blocks[i].type),
          };

          if (lastBlockAdded.parentId) {
            lastBlockParent.children.push(newBlock);
          } else {
            // They are both root header elements, h2's, add them to the root element
            headerBlocks.push(newBlock);
          }

          lastBlockAdded = newBlock;

          break;
        case 1:
          newBlock = {
            parentId: lastBlockAdded.key,
            children: [],
            content: [],
            ..._blocks[i],
            level: headerLevels[_blocks[i].type],
            projectItemMetadata,
            headerLevel: getHeaderNumbers(_blocks[i].type),
          };

          // This block is a child of the previous block. Add it to the 'children'
          // array of the closest parent
          lastBlockAdded.children.push(newBlock);

          lastBlockAdded = newBlock;

          break;

        default:
          // Report error
          break;
      }
    }

    return headerBlocks;
  }

  const isArray = blocks.constructor === Array;
  const data = isArray ? blocks : blocks.blocks;
  const parsedData = parse(data, projectItems);

  return parsedData;
}

export function flattenHeaderHierarchyArray(headerParentChildBlocks) {
  let flatBlocks = [];

  headerParentChildBlocks.forEach((headerBlock) => {
    flatBlocks.push({ ...headerBlock });

    if (headerBlock.children && headerBlock.children.length > 0) {
      flatBlocks = [
        ...flatBlocks,
        ...flattenHeaderHierarchyArray(headerBlock.children),
      ];
    }
  });

  return flatBlocks;
}

export function flattenHeaderHierarchyMap(headerParentChildBlocks) {
  let flatBlocks = {};

  headerParentChildBlocks.forEach((headerBlock) => {
    flatBlocks[headerBlock.key] = { ...headerBlock };

    if (headerBlock.children && headerBlock.children.length > 0) {
      flatBlocks = {
        ...flatBlocks,
        ...flattenHeaderHierarchyMap(headerBlock.children),
      };
    }
  });

  return flatBlocks;
}

export function createEditorStateHTMLContent(contentState) {
  const editorTables = [];
  const htmlOptions = {
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "image") {
        const data = entity.getData();
        return {
          element: "img",
          attributes: {
            src: data.src,
            class: "max-h-56",
          },
        };
      }
      if (entityType === "link") {
        const data = entity.getData();
        return {
          element: "a",
          attributes: {
            href: data.url,
            target: "_blank",
          },
        };
      }
      if (entityType === "embed-type") {
        const data = entity.getData();
        return {
          element: "iframe",
          "data-entityType": "embed-type",
          attributes: {
            src: data.src,
            allowFullScreen: true,
            class: "w-full h-64",
          },
        };
      }
    },
    blockRenderers: {
      "table-cell-type": (block) => {
        const data = block.getData();
        const tableKey = data.get("tableKey");
        const tablePosition = data.get("tablePosition");

        //If the block has table shape data that means that it is the first block
        if (block.getType() === "table-cell-type" && data.get("tableShape")) {
          editorTables.push({
            [tableKey]: {
              0: [{ [tablePosition]: block.getText() }],
            },
          });
          return "";
        }
        if (tableKey && !data.get("tableShape")) {
          //Table position's format is : "tableKey-rowNumber-cellNumber" The condition below checks if its the first cell in a row that is not a header row
          if (tablePosition.split("-")[2] === "0") {
            editorTables.filter((table) => table[tableKey])[0][tableKey][
              tablePosition.split("-")[1]
            ] = [{ [tablePosition]: block.getText() }];
          } else {
            editorTables
              .filter((table) => table[tableKey])[0]
              [tableKey][tablePosition.split("-")[1]].push({
                [tablePosition]: block.getText(),
              });
          }

          if (!data.get("isLast") || data.get("isLast") === false) {
            return "";
          }
        }
        if (data.get("isLast")) {
          return editorTables
            .map((table) => {
              return (
                table[tableKey] &&
                `<table><tbody>${Object.values(table)
                  .map((row) => {
                    return Object.values(row)
                      .map((cell, i) => {
                        return `<tr>${cell
                          .map((text) => {
                            return i === 0
                              ? `<th className='mb-1 p-1 h-10 border bg-darkBlue text-white border-white'> ${
                                  Object.values(text)[0]
                                }
                        
                          </th>`
                              : `<td className='border mb-1 p-1 h-10'> ${
                                  Object.values(text)[0]
                                }
                        
                          </td>`;
                          })
                          .join("")}</tr>`;
                      })
                      .join("");
                  })
                  .join("")}</tbody></table>`
              );
            })
            .join("");
        }
      },
    },
  };

  return stateToHTML(contentState, htmlOptions);
}

export function createContentStateFromHTML(content) {
  const customOptions = {
    customInlineFn: (el, { Style, Entity }) => {
      if (el.tagName === "IFRAME") {
        return Entity("embed-type", { src: el.src });
      }
    },
  };
  return stateFromHTML(content, customOptions);
}

/**
 * Create a HTML representation of an editor slice for a Feature/Header based on
 * a array of Draft Blocks that make that slice.
 *
 * @param {*} contentState
 * @param {Array} blocks Blocks to extract. They are used to create a state slice.
 *                   Blocks need to come from flatParentChildHierarchy generated
 *                   by flattenHeaderHierarchyArray method or the Map equivalent.
 */
export function createEditorStateHtmlSlice(contentState, blocks) {
  const rawEditorState = convertToRaw(contentState);
  const editorStateSlice =
    blocks && blocks.length
      ? EditorState.createWithContent(
          convertFromRaw({
            blocks: blocks,
            entityMap: rawEditorState.entityMap,
          })
        )
      : EditorState.createEmpty();

  const htmlContent = createEditorStateHTMLContent(
    editorStateSlice.getCurrentContent()
  );

  return htmlContent;
}

/**
 * Get the level from the block hierarchy.
 *
 * @returns
 * 1 - h1, or a part of h1 content
 * 2 - h2, or a part of h2 content
 * 3 - h3, or a part of h3 content
 * 4 - h4, or a part of h4 content
 * 5 - h5, or a part of h5 content
 * 6 - h6, or a part of h6 content
 *
 * @param {ContentBlock} block
 * @param {Object} blockHierarchyMap
 */
export function getBlockLevel(block, blockHierarchyMap) {
  // const type = block.getType();

  if (isHeaderBlock(block)) {
    return headerLevels[block.type];
  } else {
    // Get the Header parent of this block
    const headerParentBlock = Object.values(blockHierarchyMap).find(
      (headerBlock) => {
        return headerBlock.content.find(
          (childBlock) => childBlock.key === block.getKey()
        );
      }
    );

    return headerParentBlock ? headerLevels[headerParentBlock.type] : 1;
  }
}

/**
 * Based on old and new editor state determine if the change of the content is allowed.
 * Change of content if not allowed if a Header block that has an active item
 * connected to it is being removed.
 *
 * @param {EditorState} newEditorState
 * @param {EditorState} currentEditorState
 */
export function contentChangeAllowed(newEditorState, currentEditorState) {
  if (
    newEditorState.getLastChangeType() === "remove-range" ||
    newEditorState.getLastChangeType() === "backspace-character"
  ) {
    // Content change is not allowed in case there are
    // deleted blocks that have active project items assigned
    // to them.
    let newContent = newEditorState.getCurrentContent();

    const currentContent = currentEditorState.getCurrentContent();

    const diffOldNew = immutableDiff(
      currentContent.blockMap,
      newContent.blockMap
    );

    if (!diffOldNew.isEmpty()) {
      const changedBlockIds = diffOldNew
        .map((e) => e.toJSON())
        // This means the complete element is removed not just characters in the
        // element
        .filter((e) => e.op === "remove" && e.path.split("/").length === 2)
        // Get only the blockKey
        .map((e) => e.path.split("/")[1])
        // remove duplicates
        .toSet()
        .toList();

      const headerBlocks = changedBlockIds.filter((e) => {
        return currentContent
          .getBlockForKey(e)
          .getData()
          .get("headerItemLinked");
      });

      if (headerBlocks.size) {
        toast.warn("Can not remove a Header with a linked Item");
        /**
         * This means that one of the blocks we are trying to remove
         * is linked with an item in the database. Prevent the complete
         * action.
         */
        return false;
      }
    }
  }

  return true;
}

/**
 * Check if any header was modified in any way with the current change set.
 *
 * @param {*} newEditorState
 * @param {*} currentEditorState
 *
 * @return {Boolean} True if any header is modified in the current change.
 */
export function headerStructureChanged(newEditorState, currentEditorState) {
  const newContent = newEditorState.getCurrentContent();
  const currentContent = currentEditorState.getCurrentContent();
  const diffOldNew = immutableDiff(
    currentContent.blockMap,
    newContent.blockMap
  );
  const changedBlockIds = diffOldNew
    .map((entry) => {
      return entry.toJSON().path.split("/")[1];
    })
    .toSet()
    .toList();

  const headerChanged = changedBlockIds.some((blockId) => {
    const block = newContent.getBlockForKey(blockId);

    return block && isHeaderBlock(block);
  });

  return headerChanged;
}

export function addedHeader(newEditorState, currentEditorState) {
  const newContent = newEditorState.getCurrentContent();
  const currentContent = currentEditorState.getCurrentContent();
  const diffOldNew = immutableDiff(
    currentContent.blockMap,
    newContent.blockMap
  );
  const changedBlockIds = diffOldNew
    .map((entry) => {
      return entry.toJSON().path.split("/")[1];
    })
    .toSet()
    .toList();

  const oldBlocks = currentContent
    .getBlockMap()
    .filter((block) => {
      const contentBlock = newContent.getBlockForKey(block.key);
      return contentBlock && isHeaderBlock(contentBlock);
    })
    .map((block) => block)
    .toSet()
    .toList();

  if (
    !oldBlocks.includes(...changedBlockIds) &&
    newContent.getBlockForKey(...changedBlockIds) &&
    isHeaderBlock(newContent.getBlockForKey(...changedBlockIds))
  ) {
    return true;
  }
}

/**
 * erm .. good luck!
 */
export function moveEditorContent({
  hierarchyState,
  topLevelHierarchyBlock,
  changeObject,
  editorState,
}) {
  try {
    if (!changeObject.destination || !isEmpty(changeObject.destination.index)) {
      // Move did not happen
      return;
    }

    if (changeObject.destination.index === changeObject.source.index) {
      // Change did not happen
      return;
    }

    /**
     * Get the list of all keys of all children recursively
     *
     * @param {Array} children
     */
    function getDeepChildKeys(children) {
      let result = [];

      if (children && children.length) {
        children.forEach((block) => {
          result.push(block.key);

          if (block.children && block.children.length) {
            result = [...result, ...getDeepChildKeys(block.children)];
          }
        });
      }
      return result;
    }

    /**
     * Get the last content key for a particulat header.
     *
     * @param {*} hierarchyState
     * @param {*} headerBlockKey
     * @param {*} deepChildKeys
     */
    function getFinalContentKey(hierarchyState, headerBlockKey, deepChildKeys) {
      const headerBlock = hierarchyState[headerBlockKey];

      if (deepChildKeys.length) {
        // Find the last child and return it's content.
        const lastChildKey = deepChildKeys[deepChildKeys.length - 1];
        const lastChildBlock = hierarchyState[lastChildKey];

        if (lastChildBlock.content.length) {
          // Return last content block key
          return lastChildBlock.content[lastChildBlock.content.length - 1].key;
        }

        // If that child has no content return that child's key
        return lastChildBlock.key;
      }

      if (headerBlock.content.length) {
        // Return the key of the last content block
        return headerBlock.content[headerBlock.content.length - 1].key;
      }

      // No children and no content, it's a header only and it's the last key
      return headerBlock.key;
    }

    const originStartKey = changeObject.draggableId;
    const destinationStartKey =
      topLevelHierarchyBlock.children[changeObject.destination.index].key;

    const destinationChildKeys = getDeepChildKeys(
      hierarchyState[destinationStartKey].children
    );

    const destinationEndKey = getFinalContentKey(
      hierarchyState,
      destinationStartKey,
      destinationChildKeys
    );

    const originChildKeys = getDeepChildKeys(
      hierarchyState[originStartKey].children
    );

    const originEndKey = getFinalContentKey(
      hierarchyState,
      originStartKey,
      originChildKeys
    );

    // If the block is being moved up or down
    const direction =
      changeObject.source.index < changeObject.destination.index
        ? "down"
        : "up";

    const contentState = editorState.getCurrentContent();
    const arrayBlocks = contentState.getBlocksAsArray();

    // Get the necessary indexes for block groups being moved. This is used to
    // Slice the content and rearrange the blockArray to create the new ContentState
    const indexOriginStartKey = arrayBlocks.indexOf(
      contentState.getBlockForKey(originStartKey)
    );

    const indexOriginEndKey = arrayBlocks.indexOf(
      contentState.getBlockForKey(originEndKey)
    );

    const indexDestinationStartKey = arrayBlocks.indexOf(
      contentState.getBlockForKey(destinationStartKey)
    );

    const indexDestinationEndKey = arrayBlocks.indexOf(
      contentState.getBlockForKey(destinationEndKey)
    );

    // Slice 1
    const slice1IndexEnd =
      direction === "up" ? indexDestinationStartKey : indexOriginStartKey;

    const slice1 = arrayBlocks.slice(0, slice1IndexEnd);

    // Slice 2
    const slice2IndexEnd =
      direction === "up" ? indexOriginStartKey : indexOriginEndKey + 1;

    const slice2 = arrayBlocks.slice(slice1IndexEnd, slice2IndexEnd);

    // Slice 3
    const slice3IndexEnd =
      direction === "up" ? indexOriginEndKey + 1 : indexDestinationEndKey + 1;

    const slice3 = arrayBlocks.slice(slice2IndexEnd, slice3IndexEnd);

    // Slice 4
    const slice4 = arrayBlocks.slice(slice3IndexEnd);

    let endArray = [...slice1, ...slice3, ...slice2, ...slice4];

    // Create the new ContentState from the new array block
    const movedContentState = ContentState.createFromBlockArray(
      endArray,
      contentState.getEntityMap()
    )
      .set("selectionBefore", contentState.getSelectionBefore())
      .set("selectionAfter", contentState.getSelectionAfter());

    return EditorState.push(editorState, movedContentState);
  } catch (e) {
    console.error("Could not move block", e);
  }
}

/**
 * Take the EditorState and based on the currently selected Page extrapolate
 * all the visible block keys that should be shown in the editor that are
 * the children of the currently active Page
 *
 * @param {string} pageKey H1 blockKey
 * @param {EditorState} EditorState Draft EditorState
 *
 * @returns {Seq} All block keys that should be visible on the selected page
 */
export function getVisibleBlocksForPage(pageKey, editorState) {
  if (!editorState || !pageKey) return;

  // INFO - Docs about Seq used in the function!
  // https://immutable-js.com/docs/v3.8.2/Seq/

  // Seq of all blockKeys from ContentState
  const allBlocksKeysSeq = editorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq();

  // Seq of all H1 blockKeys
  const pagesBlockKeySeq = getPageHeaderBlockKeys(editorState);

  const selectedPageBlockKeyIndex = allBlocksKeysSeq.indexOf(pageKey);
  const selectedPageHeaderKeyIndex = pagesBlockKeySeq.indexOf(pageKey);
  const nextPageHeaderBlockKey = pagesBlockKeySeq.get(
    selectedPageHeaderKeyIndex + 1
  );

  let nextPageBlockKeyIndex = allBlocksKeysSeq.indexOf(nextPageHeaderBlockKey);

  if (nextPageBlockKeyIndex === -1) {
    nextPageBlockKeyIndex = allBlocksKeysSeq.size;
  }

  const allVisibleBlocks = allBlocksKeysSeq.slice(
    selectedPageBlockKeyIndex,
    nextPageBlockKeyIndex
  );

  return allVisibleBlocks;
}

/**
 *
 * @param {EditorState} editorState Draft Editor State
 * @returns {Seq} Seq of all header-one blockKeys
 */
export function getPageHeaderBlockKeys(editorState) {
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .filter((a) => a.getType() === "header-one")
    .keySeq();
}

/**
 * SYNC Related diffing stuff
 */
function isBlockAdded(diffOperation) {
  const operation = diffOperation.op;
  const path = diffOperation.path;
  const pathSegments = path.split("/");

  return (
    operation === "add" && pathSegments.length === 2 && pathSegments[1] !== ""
  );
}

function isBlockRemoved(diffOperation) {
  const operation = diffOperation.op;
  const path = diffOperation.path;
  const pathSegments = path.split("/");

  return (
    operation === "remove" &&
    pathSegments.length === 2 &&
    pathSegments[1] !== ""
  );
}

function isCharacterAdded(diffOperation) {
  const operation = diffOperation.op;
  const path = diffOperation.path;
  const pathSegments = path.split("/");

  return (
    operation === "add" &&
    pathSegments.length === 4 &&
    pathSegments[2] === "characterList"
  );
}

function isCharacterStyleChanged(diffOperation) {
  const operation = diffOperation.op;
  const path = diffOperation.path;
  const pathSegments = path.split("/");

  return (
    path.indexOf("characterList") >= 0 &&
    operation === "replace" &&
    pathSegments.length === 5 &&
    pathSegments[4] === "style"
  );
}

function isCharacterEntityReplaced(diffOperation) {
  const operation = diffOperation.op;
  const path = diffOperation.path;
  const pathSegments = path.split("/");

  return (
    operation === "replace" &&
    pathSegments.length === 5 &&
    pathSegments[4] === "entity"
  );
}

/**
 *
 * @param {array} diffOperations
 */
export function isAnyBlockAddedInDiff(diffOperations) {
  return diffOperations.some(isBlockAdded);
}

export function isAnyBlockRemovedInDiff(diffOperations) {
  return diffOperations.some(isBlockRemoved);
}

function getAddBlockPreceedingKey(newEditorState, jsonDiff) {
  const firstAddedBlockDiff = jsonDiff.find(isBlockAdded);
  const firstAddedBlockKey = firstAddedBlockDiff.value.key;
  const preceedingBlockKey = newEditorState
    .getCurrentContent()
    .getBlockBefore(firstAddedBlockKey)
    .getKey();

  return preceedingBlockKey;
}

export function getDiffChangeMeta(newEditorState, localEditorState, jsonDiff) {
  const lastChangeType = newEditorState.getLastChangeType();
  let precedingBlockKey = null;

  if (lastChangeType === "split-block") {
    // We are adding a new line or splitting an existing block
    // In order to update other ContentStates correctly we need
    // to know where to add the block on the other side, so find
    // the preceding block key!
    const newBlockKey = newEditorState.getSelection().getStartKey();

    precedingBlockKey = newEditorState
      .getCurrentContent()
      .getBlockBefore(newBlockKey)
      .getKey();
  }

  if (lastChangeType === "insert-fragment") {
    /** We are inserting one or more new blocks in a single action .. this usually
     * means that we are copy-pasteing something ..
     * */
    precedingBlockKey = localEditorState.getSelection().getStartKey();
  }

  if (
    lastChangeType === "undo" ||
    lastChangeType === "redo" ||
    lastChangeType === CHANGE_TYPE_INSERT_SECTION
  ) {
    if (isAnyBlockAddedInDiff(jsonDiff)) {
      precedingBlockKey = getAddBlockPreceedingKey(newEditorState, jsonDiff);
    }
  }

  return { lastChangeType, precedingBlockKey, ts: Date.now() };
}

export function mergeEntityMap(contentState, entityDiffs) {
  if (!entityDiffs || !entityDiffs.size) {
    return contentState.getEntityMap();
  }

  let entityMap = contentState.getAllEntities();

  if (entityDiffs.size > 0) {
    const entityDiffsPatched = entityDiffs.map((diffEntityMap) => {
      const operation = diffEntityMap.get("op");
      const entityKey = diffEntityMap.get("path").split("/")[1];
      const entityExists = entityMap.has(entityKey);
      let newContentState = contentState;

      if (operation === "add" && !entityExists) {
        const value = diffEntityMap.get("value").toJS();

        newContentState = contentState.createEntity(
          value.type,
          value.mutability,
          value.data
        );

        const entityKey = newContentState.getLastCreatedEntityKey();
        const newEntity = newContentState.getEntity(entityKey);

        return diffEntityMap.set("value", newEntity);
      }

      // TODO - to be safe, make sure path includes only DATA change
      if (operation === "replace") {
        const value = diffEntityMap.get("value").toJS();
        return diffEntityMap.set("value", value);
      }

      return diffEntityMap;
    });

    entityMap = immutablePatch(entityMap, entityDiffsPatched);
  }

  contentState.loadWithEntities(entityMap);

  return contentState.getEntityMap();
}

function isSelectionInConflict(editorState, diffs) {
  const deletedKeys = getAllRemovedBlockKeys(diffs);
  const selectedBlocksSeq = getSelectedBlocksMap(editorState);

  return deletedKeys.some((delKey) => selectedBlocksSeq.has(delKey));
}

function getBlockKeyFromPath(diff) {
  return diff.path.split("/")[1];
}

function getAllRemovedBlockKeys(diffs) {
  return diffs.filter(isBlockRemoved).map(getBlockKeyFromPath);
}

/**
 * A very naivee aproach to managing selection state merge conflict
 * that will not be user friendly at start but should just make sure
 * things keep working if there is any merge conflict.
 *
 * TODO: Case-by-case should be covered.
 *
 * @param {*} editorState
 * @param {*} deletedKeys
 */
function manageSyncSelectionConflict(editorState, deletedKeys) {
  const currentSelection = editorState.getSelection();
  const firstSelectionKey = currentSelection().getStartKey();

  /**
   * If selection START key is NOT in the list of deleted keys
   * set the new selection state at the START of that block.
   *
   * TODO - find the offset of the new value of the block and
   * put the selection state at the END of the block
   */
  if (!deletedKeys.includes(firstSelectionKey)) {
    return SelectionState.createEmpty(firstSelectionKey);
  }

  const lastSelectionKey = currentSelection().getEndKey();

  /**
   * If selection END key is NOT in the list of deleted keys
   * set the new selection state at the START of that block.
   *
   * TODO - find the offset of the new value of the block and
   * put the selection state at the END of the block
   */
  if (!deletedKeys.includes(lastSelectionKey)) {
    return SelectionState.createEmpty(lastSelectionKey);
  }

  try {
    const firstContentBlockBeforeSelection = editorState
      .getCurrentContent()
      .getBlockBefore(deletedKeys[0]);

    return SelectionState.createEmpty(firstContentBlockBeforeSelection);
  } catch (error) {
    // Fallback
    return currentSelection;
  }
}

/**
 * When we remove blocks from another client and apply the local selection
 * And these blocks don't exist anymore things will go boom
 *
 * Same thing will happen if we remove characters and our current selection
 * is on those characters.
 *
 * Basically manually check if something has been removed, and if any
 * of the keys from the current selection are affected, manually set the
 * new selection anchor and start/end.
 *
 *
 * This will be interesting and is mostly problem when something
 * is being REMOVED .. blocks of text!
 */
export function createSyncSelectionState(
  localEditorState,
  newContentState,
  diffs
) {
  // Editor state
  // Has something been removed (block or character)

  // If YES then check if the current selection state
  // contains removed blocks or keys!!

  const currentSelection = localEditorState.getSelection();

  /**
   * If selection anchorKey and focusKey and offsets are the same then the user
   * is typing only carret
   */
  const isTyping =
    currentSelection.anchorKey === currentSelection.focusKey &&
    currentSelection.anchorOffset === currentSelection.focusOffset;

  if (isTyping) {
    const currentBlockInNewState = newContentState.getBlockForKey(
      currentSelection.anchorKey
    );

    if (!currentBlockInNewState) {
      // it can happen that the block referenced in a diff
      // does not exist ( e.g. it was deleted and change wasn't synced )
      // FIXME: this is a temp fix. User's cursor will jump to the beginning of the document.
      const firstKey = localEditorState
        .getCurrentContent()
        .getFirstBlock()
        .getKey();
      return SelectionState.createEmpty(firstKey);
    }

    const textLength = currentBlockInNewState.getText().length;

    if (currentSelection.anchorOffset > textLength) {
      return currentSelection.merge({
        anchorOffset: textLength,
        focusOffset: textLength,
      });
    }
  }

  const anyBlockRemoved = isAnyBlockRemovedInDiff(diffs);

  if (!anyBlockRemoved) {
    return currentSelection;
  }

  if (!currentSelection.hasFocus) {
    // Is editor focused - if not then Draft makes the selection
    // state the complete editor state (for some reason)
    // Make the start and end key the first block!
    const firstKey = localEditorState
      .getCurrentContent()
      .getFirstBlock()
      .getKey();
    return SelectionState.createEmpty(firstKey);
  }

  if (isSelectionInConflict(localEditorState, diffs)) {
    return manageSyncSelectionConflict(localEditorState, diffs);
  }

  // Fallback
  return currentSelection;
}

/**
 * The diffs that are shared are raw JS objects.
 * That structure does not comply with Draft's internal model.
 *
 * We need to translate raw JSON diffs to Draft's internal data structure.
 *
 *
 * @param {} diffs
 */
export function parseRawDiffToDraftData(diffs) {
  let parsedEntities = Immutable.List();
  let blocksAdded = false;

  console.time("SYNC TIME - diffMap");

  const parseDiff = diffs.reduce((memo, change) => {
    let diff = change.get("diff");
    const entitiesDiff = change.get("entitiesDiff");

    if (entitiesDiff) {
      // Add this entity to the parsedEntities List.
      // It is used later to merge all entities and create
      // new ContentState correctly
      parsedEntities = parsedEntities.merge(entitiesDiff);
    }

    diff = diff.map((dChange) => {
      const rawDiff = dChange.toJS();
      /**
       * If someone added a new character to a block (they are typing)
       * when applying the patch we need to make sure that the
       * diffed change for this case will be of the CharacterMetadata type
       * because Draft expects it.
       */
      if (isCharacterAdded(rawDiff)) {
        const value = dChange.get("value");
        let config = {};

        if (value) {
          config = value.toJSON();

          if (config.style) {
            // Style needs to be an Ordered Set of the universe will collapse!
            config.style = config.style.toOrderedSet();
          }
        }

        const characterMetadata = dChange.set(
          "value",
          CharacterMetadata.create(config)
        );

        return characterMetadata;
      }

      // Style must be an OrderedSet not a List
      if (isCharacterStyleChanged(rawDiff)) {
        return dChange.set("value", dChange.get("value").toOrderedSet());
      }

      // A new block is being added
      if (isBlockAdded(rawDiff)) {
        // We need to keep track if any blocks are added in the incoming diff.
        // This is needed in other parts of the porcessing incoming diff
        blocksAdded = true;

        let valueCB = dChange.get("value");
        let config = {};

        if (valueCB) {
          const contentBlockCharacterList = valueCB.get("characterList");

          if (contentBlockCharacterList.size !== 0) {
            // Set the correct type to the character list
            // FIXME - HERE !! Cannot set unknown key “text” on CharacterMetadata
            // I think
            const contentBlockCharacterListParsed =
              contentBlockCharacterList.map((cListMap) =>
                CharacterMetadata.create(cListMap.toJSON())
              );

            // Here something breaking
            // Error: Cannot set unknown key "characterList" on CharacterMetadata
            valueCB = valueCB.set(
              "characterList",
              contentBlockCharacterListParsed
            );
          }

          config = valueCB.toJSON();
        }

        // return new ContentBlock()
        const contentBlock = dChange.set("value", new ContentBlock(config));

        return contentBlock;
      }

      if (
        rawDiff.path.indexOf("characterList") >= 0 &&
        rawDiff.op !== "remove"
      ) {
        // Not sure what happens here
      }

      return dChange;
    });

    /**
     * Default just return the change
     */
    return memo.push(...diff);
  }, Immutable.List());

  console.timeEnd("SYNC TIME - diffMap");

  return [parseDiff, parsedEntities, blocksAdded];
}

/**
 * In case of UNDO/REDO actions, blocks are added (back) to the editor but
 * their entities are not re-created since they are already in the entities
 * list.
 *
 * Caveat - every editor instance has it's own local ENTITY ID so the
 * remote client will not have the same ENTITY ID as any other client.
 *
 * Manually check if any blocks are added, if they have entities and add those
 * entities in case they are not in the entitiesDiffList already.
 *
 * @param {*} entitiesDiffList
 * @param {*} blockDiff
 */
export function addEntitiesDiff(entitiesDiffList, blockDiff, newEditorState) {
  // Example of a entity diff transaction object
  // {
  //   "op": "add",
  //   "path": "/c0cd1438-46af-4396-a4b3-cf134304298f",
  //   "value": {
  //     "type": "IMAGE",
  //     "mutability": "IMMUTABLE",
  //     "data": {
  //       "imgUploadPending": true,
  //       "imgName": "Screenshot 2021-01-20 at 09.45.55.png1612878676807",
  //       "src": "https://via.placeholder.com/400X200?text=Uploading..."
  //     }
  //   }
  // }

  const lastChangeType = newEditorState.getLastChangeType();

  // Check if action is UNDO/REDO .. in that case no new entities are created or
  // picked up by the entities diff and we need to do it manually
  if (lastChangeType === "undo" || lastChangeType === "redo") {
    // Go through all diffs, check if any block or character added/modified
    let _loc = entitiesDiffList;

    blockDiff.forEach((diff) => {
      const blockAdded = isBlockAdded(diff);

      if (blockAdded) {
        // Array of all entity keys that were added
        const entityKeysArray = diff.value.characterList.reduce(
          (memo, character) => {
            return character.entity ? [...memo, character.entity] : memo;
          },
          []
        );

        // We have some entities to update
        if (entityKeysArray.length) {
          const contentState = newEditorState.getCurrentContent();

          entityKeysArray.forEach((entityKey) => {
            const draftEntityInstance = contentState.getEntity(entityKey);

            _loc = _loc.push(
              Immutable.Map({
                op: "add",
                path: `/${entityKey}`,
                value: draftEntityInstance.toJS(),
              })
            );
          });
        }
      }

      const characterAdded = isCharacterAdded(diff);

      if (characterAdded) {
        debugger;
      }

      const characterEntityReplaced = isCharacterEntityReplaced(diff);

      if (characterEntityReplaced) {
        debugger;
      }
    });

    return _loc;
  }

  return entitiesDiffList;
}

/**
 *
 * @param {OrderedMap} rawContentStatePatched
 * @param {String} precedingBlockKey
 * @param {String} lastDiffFirstBlockAddedKey
 */
export function reorderAddedBlocks(
  rawContentStatePatched,
  precedingBlockKey,
  lastDiffFirstBlockAddedKey
) {
  console.time("SYNC TIME - blocksAdded");
  // New blocks are added to the end, it's just the way the
  // immutable patching works when working with OrderedMap,
  // need to reorder the blocks to keep it in sync with other clients

  // Split the new content State into 3 slices
  // 1. start - preceedingBlockKey
  // 2. preceedingBlockKey - blockAddedKey
  // 3. blockAddedKey - end

  // Extract the keys from the OrderedMap in order to get the position
  // index more easily
  const keyedSeq = rawContentStatePatched.keySeq();

  const indexPrevBlock = keyedSeq.findIndex((k) => k === precedingBlockKey);

  const indexAdded = keyedSeq.findIndex(
    (k) => k === lastDiffFirstBlockAddedKey
  );

  const slice1 = rawContentStatePatched.slice(0, indexPrevBlock + 1);
  const slice2 = rawContentStatePatched.slice(indexPrevBlock + 1, indexAdded);
  const slice3 = rawContentStatePatched.slice(indexAdded);

  rawContentStatePatched = slice1.merge(slice3, slice2);

  console.timeEnd("SYNC TIME - blocksAdded");

  return rawContentStatePatched;
}

const filterAddBlockDiffs = (fullDiff) => fullDiff.diff.some(isBlockAdded);

/**
 * Merge rawContentState for every diff that potentially added new blocks
 * in the order the blocks were added!
 *
 * @param {OrderedMap} rawContentStatePatched
 * @param {array} diffs
 */
export function mergeAddedBlocks(rawContentStatePatched, diffs) {
  // Get only diffs where blocks are added!
  const addedBlockDiffs = diffs.filter(filterAddBlockDiffs);

  let _rawContentStatePatched = rawContentStatePatched;

  // Go through them all and apply mergeAddedBlocks - or rename to reorderAddedBlocks
  for (let i = 0; i < addedBlockDiffs.length; i++) {
    const diffFull = addedBlockDiffs[i];

    // Get the first added block key!
    const firstBlockAddedDiff = diffFull.diff.find(isBlockAdded);
    const firstBlockAddedKey = getBlockKeyFromPath(firstBlockAddedDiff);
    // Get the preceeding block key!
    const preceedingBlockKey = diffFull.meta.precedingBlockKey;

    _rawContentStatePatched = reorderAddedBlocks(
      _rawContentStatePatched,
      preceedingBlockKey,
      firstBlockAddedKey
    );
  }

  return _rawContentStatePatched;
}
