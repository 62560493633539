import React from "react";

import { ReactComponent as CheckMark } from "assets/checkmark-icon.svg";

const Card = ({ title, children, className = "" }) => {
  return (
    <div className={`flex ${className}`}>
      <div className="h-full">
        <CheckMark style={{ marginTop: 6, marginRight: 25 }} />
      </div>
      <div>
        <h2 className="mb-1 text-2xl text-white">{title}</h2>
        <p className="leading-6 text-grayBlue">{children}</p>
      </div>
    </div>
  );
};

export default Card;
