import React, { useState } from "react";

import { useAbility } from "@casl/react";
import { inviteUser, revokeMembersAccess, updateUserPermissions } from "api";
import classNames from "classnames";
import Loader from "components/Loader";
import { AbilityContext, Can } from "components/RoleManagement/Can";
import UserAvatar from "components/UserAvatar";
import { useDispatch, ACTIONS } from "context";
import { auth } from "firebaseSetup";
import useQuickStartAction from "hooks/useQuickStartAction";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  canUserEditDocument,
  canUserEditMeta,
  canUserEditPermissions,
} from "utils/permissions";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import {
  USER_ROLES,
  PERMISSIONS_ACTIONS,
  PERMISSIONS_SUBJECTS,
} from "../../constants";
import { QUICK_START_STEPS } from "../../quickStartContext";
import { isEmpty } from "../../utils/emptyString";
import { isValidEmail } from "../../utils/validEmail";

const ShareOption = ({
  members,
  pendingUsers,
  hasRevokeRights,
  project,
  loading,
  loadedProjects,
  activeProjectId,
}) => {
  const dispatch = useDispatch();
  const quickStartAction = useQuickStartAction();

  const currentUserId = auth.currentUser.uid;
  const [emailInput, setEmailInput] = useState("");
  const ability = useAbility(AbilityContext);

  const revokeTooltipMsg = hasRevokeRights
    ? "Click to revoke user's access"
    : "Only project owner can revoke user's access";

  const closeButtonClass = classNames({
    "cursor-pointer": true,
    "w-5": true,
    "h-5": true,
    "fill-disabled": !hasRevokeRights,
  });

  const addMember = (event) => {
    event.preventDefault();
    const member = members.find((member) => member.email === emailInput);
    const pendingUser = pendingUsers.find(
      (pendingUser) => pendingUser.invitedUserEmail === emailInput
    );
    //Check if email is valid and not empty
    if (isEmpty(emailInput)) {
      toast.error("Input field is empty");
    } else if (!isValidEmail(emailInput)) {
      toast.error("Email is not valid");
    } else if (member || pendingUser) {
      toast.error("User already invited");
    } else {
      inviteUser(
        emailInput,
        project,
        dispatch,
        loadedProjects[activeProjectId].members[currentUserId].permissions
      );
      quickStartAction(QUICK_START_STEPS.INVITE_USER);
      // Reset input field
      setEmailInput("");
    }
  };

  const revokeMember = (revokedMember) => {
    revokeMembersAccess(revokedMember.id, project.id, dispatch);
  };

  const calculateMembers = () => {
    if (members === null || members === undefined || !members) {
      return 0;
    } else {
      return members.length;
    }
  };

  const calculatePendingUsers = () => {
    if (pendingUsers === null || pendingUsers === undefined || !pendingUsers) {
      return 0;
    } else {
      return pendingUsers.length;
    }
  };

  const handlePermissionsChange = (e, type, memberId) => {
    if (
      canUserEditPermissions() &&
      canUserEditDocument() &&
      !isCurrentUser(currentUserId, memberId) &&
      !isMemberOwner(memberId)
    ) {
      const permissions =
        loadedProjects[activeProjectId].members[memberId].permissions;
      let toastContent = {};
      const indexOfTargeted = permissions.findIndex(
        (item) => item.subject === type
      );

      if (type === PERMISSIONS_SUBJECTS.EDITOR) {
        permissions[indexOfTargeted] = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: PERMISSIONS_SUBJECTS.EDITOR,
        };
        toastContent = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: "document",
        };
      } else if (type === PERMISSIONS_SUBJECTS.METADATA) {
        permissions[indexOfTargeted] = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: PERMISSIONS_SUBJECTS.METADATA,
        };
        toastContent = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: "items",
        };
      } else {
        permissions[indexOfTargeted] = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
        };
        toastContent = {
          action: e.target.checked
            ? PERMISSIONS_ACTIONS.MANAGE
            : PERMISSIONS_ACTIONS.READ,
          subject: "permissions",
        };
      }
      updateUserPermissions(
        project.id,
        loadedProjects[activeProjectId].members,
        memberId,
        permissions,
        currentUserId,
        ability,
        toastContent
      );
    } else return;
  };

  const isMemberOwner = (memberId) => {
    if (
      loadedProjects[activeProjectId].members[memberId]?.role ===
      USER_ROLES.OWNER
    )
      return true;
  };

  const isCurrentUser = (currentUserId, memberId) => {
    if (currentUserId === loadedProjects[activeProjectId].members[memberId]?.id)
      return true;
  };

  const isPermissionsChecked = (memberId, subject) => {
    return (
      loadedProjects[activeProjectId].members[memberId]?.permissions.find(
        (permission) => permission.subject === subject
      ).action === PERMISSIONS_ACTIONS.MANAGE
    );
  };

  return (
    <div className="relative z-50 flex flex-col w-full h-full px-8 pt-4 pb-10 bg-white">
      <h3 className="text-2xl">Share with people</h3>

      <div className="mt-4">
        {calculateMembers() + calculatePendingUsers() < 10 ? (
          <>
            <form className="flex" onSubmit={addMember}>
              <input
                type="email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="Enter email"
                className="w-10/12 px-4 py-2 border focus:outline-none"
              />
              {loading ? (
                <div className="w-2/12 p-2 ml-10">
                  <Loader size="md" className="mx-auto" />
                </div>
              ) : (
                <button className="w-2/12 ml-10 btn btn-primary" type="submit">
                  Add
                </button>
              )}
            </form>
          </>
        ) : (
          <p>The maximum number of project members is 10</p>
        )}
      </div>
      <div className="mt-4 overflow-auto min-h-3/4">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left">Active users</th>
              <th data-tip="Users right to modify editor" className="text-left">
                Editor
              </th>
              <th data-tip="Users right to modify items" className="text-left">
                Items
              </th>
              <th
                data-tip="Users right to modify user permissions"
                className="text-left"
              >
                Permissions
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member?.id}>
                <td className="flex items-center">
                  <div className="w-8 h-8 mr-2">
                    <UserAvatar img={member?.img} />
                  </div>
                  <div className="flex flex-col w-4/12 ml-2">
                    <h4 className="text-base">{member?.name}</h4>{" "}
                    <p>{member?.email}</p>
                  </div>
                </td>

                <td
                  onChange={(e) =>
                    handlePermissionsChange(
                      e,
                      PERMISSIONS_SUBJECTS.EDITOR,
                      member?.id
                    )
                  }
                >
                  <input
                    disabled={
                      canUserEditPermissions() &&
                      canUserEditDocument() &&
                      !isCurrentUser(currentUserId, member?.id) &&
                      !isMemberOwner(member?.id)
                        ? false
                        : true
                    }
                    type="checkbox"
                    id="readEditor"
                    name="editor"
                    value="manage"
                    defaultChecked={isPermissionsChecked(
                      member?.id,
                      PERMISSIONS_SUBJECTS.EDITOR
                    )}
                  />
                </td>
                <td
                  onChange={(e) =>
                    handlePermissionsChange(
                      e,
                      PERMISSIONS_SUBJECTS.METADATA,
                      member?.id
                    )
                  }
                >
                  <input
                    disabled={
                      canUserEditPermissions() &&
                      canUserEditMeta() &&
                      !isCurrentUser(currentUserId, member?.id) &&
                      !isMemberOwner(member?.id)
                        ? false
                        : true
                    }
                    type="checkbox"
                    id="readMetadata"
                    name="metadata"
                    value="manage"
                    defaultChecked={isPermissionsChecked(
                      member?.id,
                      PERMISSIONS_SUBJECTS.METADATA
                    )}
                  />
                </td>
                <td
                  onChange={(e) =>
                    handlePermissionsChange(
                      e,
                      PERMISSIONS_SUBJECTS.USER_PERMISSIONS,
                      member?.id
                    )
                  }
                >
                  <input
                    disabled={
                      canUserEditPermissions() &&
                      !isCurrentUser(currentUserId, member?.id) &&
                      !isMemberOwner(member?.id)
                        ? false
                        : true
                    }
                    type="checkbox"
                    id="userPermissions"
                    name="userPermissions"
                    value="userPermissions"
                    defaultChecked={isPermissionsChecked(
                      member?.id,
                      PERMISSIONS_SUBJECTS.USER_PERMISSIONS
                    )}
                  />
                </td>

                <td>
                  <ReactTooltip />
                  {currentUserId !== member?.id ? (
                    <CloseIcon
                      data-tip={revokeTooltipMsg}
                      className={closeButtonClass}
                      onClick={() => hasRevokeRights && revokeMember(member)}
                    />
                  ) : (
                    <div className="w-5 h-5"></div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h4 className="mt-4">Pending invitations</h4>
        <ul>
          {pendingUsers.map(({ invitedUserEmail }) => (
            <li
              key={invitedUserEmail}
              className="flex items-center justify-start px-1 py-2 mt-2"
            >
              <div className="w-8 h-8 mr-2">
                <UserAvatar />
              </div>
              <div className="flex flex-col w-4/12 ml-2">
                <p>{invitedUserEmail}</p>
              </div>

              <div className="w-5 h-5 ml-2"></div>
            </li>
          ))}
        </ul>
      </div>

      <button
        onClick={() => {
          dispatch({
            type: ACTIONS.CLOSE_MODAL,
          });
        }}
        className="w-24 ml-auto btn btn-primary"
      >
        Done
      </button>
    </div>
  );
};

export default ShareOption;
