import { fbStorage } from "firebaseSetup";
import { createHash } from "utils/createHash";

export function saveSyncErrorData(data = {}) {
  // convert your object into a JSON-string
  const jsonString = JSON.stringify(data);

  // create a Blob from the JSON-string
  const blob = new Blob([jsonString], { type: "application/json" });

  // create a reference to the storage
  const storageRef = fbStorage.ref();

  // change
  const filename = createHash();

  // create a reference to the file
  const fileRef = storageRef.child(
    `/syncErrors/${filename}-${Date.now()}.json`
  );

  // upload you blob into the storage
  // Return a promise to handle outside when it resolves
  return fileRef.put(blob);
}
