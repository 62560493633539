import React from "react";

import { ReactComponent as DropdownArrow } from "assets/arrow_right.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar_icon.svg";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { Can } from "components/RoleManagement/Can";
import { format, toDate, isDate } from "date-fns";
import DatePicker from "react-datepicker";
import { canUserEditMeta } from "utils/permissions";

import { PERMISSIONS_SUBJECTS, PERMISSIONS_ACTIONS } from "../../constants";
import { parseDate } from "../../utils/date";
import "react-datepicker/dist/react-datepicker.css";
import "../../datepicker.css";

const DateComponent = (props) => {
  const [dateBlockOpen, setDateBlockOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());

  const { date, setMetaDate } = props;

  let _date = null;

  if (date) {
    // Handle if we are getting date from firestore. It comes in the Timestamp
    // format from Firestore
    if (date.seconds) {
      _date = date.toDate();
    } else {
      _date = date;
    }
  }
  return (
    <div className="relative sidebar-item">
      <p className="w-full font-normal text-darkBlue">{props.label}</p>
      {dateBlockOpen ? (
        <DatePicker
          selected={startDate}
          onChange={(pickerDate) => {
            setStartDate(pickerDate);
            setMetaDate(pickerDate);
            setDateBlockOpen(false);
          }}
          minDate={parseDate(props.metaStartDate)}
          maxDate={parseDate(props.metaDueDate)}
          onClickOutside={() => setDateBlockOpen(false)}
          inline
        />
      ) : !date ? (
        <span
          className="flex items-center sidebar-item-selection sidebar-item-selection--empty text-grayBlue btn-transition"
          onClick={() => {
            canUserEditMeta() && setDateBlockOpen(!dateBlockOpen);
          }}
        >
          <CalendarIcon className="mr-2 fill-current stroke-current text-grayBlue" />{" "}
          DD/MM/YYYY
          <DropdownArrow className="ml-auto mr-1 transform rotate-90 fill-current text-grayBlue" />
        </span>
      ) : (
        <div className="flex items-center">
          <span
            className="flex items-center sidebar-item-selection sidebar-item-selection--filled btn-transition"
            onClick={() => {
              canUserEditMeta() && setDateBlockOpen(!dateBlockOpen);
            }}
          >
            <CalendarIcon className="mr-2 fill-current stroke-current text-chillBlue" />{" "}
            {format(parseDate(_date), "dd MMM yyyy")}
            <Can
              I={PERMISSIONS_ACTIONS.MANAGE}
              a={PERMISSIONS_SUBJECTS.METADATA}
            >
              <ClearIcon
                className="w-5 h-5 p-1 ml-auto bg-gray-400 border border-transparent rounded-full cursor-pointer hover:border-darkBlue"
                onClick={(e) => {
                  e.stopPropagation();
                  setStartDate(new Date());
                  setMetaDate(null);
                }}
              />
            </Can>
          </span>
        </div>
      )}
    </div>
  );
};

export default DateComponent;
