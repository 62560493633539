import React from "react";

import { createNewProject } from "api";
import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import { useDispatch, ACTIONS, useTrackedState } from "context";
import { auth } from "firebaseSetup";
import useQuickStartAction from "hooks/useQuickStartAction";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { QUICK_START_STEPS } from "../../quickStartContext";
import { isEmpty } from "../../utils/emptyString";
import Modal, { MODAL_NAMES } from "./Modal";

const CreateNewProject = () => {
  const quickStartAction = useQuickStartAction();

  const [projectName, setProjectName] = React.useState("");
  const [projectCode, setProjectCode] = React.useState("");
  const [autoCreateCode, setAutoCreateCode] = React.useState(true);
  const [user] = useAuthState(auth);
  let history = useHistory();

  const state = useTrackedState();
  const dispatch = useDispatch();

  const createCode = (projectName) => {
    const pieces = projectName.split(" ").filter((word) => word != "");

    const wordSlice = (word, length = 2) => {
      if (word.length < length) {
        return word.toUpperCase();
      } else {
        return word.substring(0, length).toUpperCase();
      }
    };

    if (pieces.length === 1) {
      return wordSlice(pieces[0], 4);
    }

    if (pieces.length === 2) {
      return pieces.reduce((memo, word) => {
        return memo + wordSlice(word, 2);
      }, "");
    }

    if (pieces.length <= 4) {
      return pieces.reduce((memo, word) => {
        return memo + wordSlice(word, 1);
      }, "");
    }

    // More than 5 words
    return pieces.slice(0, 4).reduce((memo, word) => {
      return memo + wordSlice(word, 1);
    }, "");
  };

  const projectNameChanged = (event) => {
    const projectName = event.target.value;
    setProjectName(projectName);
    if (autoCreateCode) {
      setProjectCode(createCode(projectName));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (isEmpty(projectName)) {
      toast.error("Project name cannot be empty");
    } else if (isEmpty(projectCode)) {
      toast.error("Project code cannot be empty");
    } else {
      createNewProject(
        user.uid,
        projectName,
        projectCode,
        history,
        state.activeModalData.blankProject,
        state.editorState,
        dispatch
      );
      quickStartAction(QUICK_START_STEPS.CREATE_PROJECT);
      setProjectName("");
      setProjectCode("");
      setAutoCreateCode(true);
      dispatch({
        type: ACTIONS.CLOSE_MODAL,
      });
    }
  };

  const onClose = () => {
    setProjectName("");
    setProjectCode("");
    setAutoCreateCode(true);
  };

  return (
    <Modal
      name={MODAL_NAMES.CREATE_PROJECT_MODAL}
      closeFunctionCallback={onClose}
    >
      <div className="w-full column-center">
        <div className="w-full px-8 py-4 border-b border-grayBorder">
          <h2 className="text-2xl font-bold text-nearBlack">
            Create a new project
          </h2>
          <div className="flex">
            <CloseIcon
              onClick={() => {
                dispatch({
                  type: ACTIONS.CLOSE_MODAL,
                });
              }}
              className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer text-gray2 fill-current hover:text-nearBlack"
            />
          </div>
        </div>
        <div className="w-full p-6">
          <form onSubmit={onSubmit}>
            <label className="text-primaryText" htmlFor="project_name">
              Project name
            </label>
            <input
              autoFocus
              className="w-full h-10 px-4 py-2 mb-10 mt-3 border rounded"
              type="text"
              name="project_name"
              id="project_name"
              value={projectName}
              onChange={projectNameChanged}
            />

            <label className="text-primaryText" htmlFor="">
              Project code
            </label>
            <input
              className="w-full h-10 px-4 py-2 mb-10 mt-3 border rounded"
              type="text"
              name="project_code"
              id="project_code"
              value={projectCode}
              onChange={(event) => {
                setAutoCreateCode(false);
                setProjectCode(event.target.value);
              }}
            />

            <div className="flex w-full">
              <button className="relative w-24 btn btn-primary" type="submit">
                Create project
              </button>
              <button
                className="relative w-24 mr-5 ml-2 btn btn-danger"
                onClick={() => {
                  setProjectName("");
                  setProjectCode("");
                  setAutoCreateCode(true);
                  dispatch({
                    type: ACTIONS.CLOSE_MODAL,
                  });
                }}
              >
                Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNewProject;
