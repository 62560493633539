import React from "react";

import { useDispatch, useTrackedState, ACTIONS } from "context";
import { getStatusData } from "utils/status";

const SubtaskCard = ({ subtaskName, subtaskStatus, subtaskId }) => {
  const state = useTrackedState();
  const dispatch = useDispatch();

  const statuses =
    state.loadedProjects &&
    state.loadedProjects[state.selectedItemProjectId].statuses;

  const statusColor = getStatusData(statuses, subtaskStatus, "color");

  const color = statusColor ? statusColor : "gray";

  return (
    <div
      onClick={() => {
        dispatch({
          type: ACTIONS.SHOW_ITEM_DETAILS,
          payload: {
            selectedItemId: state.projectItems.find((pi) => pi.id === subtaskId)
              .id,
            sidebarVisible: true,
          },
        });
      }}
      className="flex items-center justify-between w-full px-4 py-2 mt-2 bg-white border rounded-lg shadow-sm cursor-pointer border-gray1 hover:border-darkBlue"
    >
      <p className="max-w-xs">{subtaskName}</p>
      <div className={`rounded-full bg-${color}-600 w-5 h-5`}></div>
    </div>
  );
};

export default SubtaskCard;
