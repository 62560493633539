import { ITEM_TYPES } from "utils/metadataModel";

export function findItem(blockId, projectItems) {
  let item = null;
  item = projectItems.find((item) => item.blockId === blockId);

  return item;
}

export function findTasksFromBucket(blockId, projectItems) {
  const featureItemId = findItem(blockId, projectItems)?.id;
  let tasks = [];
  let bugs = [];
  let adhocs = [];

  projectItems.forEach((pItem) => {
    if (pItem?.featureItemId && pItem.featureItemId === featureItemId) {
      if (pItem.itemType === ITEM_TYPES.SUBTASK) {
        tasks.push(pItem);
      }
      if (pItem.itemType === ITEM_TYPES.BUG) {
        bugs.push(pItem);
      }
      if (pItem.itemType === ITEM_TYPES.ADHOC) {
        adhocs.push(pItem);
      }
    }
  });

  return { tasks: tasks, bugs: bugs, adhocs: adhocs };
}
