import React from "react";

import Editor from "@draft-js-plugins/editor";
import createPlugins from "components/DraftEditor/plugins/createPlugins";
import { useDispatch, ACTIONS } from "context";
import Scrollbars from "react-custom-scrollbars";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/linkify/lib/plugin.css";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { formatDateTime } from "../../utils/date";
import Modal, { MODAL_NAMES } from "./Modal";

const DocumentVersionModal = ({ version = {} }) => {
  const pluginsRef = React.useRef(createPlugins());
  const { editorPlugins } = pluginsRef.current;

  const plugins = [...editorPlugins];
  const dispatch = useDispatch();
  if (version.editorState) {
    const date = formatDateTime(version.createdTs.seconds * 1000);

    return (
      <Modal
        style={{ height: "80%" }}
        name={MODAL_NAMES.DOCUMENT_VERSION_MODAL}
      >
        <div className="flex items-center justify-center w-full h-full px-8">
          <div className="flex flex-col items-start justify-start w-8/12 h-full py-6">
            <div className="flex self-stretch justify-between">
              <h6>Version preview: {version.label}</h6> <h6>{date}</h6>
            </div>

            <Scrollbars autoHide autoHeight autoHeightMin={`calc(100%)`}>
              <div className="prose">
                <Editor
                  editorState={version.editorState}
                  plugins={plugins}
                  readOnly={true}
                />
              </div>
            </Scrollbars>
            <div className="flex">
              <CloseIcon
                onClick={() => {
                  dispatch({
                    type: ACTIONS.CLOSE_MODAL,
                  });
                }}
                className="absolute top-0 right-0 w-6 h-6 mt-6 mr-6 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  } else return null;
};

export default DocumentVersionModal;
