import React, { useEffect } from "react";

import { updateProjectItem } from "api";
import { ReactComponent as EmptyStatus } from "assets/editor-tasks-icons/status.svg";
import { useTrackedState } from "context";
import useOnClickOutside from "hooks/useOnClickOutside";
import { canUserEditMeta } from "utils/permissions";
import { getStatusById, getStatusBySlug } from "utils/status";

import { STATUSES_SLUGS } from "../../../../constants";

const Status = (props) => {
  const [statusBlockOpen, setStatusBlockOpen] = React.useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => setStatusBlockOpen(false));

  const state = useTrackedState();
  const { loadedProjects, selectedItemProjectId, activeProjectId } = state;
  let { editorStateForceSelection, metaStatus, setMetaStatus } = props;

  const statuses =
    loadedProjects && activeProjectId
      ? loadedProjects[activeProjectId].statuses
      : loadedProjects[selectedItemProjectId].statuses;
  const statusData =
    getStatusById(statuses, metaStatus) ||
    getStatusBySlug(statuses, STATUSES_SLUGS.OPEN);

  useEffect(() => {
    setMetaStatus(statusData.id);
  }, [statusData]);

  const color = statusData ? statusData.color : "gray";
  const label = statusData ? statusData.label : "label";

  const filteredStatuses = statuses?.filter((status) => {
    if (status.slug === "DRAFT") return false;

    return true;
  });

  return statusData ? (
    <div className="relative">
      <span
        onClick={(event) => {
          event.stopPropagation();
          canUserEditMeta() && setStatusBlockOpen(!statusBlockOpen);
        }}
        className={`flex items-center justify-center text-tiny text-white uppercase rounded-2xl w-16 py-1 px-2 cursor-pointer btn-transition hover:bg-opacity-75 bg-${color}-600`}
      >
        {label}
      </span>
      {statusBlockOpen && (
        <div ref={ref} className="task-item-dropdown">
          {filteredStatuses.map((status, index) => (
            <div
              onClick={(event) => {
                event.stopPropagation();
                setMetaStatus(status.id);

                updateProjectItem({
                  dispatch: props.dispatch,
                  itemId: props.itemId,
                  projectId: props.activeProjectId,
                  projectItems: props.projectItems,
                  data: { ...props.metadata, status: `${status.id}` },
                });

                //Only relevant if the task is in editor. This function updates editorState to show the change.
                if (editorStateForceSelection) {
                  editorStateForceSelection();
                }

                setStatusBlockOpen(false);
              }}
              className={`task-item-dropdown-option py-2`}
              key={index}
              value={`${status.id}`}
            >
              <div
                className={`w-3 h-3 mr-2 rounded-full bg-${status.color}-600`}
              ></div>{" "}
              <span className="text-dark2">{status.label}</span>
            </div>
          ))}{" "}
        </div>
      )}
    </div>
  ) : (
    <EmptyStatus />
  );
};

export default Status;
