/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import amplitude from "amplitude-js";
import { ReactComponent as DashboardIcon } from "assets/dashboard-icon.svg";
import logo from "assets/logo.svg";
import { ReactComponent as ShareIcon } from "assets/share-icon.svg";
import { MODAL_NAMES } from "components/Modal/Modal";
import QuickStartBar from "components/QuickStart/QuickStartBar";
import UserAvatar from "components/UserAvatar";
import { useDispatch, ACTIONS } from "context";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory, useLocation } from "react-router-dom";
import { TOUR_STAGES, useTourTrackedState } from "tourContext";
import { addAnalyticsEvent, CATEGORIES } from "utils/analytics";
import { isSandbox } from "utils/isSandboxEnv";
import { ReactComponent as SearchIcon } from "assets/search-icon.svg";

import { auth } from "../../firebaseSetup";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Header = ({ isDashboard }) => {
  const [user] = useAuthState(auth);
  let history = useHistory();
  const tourState = useTourTrackedState();
  const location = useLocation();

  const dispatch = useDispatch();

  const [settingsOpen, toggleSettingsOpen] = useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => toggleSettingsOpen(false));

  return (
    <div
      className={`${
        tourState.tourStage === TOUR_STAGES.FINISHED &&
        isSandbox() &&
        location.pathname !== "/"
          ? "relative"
          : "fixed top-0"
      } z-50 flex justify-end items-center w-full pl-12 pr-6 shadow-md h-14 bg-darkBlue`}
    >
      <Link to="/">
        <img className="h-8" src={logo} alt="JadeALM" />
      </Link>
      {user && !isSandbox() && (
        <Link
          to="/dashboard"
          className="flex items-center px-4 py-2 ml-4 mr-auto text-sm text-white bg-yellow-600 transition duration-300 hover:bg-yellow-700 rounded-md group"
        >
          <DashboardIcon fill="white" height="14" className="mr-2" />
          Dashboard
        </Link>
      )}
      {!isDashboard && !isSandbox() && (
        <div className="flex ml-auto">
          <div
            onClick={() => {
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.SEARCH_PROJECT_MODAL,
                  activeModalData: { blankProject: true },
                },
              });
            }}
            className="mx-2 flex h-15 bg-white rounded-md hover:border-darkBlue transition duration-300 w-56 focus-within:border-darkBlue"
          >
            <div className="flex w-15">
              <span className="flex items-center leading-normal bg-white pl-3 border-0 rounded rounded-r-none text-2xl text-gray-600">
                <SearchIcon />
              </span>
            </div>
            <input
              type="button"
              className="h-10 bg-white outline-none pr-8 w-40 text-gray-400"
              value="Search project..."
            />
          </div>
          <button
            className="flex items-center px-4 py-2 mr-4 text-sm text-white rounded-md bg-green1 transition duration-300 hover:bg-opacity-75 group"
            onClick={() =>
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.SHARE_MODAL,
                },
              })
            }
          >
            <ShareIcon className="mr-2" />
            Invite
          </button>
        </div>
      )}
      {!isSandbox() && <QuickStartBar user={user} />}
      <div
        className={`flex items-center ${
          !isDashboard && !isSandbox() && "ml-2"
        }`}
      >
        <div className="m-5 text-base font-medium leading-none text-white">
          {user && user.displayName}
        </div>
        <button
          className="flex items-center max-w-xs text-sm text-white rounded-full focus:outline-none focus:shadow-solid"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          onClick={() => toggleSettingsOpen(!settingsOpen)}
        >
          <div className="w-8 h-8">
            <UserAvatar img={user?.photoURL} />
          </div>
        </button>
      </div>
      {settingsOpen && (
        <div
          className="absolute right-0 w-48 mt-24 origin-top-right shadow-lg"
          ref={ref}
        >
          <div className="py-1 bg-white shadow-xs">
            <a
              href="#"
              className="block px-4 py-2 text-sm text-green-700 hover:bg-gray-100"
              onClick={() => {
                auth.signOut().then(() => {
                  toggleSettingsOpen(false);
                  addAnalyticsEvent(CATEGORIES.AUTH, `Log out success`);

                  amplitude.getInstance().logEvent("log out");
                  /**
                   * Manually re-set the history state for "/"
                   *
                   * Initially referer value is saved for this route state
                   * and it causes unwanted behaviour for re-log in
                   */
                  history.replace("/", {
                    pathname: "/",
                    hash: "",
                    search: "",
                    state: undefined,
                  });

                  // Reset the context and all loaded projects etc
                  dispatch({
                    type: ACTIONS.USER_LOGOUT,
                  });
                });
              }}
            >
              Sign out
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
