import React, { useState, useEffect } from "react";

import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { useDispatch, useTrackedState, ACTIONS } from "context";
import {
  setMetadataStatus,
  setMetadataAssignee,
  setMetadataPriority,
  setMetadataDueDate,
  setMetadataStartDate,
  setMetadataSprint,
  setLabels,
  setMetadataTitleAndContent,
} from "utils/metadataModel";

import EmptyTask from "./EmptyTask";
import TaskAssignee from "./TaskAssignee";
import TaskDate from "./TaskDate";
import TaskLabels from "./TaskLabels";
import TaskPriority from "./TaskPriority";
import TaskSprint from "./TaskSprint";
import TaskStatus from "./TaskStatus";
import TaskTitle from "./TaskTitle";

const SingleTaskComponent = ({ ...props }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { selectedItemId, projectItems, activeProjectId } = state;

  const [metadata, setMetadata] = useState(null);
  const title = metadata && metadata.title ? metadata.title : "";
  const content = metadata ? metadata.htmlContent : null;

  const status = metadata ? metadata.status : null;
  const assignee = metadata ? metadata.assignee : null;
  const priority = metadata && metadata.priority ? metadata.priority : null;
  const sprint = metadata ? metadata.sprint : null;
  const dueDate = metadata ? metadata.dueDate : null;
  const startDate = metadata ? metadata.startDate : null;
  const labels = metadata && metadata.labels ? metadata.labels : null;

  useEffect(() => {
    const _metadata = projectItems.find((pItem) => pItem.id === props.id);

    if (_metadata) {
      setMetadata({ ..._metadata });
      return;
    }
  }, [selectedItemId, projectItems, props.id]);

  const setTitle = (title) => {
    setMetadata(setMetadataTitleAndContent(metadata, title, content));
  };

  function setStatus(status) {
    setMetadata(setMetadataStatus(metadata, status));
  }

  function setAssignee(assignee) {
    setMetadata(setMetadataAssignee(metadata, assignee));
  }

  function setPriority(priority) {
    setMetadata(setMetadataPriority(metadata, priority));
  }

  function setSprint(sprint) {
    setMetadata(setMetadataSprint(metadata, sprint));
  }

  function setMetaDueDate(date) {
    setMetadata(setMetadataDueDate(metadata, date));
  }

  function setMetaStartDate(date) {
    setMetadata(setMetadataStartDate(metadata, date));
  }

  /**
   * @param {array} labelIds an array of IDs where each ID is unique to a certain label
   */
  function setMetaLabels(labelIds) {
    setMetadata(setLabels(metadata, labelIds));
  }

  return !props.isEmpty ? (
    <div
      onClick={(event) => {
        dispatch({
          type: ACTIONS.SHOW_ITEM_DETAILS,
          payload: {
            selectedItemId: props.id,
            sidebarVisible: true,
          },
        });
      }}
      className="cursor-pointer relative w-full p-4 flex flex-col justify-between border shadow-singleTaskComponent rounded-xl"
    >
      <div className="flex justify-between items-center mb-1">
        <TaskTitle
          dispatch={dispatch}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          metadata={metadata}
          activeProjectId={activeProjectId}
          projectItems={projectItems}
          setMetaTitle={setTitle}
          metaTitle={title}
          editorStateForceSelection={props.editorStateForceSelection}
          itemId={props.id}
        />

        {
          // This feature is left out until we implement history
          /* <div className="flex items-center">
          <div className="text-xs ml-4">Last edit: 02/08/2021 by John Doe</div>
        </div> */
        }
      </div>
      <div className="h-full flex justify-between items-center">
        <div className="flex w-full items-center gap-1">
          <TaskStatus
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            setMetaStatus={setStatus}
            metaStatus={status}
            editorStateForceSelection={props.editorStateForceSelection}
            itemId={props.id}
          />
          <TaskAssignee
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            setMetaAssignee={setAssignee}
            metaAssignee={assignee}
            editorStateForceSelection={props.editorStateForceSelection}
            itemId={props.id}
          />
          <TaskPriority
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            editorStateForceSelection={props.editorStateForceSelection}
            setMetaPriority={setPriority}
            metaPriority={priority}
            itemId={props.id}
          />
          <TaskDate
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            editorStateForceSelection={props.editorStateForceSelection}
            metaDueDate={dueDate}
            metaStartDate={startDate}
            setMetaStartDate={setMetaStartDate}
            setMetaDueDate={setMetaDueDate}
            itemId={props.id}
          />
          <TaskSprint
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            editorStateForceSelection={props.editorStateForceSelection}
            setMetaSprint={setSprint}
            metaSprint={sprint}
            itemId={props.id}
          />
          <TaskLabels
            dispatch={dispatch}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            setMetaLabels={setMetaLabels}
            metaLabels={labels}
            metadata={metadata}
            activeProjectId={activeProjectId}
            projectItems={projectItems}
            editorStateForceSelection={props.editorStateForceSelection}
            itemId={props.id}
          />
        </div>

        <div className="absolute right-0 bottom-0 mb-4 mr-4 flex justify-end items-center text-darkGray hover:text-chillBlue">
          <EyeIcon className="cursor-pointer fill-current" />
        </div>
      </div>
    </div>
  ) : (
    <EmptyTask
      {...props}
      isEmpty={props.isEmpty}
      emptyTasks={props.emptyTasks}
      sandboxId
    />
  );
};

export default SingleTaskComponent;
