import React from "react";

import { addBlock, removeBlock } from "@jimmycode/draft-js-toolbox";
import CustomTableComponent from "components/DraftEditor/components/CustomTableComponent";
import TableGrid from "components/DraftEditor/components/TableGrid";
import { useDispatch, ACTIONS } from "context";
import decorateComponentWithProps from "decorate-component-with-props";
import {
  RichUtils,
  ContentBlock,
  EditorState,
  genKey,
  Modifier,
  ContentState,
  SelectionState,
} from "draft-js";
import { Map } from "immutable";

import { ReactComponent as EmbedIcon } from "../../../../assets/embed-24px.svg";

const TABLE_TYPE = "table-cell-type";
const TABLE_GRID_TYPE = "table-grid-type";

function TableButtonComponent(props) {
  const { theme } = props;
  function onClick(event) {
    event.preventDefault();

    props.setEditorState(props.addEmbedder(props.getEditorState()));
  }

  function onMouseDown(event) {
    event.preventDefault();
  }

  return (
    <div className={theme.buttonWrapper} onMouseDown={onMouseDown}>
      <button className={theme.button} onClick={onClick} type="button">
        <EmbedIcon />
      </button>
    </div>
  );
}

export default function ({
  tableType = TABLE_TYPE,
  tableGridType = TABLE_GRID_TYPE,
  options = {},
} = {}) {
  const insertTable = (editorState, size, selection, setEditorState) => {
    if (!selection.isCollapsed()) {
      return null;
    }

    // Important to come back here and find a solution

    // don't insert a table within a table
    // if (
    //   getEditorState()
    //     .getCurrentContent()
    //     .getBlockForKey(selection.getAnchorKey())
    //     .getType() === "table-type"
    // ) {
    //   return null;
    // }

    const defaultCellStyle = {
      border: "1px solid rgba(0, 0, 0, 0.2)",
      padding: "6px",
      "text-align": "center",
    };
    const cols = Array(size.cols).fill(1);
    const tableShape = Array(size.rows)
      .fill(cols)
      .map((row, i) => {
        return {
          [row]: row.map((j) => ({
            element: i === 0 ? "th" : "td",
            style: { ...defaultCellStyle },
          })),
        };
      });

    const tableKey = genKey();
    const newBlocks = [];
    tableShape.forEach((row, i) => {
      Object.values(row)[0].forEach((cell, j) => {
        let data = Map({
          tableKey,
          tablePosition: `${tableKey}-${i}-${j}`,
          "text-align": "center",
        });
        if (i === 0 && j === 0) {
          data = data
            .set("tableShape", tableShape)
            .set("tableStyle", {
              "border-collapse": "collapse",
              margin: "15px 0",
              width: "100%",
            })
            .set("rowStyle", []);
        }
        if (
          i === tableShape.length - 1 &&
          j === Object.values(row)[0].length - 1
        ) {
          data = data.set("isLast", true);
        }
        const newBlock = new ContentBlock({
          key: genKey(),
          type: TABLE_TYPE,
          text: " ",
          data,
        });

        newBlocks.push(newBlock);
      });
    });

    const selectionKey = selection.getAnchorKey();
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.splitBlock(contentState, selection);
    const blockArray = contentState.getBlocksAsArray();
    const currBlock = contentState.getBlockForKey(selectionKey);
    const index = blockArray.findIndex((block) => block === currBlock);
    const isEnd = index === blockArray.length - 1;
    if (blockArray[index]?.getType() === "table-cell-type") {
      newBlocks.unshift(new ContentBlock({ key: genKey() }));
    }
    if (blockArray[index + 1]?.getType() === "table-cell-type") {
      newBlocks.push(new ContentBlock({ key: genKey() }));
    }
    blockArray.splice(index + 1, 0, ...newBlocks);
    if (isEnd) {
      blockArray.push(new ContentBlock({ key: genKey() }));
    }
    const entityMap = contentState.getEntityMap();
    contentState = ContentState.createFromBlockArray(blockArray, entityMap);
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-fragment"
    );
    const key = newBlocks[1].getKey();
    selection = SelectionState.createEmpty(key);

    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    setTimeout(() => {
      setEditorState(newEditorState);
    }, 0);
    return newEditorState;
    // onChange(newEditorState);
  };
  const dispatch = useDispatch();

  /** Add the TableGrid element that will show the input for the table size */
  function addTableGrid(editorState, data = {}) {
    return addBlock(editorState, tableGridType, data);
  }
  /** Add the Table component based on the size provided by TableGridComponent */
  function addTable(editorState, data, selection, setEditorState) {
    return insertTable(editorState, data, selection, setEditorState);
  }

  return {
    onChange: (editorState, action) => {
      const selectedBlock = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getAnchorKey());

      if (selectedBlock.getData().get("tableKey")) {
        editorState = EditorState.forceSelection(
          editorState,
          editorState.getSelection()
        );
      }
      return editorState;
    },
    blockRendererFn: function (block, { getEditorState, setEditorState }) {
      if (block.getType() === tableType) {
        return {
          component: CustomTableComponent,
          editable: true,
          props: {
            editorState: getEditorState(),
            dispatch,
            setEditorState,
          },
        };
      } else if (block.getType() === tableGridType) {
        return {
          component: TableGrid,
          editable: false,
          props: {
            editorState: getEditorState(),

            onCancel: (block) => {
              setEditorState(removeBlock(getEditorState(), block.key));
            },

            onConfirm: (block, size) => {
              let editorState = RichUtils.toggleBlockType(
                getEditorState(),
                tableType
              );
              setEditorState(editorState);
              if (size && size !== "") {
                editorState = addTable(
                  editorState,
                  size,
                  editorState.getSelection(),
                  setEditorState
                );

                editorState = EditorState.forceSelection(
                  editorState,
                  editorState.getSelection()
                );
              }
              setEditorState(editorState);
            },
          },
        };
      }

      return null;
    },

    TableButton: decorateComponentWithProps(TableButtonComponent, {
      tableGridType,
      addTableGrid,
    }),

    addTableGrid,
    addTable,
  };
}
