import { addDays, format, isDate } from "date-fns";

export const getNextWeekday = (date) => {
  var tomorrow = addDays(date, 1);
  return tomorrow.getDay() % 6 ? tomorrow : getNextWeekday(tomorrow);
};

export const getWeekday = (date) => {
  return date.getDay() % 6 ? date : getNextWeekday(date);
};

export function formatDate(date) {
  try {
    return format(date, "dd MMM yyyy");
  } catch (error) {
    return null;
  }
}

export function formatDateTime(date) {
  try {
    return format(date, "dd MMM yyyy HH:mm:ss ");
  } catch (error) {
    return null;
  }
}

export function parseDate(date) {
  // This function is expected to receive either a date instance, string or a firestore timestamp. Return parameter if its date, call a toDate() if it is a timestamp and return null if it's not any of those.
  try {
    if (isDate(date)) {
      return date;
    } else if (typeof date === "string" || date instanceof String) {
      return new Date(date);
    } else {
      return date.toDate();
    }
  } catch (error) {
    return null;
  }
}
