import React from "react";

import { ReactComponent as CreateIcon } from "assets/plus-icon.svg";
import { useDispatch, useTrackedState, ACTIONS } from "context";

import { ITEM_TYPES } from "../../../utils/metadataModel";
import { MODAL_NAMES } from "../../Modal/Modal";
import BugCard from "./BugCard";

const Bugs = ({ status }) => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { projectItems, selectedItemId } = state;
  return (
    <div className="flex flex-col my-10">
      <div className="flex items-center justify-between">
        <h4 className="sidebar-section-title">Bugs</h4>
        <button
          className="p-2 border rounded-lg cursor-pointer text-chillBlue shadow-sidebarItem border-grayBorder hover:border-chillBlue focus:outline-none btn-transition"
          onClick={() => {
            dispatch({
              type: ACTIONS.OPEN_MODAL,
              payload: {
                name: MODAL_NAMES.BUGS_MODAL,
              },
            });
          }}
        >
          <CreateIcon className="fill-current text-chillBlue" />
        </button>
      </div>

      {projectItems.filter(
        (pi) =>
          pi.itemType === ITEM_TYPES.BUG && pi.featureItemId === selectedItemId
      ).length ? (
        <ul className="w-full">
          {projectItems
            .filter(
              (pi) =>
                pi.itemType === ITEM_TYPES.BUG &&
                pi.featureItemId === selectedItemId
            )
            .sort((a, b) => (a.createdTs < b.createdTs ? -1 : 1))
            .map((pi) => {
              return (
                <BugCard
                  bugId={pi.id}
                  bugName={pi.title}
                  bugStatus={pi.status}
                />
              );
            })}
        </ul>
      ) : (
        <p className="mt-2 text-sm text-grayBlue">
          No bugs for now.{" "}
          <a
            onClick={() => {
              dispatch({
                type: ACTIONS.OPEN_MODAL,
                payload: {
                  name: MODAL_NAMES.BUGS_MODAL,
                },
              });
            }}
            className="text-linkBlue"
            href="#"
          >
            Create now
          </a>
        </p>
      )}
    </div>
  );
};

export default Bugs;
