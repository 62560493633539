export const STATUSES_SLUGS = {
  DRAFT: "DRAFT",
  BACKLOG: "BACKLOG",
  OPEN: "OPEN",
  INPROGRESS: "INPROGRESS",
  DONE: "DONE",
  CLOSED: "CLOSED",
};

export const SUGGESTED_LABELS = [
  { value: "dev", label: "dev" },
  { value: "design", label: "design" },
];

export const SPRINT_STATE = {
  OPEN: "OPEN",
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
};

export const PRIORITIES = {
  TRIVIAL: "Trivial",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
};

export const USER_ROLES = {
  OWNER: "OWNER",
};

export const PERMISSIONS_SUBJECTS = {
  METADATA: "metadata",
  EDITOR: "editor",
  USER_PERMISSIONS: "userPermissions",
  PROJECT_SETTINGS: "projectSettings",
};

export const PERMISSIONS_ACTIONS = {
  READ: "read",
  MANAGE: "manage",
};

export const INITIAL_PRIORITIES = [
  { label: PRIORITIES.TRIVIAL, number: 4 },
  { label: PRIORITIES.LOW, number: 3 },
  { label: PRIORITIES.MEDIUM, number: 2 },
  { label: PRIORITIES.HIGH, number: 1 },
];

export const ITEM_HISTORY_CHANGE_TYPES = {
  CHANGE_ITEM_DESCRIPTION: "CHANGE_ITEM_DESCRIPTION",
};

export const NEW_PROJECT_WALKTROUGH_DOCUMENT_CONTENT = {
  blocks: [
    {
      key: "asmrk",
      text: "Your first requirement !",
      type: "header-one",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ltsgt",
      text: "We took the liberty to write it down for you.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

export const NEW_PROJECT_DOCUMENT_CONTENT = {
  blocks: [
    {
      key: "asvrm",
      text: "Welcome to JadeALM !",
      type: "header-one",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "6k6hp",
      text: "If this is your first time using JadeALM take a few moments to go through this document. It will give you a quick overview of basic functionality.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "2s2fg",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 8,
        },
      ],
      data: {},
    },
    {
      key: "e7tdj",
      text: "How JadeALM works",
      type: "header-one",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "em89i",
      text: "This document is the heart of Jade's power. The way you structure your content will have an effect on the tools you use later on. Don't be afraid, you can always change things later on! ",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "22r2b",
      text: 'When you type "/" (slash) you\'ll get a searchable list of content types to select like Headers, Ordered lists, Numbered lists etc..',
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "bijaq",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "fj0ge",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 0,
        },
      ],
      data: {},
    },
    {
      key: "faac3",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "4jqep",
      text: "Selecting a piece of text will show additional formatting options.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "f8ia2",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3qdka",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 1,
        },
      ],
      data: {},
    },
    {
      key: "eba7u",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "22o8j",
      text: "Start small",
      type: "header-two",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ee2tu",
      text: "JadeALM is not about forcing you to sit down for hours and write. Just the oposite we give you the tools that allow you to steadily grow your project's content where and when it's needed.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "8puul",
      text: "Write down things that you need now and come back when you have more information to add.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 32,
          length: 3,
          style: "UNDERLINE",
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: "7qrvn",
      text: "Write up a high level proposal now, collaborate on it and extend it as new knowledge come up!",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "f5tkp",
      text: "Table Of Contents on the left shows you the visual hierarchy of the content and it's updated in real time.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "f166e",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 2,
        },
      ],
      data: {},
    },
    {
      key: "cnrg1",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "9u97s",
      text: "Navigation",
      type: "header-two",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "9h77v",
      text: "On the left you can see different tools for specific aspects of your project's planning and execution.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "2bghg",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 3,
        },
      ],
      data: {},
    },
    {
      key: "8uslk",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "dhuh8",
      text: "Headers are important",
      type: "header-two",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ceibq",
      text: "When describing your project use different levels of Headers to create a structure to your project.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "64ggb",
      text: "There is no real restriction here but if you have an Agile mindset you can think of H1 as Initiatives, H2 as Epics, H3 as Features, and H4, H5, H6 as very granular feature descriptions.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 90,
          length: 11,
          style: "UNDERLINE",
        },
        {
          offset: 109,
          length: 5,
          style: "UNDERLINE",
        },
        {
          offset: 122,
          length: 8,
          style: "UNDERLINE",
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: "3hlsd",
      text: "You can come up with your own of course! And don't be afraid to experiment, the structure can be changed later on",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "f2sl2",
      text: "Tools",
      type: "header-two",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ck1ba",
      text: "The content in Jade is not just content! We use your Document structure to create special tools that help you organise and plan better!",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3fuj1",
      text: "WBS",
      type: "header-three",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "b0lhe",
      text: "Work Breakdown Structure is a high level representation of your project. ",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "1m2qo",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 4,
        },
      ],
      data: {},
    },
    {
      key: "d47ch",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "afdvd",
      text: "Click on the WBS button on the Navigation on the left and see what we mean. Once on WBS click the 'Expand all' button to see the big picture.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ab83l",
      text: "Play around, add a few headers and see how WBS gets updated.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "etmh5",
      text: "Timeline",
      type: "header-three",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "ens13",
      text: "Timeline or Gantt gives you a visual representation of your project roadmap",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "tf1c",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 5,
        },
      ],
      data: {},
    },
    {
      key: "4sc2b",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "46ape",
      text: "Board",
      type: "header-three",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3cqid",
      text: "Board (like Trello or Kanban board) gives you a way to track your development progress per stage.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "10sad",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "5a4ad",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 6,
        },
      ],
      data: {},
    },
    {
      key: "creqp",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "cn524",
      text: "Items",
      type: "header-two",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "24sob",
      text: "You might have noticed Kanban was a bit empty. Let's change that!",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "1osvj",
      text: "Headers can become items!",
      type: "header-three",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "bsb01",
      text: "Stay with us on this one! This is one of the most powerful aspects of Jade and once you get the hang of it you'll think you have superpowers.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "d7l13",
      text: "My awesome feature!",
      type: "header-three",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "50shr",
      text: "Yep, the feature you're about to build is awesome. And we'll show you how.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "69jv0",
      text: "Hover over 'My awesome feature' header and click on the 'Create' link! You now have an Item (think of it as a JIRA ticket) that's linked to this part of text.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "abtcq",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 7,
        },
      ],
      data: {},
    },
    {
      key: "68ud9",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "4qnan",
      text: "Every time you change this text the item's description will update as well!",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "54bcs",
      text: "Go check out your Kanban now, you're gonna see 'My awesome feature' card. You can now transition the card through statuses, assign to people, add start and end date and much more.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "e0lgr",
      text: "Delete my awesome feature :(",
      type: "header-four",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "d4u90",
      text: "Any time an item is linked with a part of the document, that Header can not be deleted. You need to remove the link explicitly by clicking 'Unlink' when you hover over a header. ",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "biljg",
      text: "This will unlink the part of the document with that Item and changes will no longer be synchronized.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "7juqr",
      text: "Go for it",
      type: "header-one",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "a89mv",
      text: "JadeALM gives you a set of powerfull tools to Document, Plan and Execute your project. We've only scratched the surface with what you can do with Jade.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "dd2bt",
      text: "If there's anything that you need help with don't hesitate to reach out to us support@jadealm.com",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "fukvd",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "71t08",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "78elr",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3ei9g",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3m62h",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "9vkpe",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {
    0: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        width: 45,
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2011.51.56.png?alt=media&token=d2b5cf92-943c-4c49-ab3e-52da112580c4",
      },
    },
    1: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.17.06.png?alt=media&token=dfd6971e-3072-4163-ba0f-096cc2298db7",
        width: 74,
      },
    },
    2: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.15.34.png?alt=media&token=b7e6ab4d-ec6a-4c6d-ae65-1bbedbbc0034",
      },
    },
    3: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        width: 9,
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.13.44.png?alt=media&token=56d4515a-46f3-4110-bc88-48eba99a6a4e",
      },
    },
    4: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        width: 100,
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.09.26.png?alt=media&token=5f73fa5b-dfca-4d19-8906-381f8688b376",
      },
    },
    5: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.24.42.png?alt=media&token=663dc65b-2306-4631-bab5-17f420d26cbd",
        width: 100,
      },
    },
    6: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        width: 100,
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.31.40.png?alt=media&token=14782441-7459-4b55-8dec-1d789e1e26bc",
      },
    },
    7: {
      type: "IMAGE",
      mutability: "IMMUTABLE",
      data: {
        src: "https://firebasestorage.googleapis.com/v0/b/codename-manticore.appspot.com/o/projects%2FoTNSks0QOfuxDB2jiSRP%2FScreenshot%202020-10-14%20at%2012.12.34.png?alt=media&token=2585fcba-0aab-45a6-91e6-5fcaeeeca5c3",
        width: 79,
      },
    },
    8: {
      type: "tasksBlock",
      mutability: "IMMUTABLE",
      data: {
        bucketBlockId: "asvrm",
      },
    },
  },
};

export const ALLOWED_FEATURES = {
  SPRINT_MANAGEMENT: "sprintManagement",
};

export const MOCK_MEMBERS = {
  SANDBOX_MEMBER: "sandboxMember",
};
