import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { trackLoginInfo } from "utils/analytics";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(config);

/**
 * This is important. It can happen that firebase save/update
 * will fail because some of the parameters are 'undefined'
 *
 * This can happen for various reasons in plugins that Editor uses where
 * we don't control the data structure.
 *
 * This strips out 'undefined' values before they are
 * saved to firestore.
 */
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});

export const auth = app.auth();
export const db = app.firestore();
export const fbStorage = app.storage();

if (
  process.env.REACT_APP_FIREBASE_DATABASE_URL &&
  process.env.REACT_APP_FIREBASE_DATABASE_URL.search("localhost") >= 0
) {
  // Connecting to local db

  db.settings({
    host: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    ssl: false,
    ignoreUndefinedProperties: true,
  });
}

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/signedIn",
  signInSuccessUrl: "/dashboard",
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      trackLoginInfo(authResult);
      const user = authResult.user;

      if (!user.emailVerified) {
        user.sendEmailVerification();
      }

      // Add/Update user's basic info
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            db.collection("users").doc(user.uid).set({
              name: user.displayName,
              email: user.email,
              id: user.uid,
              img: user.photoURL,
              invitationPending: false,
              quickStartActions: [],
            });
          }
        })
        .catch((error) => {
          // TODO - FIXME!
          // console.log(error);
        });

      return false;
    },
  },
  // TODO: Add other providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

export const firebaseLib = firebase;
