import React from "react";

import { ReactComponent as VerySad } from "assets/very_sad.svg";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../firebaseSetup";

const NoMatch = () => {
  const [user] = useAuthState(auth);
  return (
    <div className="absolute inset-0 w-full flex flex-col items-center justify-center">
      <div className="w-1/4 flex justify-center items-center">
        <VerySad className="w-1/2" />
      </div>
      <p className="w-1/4 text-xl mt-6">
        <span className="mb-2 inline-block">Bummer!</span> <br />
        <span className="mb-2 inline-block">
          {" "}
          You managed to reach a place we have no knowledge of.
        </span>{" "}
        <br />
        Since we aren't really sure what exactly are you looking for, our
        suggestions is that you return to{" "}
        {`${user ? "dashboard" : "landing page."}`}
      </p>

      <a
        href={`${user ? window.origin + "/dashboard" : "https://jadealm.com/"}`}
      >
        <button className="btn btn-primary h-16 mt-6 text-lg">
          Get me out of here!
        </button>
      </a>
    </div>
  );
};

export default NoMatch;
