import React from "react";

import { useHistory } from "react-router-dom";

import { auth } from "../firebaseSetup";
import useIsDesktop from "./useIsDesktop";

function useForceDesktop() {
  const isDesktop = useIsDesktop();
  let history = useHistory();

  if (!isDesktop) {
    // logout the user
    auth.signOut().then((a) => {
      // redirect to root

      history.push("/");
    });
  }
}

export default useForceDesktop;
