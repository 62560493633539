import React from "react";

import { createBlockStyleButton } from "@draft-js-plugins/buttons";

export default createBlockStyleButton({
  blockType: "blockquote",
  children: (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42188 9.6875C2.57292 10.25 1.5651 10.6094 0.398438 10.7656V9.32812C1.13802 9.18229 1.79948 8.95573 2.38281 8.64844C2.96615 8.34115 3.42708 7.95312 3.76562 7.48438C4.10417 7.01562 4.27344 6.47917 4.27344 5.875C4.27344 5.36458 4.09115 5.10938 3.72656 5.10938C3.6224 5.10938 3.42708 5.13542 3.14062 5.1875C2.85417 5.23958 2.6224 5.26562 2.44531 5.26562C1.88281 5.26562 1.36198 5.03906 0.882812 4.58594C0.403646 4.13281 0.164062 3.56771 0.164062 2.89062C0.164062 2.15104 0.416667 1.55729 0.921875 1.10938C1.42708 0.661458 2.01823 0.4375 2.69531 0.4375C3.1849 0.4375 3.6849 0.596354 4.19531 0.914062C4.70573 1.23177 5.13281 1.71875 5.47656 2.375C5.82031 3.03125 5.99219 3.8125 5.99219 4.71875C5.99219 5.76042 5.77604 6.70833 5.34375 7.5625C4.91146 8.41667 4.27083 9.125 3.42188 9.6875ZM13.3672 8.73438C12.3984 9.83854 11.0182 10.5156 9.22656 10.7656V9.32812C9.96615 9.18229 10.6276 8.95573 11.2109 8.64844C11.7943 8.34115 12.2552 7.95312 12.5938 7.48438C12.9323 7.01562 13.1016 6.47917 13.1016 5.875C13.1016 5.36458 12.9193 5.10938 12.5547 5.10938C12.4505 5.10938 12.25 5.13542 11.9531 5.1875C11.6562 5.23958 11.4297 5.26562 11.2734 5.26562C10.7109 5.26562 10.1927 5.03906 9.71875 4.58594C9.24479 4.13281 9.00781 3.56771 9.00781 2.89062C9.00781 2.15104 9.26042 1.55729 9.76562 1.10938C10.2708 0.661458 10.862 0.4375 11.5391 0.4375C12.0286 0.4375 12.526 0.596354 13.0312 0.914062C13.5365 1.23177 13.9609 1.71875 14.3047 2.375C14.6484 3.03125 14.8203 3.8125 14.8203 4.71875C14.8203 6.29167 14.3359 7.63021 13.3672 8.73438Z"
      />
    </svg>
  ),
});
