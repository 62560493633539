import React from "react";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { updateProjectItem } from "api";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as EmptySprint } from "assets/editor-tasks-icons/sprints.svg";
import { ReactComponent as SprintsIcon } from "assets/sprints_icon.svg";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState } from "context";
import useOnClickOutside from "hooks/useOnClickOutside";
import isEmpty from "lodash.isempty";
import { canUserEditMeta } from "utils/permissions";
import ReactTooltip from "react-tooltip";

import {
  PERMISSIONS_SUBJECTS,
  PERMISSIONS_ACTIONS,
} from "../../../../constants";

import "@reach/combobox/styles.css";

const Sprint = (props) => {
  const state = useTrackedState();
  const [sprintBlockOpen, setSprintBlockOpen] = React.useState(false);
  const { loadedProjects, selectedItemProjectId, activeProjectId } = state;

  const ref = React.useRef();

  useOnClickOutside(ref, () => setSprintBlockOpen(false));

  const sprints = selectedItemProjectId
    ? loadedProjects[selectedItemProjectId].sprints
    : loadedProjects[activeProjectId].sprints;

  const { editorStateForceSelection, metaSprint, setMetaSprint } = props;
  return (
    <div className="relative">
      {!metaSprint ? (
        <span
          className={`flex text-darkGray items-center btn-transition text-sm ${
            sprints.length === 0
              ? "opacity-50"
              : "cursor-pointer hover:text-chillBlue"
          }`}
          onClick={(event) => {
            event.stopPropagation();
            canUserEditMeta() &&
              !isEmpty(sprints) &&
              setSprintBlockOpen(!sprintBlockOpen);
          }}
        >
          <ReactTooltip />
          <EmptySprint
            data-tip="Sprint"
            className={`${
              sprintBlockOpen && "text-chillBlue"
            } text-gray-500 stroke-current hover:text-chillBlue`}
          />
        </span>
      ) : (
        <div className="relative flex items-center border border-darkGray group rounded-2xl px-2">
          <span
            className="flex items-center btn-transition  text-darkGray text-xs py-0.5"
            onClick={(event) => {
              event.stopPropagation();
              canUserEditMeta() && setSprintBlockOpen(!sprintBlockOpen);
            }}
          >
            <SprintsIcon className="w-3 mr-1 fill-current stroke-current" />{" "}
            {metaSprint}{" "}
          </span>
          <Can I={PERMISSIONS_ACTIONS.MANAGE} a={PERMISSIONS_SUBJECTS.METADATA}>
            <div className="absolute top-0 right-0 flex items-center justify-center -mr-1 w-3 h-3 bg-white rounded-full shadow-subtle cursor-pointer opacity-0 group-hover:opacity-100 z-50">
              <ClearIcon
                className="text-cloudyGray hover:text-chillBlue fill-current w-2/3"
                onClick={(e) => {
                  e.stopPropagation();
                  setMetaSprint(null);

                  updateProjectItem({
                    dispatch: props.dispatch,
                    itemId: props.itemId,
                    projectId: props.activeProjectId,
                    projectItems: props.projectItems,
                    data: { ...props.metadata, sprint: null },
                  });
                  if (editorStateForceSelection) {
                    editorStateForceSelection();
                  }
                }}
              />
            </div>
          </Can>
        </div>
      )}
      {sprintBlockOpen && (
        <div ref={ref} className="task-item-dropdown mt-8">
          {sprints.map((sprint) => (
            <div
              onClick={(event) => {
                event.stopPropagation();
                setMetaSprint(sprint.name);

                updateProjectItem({
                  dispatch: props.dispatch,
                  itemId: props.itemId,
                  projectId: props.activeProjectId,
                  projectItems: props.projectItems,
                  data: { ...props.metadata, sprint: sprint.name },
                });

                if (editorStateForceSelection) {
                  editorStateForceSelection();
                }
                setSprintBlockOpen(false);
              }}
              key={sprint.id}
              value={`${sprint.name}`}
              className="task-item-dropdown-option"
            >
              {sprint.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sprint;
