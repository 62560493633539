import React, { useEffect } from "react";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import ReadMore from "@crossfield/react-read-more";
import { Parser } from "html-to-react";
import { content } from "tailwindcss/defaultTheme";

const htmlToReactParser = new Parser();

const DetailsContent = ({
  htmlContent,
  editorState,
  isOwnContent,
  isFeature,
}) => {
  const [reactElement, setEditorState] = React.useState(
    htmlToReactParser.parse(htmlContent)
  );
  const [key, setKey] = React.useState(Math.random());

  const isContentShown = checkContentExists(htmlContent);

  React.useEffect(() => {
    setEditorState(htmlToReactParser.parse(htmlContent));
    setKey(Math.random());
  }, [htmlContent]);

  return (
    <ReadMore
      initialHeight={300}
      key={key}
      readMore={(props) => (
        <button
          className={`${
            (isOwnContent || isFeature) && "readLess"
          } block px-6 py-2 mx-auto text-sm bg-white rounded-lg text-grayBlue border border-grayBlue focus:outline-none"
          `}
          onClick={props.onClick}
        >
          {props.open ? "Read Less" : "Read More"}
        </button>
      )}
    >
      <div className={`editor-content ${isFeature && "featureContent"}`}>
        {!isContentShown ? (
          <p className="text-sm text-midGray">
            There is no subsection description yet.
          </p>
        ) : (
          reactElement
        )}
      </div>
    </ReadMore>
  );
};

const checkContentExists = (htmlContent) => {
  const cleanText = htmlContent.replace(/<\/?[^>]+(>|$)/g, "");

  // This regEx removes line breaks
  const noLineBreaks = cleanText.replace(/\r?\n|\r/g, "");

  if (
    // We trim the whitespace from both ends of string
    noLineBreaks?.replace(/&nbsp;/g, " ")?.trim()
  ) {
    //str has text

    return true;
  }
  return false;
};

export default DetailsContent;
