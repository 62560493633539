import React from "react";

import Editor from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";
import Scrollbars from "react-custom-scrollbars";

import {
  createEditorStateHTMLContent,
  createContentStateFromHTML,
} from "../../utils/editor";
import createPlugins from "../DraftEditor/plugins/createPlugins";

const TitleContent = ({
  title,
  setMetadataTitle,
  setMetadataContent,
  activeProjectId,
  content,
  showContentInput = true,
}) => {
  function initialState() {
    if (content) {
      const state = createContentStateFromHTML(content);
      return EditorState.createWithContent(state);
    }
    return EditorState.createEmpty();
  }
  const [workingEditorState, setEditorState] = React.useState(initialState());

  function onChange(newState) {
    setEditorState(newState);

    const htmlContent = createEditorStateHTMLContent(
      newState.getCurrentContent()
    );

    setMetadataContent(htmlContent);
  }

  const pluginsRef = React.useRef(createPlugins());
  const { editorPlugins, instances } = pluginsRef.current;
  const plugins = [...editorPlugins];
  const { SlashToolbarComponent } = instances;

  return (
    <div className="w-full">
      <div className="flex flex-col w-full mb-0 sidebar-item">
        <p className="mb-3 text-primaryText font-bold">Title</p>
        <input
          type="text"
          autoFocus
          name="title"
          id=""
          className="px-4 py-2 border"
          value={title}
          onChange={(event) => {
            setMetadataTitle(event.target.value);
          }}
        />
      </div>
      {showContentInput && (
        <div className="flex flex-col w-full sidebar-item">
          <p className="w-full my-3 text-primaryText font-bold">Description</p>

          <div className="px-4 py-2 border h-84">
            <Scrollbars autoHide autoHeightMin={`calc(100% - 5rem)`}>
              <div className="h-64 prose">
                <Editor
                  placeholder=""
                  editorState={workingEditorState}
                  onChange={onChange}
                  plugins={plugins}
                  activeProjectId={activeProjectId}
                />
              </div>
              <SlashToolbarComponent />
            </Scrollbars>
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleContent;
