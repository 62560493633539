import React from "react";

import { saveEditorState } from "api";
import { useTrackedState, useDispatch, ACTIONS } from "context";
import usePrevious from "hooks/usePrevious";
import useQuickStartAction from "hooks/useQuickStartAction";
import debounce from "lodash.debounce";
import { QUICK_START_STEPS } from "quickStartContext";
import { headerStructureChanged, addedHeader } from "utils/editor";

const debouncedSaveEditorState = debounce(saveEditorState, 2000);
const debouncedSaveHierarchy = debounce(saveEditorHierarchy, 2000);

const useSaveDraftState = () => {
  const {
    editorState,
    projectItems,
    editorLoaded,
    lastFirestoreEditorState,
    activeProjectId,
  } = useTrackedState();

  const dispatch = useDispatch();

  const oldEditorState = usePrevious(editorState);

  const quickStartAction = useQuickStartAction();

  React.useEffect(() => {
    processEditorStateChange({
      activeProjectId,
      editorState,
      oldEditorState,
      projectItems,
      editorLoaded,
      lastFirestoreEditorState,
      quickStartAction,
      dispatch,
    });
  }, [
    editorState,
    oldEditorState,
    activeProjectId,
    lastFirestoreEditorState,
    projectItems,
    editorLoaded,
    quickStartAction,
    dispatch,
  ]);

  return null;
};

function processEditorStateChange({
  editorState,
  activeProjectId,
  oldEditorState,
  projectItems,
  editorLoaded,
  lastFirestoreEditorState,
  quickStartAction,
  dispatch,
}) {
  if (!editorState || !oldEditorState) return;

  const newEditorContent = editorState.getCurrentContent();
  const oldEditorContent = oldEditorState.getCurrentContent();
  const contentChanged = newEditorContent !== oldEditorContent;

  if (contentChanged) {
    if (headerStructureChanged(editorState, oldEditorState)) {
      // Check if modified header is added (created) for quickStart check
      if (addedHeader(editorState, oldEditorState)) {
        quickStartAction(QUICK_START_STEPS.CREATE_HEADER);
      }
      // Update rawContentState and hierarchy state in the context
      debouncedSaveHierarchy(dispatch, editorState);
    }

    // Save the editor state to firestore
    debouncedSaveEditorState(
      activeProjectId,
      editorState,
      lastFirestoreEditorState,
      [...projectItems],
      editorLoaded,
      dispatch
    );
  }
}

function saveEditorHierarchy(dispatch, newEditorState) {
  dispatch({
    type: ACTIONS.RECREATE_HIERARCHY_STATE,
    payload: {
      newEditorState,
    },
  });
}

export default useSaveDraftState;
