import React from "react";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { ReactComponent as DropdownArrow } from "assets/arrow_right.svg";
import { ReactComponent as ClearIcon } from "assets/close-icon.svg";
import { ReactComponent as SprintsIcon } from "assets/sprints_icon.svg";
import { Can } from "components/RoleManagement/Can";
import { useTrackedState } from "context";
import isEmpty from "lodash.isempty";
import { canUserEditMeta } from "utils/permissions";

import { PERMISSIONS_SUBJECTS, PERMISSIONS_ACTIONS } from "../../constants";

import "@reach/combobox/styles.css";

const Sprint = (props) => {
  const state = useTrackedState();
  const [sprintBlockOpen, setSprintBlockOpen] = React.useState(false);
  const { loadedProjects, selectedItemProjectId } = state;

  const sprints = selectedItemProjectId
    ? loadedProjects[selectedItemProjectId].sprints
    : loadedProjects[props.metadata.projectId].sprints;

  const { sprint, setMetaSprint } = props;
  return (
    <div className="sidebar-item">
      <p className="w-3/12 font-normal text-darkBlue">Sprint</p>
      {!sprints ? (
        <b>None available</b>
      ) : sprintBlockOpen ? (
        <Combobox
          openOnFocus
          aria-labelledby="demo"
          onSelect={(selectedItem) => {
            setMetaSprint(selectedItem);

            setSprintBlockOpen(false);
          }}
          onBlur={() => setTimeout(() => setSprintBlockOpen(false), 200)}
        >
          <ComboboxInput
            autoFocus
            placeholder="Search for sprint"
            className="sidebar-item-input"
          />
          <ComboboxPopover style={{ zIndex: "60", borderColor: "transparent" }}>
            <ComboboxList className="sidebar-item-dropdown-list">
              {sprints.map((sprint) => (
                <ComboboxOption
                  key={sprint.id}
                  value={`${sprint.name}`}
                  className="sidebar-item-dropdown-option"
                />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      ) : !sprint ? (
        <span
          className="flex items-center sidebar-item-selection sidebar-item-selection--empty btn-transition"
          onClick={() => {
            canUserEditMeta() &&
              !isEmpty(sprints) &&
              setSprintBlockOpen(!sprintBlockOpen);
          }}
        >
          <SprintsIcon className="mr-2 fill-current stroke-current text-grayBlue" />{" "}
          Choose sprint{" "}
          <DropdownArrow className="ml-auto mr-1 transform rotate-90 fill-current text-grayBlue" />
        </span>
      ) : (
        <div className="flex items-center">
          <span
            className="flex items-center sidebar-item-selection sidebar-item-selection--filled btn-transition"
            onClick={() => {
              canUserEditMeta() && setSprintBlockOpen(!sprintBlockOpen);
            }}
          >
            <SprintsIcon className="mr-2 fill-current stroke-current text-chillBlue" />{" "}
            {sprint}
            <Can
              I={PERMISSIONS_ACTIONS.MANAGE}
              a={PERMISSIONS_SUBJECTS.METADATA}
            >
              <ClearIcon
                className="w-5 h-5 p-1 ml-auto bg-gray-400 border border-transparent rounded-full cursor-pointer hover:border-darkBlue"
                onClick={(e) => {
                  e.stopPropagation();
                  setMetaSprint(null);
                }}
              />
            </Can>
          </span>
        </div>
      )}
    </div>
  );
};

export default Sprint;
