import React from "react";

import userIcon from "assets/account_circle-24px.svg";

const UserAvatar = ({ img, className = "" }) => {
  const source = img ? img : userIcon;

  const css = `w-full rounded-full ${className}`;

  return (
    <img
      src={source}
      alt="User avatar"
      className={css}
      referrerPolicy="no-referrer"
    />
  );
};

export default UserAvatar;
