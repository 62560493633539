import React from "react";

import { getWalktroughProjectData } from "api";
import { ReactComponent as TourCelebrate } from "assets/tour_celebrate.svg";
import { useDispatch, ACTIONS, useTrackedState } from "context";
import { useHistory } from "react-router-dom";
import Tour from "reactour";
import {
  useTourDispatch,
  TOUR_ACTIONS,
  TOUR_STEPS,
  TOUR_STAGES,
  useTourTrackedState,
  showNextButton,
  getMaskClassName,
  getCurrentStepIndex,
} from "tourContext";
import { isEmpty } from "utils/emptyString";
import { isSandbox } from "utils/isSandboxEnv";

const StepTitle = ({ children }) => (
  <h3 className="mb-4 -mt-5 text-xl font-bold">{children}</h3>
);

function clearAnimate() {
  Array.from(document.querySelectorAll(".animate-bounce")).forEach((el) =>
    el.classList.remove("animate-bounce")
  );
}

function addAnimate(node) {
  node.classList.add("animate-bounce");
}

const OnboardingTour = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const tourDispatch = useTourDispatch();
  const tourState = useTourTrackedState();

  let history = useHistory();

  const { tourStage, currentStep } = tourState;
  const steps = [
    {
      selector: ".editor-document",
      content: (
        <div>
          <StepTitle>Document</StepTitle>
          <p>The Jade Document is the heart of your project.</p>
          <p className="mt-6">
            It is the basis from which we build everything else as you will see
            in the next few steps!
          </p>
        </div>
      ),
      position: [50, 50],
    },
    {
      selector: "#editorWrapperTour",
      content: (
        <div>
          <StepTitle>Document</StepTitle>
          <p>
            We've set you up with initial content, but let's walk you through
            the process step by step.
          </p>
        </div>
      ),
      position: [50, 50],
    },
    {
      selector: "[data-offset-key=asmrk-0-0] button",
      content: (
        <div>
          <StepTitle>Requirements</StepTitle>
          <p>
            You can write any kind of content but <b>Headers</b> are special in
            JadeALM.
          </p>

          {/* <p className="mt-6">
            <b>Click</b> on the 'Create' button beneath the Header to create an
            Item directly from the Document!
          </p> */}
        </div>
      ),
      // action: (node) => {
      //   addAnimate(node);

      //   node.onclick = () => {
      //     node.classList.remove("animate-bounce");

      //     setTimeout(() => {
      //       tourDispatch({
      //         type: TOUR_ACTIONS.CURRENT_STEP,
      //         payload: TOUR_STEPS.TOUR_ITEM_SIDEBAR,
      //       });
      //     }, 100);
      //   };
      // },
    },
    {
      selector: "#sandboxTask",
      content: (
        <div>
          <StepTitle>Creating tasks from document</StepTitle>
          <p>Start writing the name of the task you wish to create.</p>
        </div>
      ),
      action: (node) => {
        addAnimate(node);

        node.onkeydown = (e) => {
          if (
            [
              13, 16, 17, 18, 19, 20, 27, 35, 36, 37, 38, 39, 40, 91, 93, 224,
            ].indexOf(e.keyCode) > -1
          ) {
            return;
          }
          node.classList.remove("animate-bounce");

          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_CLICK_TASK,
            });
          }, 100);
        };
      },
    },
    {
      selector: "#sandboxTaskButton",
      content: (
        <div>
          <StepTitle>Creating tasks from document</StepTitle>
          <p>
            When you're done, click on the <b>Create</b> button which will show
            up when you start typing
          </p>
        </div>
      ),
      action: (node) => {
        addAnimate(node);
        // console.log(node);

        node.onclick = () => {
          node.classList.remove("animate-bounce");

          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_ITEM_SIDEBAR,
            });
          }, 100);
        };
      },
    },
    {
      selector: "#taskSidebar",
      content: (
        <div>
          <StepTitle>Task</StepTitle>
          <p>You just created a task directly from the document!</p>
          <p>
            <b>Task details sidebar</b> shows everything related to that Task.
          </p>
        </div>
      ),
    },
    {
      selector: "#assigneeSelect",
      content: (
        <div>
          <StepTitle>Task fields</StepTitle>
          <p>
            Try changing the <b>Assignee</b> to Johnny
          </p>
        </div>
      ),
      action: (node) => {
        addAnimate(node.querySelector("span.sidebar-item-selection"));
      },
    },
    {
      selector: "#sidebarApply",
      content: (
        <div>
          <StepTitle>Save changes</StepTitle>
          <p>
            <b>Apply</b> the changes before closing the Task sidebar.
          </p>
        </div>
      ),
      action: (node) => {
        node.onclick = () => {
          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_PROCEED_WBS,
            });
          }, 10);
        };
      },
    },
    {
      selector: "#tourWBS",
      content: (
        <div>
          <StepTitle>Project Views</StepTitle>
          <p>
            Let's add some perspective! Our task is now automatically synced
            with other project views.
          </p>
          <p className="mt-6">
            <b>Click</b> on the WBS link in project navigation bar.
          </p>
        </div>
      ),

      action: (node) => {
        addAnimate(node);

        node.onclick = () => {
          node.classList.remove("animate-bounce");
          dispatch({
            type: ACTIONS.SHOW_ITEM_DETAILS,
            payload: {
              selectedItemId: null,
              sidebarVisible: false,
            },
          });
          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_VIEW_WBS,
            });
          }, 500);
        };
      },
    },
    {
      selector: "#tourWBSView",
      content: (
        <div>
          <StepTitle>Project Views</StepTitle>
          <p>
            <b>Work Breakdown Structure (WBS)</b> shows you a high level
            overview of your complete project.
          </p>
          <p className="mt-6">
            It's generated automatically from the content in your Document!
          </p>
        </div>
      ),

      position: [200, 200],
    },
    {
      selector: "#tourTimeline",
      content: (
        <div>
          <StepTitle>Timeline</StepTitle>
          <p>
            Roadmapping is a crucial part in your project execution success.
          </p>
          <p className="mt-6">
            Let's check the <b>Timeline</b> view.
          </p>
        </div>
      ),

      action: (node) => {
        addAnimate(node);

        node.onclick = () => {
          node.classList.remove("animate-bounce");
          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_VIEW_TIMELINE,
            });
          }, 500);
        };
      },
    },
    {
      selector: "#tourTimelineView",
      content: (
        <div>
          <StepTitle>Timeline</StepTitle>
          <p>Timeline is auto generated from the Document content!</p>

          <p className="mt-6">
            It's a perfect place to plan and track the project completion
            progress.
          </p>
        </div>
      ),
    },
    {
      selector: "#tourBoard",
      content: (
        <div>
          <StepTitle>Board</StepTitle>
          <p>
            Without the status of our tasks organising projects would be a
            complete mess.
          </p>
          <p className="mt-6">
            Why don’t we pay a visit to Board view to see the statuses of our
            tasks?
          </p>
        </div>
      ),

      action: (node) => {
        addAnimate(node);
        node.onclick = () => {
          node.classList.remove("animate-bounce");

          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.CURRENT_STEP,
              payload: TOUR_STEPS.TOUR_VIEW_KANBAN,
            });
          }, 500);
        };
      },
    },
    {
      selector: "#tourBoardView",
      content: (
        <div>
          <StepTitle>Project Board</StepTitle>
          <p>
            Board gives you an accurate overview of who is doing what and what
            phase certain items are in.
          </p>
          <p className="mt-6">
            It's also a place where you can organise your items to Sprints!
          </p>
        </div>
      ),
      position: "right",
    },
    {
      selector: "#tourBoardView",
      content: (
        <div className="flex flex-col items-center justify-center">
          <TourCelebrate />

          <div>
            <h3 className="mt-6 text-xl font-bold text-center">
              Congratulations!
            </h3>
            <p className="mt-6">
              <span className="inline-block mt-6">
                You now know the basics of JadeALM but there's so much more than
                meets the eye!
              </span>

              <span className="inline-block mt-6">
                You are now free to explore but you are not alone. If you need
                help you can always reach us via support@jadealm.com!
              </span>

              <span className="inline-block mt-6">
                When you are ready go ahead and create an account where you can
                invite people to your project and finally bring everyone to the
                same page!
              </span>
            </p>
          </div>
        </div>
      ),
    },
    // ...
  ];

  return (
    <>
      <Tour
        className="reactour__helper--large"
        accentColor="#45B872"
        steps={steps}
        isOpen={tourStage === TOUR_STAGES.IN_PROGRESS}
        goToStep={getCurrentStepIndex(currentStep)}
        nextStep={() => {
          clearAnimate();
          if (currentStep === TOUR_STEPS.TOUR_PROCEED_INITIAL_CONTENT) {
            getWalktroughProjectData({ dispatch, tourDispatch, history });
          }

          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.NEXT_STEP,
            });
          }, 10);
        }}
        onRequestClose={() => {
          clearAnimate();
          setTimeout(() => {
            tourDispatch({
              type: TOUR_ACTIONS.TOGGLE_TOUR,
              payload: {
                tourStage: TOUR_STAGES.FINISHED,
              },
            });
          }, 1);
          tourDispatch({
            type: TOUR_ACTIONS.CURRENT_STEP,
            payload: TOUR_STEPS.TOUR_JADE_DOCUMENT,
          });
        }}
        disableFocusLock
        maskSpace={currentStep === TOUR_STEPS.TOUR_CHANGE_ASSIGNEE ? 80 : 20}
        closeWithMask={false}
        prevButton={<span className="hidden"></span>}
        nextButton={
          showNextButton(currentStep) ? (
            <div className="flex items-center justify-start ml-auto text-base border-b border-white text-linkBlue2 hover:border-linkBlue2">
              <span>Next</span>
            </div>
          ) : (
            <></>
          )
        }
        maskClassName={getMaskClassName(currentStep)}
        rounded={15}
        disableDotsNavigation
        disableKeyboardNavigation
        showNavigation={false}
        lastStepNextButton={
          <span
            onClick={() => {
              setTimeout(() => {
                tourDispatch({
                  type: TOUR_ACTIONS.TOGGLE_TOUR,
                  payload: {
                    tourStage: TOUR_STAGES.FINISHED,
                  },
                });
              }, 1);
              tourDispatch({
                type: TOUR_ACTIONS.CURRENT_STEP,
                payload: TOUR_STEPS.TOUR_JADE_DOCUMENT,
              });
            }}
            className="btn btn-primary"
          >
            Continue exploring
          </span>
        }
      />
    </>
  );
};

export default OnboardingTour;
