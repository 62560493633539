import React from "react";

import { createInlineStyleButton } from "@draft-js-plugins/buttons";

export default createInlineStyleButton({
  style: "UNDERLINE",
  children: (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4487 14.7153C10.4712 15.0841 9.53125 15.2686 8.62891 15.2686C7.96289 15.2686 7.27718 15.1808 6.57178 15.0054C5.86637 14.8299 5.20931 14.5417 4.60059 14.1406C3.99186 13.7396 3.52995 13.2132 3.21484 12.5615C3.04297 12.2035 2.91048 11.8436 2.81738 11.4819C2.72428 11.1203 2.66878 10.7801 2.65088 10.4614C2.63298 10.1427 2.62402 9.72559 2.62402 9.20996L2.60254 3.14062C2.60254 2.51042 2.479 2.06641 2.23193 1.80859C1.98486 1.55078 1.40658 1.42188 0.49707 1.42188V0.433594H7.84473V1.42188C7.22168 1.42188 6.75619 1.48454 6.44824 1.60986C6.1403 1.73519 5.93978 1.91423 5.84668 2.14697C5.75358 2.37972 5.70703 2.71094 5.70703 3.14062V9.20996C5.70703 9.83301 5.73747 10.3791 5.79834 10.8481C5.85921 11.3172 5.9541 11.7272 6.08301 12.0781C6.3265 12.6582 6.74007 13.1219 7.32373 13.4692C7.90739 13.8166 8.55013 13.9902 9.25195 13.9902C10.0039 13.9902 10.7039 13.8058 11.3521 13.437C12.0002 13.0682 12.4495 12.5902 12.7002 12.0029C12.9007 11.5446 13.0314 11.0701 13.0923 10.5796C13.1532 10.089 13.1836 9.50358 13.1836 8.82324V3.58105C13.1836 3.1084 13.1693 2.75749 13.1406 2.52832C13.112 2.29915 13.0332 2.09684 12.9043 1.92139C12.7754 1.74593 12.5623 1.61882 12.2651 1.54004C11.9679 1.46126 11.5579 1.42188 11.0352 1.42188V0.433594H16.3955V1.42188C15.7438 1.42188 15.2765 1.49707 14.9937 1.64746C14.7108 1.79785 14.5371 2.0127 14.4727 2.29199C14.4082 2.57129 14.376 3.00098 14.376 3.58105V8.95215C14.376 9.94043 14.342 10.6834 14.2739 11.1812C14.2059 11.6789 14.0251 12.182 13.7314 12.6904C13.1872 13.6715 12.4263 14.3465 11.4487 14.7153ZM2 20C1.44772 20 1 20.4477 1 21C1 21.5523 1.44772 22 2 22H16C16.5523 22 17 21.5523 17 21C17 20.4477 16.5523 20 16 20H2Z"
      />
    </svg>
  ),
});
