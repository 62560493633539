import React from "react";

import { createDiscussion, getDiscussions } from "api";
import { useDispatch } from "context";
import { toast } from "react-toastify";

import { isEmpty } from "../../../utils/emptyString";

const CreateDiscussion = ({ selectedItemId }) => {
  const [discussionName, setDiscussionName] = React.useState("");

  const dispatch = useDispatch();

  const onSubmit = (event) => {
    event.preventDefault();
    if (isEmpty(discussionName)) {
      toast.error("Discussion name cannot be empty");
    } else {
      createDiscussion(selectedItemId, discussionName);
      getDiscussions(selectedItemId, dispatch);
      setDiscussionName("");
    }
  };

  return (
    <div className="w-full column-center px-8">
      <div className="w-full my-2">
        <form className="w-full flex flex-col" onSubmit={onSubmit}>
          <input
            className="border border-gray-200 py-2 px-2 w-full rounded-lg outline-none"
            type="text"
            autoFocus
            name="discussion_name"
            id="discussion_name"
            onChange={(e) => setDiscussionName(e.target.value)}
            value={discussionName}
            placeholder="Type here for new discussion...
            "
          />
        </form>
      </div>
    </div>
  );
};

export default CreateDiscussion;
