import React, { Suspense } from "react";

import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import DashboardRestyle from "components/Dashboard/DashboardRestyle";
import NoMatch from "components/NoMatch/NoMatch";
import { AbilityContext, Can } from "components/RoleManagement/Can";
import ability from "components/RoleManagement/defineAbility";
import useForceDesktop from "hooks/useForceDesktop";
import useSnapshotListener from "hooks/useSnapshotListener";
import { useAuthState } from "react-firebase-hooks/auth";
import TagManager from "react-gtm-module";
import { PrerenderedComponent } from "react-prerendered-component";
import { Route, Redirect, Switch } from "react-router-dom";
import "./index.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { PERMISSIONS_ACTIONS, PERMISSIONS_SUBJECTS } from "../src/constants";
import EmailVerification from "./components/EmailVerification";
import Header from "./components/Header";
import Login from "./components/Login";
import ModalsContainer from "./components/Modal/ModalsContainer";
import ProjectEditor from "./components/ProjectEditor";
import ProjectLayout from "./components/ProjectLayout";
import TaskSidebar from "./components/TaskSidebar";
import OnboardingTour from "./components/Tour/OnboardingTour";
import { auth } from "./firebaseSetup";

const prerenderedLoadable = (dynamicImport) => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo((props) => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const WBS = prerenderedLoadable(() => import("./components/WBS/WBS"));
const Kanban = prerenderedLoadable(() => import("./routes/Kanban"));
const Gantt = prerenderedLoadable(() => import("./components/Gantt"));
const ProjectSettings = prerenderedLoadable(() =>
  import("./components/ProjectSettings/ProjectSettings")
);

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
    includeReferrer: true,
  });
}

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH || undefined,
    preview: process.env.REACT_APP_GTM_PREVIEW || undefined,
  };

  TagManager.initialize(tagManagerArgs);
}

const PrivateRoute = ({ children, ...rest }) => {
  const [user, initialising] = useAuthState(auth);
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";

  useForceDesktop();

  /**
   * Moving Header outside route makes it render at all times, we don't want that.
   * This is not a good way of solving this, will have to re-asses to whole
   * application layout issues
   */
  const isRoot = location.pathname === "/";

  return (
    <React.Fragment>
      {!isRoot && !initialising && <Header isDashboard={isDashboard} />}
      <Route
        {...rest}
        render={({ location }) => {
          if (initialising) {
            return null;
          }

          return user ? (
            <React.Fragment>
              {children}

              <TaskSidebar />
            </React.Fragment>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { referer: location },
              }}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

function App() {
  const [user] = useAuthState(auth);

  useSnapshotListener();

  if (user && user.uid) {
    TagManager.dataLayer({ dataLayer: { userId: user.uid } });
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().setUserId(user.uid);
  }
  //  TODO: TEMPORARY ALLOWING ALL USERS
  //  useEffect(() => {
  //   if (user && user.email && process.env.NODE_ENV === "production"
  //     ) {
  //     // Check if user is whitelisted
  //     httpClient
  //       .post("/api/isEmailWhitelisted", {
  //         email: user.email,
  //       })
  //       .catch((e) => auth.signOut());
  //   }
  // }, [user]);

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.

  return (
    <Suspense fallback={<div></div>}>
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <AbilityContext.Provider value={ability}>
          <EmailVerification>
            <OnboardingTour />
            <Switch>
              <PrivateRoute exact path="/dashboard">
                <div className="h-full pt-16">
                  <DashboardRestyle />
                </div>
              </PrivateRoute>
              <PrivateRoute exact path="/project/:projectId/editor">
                <ProjectLayout>
                  <ProjectEditor className="w-full" />
                </ProjectLayout>
              </PrivateRoute>
              <PrivateRoute path="/project/:projectId/kanban">
                <ProjectLayout>
                  <section className="overflow-x-hidden">
                    <Kanban />
                  </section>
                </ProjectLayout>
              </PrivateRoute>
              <PrivateRoute path="/project/:projectId/gantt/">
                <ProjectLayout>
                  <section className="w-full">
                    <Gantt />
                  </section>
                </ProjectLayout>
              </PrivateRoute>
              <PrivateRoute path="/project/:projectId/wbs">
                <ProjectLayout>
                  <section className="w-full">
                    <WBS />
                  </section>
                </ProjectLayout>
              </PrivateRoute>
              <PrivateRoute path="/project/:projectId/settings/">
                <ProjectLayout>
                  <section className="w-full">
                    <Can
                      I={PERMISSIONS_ACTIONS.MANAGE}
                      a={PERMISSIONS_SUBJECTS.PROJECT_SETTINGS}
                    >
                      <ProjectSettings />
                    </Can>
                    <Can
                      not
                      I={PERMISSIONS_ACTIONS.MANAGE}
                      a={PERMISSIONS_SUBJECTS.PROJECT_SETTINGS}
                    >
                      Sorry, you dont have the permission to be here
                    </Can>
                  </section>
                </ProjectLayout>
              </PrivateRoute>
              <Route exact path="/">
                <Login />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
            <ModalsContainer />
          </EmailVerification>
        </AbilityContext.Provider>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
}

function FallbackComponent() {
  toast.error(
    "There was an issue with your last action. The issue has been reported and our busy bees are already looking into it. If the issue persists please contact support@jadealm.com for a more immediate action."
  );

  /**
   * When an uncaught error boundary happens the whole React tree is deleted
   * so rerouting counts for nothing :(
   *
   * So we reload after 3 seconds.
   */
  if (process.env.NODE_ENV === "production") {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  return <Redirect to="/dashboard" />;
}

export default Sentry.withErrorBoundary(App, { fallback: FallbackComponent });
